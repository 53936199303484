<template>
  <div class="ui-input-editable__wrapper">
    <span class="ui-input-editable__content" v-if="!editMode" @click="select">
      {{ value }}
    </span>
    <input
      ref="input"
      :class="['ui-input-editable__input', inputClass]"
      type="text"
      :value="value"
      @blur="onBlur"
      @keydown.enter="onBlur"
      v-else
    />
    <ButtonIconOnly
      class="ui-input-editable__edit-btn"
      icon="edit"
      :id="`${uuid}-input-editable`"
      @click-handler="edit"
      v-show="!editMode"
    >
      <VisibleText> edit topic title</VisibleText>
    </ButtonIconOnly>
  </div>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

export default {
  name: "InputEditable",
  props: ["value", "inputClass", "isFocused", "select", "uuid"],
  components: {
    ButtonIconOnly
  },
  data() {
    return {
      editMode: false,
      oldValue: null
    }
  },
  mounted() {
    if (this.$props.isFocused) {
      setTimeout(
        function() {
          this.edit()
        }.bind(this),
        500
      )
    }
    this.oldValue = this.$props.value.trim()
  },
  methods: {
    edit($e) {
      this.editMode = true
      this.$nextTick(() => this.$refs.input.focus())
      $e.stopPropagation()
    },
    onBlur: function(event) {
      const newValue = event.target.value.trim()
      if (this.oldValue !== newValue) {
        this.$emit("update", newValue)
        this.oldValue = newValue
      }
      this.editMode = false
    }
  }
}
</script>
