<template>
  <form @submit.prevent="searchOnSubmit" class="forms__search">
    <label for="search-input" class="forms__search-label">
      <slot name="label">
        <SvgIconDecorative icon="search" />
        <span class="sr-only"><VisibleText>search</VisibleText></span>
      </slot>
    </label>
    <input
      type="text"
      name="search"
      id="search-input"
      class="forms__search-input visible-text__placeholder"
      v-on:input="search"
      :placeholder="visiblePlaceholder"
    />
  </form>
</template>

<script>
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "SearchBar",
  components: {
    SvgIconDecorative
  },
  props: {
    placeholder: {
      type: String,
      required: false
    }
  },
  computed: {
    visiblePlaceholder() {
      return this.checkTranslationCache(
        this.placeholder,
        "search",
        this.$store.getters.getLanguage
      )
    }
  },
  methods: {
    search($event) {
      this.$emit("search", $event.target.value)
    },
    searchOnSubmit() {
      this.$emit("search", document.getElementById("search-input").value)
    }
  }
}
</script>
