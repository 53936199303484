<template>
  <MessageBlock class="banner-msg" message-type="warning">
    Your account is pending verification. Some features may be unavailable. We
    will contact you by email once we have verified your organization's details.
  </MessageBlock>
</template>

<script>
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

export default {
  name: "AccountNotVerifiedMessage",
  components: {
    MessageBlock
  }
}
</script>
