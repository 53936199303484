import DriversService from "@/services/driversService.js"

export default {
  props: {
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      DRIVERS_SERVICE: new DriversService(this.$pigeonline),
      dependentVariableTitle: "Overall Satisfaction",
      chartData: null,
      matrixNode: null,
      isLoadingChartData: false
    }
  },
  methods: {
    getDependentVarTitle(questionID) {
      // if global match, return match title
      const matchesDetails = this.$store.getters[
        "datasetDetails/getMatchesDetails"
      ]
      const clientQs = this.$store.getters["datasetDetails/getClientQuestions"]
      if (Object.keys(matchesDetails).includes(questionID))
        return matchesDetails[questionID].global_question_title

      // no global match, return concat of question title and quesiton text
      const question = clientQs.find(q => q._id.$oid === questionID)
      const questionText =
        question.question_text.length > 0 ? `- ${question.question_text}` : ""
      return `${question.question_title} ${questionText}`
    },
    /**
     * Fetch drivers analysis data to generate drivers analysis charts.
     * @param {Array} datasetIDs array of dataset IDs
     * @param {Object} dependentIDs {<data_set_id> : <dependent_variable_id>}
     * @param {Object} independentIDs {<data_set_id>: [list of independent variables]}
     * @returns {Array} 2d array where each item is data for client_questions (independent vairbales),
     * except the last item which describes segment data (when running drivers analysis for multiple datasets)
     * see DRIVERS_SERVICE.driversAnalysis for sample response.
     */
    async fetchDriversAnalysis(datasetIDs, dependentIDs, independentIDs) {
      try {
        const response = await this.DRIVERS_SERVICE.driversAnalysis({
          data_set_ids: datasetIDs,
          dependent_variable_ids: dependentIDs,
          independent_variables_ids: independentIDs
        })
        return response.data
      } catch (e) {
        //TODO: better error handling mechanisms
        if (e) {
          return { err: true, errObj: e }
        } else {
          throw new Error("ProjectAnalysisDrivers:fetchDriversAnalysis " + e)
        }
      }
    },
    async fetchChartData(datasetID, dependentIDs, independentIDs) {
      let datasetToDepVariable = {},
        datasetToIndVariable = {}
      datasetToDepVariable[datasetID] = dependentIDs
      datasetToIndVariable[datasetID] = independentIDs

      const chartData = await this.fetchDriversAnalysis(
        [datasetID],
        datasetToDepVariable,
        datasetToIndVariable
      )

      return chartData
    }
  }
}
