<template>
  <div>
    <Table
      class="datasets__table"
      :caption="'Datasets'"
      :hide-caption="true"
      :row-data="filteredDatasets"
      :column-headers="columnHeaders"
      :sortable="sortable"
    >
      <template slot-scope="{ row }">
        <td class="td-dataset-select">
          <input
            type="checkbox"
            class="datasets__table-checkbox"
            name="dataset"
            :id="`${row.id}`"
            :value="row.id"
            @change="onChangeSelectDataset(row, $event)"
          />
          <label :for="`${row.id}`" class="sr-only">
            <VisibleText>select </VisibleText>{{ row.name }}
          </label>
        </td>
        <td class="td-dataset-name" data-label="dataset name">
          <router-link
            class="datasets__table-name"
            :to="`/dataset/${row.dataset_id}?project_id=${row.id}`"
          >
            {{ row.name }}
          </router-link>
          <span
            class="datasets__table-shared-status"
            v-if="checkIsReadOnly(row)"
          >
            <SvgIconMeaningful icon="eye" title="read only" />
          </span>
          <span
            class="datasets__table-shared-status"
            v-if="getDatasetSharedStatus(row) === 'shared'"
          >
            <SvgIconMeaningful icon="shared" title="shared" />
          </span>
        </td>
        <td data-label="owner">
          {{ getOwner(row) }}
        </td>
        <td data-label="file type">
          {{ row.file_type }}
        </td>
        <td data-label="last modified">
          {{ row.last_modified_on.$date | formatDate }}
        </td>
        <td data-label="status">
          <span
            class="status"
            :class="row.currStatusText() | replaceSpaceWithDash"
            v-if="!row.isBenchmarksPending()"
          >
            {{ row.currStatusText() }}
          </span>
          <details class="datasets__table-status-details" v-else>
            <summary class="status pending">
              {{ row.currStatusText() }}
            </summary>
            <p><VisibleText>Benchmarks are pending approval</VisibleText></p>
          </details>
        </td>
        <td>
          <button
            class="datasets__table-share-btn"
            @click="shareDataset(row)"
            v-if="isDatasetOwner(row)"
          >
            <SvgIconDecorative icon="share" />
            <VisibleText>Share</VisibleText>
          </button>
        </td>
      </template>
    </Table>
  </div>
</template>

<script>
// Componenets
import Table from "@/components/UI/Table.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"

// Models
import BenchmarkingDataset from "@/models/BenchmarkingDatasetModel"

// Mixins
import SharedStatusMixin from "@/utils/mixins/sharedStatusMixin.js"

// Services
import BenchmarkingService from "@/services/benchmarkingService.js"
import ProfilesService from "@/services/profilesService.js"

// Helpers
import { mapGetters, mapActions } from "vuex"

export default {
  name: "DatasetsTable",
  mixins: [SharedStatusMixin],
  components: {
    Table,
    SvgIconDecorative,
    SvgIconMeaningful
  },
  props: {
    queryTerm: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      BENCHMARKING_SERVICE: new BenchmarkingService(this.$pigeonline),
      PROFILES_SERVICE: new ProfilesService(this.$pigeonline),
      BenchmarkingDataset: BenchmarkingDataset,
      columnHeaders: [
        "",
        "Dataset name",
        "Owner",
        "File type",
        "Last modified",
        "Status",
        ""
      ],
      sortable: { Date: "sortDate" },
      datasets: [],
      users: [],
      selected: []
    }
  },
  async created() {
    this.setLoading(true)
    this.loadData().then(async ({ datasets, users }) => {
      this.datasets = datasets
      this.users = users
      this.fetchBenchmarkUpdates()
      this.setLoading(false)
    })
  },
  computed: {
    ...mapGetters("user", {
      profile: "getProfile"
    }),
    filteredDatasets() {
      const keys = ["name"]
      return this.$options.filters.searchInObjectValues(
        this.datasets,
        keys,
        this.queryTerm
      )
    }
  },
  methods: {
    ...mapActions("loader", ["setLoading"]),
    async loadData() {
      const [datasets, users] = await Promise.all([
        await this.$pigeonline.projects.get(BenchmarkingDataset),
        await this.PROFILES_SERVICE.users()
      ])
      return { datasets, users }
    },
    fetchBenchmarkUpdates() {
      this.datasets.forEach(async datasetProject => {
        // not in progress, skip it
        if (datasetProject.currStatusText() != "in progress") return
        // benchmarks are sent & its in progress, check if benchmarks were approved
        if (datasetProject.status.benchmarksSent) {
          const benchmarks = await this.BENCHMARKING_SERVICE.benchmarking({
            client_data_set_id: datasetProject.dataset_id,
            approved: "False"
          })
          // all benchmarks are approved, set it to complete
          if (benchmarks.length == 0) {
            datasetProject.updateStatus("benchmarksApproved")
            this.$pigeonline.projects.update(datasetProject)
          }
        }
      })
    },
    getDatasetSharedStatus(dataset) {
      const shared = dataset.shared && dataset.shared.map(r => r.pk)
      const access = dataset.access_type
      if (access && access == "public__all") return "public"
      if (access && access == "public__org") return "organization"
      if (access && access == "demo") return "demo"
      if (shared.length > 0) return "shared"
      return "private"
    },
    getSelectedDatasetIndex(projectID) {
      return this.selected.map(dataset => dataset.project_id).indexOf(projectID)
    },
    getOwner(dataset) {
      let owner = ""
      if (this.users.length == 0) return owner
      if (this.isDatasetOwner(dataset)) return "me"
      const user = this.users.find(user => user.id === dataset.owner)
      if (!user) return ""
      return user.fullname || ""
    },
    isDatasetOwner(dataset) {
      return dataset.owner === this.profile.django_ref_id
    },
    onChangeSelectDataset(row, $event) {
      if ($event.target.checked) {
        this.selected.push({
          project_id: row.id,
          dataset_id: row.dataset_id,
          dataset_proj: row
        })
      } else if (this.getSelectedDatasetIndex(row.id) > -1) {
        let indx = this.getSelectedDatasetIndex(row.id)
        this.selected.splice(indx, 1)
      }
    },
    shareDataset(dataset) {
      this.$emit("share-dataset", dataset)
    }
  },
  watch: {
    selected: function() {
      this.$emit("updateSelected", this.selected)
    }
  }
}
</script>
