const section1 = () => {
  return [
    {
      type: "heading",
      content: "1. CMT INTRODUCTION"
    },
    {
      type: "text",
      content:
        "The Common Measurements  Tool  (CMT)  is  an  inter-jurisdictional \
      tool for designing client satisfaction surveys in the public sector. \
      By using the questions set out in the CMT, organizations can compare \
      their results against  peer  organizations,  identifying  good  \
      practices  and  sharing lessons learned."
    },
    {
      type: "text",
      content:
        "This  benchmarking  report  is  based  on  client  satisfaction  survey \
      results that you submitted to the Institute for Citizen-Centred Service \
      (ICCS),  and  compares  these  survey  results  with  selected  peer \
      groups.  It  is  hoped  that  this  report  will  supplement  your  own \
      analysis,  providing  you  with  valuable  insights  that  you  can  use  in \
      your service improvement plan."
    },
    {
      type: "text",
      content:
        "The CMT Benchmarking report is divided into section as follows: <br><br> \
      - Benchmarking Summary <br> \
      -  Question-by-Question Results and Benchmarking <br> \
      (including the benchmarking criteria identifying the <br> \
      criteria used to select the benchmark groups) <br> \
      - Priorities for Service Improvement (present only if \
        important/satisfied paired questions were asked) <br> \
      - Survey descriptions"
    },
    {
      type: "heading",
      content: "1.1 Benchmarking Summary"
    },
    {
      type: "text",
      content:
        "The  Benchmarking  Summary  provides  an  overall  average  score  for \
      each  CMT  question  selected  for  benchmarking,  and  compares  the \
      score of your organization to each benchmarking group selected. In \
      the  Question-by-Question  Results  and  Benchmarking  section,  these \
      results will be expanded."
    },
    {
      type: "heading",
      content: "1.2 Question-by-Question Results and Benchmarking"
    },
    {
      type: "heading",
      content: "1.2.1 Question-by-Question Results"
    },
    {
      type: "text",
      content:
        "For each CMT question you included in your client survey, the report \
      provides  basic  summary  information  about  how  your  clients \
      responded.  For  example,  the  report  provides  the  number  of  clients \
      who  answered  the  question,  the  average  rating  they  gave  your \
      organization, and a graph illustrating the distribution of these ratings. \
      This  section  of  the  report  provides  only  information  about  your \
      survey, and does not contain any comparison information."
    },
    {
      type: "heading",
      content: "1.2.2 Benchmarking"
    },
    {
      type: "text",
      content:
        "For each CMT question you included in your client survey, the report \
      then provides benchmarking information against up to three selected \
      peer  groups.  For  example,  the  report  shows  you  how  your  rating \
      compares with the highest and lowest ratings in that peer group. A \
      percentile score and ranking are also provided so that you can see \
      how many other organizations received ratings both above and below \
      your organization. An overall average score of all the organizations is \
      calculated, which gives a general indication of that aspect of service \
      delivery across all organizations."
    }
  ]
}

export default section1
