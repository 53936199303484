var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-modal__dataset-wrapper"},_vm._l((_vm.datasets),function(dataset){return _c('label',{key:dataset.id,class:[
      'form-radio-label',
      'create-modal__dataset-label',
      _vm.selectedDataset === dataset.dataset_id ? 'selected' : ''
    ],attrs:{"for":dataset.id}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedDataset),expression:"selectedDataset"}],staticClass:"form-radio-input create-modal__dataset-input",attrs:{"type":"radio","name":dataset.name,"id":dataset.id,"disabled":dataset.currStatusText() == 'in progress'},domProps:{"value":dataset.dataset_id,"checked":_vm._q(_vm.selectedDataset,dataset.dataset_id)},on:{"change":function($event){_vm.selectedDataset=dataset.dataset_id}}}),_c('span',{staticClass:"form-radio-selector create-modal__dataset-selector"}),_c('Card',{class:[
        'create-modal__dataset-info',
        { disabled: dataset.currStatusText() === 'in progress' }
      ],attrs:{"title-tag":"span","sub-title-tag":"span"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(dataset.name)+" ")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(dataset.last_modified_on.$date))+" ")]},proxy:true}],null,true)},[_c('span',{staticClass:"status create-modal__dataset-status",class:_vm._f("replaceSpaceWithDash")(dataset.currStatusText())},[_vm._v(" "+_vm._s(dataset.currStatusText())+" ")])])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }