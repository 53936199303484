<template>
  <Modal
    class="set-performance-measure"
    uid="set-performance-measure-modal"
    :key="updateKey"
    :show="show"
    :reset-listener="resetListener"
    @close="$emit('close-modal')"
  >
    <template v-slot:header>
      Select performance measures
    </template>
    <p class="set-performance-measure__hint">
      Set a question that you want to track as a key measure of performance
    </p>
    <AddQuestions
      ref="add-questions"
      :questions-list="tableDataPreprocessed"
      @updated="handleAddQuestionsUpdate"
    />
    <template v-slot:footer>
      <button
        class="set-performance-measure__save-btn"
        @click="savePerformanceMeasure"
        :disabled="isSavePerformanceMeasureDisabled"
      >
        <SvgIconDecorative
          icon="check"
          class="set-performance-measure__save-btn-icon"
        />
        Save measure
      </button>
      <IndexStrength
        :dataset-id="datasetId"
        :client-questions-ids="selectedClientQuestionsIds"
      />
    </template>
  </Modal>
</template>

<script>
// Components
import Modal from "@/components/UI/Modal.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import AddQuestions from "../Components/AddQuestions.vue"
import IndexStrength from "../Components/IndexStrength.vue"

// Models
import BenchmarkingProject from "@/models/BenchmarkingProjectModel"

// Libraries
import _ from "lodash"

export default {
  name: "SetPerformanceMeasure",
  components: {
    Modal,
    SvgIconDecorative,
    AddQuestions,
    IndexStrength
  },
  props: {
    project: {
      type: BenchmarkingProject
    },
    datasetId: {
      type: String
    },
    tableData: {
      type: Array,
      default: () => []
    },
    show: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      tableDataPreprocessed: [],
      selectedClientQuestionsIds: [],
      isSavePerformanceMeasureDisabled: true,
      updateKey: Date.now(),
      resetListener: false
    }
  },
  mounted() {
    this.preprocessTableData()
  },
  methods: {
    preprocessTableData() {
      this.tableDataPreprocessed = _.cloneDeep(this.tableData)
        .map(
          function(el, index) {
            if (!el.id) el.id = el._id.$oid
            if (!el.options) el.options = {}
            el.options.hidden = !(
              el.approved_data_type &&
              el.approved_data_type.match(/(SCALE_\d+_TO_\d+)+/)
            )
            el.options.selected =
              (this.project.settings.performanceMeasure &&
                this.project.settings.performanceMeasure.clientQuestionsIds &&
                this.project.settings.performanceMeasure.clientQuestionsIds.includes(
                  el.id
                )) ||
              false
            el.options.sortIndex = index
            return el
          }.bind(this)
        )
        .filter(el => !el.options.hidden)
    },
    handleAddQuestionsUpdate() {
      this.isSavePerformanceMeasureDisabled =
        this.$refs["add-questions"].questionsSelected.length == 0
      this.selectedClientQuestionsIds = this.$refs[
        "add-questions"
      ].questionsSelected.map(q => q._id.$oid)
    },
    savePerformanceMeasure() {
      this.$emit("save-performance-measure", this.selectedClientQuestionsIds)
    }
  },
  watch: {
    show: function(val) {
      if (!val) return
      this.isSavePerformanceMeasureDisabled = true
      this.updateKey = Date.now()
      this.resetListener = !this.resetListener
      this.preprocessTableData()
    }
  }
}
</script>
