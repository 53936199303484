<template>
  <nav aria-label="dataset" id="dataset-progress">
    <ol class="dataset-progress__list">
      <li>
        <button
          class="dataset-progress__view-btn"
          @click="backToDataset"
          :disabled="!$store.getters['datasetDetails/getDataset']"
        >
          <TableIcon class="table-icon" />
          <span class="title">
            <VisibleText>View Dataset</VisibleText>
          </span>
          <span class="subtitle">
            <VisibleText>Go to data table view.</VisibleText>
          </span>
        </button>
      </li>
      <li
        v-for="item in items"
        :key="item.name"
        :class="[
          'dataset-progress__list-item',
          {
            'dataset-progress__list-item-active':
              activeSection === item.component
          }
        ]"
      >
        <button
          class="dataset-progress__list-item-btn"
          :disabled="isDisabled(item)"
          @click="goToSection(item)"
        >
          <span class="dataset-progress__list-item-title">
            {{ item.name }}
          </span>
          <span class="dataset-progress__list-item-step">
            {{ item.step }}
            <HasPermission to="benchmarking-group-select">
              <template v-if="item.optional">
                (
                <VisibleText>optional</VisibleText>
                )
              </template>
            </HasPermission>
          </span>
          <span
            :class="[
              'dataset-progress__list-item-status',
              progressClassName(item)
            ]"
          >
            {{ sectionProgress(item) }}
          </span>
        </button>
      </li>
    </ol>
  </nav>
</template>

<script>
import TableIcon from "@/assets/icons/Datasets/table.svg"

// Mixin
import DatasetMixin from "@/utils/mixins/datasetMixin.js"

export default {
  name: "DatasetProgressList",
  mixins: [DatasetMixin],
  components: {
    TableIcon
  },
  props: {
    items: {
      type: Array
    }
  },
  methods: {
    backToDataset() {
      this.setActiveSection("DatasetTableView")
    },
    isDisabled(item) {
      return (
        item.component !== "DatasetUploader" && !this.datasetProject.dataset_id
      )
    },
    sectionProgress(item) {
      let status = "incomplete"
      if (item.completed) status = "completed"
      else if (item.skipped) status = "skipped"
      else if (this.activeSection === item.component) status = "in progress"
      return status
    },
    progressClassName(item) {
      let className = "incomplete"
      if (item.completed) className = "completed"
      else if (item.skipped) className = "skipped"
      else if (this.activeSection === item.component) className = "inprogress"
      return className
    },
    goToSection(item) {
      this.setActiveSection(item.component)
    }
  }
}
</script>
