const transpose = m => m[0].map((x, i) => m.map(x => x[i]))

/**
 * Create chart data for pigeondoc chart
 * @param {Object} data comaprision analysis chart data
 * @returns style information for table data
 */
const getChartData = (chartLegend, data, categoryID, categoryValues) => {
  let columnNames = [""]
  let rowNames = []
  let catData = []
  const isFilterActive =
    categoryValues && Array.isArray(categoryValues) && categoryValues.length > 0

  for (let key in data) {
    let item = data[key]
    let questionText = item.client_question_analysis.question_text
    let catFiltered = item.analysis_by_categories[categoryID].mean_values
      .filter(el =>
        isFilterActive ? categoryValues.includes(el.cat_value) : true
      )
      .sort((a, b) =>
        new String(a.cat_value).localeCompare(new String(b.cat_value))
      )
    const isGreaterThan = mean =>
      mean >= item.client_question_analysis.overall_mean
    let catValues = catFiltered.map(el =>
      el.is_outlier
        ? {
            value: `${(el.mean || 0).toFixed(1)}<span class="sr-only">${
              isGreaterThan(el.mean)
                ? " (greater than or equal to mean)"
                : " (lower than mean)"
            }</span>`,
            style: {
              ...chartLegend[isGreaterThan(el.mean) ? "positive" : "negative"]
                .style
            },
            info: el.outlier_reason
          }
        : (el.mean || 0).toFixed(1)
    )

    if (rowNames.length === 0) {
      rowNames = catFiltered.map(el => el.cat_value)
    }

    columnNames.push(questionText)
    catData.push(catValues)
  }

  return {
    columnNames: columnNames,
    rowNames: rowNames,
    data: transpose(catData)
  }
}

/*******************************
 *
 * Generate nodes required for comaprision analysis chart
 * @param {Object} data comaprision analysis chart data
 * @returns {Object} pigeondoc table node
 *
 ******************************/
const ComparisonAnalysisChart = (
  chartType,
  chartLegend,
  data,
  categoryID,
  categoryName,
  categoryValues
) => {
  const chartData = getChartData(
    chartLegend,
    data.summary_table,
    categoryID,
    categoryValues
  )
  const chart = {
    type: chartType.toLowerCase(),
    content: {
      data: chartData
    },
    meta: {
      caption: categoryName
    }
  }
  return chart
}

export default ComparisonAnalysisChart
