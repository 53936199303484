<template>
  <div class="drivers__chart-container">
    <LoadingSpinner
      class="drivers__chart-spinner"
      :component-style="true"
      :isLoading="isLoading"
    />
    <template v-if="!error && chartData">
      <p class="drivers__chart-desc">
        <VisibleText>To compute this driver we used:</VisibleText>
        <Tooltip
          class="drivers__info"
          :text-visible="isInfoTooltipOpen"
          @close="isInfoTooltipOpen = false"
        >
          <template #title>
            <button
              class="drivers__regression-btn"
              @click="isInfoTooltipOpen = true"
            >
              {{
                chartData.estimator_name
                  ? chartData.estimator_name.replace(/_/g, " ")
                  : "regression"
              }}
              <SvgIconDecorative
                icon="info"
                class="drivers__regression-btn-icon"
                title="details"
              />
            </button>
          </template>
          <VisibleText>How is drivers analysis computed?</VisibleText>
          <p class="drivers__info-text">{{ chartData.estimator_summary }}</p>
        </Tooltip>
        {{ chartData.dynamic_summary.drivers_summary }}
        <VisibleText
          >The following chart shows each driver and their relative importance
          to each other.
        </VisibleText>
      </p>
      <DriversChart
        type="HorizontalBarChart"
        :chart-node="chartNode"
        :key="chartNodeKey"
      />
      <p class="drivers__chart-desc">
        {{ chartData.dynamic_summary.priority_matrix_summary }}
      </p>
      <DriversChart
        type="PriorityMatrix"
        :chart-node="matrixNode"
        :key="matrixNodeKey"
      />
    </template>
    <template v-else>
      <p class="error-msg__inline" style="text-align: center; width: 100%">
        {{ errorMsg }}
      </p>
    </template>
  </div>
</template>

<script>
// Components
import DriversChart from "./ProjectAnalysisDriversChart.vue"
import LoadingSpinner from "@/components/UI/Spinner.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import Tooltip from "@/components/UI/Tooltip.vue"

// Helper
import { PigeonDocNodeModel } from "@pigeonline/pigeondoc"
import DriversAnalysisChart from "@/components/Reports/helpers/driversAnalysisChart.js"
import PriorityMatrixChart from "@/components/Reports/helpers/priorityMatrixChart.js"

export default {
  name: "ProjectAnalysisDriversCharts",
  props: {
    dependentVariableTitle: {
      default: () => "Overall Satisfaction",
      type: String
    },
    chartData: {
      type: [Object, Number],
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DriversChart,
    LoadingSpinner,
    SvgIconDecorative,
    Tooltip
  },
  data() {
    return {
      chartNode: null,
      chartNodeKey: 0,
      error: false,
      errorMsg: null,
      matrixNode: null,
      matrixNodeKey: 1,
      isInfoTooltipOpen: false
    }
  },
  watch: {
    chartData: {
      immediate: true,
      handler() {
        if (this.chartData && this.chartData.err) {
          this.error = true
          if (this.chartData.errObj.status === 424) {
            this.errorMsg =
              "Error: Drivers analysis only accepts questions with 5-point scale \
            data type. Please remove any non-scale questions."
          } else if (this.chartData.errObj.status === 422) {
            this.errorMsg = `Error: The sample size is too low. ${this.chartData.errObj.data}`
          } else if (this.chartData.errObj.status) {
            this.errorMsg = `Error: ${this.chartData.errObj.data}`
          } else {
            this.errorMsg =
              "Error: There was an error with drivers analysis. Please try again."
          }
        } else if (this.chartData) {
          this.error = false
          this.errorMsg = null
          this.chartNode = new PigeonDocNodeModel(
            DriversAnalysisChart(
              this.dependentVariableTitle,
              this.chartData,
              false
            )
          )
          this.matrixNode = new PigeonDocNodeModel(
            PriorityMatrixChart(
              this.dependentVariableTitle,
              this.chartData,
              false
            )
          )

          // update they key so it re-renders the charts
          this.chartNodeKey += 1
          this.matrixNodeKey += 1
        }
      }
    }
  }
}
</script>
