<template>
  <Table
    class="benchmarking__table benchmarking__summary-results-table"
    id="benchmarking-table"
    :caption="'Benchmarking Averages'"
    :hide-caption="true"
    :row-data="data"
    :column-headers="columnHeaders"
    :row-keys="columnHeaders"
  >
  </Table>
</template>

<script>
// Components
import Table from "@/components/UI/Table.vue"

export default {
  name: "ProjectAnalysisBenchmarkingGroupsTable",
  components: {
    Table
  },
  props: {
    data: { type: Array },
    columnHeaders: { type: Array }
  }
}
</script>
