// Components
import Spinner from "@/components/UI/Spinner.vue"

// Helpers
import { mapActions, mapGetters } from "vuex"

// Models
import BenchmarkingProject from "@/models/BenchmarkingProjectModel"
import BenchmarkingDataset from "@/models/BenchmarkingDatasetModel.js"

// Mixins
import UserTypeMixin from "@/utils/mixins/userTypeMixin.js"

// Services
import BenchmarkingService from "@/services/benchmarkingService.js"
import DatasetsService from "@/services/datasetsService.js"

export default {
  mixins: [UserTypeMixin],
  components: {
    Spinner
  },
  data() {
    return {
      BENCHMARKING_SERVICE: new BenchmarkingService(this.$pigeonline),
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      isLoading: false
    }
  },
  computed: {
    ...mapGetters("confirm", {
      confirmSourceComponent: "getSourceComponent",
      confirmStatus: "getStatus",
      confirmTarget: "getTarget",
      confirmType: "getType"
    }),
    ...mapGetters("project", {
      project: "getProject"
    }),
    ...mapGetters("user", {
      profile: "getProfile",
      valid: "getValid"
    }),
    ...mapGetters("globalModule", {
      modalOpen: "getModalOpen"
    }),
    ...mapGetters("loader", { loading: "getLoading" }),
    isProjectOwner() {
      if (!this.profile || !this.project) return false
      return this.project.owner == this.profile.django_ref_id
    },
    statusOrgNotVerified() {
      return !this.valid && !this.isDemoUser
    }
  },
  methods: {
    ...mapActions("confirm", [
      "setConfirmIsVisible",
      "setConfirmStatus",
      "setConfirmSourceComponent",
      "setConfirmTarget",
      "setConfirmText",
      "setConfirmType",
      "resetConfirm"
    ]),
    ...mapActions("project", ["setProject", "resetProject"]),
    ...mapActions("loader", ["setLoading"]),
    async fetchDatasetProject(datasetId) {
      try {
        const response = await this.$pigeonline.projects.get(
          BenchmarkingDataset,
          { dataset_id: datasetId }
        )
        this.$store.dispatch("datasetWizard/setDatasetProject", response[0])
        return response
      } catch (e) {
        throw new Error("projectMixin:fetchDatasetProject " + e.message)
      }
    },
    /**
     * Fetching and saving project
     * note that vue-core converts projects to apporpriate model so
     * response is of BenchmarkingProject type.
     */
    async fetchProject() {
      try {
        const response = await this.$pigeonline.projects.getByID(
          BenchmarkingProject,
          this.$route.params.projid
        )
        return response
      } catch (e) {
        throw new Error("ProjectMixin.js:fetchProject " + e.message)
      }
    },
    async saveProject(project) {
      try {
        this.setProject(await this.$pigeonline.projects.update(project))
      } catch (e) {
        throw new Error("ProjectMixin.js:saveProject " + e.message)
      }
    },
    /**
     * Helpers
     */
    updateProjectDataset(datasetID) {
      this.project.addDataset(datasetID)
      this.project.updateStatus("datasetSelected")
      this.saveProject(this.project)
    }
  }
}
