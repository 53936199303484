<template>
  <Modal
    class="drivers__select-wrapper"
    :uid="uuid"
    :show="showDriversVariables"
    @close="$emit('close-drivers-variables')"
  >
    <template #header>
      {{ dependentVariableTitle }} {{ newDriver ? "" : "Variables" }}
    </template>
    <p class="warning-msg__inline">
      <VisibleText
        >Drivers analysis only accepts questions with scale data type
      </VisibleText>
      <HasPermission to="benchmarking-group-iccs">
        <VisibleText>
          and have a global match to the CMT
        </VisibleText></HasPermission
      >.
    </p>
    <h2 class="drivers__select-table-title">
      <VisibleText>Dependent Variable</VisibleText>
    </h2>
    <ProjectAnalysisSelectQuestionsTable
      :uid="`dependent-var-${uuid}`"
      :questions="dependentQuestionsLocal"
      :selected="selectedDependentQuestionId"
      @update-selected-questions="updateSelectedQuestions"
      v-if="dependentQuestionsLocal.length > 0"
    />
    <p class="error-msg__inline" v-else>
      <VisibleText>Please select a dependent variable.</VisibleText>
    </p>
    <h2 class="drivers__select-table-title">
      <VisibleText>Independent Variables</VisibleText>
    </h2>
    <ProjectAnalysisSelectQuestionsTable
      :uid="`independent-var-${uuid}`"
      :disableInput="disableInput"
      :for-drivers="true"
      :questions="independentQuestionsLocal"
      :selected="selectedIndependentQuestionIds"
      @update-selected-questions="updateSelectedQuestions"
      @set-dependent="setDependentVariable"
    />
    <template #footer>
      <button class="btn-default" @click="saveAndClose">
        <VisibleText>Save Variables</VisibleText>
      </button>
      <button class="btn-cancel" @click="$emit('close-drivers-variables')">
        <VisibleText>Close</VisibleText>
      </button>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/UI/Modal.vue"
import ProjectAnalysisSelectQuestionsTable from "../ProjectAnalysisSelectQuestionsTable.vue"

export default {
  name: "ProjectAnalysisDriversVariables",
  props: {
    dependentVariableTitle: {
      default: () => "Drivers",
      type: String
    },
    dependentQuestions: {
      default: () => [],
      type: Array
    },
    independentQuestions: {
      default: () => [],
      type: Array
    },
    suggestedVariables: {
      default: () => null,
      type: [Object, Number]
    },
    newDriver: {
      defualt: () => false,
      type: Boolean
    },
    showDriversVariables: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      newDependentQuestionId: [],
      newIndependentQuestionIds: [],
      uuid: Date.now()
    }
  },
  components: {
    Modal,
    ProjectAnalysisSelectQuestionsTable
  },
  computed: {
    // local copy of depdentent questions so we can re-render table
    dependentQuestionsLocal() {
      return this.$store.getters["datasetDetails/getClientQuestions"].filter(
        q => q._id.$oid === this.newDependentQuestionId[0]
      )
    },
    // local copy of indepdentent questions so we can re-render table
    independentQuestionsLocal() {
      return this.$store.getters["datasetDetails/getClientQuestions"].filter(
        q => q._id.$oid !== this.newDependentQuestionId[0]
      )
    },
    selectedDependentQuestionId() {
      return this.dependentQuestionsLocal.map(q => q._id.$oid)
    },
    selectedIndependentQuestionIds() {
      return this.independentQuestions.map(q => q._id.$oid)
    }
  },
  methods: {
    disableInput(item) {
      const invalidTypes = [
        "OPEN_ENDED",
        "NUMERIC",
        "ID",
        "CAT",
        "DATE",
        "OTHER"
      ]

      // if iccs, disable any non-benchmarked items
      if (
        this.$pigeonline.permissions.hasPermission("benchmarking-group-iccs")
      ) {
        return (
          !item.benchmarking_id ||
          (invalidTypes.includes(item.approved_data_type) &&
            !this.selectedIndependentQuestionIds.includes(item._id.$oid))
        )
      }
      return (
        invalidTypes.includes(item.approved_data_type) &&
        !this.selectedIndependentQuestionIds.includes(item._id.$oid)
      )
    },
    updateSelectedQuestions(selectedQuestions, uid) {
      uid === "dependent-var"
        ? (this.newDependentQuestionId = selectedQuestions)
        : (this.newIndependentQuestionIds = selectedQuestions)
    },
    setDependentVariable(questionId) {
      this.newDependentQuestionId = [questionId]
    },
    saveVariables() {
      // when we are changing dependent variable - remove it from independent
      let indx = this.newIndependentQuestionIds.indexOf(
        this.newDependentQuestionId[0]
      )
      if (indx > -1) {
        this.newIndependentQuestionIds.splice(indx, 1)
      }

      this.$emit(
        "modify-vars",
        this.newDependentQuestionId[0],
        this.newIndependentQuestionIds
      )
    },
    saveAndClose() {
      this.saveVariables()
      this.$emit("close-drivers-variables")
    }
  },
  watch: {
    dependentQuestions: {
      immediate: true,
      handler() {
        if (this.dependentQuestions[0])
          this.setDependentVariable(this.dependentQuestions[0]._id.$oid)
      }
    }
  }
}
</script>
