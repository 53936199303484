<template>
  <div
    class="project-settings"
    id="project-settings"
    v-click-outside="closeMenu"
  >
    <button
      aria-haspopup="true"
      class="project-settings__btn"
      id="project-settings-btn"
      ref="project-settings"
      :aria-expanded="isMenuExpanded.toString()"
      @click="toggleMenu"
    >
      <span class="title">Project Settings</span>
      <SvgIconDecorative icon="chevronDown" />
    </button>
    <transition name="fade">
      <div
        class="project-settings__menu"
        id="project-settings-menu"
        role="menu"
        ref="project-settings-menu"
        v-show="isMenuExpanded"
      >
        <button
          class="project-settings__menu-item"
          role="menuitem"
          tabindex="-1"
          @click="setActiveModal('SetPerformanceMeasure')"
          @keydown="focusNextItem"
        >
          Performance measure
        </button>
        <button
          class="project-settings__menu-item"
          role="menuitem"
          tabindex="-1"
          @click="setActiveModal('SetPerformanceType')"
          @keydown="focusNextItem"
        >
          Performance type
        </button>
      </div>
    </transition>

    <!-- Modals -->
    <SetPerformanceMeasure
      :show="isModalVisible.SetPerformanceMeasure"
      :project="project"
      :dataset-id="dataset._id.$oid"
      :table-data="clientQuestions"
      @close-modal="closeModal"
      @save-performance-measure="savePerformanceMeasure($event)"
    />
    <SetPerformanceType
      :project="project"
      :show="isModalVisible.SetPerformanceType"
      @close-modal="closeModal"
      @save-performance-type="savePerformanceType($event)"
    />

    <!-- Spinner -->
    <Spinner :is-loading="isLoading" message="saving" />
  </div>
</template>

<script>
// Components
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import SetPerformanceMeasure from "./Modals/SetPerformanceMeasure.vue"
import SetPerformanceType from "./Modals/SetPerformanceType.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import ProjectMixin from "@/utils/mixins/projectMixin.js"

export default {
  name: "ProjectSettings",
  mixins: [DatasetDetailsMixin, ProjectMixin],
  components: {
    SvgIconDecorative,
    SetPerformanceMeasure,
    SetPerformanceType
  },
  data() {
    return {
      isMenuExpanded: false,
      isModalVisible: {
        SetPerformanceType: false,
        SetPerformanceMeasure: false
      }
    }
  },
  methods: {
    toggleMenu: function() {
      this.isMenuExpanded = !this.isMenuExpanded
    },
    closeMenu: function() {
      this.isMenuExpanded = false
    },
    setActiveModal: function(type) {
      this.closeModal()
      this.isModalVisible[type] = true
    },
    closeModal: function() {
      Object.keys(this.isModalVisible).map(
        type => (this.isModalVisible[type] = false)
      )
    },
    savePerformanceMeasure: async function(selectedClientQuestionsIds) {
      if (
        !selectedClientQuestionsIds ||
        !Array.isArray(selectedClientQuestionsIds)
      ) {
        throw new Error("`selectedClientQuestionsIds` must be an array.")
      }

      this.isLoading = true
      try {
        this.project.settings.performanceMeasure = {
          clientQuestionsIds: selectedClientQuestionsIds
        }
        await this.saveProject(this.project)
        alert("Performance measure saved successfully.")
      } catch (e) {
        alert(
          "There was an error processing your request. Please try again later."
        )
        console.error(
          "ProjectSettings.vue:savePerformanceMeasure:: " + e.message
        )
      } finally {
        this.isLoading = false
      }
    },
    savePerformanceType: async function(selectedType) {
      this.isLoading = true
      try {
        this.project.settings.performanceType = {
          selected: selectedType
        }
        await this.saveProject(this.project)
        alert("Performance type saved successfully.")
      } catch (e) {
        alert(
          "There was an error processing your request. Please try again later."
        )
        console.error("ProjectSettings.vue:savePerformanceTypes:: " + e.message)
      } finally {
        this.isLoading = false
      }
    },

    /**
     * Navigation keyboard helpers
     */
    focusNextItem($e) {
      switch ($e.key) {
        case "Down":
        case "ArrowDown":
          if ($e.target.nextElementSibling) {
            $e.target.nextElementSibling.focus()
          } else {
            this.$refs["project-settings-menu"].firstElementChild.focus()
          }
          break
        case "Up":
        case "ArrowUp":
          if ($e.target.previousElementSibling) {
            $e.target.previousElementSibling.focus()
          } else {
            this.$refs["project-settings-menu"].lastElementChild.focus()
          }
          break
        case "Esc":
        case "Escape":
          this.$refs["project-settings"].focus()
          this.isMenuExpanded = false
          break
        case "Tab":
          this.isMenuExpanded = false
          break
        default:
          return
      }
      $e.preventDefault()
    }
  },
  watch: {
    isMenuExpanded: function() {
      this.$refs["project-settings"].setAttribute(
        "aria-expanded",
        this.isMenuExpanded
      )
      if (this.isMenuExpanded) {
        this.$nextTick(() =>
          this.$refs["project-settings-menu"].firstElementChild.focus()
        )
      }
    }
  }
}
</script>
