<template>
  <section id="dataset">
    <div class="section__top-bar">
      <h1 class="section__top-bar-title">
        <VisibleText>Dataset Wizard</VisibleText>
      </h1>
    </div>
    <!-- Main content -->
    <div class="dataset__content">
      <div class="dataset__demo-info" v-if="isDemoUser || isReadOnly">
        <MessageBlock message-type="information">
          This is a read-only dataset.
        </MessageBlock>
      </div>
      <div class="dataset__menu-description" v-else>
        <VisibleText>
          You can clean and label your data in just a few clicks
        </VisibleText>
      </div>
      <DatasetProgress />
      <keep-alive :exclude="[exclude]">
        <component
          :is="activeSection"
          :read-only="isReadOnly"
          @open-template-modal="isShowingTemplateModal = true"
        ></component>
      </keep-alive>
    </div>
    <!-- Tempalte modal -->
    <DatasetUploaderTemplateModal
      :show="isShowingTemplateModal"
      @close="closeTemplateModal"
    />
  </section>
</template>

<script>
// Components
import DatasetProgress from "@/components/Dataset/DatasetProgress/DatasetProgress.vue"
import DatasetUploader from "@/components/Dataset/DatasetUploader/DatasetUploader.vue"
import DatasetUploaderTemplateModal from "@/components/Dataset/DatasetUploader/DatasetUploaderTemplateModal.vue"
import DatasetSegments from "@/components/Dataset/DatasetSegments/DatasetSegments.vue"
import DatasetBenchmarks from "@/components/Dataset/DatasetBenchmarks/DatasetBenchmarks.vue"
import DatasetCleaner from "@/components/Dataset/DatasetCleaner/DatasetCleaner.vue"
import DatasetTableView from "@/components/Dataset/DatasetTable/DatasetTableView.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

// Mixins
import DatasetMixin from "@/utils/mixins/datasetMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import SharedStatusMixin from "@/utils/mixins/sharedStatusMixin.js"
import UserTypeMixin from "@/utils/mixins/userTypeMixin.js"

// Models
import BenchmarkingDataset from "@/models/BenchmarkingDatasetModel.js"
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

const LICENSE_TO_BENCHMARK_GROUP = {
  free: "free",
  "iccs-admin": "iccs",
  iccs: "iccs",
  basic: "free"
}

export default {
  name: "Dataset",
  mixins: [DatasetMixin, DatasetDetailsMixin, SharedStatusMixin, UserTypeMixin],
  components: {
    DatasetProgress,
    DatasetUploader,
    DatasetSegments,
    DatasetBenchmarks,
    DatasetCleaner,
    DatasetTableView,
    MessageBlock,
    DatasetUploaderTemplateModal
  },
  data() {
    return {
      exclude: "",
      isShowingTemplateModal: false
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.deleteIncompleteUpload) {
      await this.$pigeonline.projects.delete(this.deleteIncompleteUpload)
      this.resetDatasetWizard()
      this.resetDatasetDetails()
      this.$store.dispatch("confirm/resetConfirm")
      next()
    } else {
      this.resetDatasetWizard()
      this.resetDatasetDetails()
      this.$store.dispatch("confirm/resetConfirm")
      next()
    }
  },
  async created() {
    // if url and id given, dataset exists so fetch details
    if (typeof this.$route.params.id !== "undefined") {
      this.loadData().then(async ({ datasetProject, dataset }) => {
        this.setDatasetProject(datasetProject)
        this.setDataset(dataset)
        await this.setDatasetBenchmarkGroup()
        await this.loadDatasetDetails(this.dataset)

        // if we just uploaded a file, go to next step otherwise go to data table
        const component =
          this.$route.query.from === "datasetUploader"
            ? "DatasetCleaner"
            : "DatasetTableView"

        this.isReadOnly = this.checkIsReadOnly(datasetProject)
        this.setActiveSection(component)
      })
    } else {
      this.setDatasetProject(new BenchmarkingDataset({ name: null }))
      this.setActiveSection("DatasetUploader")
    }

    //setup global confirmation modal
    this.$store.dispatch("confirm/setConfirmText", {
      btn: "delete question(s)",
      message: "You will not be able to recover your data once it is deleted.",
      title: "delete question?"
    })
    this.$store.dispatch("confirm/setConfirmType", "delete")
    this.$store.dispatch("confirm/setConfirmSourceComponent", "dataset")
  },
  methods: {
    closeTemplateModal() {
      this.isShowingTemplateModal = false
    },
    async loadData() {
      const [datasetProject, dataset] = await Promise.all([
        this.fetchDatasetProject(),
        this.fetchDataset(this.$route.params.id)
      ])
      return { datasetProject, dataset }
    },
    async setDatasetBenchmarkGroup() {
      /**
       * set chosen group (backwards compatibility - beta had benchmark group
       * in datasetProject but now it is in dataset)
       **/
      if (this.dataset.benchmark_group || this.datasetProject.benchmark_group) {
        const benchmarkGroupId =
          this.dataset.benchmark_group || this.datasetProject.benchmark_group
        const benchmarkGroup = await this.$pigeonline.projects.getByID(
          BenchmarkGroup,
          benchmarkGroupId
        )
        this.setBenchmarkGroup(benchmarkGroup)
      }
      // set default group
      else if (
        ["iccs-admin", "iccs", "free"].includes(
          this.$store.getters["user/getLicense"].name
        )
      ) {
        const defaultBenchmarkGroup = await this.$pigeonline.projects.get(
          BenchmarkGroup,
          {
            official_group:
              LICENSE_TO_BENCHMARK_GROUP[
                this.$store.getters["user/getLicense"].name
              ]
          }
        )
        this.setBenchmarkGroup(defaultBenchmarkGroup[0])
        // update dataset with default group
        await this.updateDataset(this.dataset._id.$oid, {
          benchmark_group: defaultBenchmarkGroup[0].id
        })
      }
    }
  },
  watch: {
    "$route.params.id": {
      handler: function(id) {
        if (!id) {
          this.exclude = "DatasetUploader"
        } else {
          this.exclude = ""
        }
      },
      immediate: true
    },
    datasetDetailsLoaded: function(loaded) {
      if (loaded && this.dataset.name) {
        document.title = `${this.dataset.name} - Dataset Wizard | ${this.$theme.theme.pageTitle}`
      } else {
        document.title = `Dataset Wizard | ${this.$theme.theme.pageTitle}`
      }
    }
  }
}
</script>
