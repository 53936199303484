<template>
  <span class="icon-only-btn-wrapper tooltip">
    <!-- primary label only -->
    <template v-if="!auxiliary">
      <button
        class="tooltip__hoverable icon-only-btn center-align-parent"
        :aria-labelledby="`${id}-label`"
        :type="buttonType"
        @click.prevent="$emit('click-handler', $event)"
      >
        <SvgIcon
          class="center-align-child"
          :aria-hidden="true"
          :focusable="false"
          :icon="icon"
        />
      </button>
      <span
        class="tooltip__text tooltip__text--compact dark bottom no-arrow hoverable"
        role="tooltip"
        :id="`${id}-label`"
      >
        <slot></slot>
      </span>
    </template>

    <!-- with auxiliary desc -->
    <template v-if="auxiliary">
      <button
        class="tooltip__hoverable icon-only-btn center-align-parent"
        :aria-labelledby="`${id}-desc`"
        :type="buttonType"
        @click.prevent="$emit('click-handler', $event)"
      >
        <SvgIcon
          class="center-align-child"
          :aria-hidden="true"
          :focusable="false"
          :icon="icon"
        />
        <span class="sr-only-nohover">
          <slot></slot>
        </span>
      </button>
      <span
        class="tooltip__text tooltip__text--compact dark bottom no-arrow hoverable"
        role="tooltip"
        :id="`${id}-desc`"
      >
        <slot name="desc"></slot>
      </span>
    </template>
  </span>
</template>

<script>
import SvgIcon from "@/components/UI/Svg/SvgIcon.vue"

export default {
  name: "ButtonIconOnly",
  components: {
    SvgIcon
  },
  props: {
    auxiliary: {
      default: () => false,
      type: Boolean
    },
    buttonType: {
      default: () => "button",
      type: String
    },
    // file name of an icon in /assets/sprites
    icon: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  }
}
</script>
