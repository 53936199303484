<template>
  <span id="copy-details-options-wrapper">
    <button
      aria-expanded="false"
      aria-haspopup="true"
      class="table__toolbar-btn"
      id="copy-details-btn"
      ref="copy-details-btn"
      type="button"
      @click="toggleCopyDetailsOptions"
    >
      <SvgIconDecorative icon="copy" />
      <VisibleText>Copy Details</VisibleText>
    </button>
    <transition name="fade">
      <div
        id="copy-details-options"
        ref="copy-details-options"
        v-show="isOptionsExpanded"
      >
        <span class="copy-details-options-err" v-if="tooManyQuestions">
          <VisibleText
            >You have more than one question selected. Please select only one to
            copy details.
          </VisibleText>
        </span>
        <span v-else>
          <button
            class="copy-details-options-btn"
            @click="setMethodName('copyLabels')"
          >
            <VisibleText>Copy Labels</VisibleText>
            <span class="copy-details-options-btn-desc">
              <VisibleText
                >Copy value labels to other questions with the same data type.
              </VisibleText>
            </span>
          </button>
        </span>
      </div>
    </transition>
  </span>
</template>

<script>
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "DatasetTableOptionsCopyDetails",
  components: {
    SvgIconDecorative
  },
  props: {
    tooManyQuestions: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      isOptionsExpanded: false
    }
  },
  methods: {
    setMethodName(name) {
      this.$store.dispatch("datasetWizard/setCopyingDetails", {
        isCopyingDetails: true,
        methodName: name
      })
    },
    toggleCopyDetailsOptions() {
      this.isOptionsExpanded = !this.isOptionsExpanded
    }
  }
}
</script>
