<template>
  <MessageBlock
    :message-type="messages[statusName].type"
    :title="messages[statusName].title"
    v-if="Object.keys(messages).includes(statusName)"
  >
    {{ messages[statusName].message }}
  </MessageBlock>
</template>

<script>
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

const MESSAGES = {
  notEnoughScale: {
    title: "Not enough scale questions",
    message:
      "We could not find enough scale questions. You need at least 2 to run\
      drivers analysis."
  },
  noCmt: {
    title: "No CMT questions",
    message:
      "We could not find enough CMT scale questions. To run benchmarking\
      analysis, please edit your dataset and match a question to CMT global\
      questions (step 3). You need at least 2 to run drivers analysis."
  },
  notSuitableModel: {
    title: "No suitable model",
    message:
      "We could not find a suitable model for calculating drivers analysis."
  },
  orgNotVerified: {
    title: "Account not verified",
    message:
      "You can view drivers, but to save them to a report your \
    organization must be verified.",
    type: "warning"
  }
}

export default {
  name: "ProjectAnalysisDriversMessages",
  props: {
    statusName: {
      default: () => "",
      type: String
    }
  },
  components: {
    MessageBlock
  },
  data() {
    return {
      messages: MESSAGES
    }
  }
}
</script>
