<template>
  <Accordion
    title-element-type="span"
    class="dataset-table__values-wrapper"
    :style="{ height: contentHeight + 'px' }"
    @toggled="toggled"
  >
    <template slot="heading">
      <span ref="btn" class="sr-only"> show row {{ index }} values </span>
    </template>
    <div
      class="dataset-table__values-content"
      :ref="`${clientQuestion._id.$oid}-content`"
    >
      <DatasetTableValuesScale
        :client-question="clientQuestion"
        :hide-options="hideOptions"
        v-if="clientQuestion.scale_range"
      />
      <DatasetTableValuesList
        :client-question="clientQuestion"
        :hide-options="hideOptions"
        v-else
      />
    </div>
  </Accordion>
</template>

<script>
import Accordion from "@/components/UI/Accordion.vue"

export default {
  name: "DatasetTableValues",
  props: {
    clientQuestion: {
      default: () => {},
      type: Object
    },
    index: {
      default: () => null,
      type: Number
    },
    hideOptions: {
      default: () => true,
      type: Boolean
    }
  },
  components: {
    Accordion,
    DatasetTableValuesList: () => import("./DatasetTableValuesList.vue"),
    DatasetTableValuesScale: () => import("./DatasetTableValuesScale.vue")
  },
  data() {
    return {
      contentHeight: "100%",
      expanded: false
    }
  },
  mounted() {
    // set initial index to -1 for keyboard accessibility within a grid
    this.$refs["btn"].parentElement.tabIndex = "-1"
  },
  methods: {
    toggled() {
      this.$nextTick(() => {
        const height = this.$refs[`${this.clientQuestion._id.$oid}-content`]
          .clientHeight
        this.expanded = !this.expanded
        this.contentHeight = this.expanded ? height + 200 : height
        this.$emit("details-toggled", this.expanded, this.index)
      })
    }
  }
}
</script>
