<template>
  <section id="project-analysis-text-main-themes">
    <div class="themes__header">
      <ul
        class="themes__header-labels main__search-labels"
        v-if="!search.isSearching"
      >
        <transition name="fade">
          <li v-show="isSearchMode">
            <span class="main__highlight yellow" id="themes-search-keyword">
              <VisibleText>Search keyword</VisibleText>
            </span>
          </li>
        </transition>
        <transition name="fade">
          <li v-show="!isSearchMode && selectedThemeIndex !== -1">
            <span class="main__highlight theme" id="theme-keyword">
              {{ themeName }}
              <VisibleText>keyword</VisibleText>
            </span>
          </li>
        </transition>
        <transition name="fade">
          <li v-show="!isSearchMode && selectedThemeIndex !== -1">
            <span class="main__highlight excerpt" id="excerpt">
              {{ themeName }}
              <VisibleText>excerpt</VisibleText>
            </span>
          </li>
        </transition>
        <transition name="fade">
          <li v-show="!isSearchMode && sortBy !== -1">
            <span class="main__highlight theme" id="matched-keyword">
              {{ themes[sortBy] ? themes[sortBy].name : "Matched" }}
              <VisibleText> keyword</VisibleText>
            </span>
          </li>
        </transition>
      </ul>
      <div
        class="select-wrapper themes__sort-wrapper"
        v-if="!search.isSearching"
      >
        <label for="theme-sort-by" class="themes__sort-label">
          <VisibleText>Sort by: </VisibleText>
        </label>
        <select
          class="select themes__sort-options"
          id="theme-sort-by"
          v-model.number="sortBy"
        >
          <option value="-1">
            <VisibleText>Select a theme</VisibleText>
          </option>
          <option :value="index" :key="index" v-for="(theme, index) in themes">
            {{ theme.name }}
          </option>
        </select>
      </div>
    </div>
    <ul class="themes__response-list">
      <li
        class="themes__response-list-item"
        v-for="item in textResponsesSorted"
        :key="item.idx"
      >
        <ResponseListItem
          :item="item"
          :is-pinned="pinnedComments.includes(item.id)"
          :is-hidden="hiddenComments.includes(item.id)"
          :highlight-items="highlightItems"
          :highlight-method="highlight"
          @excerpt="onExcerpt"
          @keyword="onKeyword"
          @note="onNote"
          @ban="onBan"
          @remove="removeItem"
          @toggle-response-pin="toggleResponseItemPin"
          @ban-response="banResponseItem"
          @toggle-response-hide="toggleResponseItemHide"
        />
      </li>
    </ul>
    <SelectedTextModal
      uid="select-text-themes-modal"
      :type="selectedTextModalType"
      :text="selectedText"
      :show="isSelectedTextModalVisible"
      @closeModal="closeModal"
    />
  </section>
</template>

<script>
// Components
import ResponseListItem from "./Components/ResponseListItem.vue"

// Mixins
import AnalysisTextMixin from "@/utils/mixins/analysisTextMixin.js"
import SelectTextMixin from "./Mixins/selectTextMixin.js"

export default {
  name: "ProjectTextAnalysisMainThemes",
  mixins: [AnalysisTextMixin, SelectTextMixin],
  components: {
    ResponseListItem
  },
  data() {
    return {
      sortBy: -1 /* sort by themes */
    }
  },
  computed: {
    themeName() {
      return this.themes[this.selectedThemeIndex]
        ? this.themes[this.selectedThemeIndex].name
        : "Theme"
    },
    themeKeywords: function() {
      return (this.isThemeSelected
        ? this.themes[this.selectedThemeIndex].keywords.map(item =>
            item.toLowerCase()
          )
        : []
      ).sort((a, b) => b.length - a.length)
    },
    themeExcerpts: function() {
      return (this.isThemeSelected
        ? this.themes[this.selectedThemeIndex].excerpts.map(item =>
            item.toLowerCase()
          )
        : []
      ).sort((a, b) => b.length - a.length)
    },
    alsoFound: function() {
      let _alsoFound = new Set(
        this.search.searchResults
          .map(item =>
            item[0].matching_reasons.map(kw =>
              String(kw)
                .trim()
                .toLowerCase()
            )
          )
          .flat()
      )
      _alsoFound.delete(this.search.searchString)
      return [..._alsoFound]
    },
    highlightItems: function() {
      if (this.isSearchMode) {
        return this.alsoFound
      }
      return [...this.themeKeywords, ...this.themeExcerpts].map(el =>
        el.trim().toLowerCase()
      )
    },
    /* sorted text responses by themes */
    textResponsesSorted: function() {
      const pinnedResponses = this.textResponsesFiltered.filter(el =>
        this.pinnedComments.includes(el.id)
      )
      const unpinnedResponses = this.textResponsesFiltered.filter(
        el => !this.pinnedComments.includes(el.id)
      )
      return [
        ...pinnedResponses,
        ...unpinnedResponses.sort(
          (a, b) => this.findResponseScore(b) - this.findResponseScore(a)
        )
      ]
    }
  },
  methods: {
    findResponseScore(response) {
      return (
        response.scores.themes[this.sortBy] ||
        response.scores.themes[this.selectedThemeIndex] ||
        0
      )
    },
    highlight(text, matchKws) {
      var result = text // text

      const _replace = function(string, keywords, highlightClass = "grey") {
        if (keywords.length == 0) return string
        let _string = string
        let _pattern = new RegExp("\\b(" + keywords.join("|") + ")(\\b)?", "gi")
        return _string.replace(_pattern, match => {
          return `<mark class="main__highlight keyword ${highlightClass}" data-highlight-type="${highlightClass}">${match}</mark>`
        })
      }
      if (!this.isSearchMode) {
        if (this.themeExcerpts.length > 0) {
          const pattern = new RegExp(
            "(\\b)?(" + this.themeExcerpts.join("|") + ")(\\b)?",
            "gi"
          )
          result = text.replace(pattern, excerpt => {
            return (
              '<mark class="main__highlight excerpt" data-highlight-type="excerpt">' +
              excerpt +
              "</mark>"
            )
          })
        }
        result = _replace(
          result,
          this.filterBannedKeywords(
            (matchKws.themes[this.sortBy] || this.themeKeywords).filter(
              item => item !== ""
            )
          ),
          "theme"
        )
      } else {
        result = _replace(
          text,
          [this.search.searchString, ...this.search.alsoFound].filter(
            item => item !== ""
          ),
          "search"
        )
      }
      return result
    },
    async removeItem(text) {
      const text_ = text.trim().toLowerCase()
      let theme = this.themes[this.selectedThemeIndex]
      this.setShowSpinner(true)

      if (!this.isSearchMode) {
        // check if the text is a theme keyword or excerpt
        if (this.themeKeywords.includes(text_)) {
          let keywords = [...this.themeKeywords]
          keywords.splice(keywords.indexOf(text_), 1)

          // compute coverage
          let coverage = await this.computeCoverage(keywords)
          this.themes[this.selectedThemeIndex] = {
            ...theme,
            keywords,
            coverage
          }

          await this.saveThemes(this.themes)
          await this.setSelectedThemeResponses(await this.fetchThemeResponses())
        } else if (this.themeExcerpts.includes(text_)) {
          let excerpts = [...this.themeExcerpts]
          excerpts.splice(excerpts.indexOf(text_), 1)

          this.themes[this.selectedThemeIndex] = {
            ...theme,
            excerpts
          }

          await this.saveThemes(this.themes)
        }
      } else {
        // possibly a search keyword (also found)
        if (this.search.alsoFound.includes(text_)) {
          this.search.alsoFound.splice(this.search.alsoFound.indexOf(text), 1)
          this.setSearch({ alsoFound: this.search.alsoFound })
        }
      }

      this.setShowSpinner(false)
    }
  },
  watch: {
    search: function(val) {
      const searchString = val.searchString
      // reset sort
      if (searchString && searchString.trim() != "") this.sortBy = -1
    }
  }
}
</script>
