import PerformanceAnalysisChart from "../helpers/performanceAnalysisChart.js"

const performanceSectionHeader = () => {
  const heading = {
    type: "heading",
    content: "Performance Analysis",
    id: "section-title-performance",
    meta: {
      level: "h1",
      pdfConfig: {
        pageBreak: "before"
      }
    }
  }
  const description = {
    type: "text",
    content: `Performance analysis shows you how well your categorical questions score, \
    and finds unique stories by finding outliers and difference in performance \
    across segments.`,
    id: "section-description-performance"
  }

  return [heading, description]
}

const performanceSection = (
  data,
  pChartType,
  cChartType,
  cChartNodes,
  cChartNodesTitles,
  uuid,
  index
) => {
  const performanceTitle = {
    type: "heading",
    content: `Question Performance ${index}`,
    id: "subsection-title-performance",
    meta: {
      level: "h2",
      report: {
        uuid: uuid, // unique subsection id for each performance analysis
        index: index // performance subsection index -- NOT to confuse with report index
      }
    }
  }
  const performanceChart = PerformanceAnalysisChart(
    pChartType,
    data,
    "Question Performance Chart"
  )
  let performance = [performanceTitle, performanceChart]

  if (
    cChartType &&
    cChartNodes &&
    Array.isArray(cChartNodes) &&
    cChartNodes.length > 0
  ) {
    const comparisonTitle = {
      type: "heading",
      content: "Comparison across segments",
      id: "subsubsection-title-performance",
      meta: {
        level: "h3"
      }
    }
    performance.push(comparisonTitle)

    /**
     * TODO: update pdf to support table styling,
     * set remove pdfConfig.ignore when complete
     **/
    const legendText = `<div class="comparison-analysis-legend"><strong>Legend</strong><br/><span class="gte">Greater or equal to mean (outlier)</span><span class="lt">Lower than mean (outlier)</span></div>`
    performance.push({
      type: "text",
      content: legendText,
      meta: {
        pdfConfig: {
          style: "comparison-analysis-legend",
          ignore: true
        }
      }
    })

    for (let i = 0; i < cChartNodes.length; i++) {
      performance = performance.concat([cChartNodes[i]])
    }
  }
  return performance
}

export default {
  performanceSectionHeader,
  performanceSection
}
