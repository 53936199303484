<template>
  <div class="theme__list-row-sentiment-charts">
    <SwitchToggle
      class="switch--small switch--monochromatic"
      title="set chart type"
      op1="keywords sentiment"
      op2="respondent sentiment"
      :default="0"
      @onToggle="toggle = $event"
    />
    <HorizontalSingleStackBarChart v-if="toggle == 0" :value="singleStackData">
      <template v-slot:description="{ chartDescription }">
        <details class="chart-description__details">
          <summary>
            <span class="sr-only"> theme {{ themeName }} </span> chart details
          </summary>
          <p
            class="chart-description__details-text"
            v-html="chartDescription"
          ></p>
        </details>
      </template>
    </HorizontalSingleStackBarChart>
    <PositiveNegativeBarChartSmall v-if="toggle == 1" :value="posNegData">
      <template v-slot:description="{ chartDescription }">
        <details class="chart-description__details">
          <summary>
            <span class="sr-only"> theme {{ themeName }} </span> chart details
          </summary>
          <p
            class="chart-description__details-text"
            v-html="chartDescription"
          ></p>
        </details>
      </template>
    </PositiveNegativeBarChartSmall>
  </div>
</template>

<script>
// Components
import SwitchToggle from "@/components/UI/SwitchToggle.vue"

import {
  PigeonDocNodeModel,
  HorizontalSingleStackBarChart,
  PositiveNegativeBarChartSmall
} from "@pigeonline/pigeondoc"

export default {
  name: "SentimentCharts",
  components: {
    SwitchToggle,
    HorizontalSingleStackBarChart,
    PositiveNegativeBarChartSmall
  },
  props: {
    themeName: {
      type: String
    },
    singleStackData: {
      type: PigeonDocNodeModel
    },
    posNegData: {
      type: PigeonDocNodeModel
    }
  },
  data() {
    return {
      toggle: 0
    }
  }
}
</script>
