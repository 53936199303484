// for global mixin
import language_cache from "@/components/Translation/LanguageCache.json"

export default {
  methods: {
    checkTranslationCache(local_en, inputName, setLanguage) {
      let filteredCache = language_cache.filter(
        translation =>
          translation.text_en === local_en &&
          translation.parentComponent === "Input name=" + inputName &&
          translation.uri === document.baseURI
      )
      return filteredCache.length > 0 && setLanguage === "fr"
        ? filteredCache[0].text_fr
        : local_en
    }
  }
}
