<template>
  <fieldset
    class="form-fieldset-group dataset-segments__form-group"
    id="about-survey"
    tabindex="-1"
  >
    <legend
      class="form-fieldset-group__legend form-fieldset-group__legend--base-colour"
    >
      Survey Details
    </legend>
    <p class="form-fieldset-group__p">
      Tell us a little bit about about your survey. All fields marked with
      <span class="required-indicator"> * </span>
      are required.
    </p>

    <div class="form-inline-group">
      <label for="survey-start-date">
        Survey Start Date <span class="required-indicator"> * </span>
      </label>
      <p
        class="error-msg__inline form__err--block"
        id="end-start-err"
        v-show="errMsg.surveyStartDate"
      >
        {{ errMsg.surveyStartDate }}
      </p>
      <input
        aria-describedby="end-date-err"
        required
        ref="start-date"
        type="date"
        id="survey-start-date"
        :aria-invalid="
          errMsg.surveyStartDate && !surveyDetails.surveyStartDate
            ? 'true'
            : 'false'
        "
        :readOnly="readOnly"
        v-model="surveyDetails.surveyStartDate"
        @change="errMsg.surveyStartDate = null"
      />
    </div>

    <div class="form-inline-group">
      <label for="survey-end-date">
        Survey End Date <span class="required-indicator"> * </span>
      </label>
      <p
        class="error-msg__inline form__err--block"
        id="end-date-err"
        v-show="errMsg.surveyEndDate"
      >
        {{ errMsg.surveyEndDate }}
      </p>
      <input
        aria-describedby="end-date-err"
        required
        ref="end-date"
        type="date"
        id="survey-end-date"
        :aria-invalid="
          errMsg.surveyEndDate && !surveyDetails.surveyEndDate
            ? 'true'
            : 'false'
        "
        :readOnly="readOnly"
        v-model="surveyDetails.surveyEndDate"
        @change="errMsg.surveyEndDate = null"
      />
    </div>

    <div class="form-inline-group">
      <label for="response-rate">
        Response Rate <span class="required-indicator"> * </span>
        <span class="form-label-details">
          ( Number of completed surveys / Number of respondents contacted )%
        </span>
      </label>
      <div class="dataset-segments__response-rate">
        <p
          class="error-msg__inline form__err--block"
          id="response-rate-err"
          v-show="errMsg.responseRate"
        >
          {{ errMsg.responseRate }}
        </p>
        <input
          aria-describedby="response-rate-err"
          class="dataset-segments__response-rate-input"
          ref="response-rate"
          type="number"
          min="0"
          step="1"
          id="response-rate"
          :aria-invalid="Boolean(errMsg.responseRate)"
          :readOnly="readOnly"
          v-model="surveyDetails.rate"
          @change="onChangeResponseRate"
        />
        <label
          for="response-rate-na"
          class="dataset-segments__response-rate-na"
        >
          <input
            id="response-rate-na"
            type="checkbox"
            :disabled="readOnly"
            v-model="surveyDetails.responseRateNa"
            @change="errMsg.responseRate = null"
          />
          response rate not available
        </label>
      </div>
    </div>

    <div class="form-inline-group">
      <label for="coverage-area">Coverage Area</label>
      <input
        type="text"
        id="coverage-area"
        :readOnly="readOnly"
        v-model="surveyDetails.coverageArea"
      />
    </div>

    <div class="form-inline-group">
      <label for="coverage-area-population">Coverage Area Population</label>
      <input
        type="text"
        id="coverage-area-population"
        :readOnly="readOnly"
        v-model="surveyDetails.coveragePopulation"
      />
    </div>

    <fieldset class="form-fieldset-child">
      <legend>Third Party Delivery</legend>
      <label>
        <input
          type="radio"
          value="yes"
          :disabled="readOnly"
          v-model="surveyDetails.thirdParty"
        />
        Yes
      </label>
      <label>
        <input
          type="radio"
          value="no"
          :disabled="readOnly"
          v-model="surveyDetails.thirdParty"
        />
        No
      </label>
    </fieldset>

    <div class="form-inline-group">
      <label for="data-collector">
        Data Collector
        <span class="form-label-details">
          Company/organization that conducted the survey
        </span>
      </label>
      <input
        type="text"
        id="data-collector"
        :readOnly="readOnly"
        v-model="surveyDetails.dataCollector"
      />
    </div>

    <div class="form-inline-group">
      <label for="survey-frequency">
        Survey Frequency
        <span class="form-label-details">
          one time, annual, monthly, etc.
        </span>
      </label>
      <input
        type="text"
        id="survey-frequency"
        :readOnly="readOnly"
        v-model="surveyDetails.frequency"
      />
    </div>

    <div class="form-inline-group">
      <label for="sampling-procedure">
        Sampling Procedure
        <span class="form-label-details">
          random, stratified, census, etc
        </span>
      </label>
      <input
        type="text"
        id="sampling-procedure"
        :readOnly="readOnly"
        v-model="surveyDetails.procedure"
      />
    </div>

    <fieldset class="form-fieldset-child">
      <legend>Survey Language</legend>
      <label>
        <input
          type="radio"
          value="english"
          :disabled="readOnly"
          v-model="surveyDetails.language"
        />
        English
      </label>
      <label>
        <input
          type="radio"
          value="french"
          :disabled="readOnly"
          v-model="surveyDetails.language"
        />
        French
      </label>
      <label>
        <input
          type="radio"
          value="both"
          :disabled="readOnly"
          v-model="surveyDetails.language"
        />
        Both
      </label>
      <label>
        <input
          type="radio"
          value="other"
          :disabled="readOnly"
          v-model="surveyDetails.language"
        />
        Other
      </label>
    </fieldset>

    <div class="form-inline-group">
      <label for="respondent-sources">
        Respondent Sources
        <span class="form-label-details">
          tax rolls, client lists, etc.
        </span>
      </label>
      <input
        type="text"
        id="respondent-sources"
        :readOnly="readOnly"
        v-model="surveyDetails.sources"
      />
    </div>
  </fieldset>
</template>

<script>
export default {
  name: "DatasetSegmentsSurveyDetails",
  props: {
    surveyDetails: {
      type: Object,
      required: true
    },
    readOnly: {
      default: () => false,
      type: Boolean
    },
    validate: { type: Boolean }
  },
  data() {
    return {
      errMsg: {
        responseRate: null,
        surveyEndDate: null,
        surveyStartDate: null
      }
    }
  },
  methods: {
    onChangeResponseRate() {
      this.errMsg.responseRate = null
      if (this.surveyDetails.rate) this.surveyDetails.responseRateNa = false
    },
    validateFields() {
      let firstErr = null
      if (!this.surveyDetails.surveyStartDate) {
        this.errMsg.surveyStartDate = "Error: survey start date is required."
        if (!firstErr) firstErr = "start-date"
      }
      if (!this.surveyDetails.surveyEndDate) {
        this.errMsg.surveyEndDate = "Error: survey end date is required."
        if (!firstErr) firstErr = "end-date"
      }
      if (!this.surveyDetails.rate && !this.surveyDetails.responseRateNa) {
        this.errMsg.responseRate =
          "Error: enter a response rate or check 'response rate not available'."
        if (!firstErr) firstErr = "response-rate"
      }
      if (firstErr) this.$refs[firstErr].focus()
      return !(firstErr && firstErr.length > 0)
    }
  },
  watch: {
    validate: function(val) {
      if (val) this.validateFields()
    }
  }
}
</script>
