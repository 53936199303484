<template>
  <article class="project-analysis-item__wrapper">
    <!--------------------------------------------
    |
    | HEADER
    |
    --------------------------------------------->
    <div class="project-analysis-item__header-wrapper">
      <h3 class="project-analysis-item__header-wrapper-title">
        <VisibleText>Performance Analysis</VisibleText> #{{ rIndex }}
      </h3>
      <!--------------------------------------------
      |
      | ACTIONS
      |
      --------------------------------------------->
      <button
        class="project-analysis-item__header-wrapper-btn"
        @click.stop="saveToReport()"
        :disabled="propReadOnly"
      >
        <SvgIconDecorative
          class="project-analysis-item__header-wrapper-icon"
          icon="report"
        />
        <VisibleText>Save To report</VisibleText>
      </button>
      <button
        class="project-analysis-item__header-wrapper-btn delete"
        @click.stop="confirmDelete"
        :disabled="propReadOnly"
      >
        <SvgIconDecorative
          class="project-analysis-item__header-wrapper-icon-delete"
          icon="delete"
        />
        <VisibleText>Delete</VisibleText>
      </button>
    </div>

    <!--------------------------------------------
    |
    | PERFORMANCE ANALYSIS
    |
    --------------------------------------------->
    <div class="performance-analysis__wrapper">
      <!--------------------------------------------
      |
      | FETCHING SPINNER
      |
      --------------------------------------------->
      <Spinner
        :is-loading="analysisData.isFetching"
        :componentStyle="true"
        message="generating performance analysis..."
        v-if="analysisData.isFetching"
      />

      <!--------------------------------------------
      |
      | MAIN CONTENT
      |
      --------------------------------------------->
      <template v-else>
        <div class="performance-analysis__select-chart">
          <label
            :for="`performance-analysis__select-chart-${rIndex}`"
            class="inline-edit-text__label"
          >
            <VisibleText>Select performance chart type</VisibleText>
          </label>
          <span class="select-wrapper">
            <select
              :id="`performance-analysis__select-chart-${rIndex}`"
              class="performance-analysis__select-chart select"
              v-model="chartType"
            >
              <option
                v-for="(cType, index) in Object.keys(chartTypeMappings)"
                :key="`chart-type-${index}`"
                :value="cType"
              >
                {{ cType }}
              </option>
            </select>
          </span>
        </div>
        <div class="performance-analysis__chart-wrapper">
          <component
            class="performance-analysis__chart"
            :is="chartTypeMappings[chartType]"
            :value="chartNode"
            v-if="chartNode"
          >
          </component>
        </div>
        <ComparisonAnalysis
          ref="comparison-analysis"
          :index="rIndex"
          :data="analysisData"
          :type="comparisonTypeActive"
          :categories="comparisonCategories"
          @remove-category="removeCategory"
          v-if="comparisonTypeActive"
        />
        <div class="performance-analysis__comparison-options">
          <button class="comparison-btn__own" @click="comparisonType = 'own'">
            <VisibleText>Make your own comparisons</VisibleText>
          </button>
          <button
            class="comparison-btn__suggested"
            @click="comparisonType = 'suggested'"
            :disabled="isSuggestedComparisonActive"
          >
            <VisibleText>
              {{
                isSuggestedComparisonActive
                  ? "Suggested outliers"
                  : "Get suggested comparisons"
              }}
            </VisibleText>
          </button>
        </div>
      </template>
    </div>

    <!--------------------------------------------
    |
    | MODALS
    |
    --------------------------------------------->
    <MakeComparisonsModal
      class="performance-analysis__make-comparisons-modal"
      :show="!!comparisonType"
      :type="comparisonType || 'own'"
      :data="questionsData"
      @close-modal="closeModal"
      @confirmSelection="confirmSelection"
    />
  </article>
</template>

<script>
// Components
import {
  PigeonDocNodeModel,
  HorizontalBarChart,
  VerticalBarChart
} from "@pigeonline/pigeondoc"
import Spinner from "@/components/UI/Spinner.vue"
import PerformanceAnalysisChart from "@/components/Reports/helpers/performanceAnalysisChart.js"
import MakeComparisonsModal from "../Modals/MakeComparisons.vue"
import ComparisonAnalysis from "./ComparisonAnalysis.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import PerformanceAnalysisMixin from "@/utils/mixins/performanceAnalysisMixin.js"
import ProjectReportMixin from "@/utils/mixins/projectReportMixin.js"

export default {
  name: "PerformanceAnalysis",
  mixins: [DatasetDetailsMixin, PerformanceAnalysisMixin, ProjectReportMixin],
  components: {
    Spinner,
    HorizontalBarChart,
    VerticalBarChart,
    MakeComparisonsModal,
    ComparisonAnalysis
  },
  props: {
    analysisData: {
      type: Object,
      default: () => {}
    },
    questionsData: {
      type: Array,
      default: () => {}
    },
    index: {
      type: Number
    }
  },
  data() {
    return {
      chartType: "% Favorable",
      chartTypeMappings: {
        "% Favorable": "HorizontalBarChart"
      },
      comparisonType: null,
      comparisonTypeActive: null,
      comparisonCategories: [],
      unsavedChanges: false
    }
  },
  computed: {
    rIndex: function() {
      return this.project.performanceAnalysis.length - this.index
    },
    chartNode: function() {
      if (this.analysisData && this.analysisData.summary_table) {
        return new PigeonDocNodeModel(
          PerformanceAnalysisChart(
            this.analysisData.chartTypePerformance ||
              this.chartTypeMappings[this.chartType],
            this.analysisData,
            `Question Performance Chart #${this.rIndex}`,
            false
          )
        )
      }
      return null
    },
    isSuggestedComparisonActive: function() {
      return (
        this.comparisonCategories.length > 0 &&
        this.comparisonTypeActive == "suggested"
      )
    },
    isSaveBtnDisabled: function() {
      return this.propReadOnly
    },
    $comparisonEl: function() {
      return this.$refs["comparison-analysis"]
    },
    chartTypePerformance: function() {
      return this.chartTypeMappings[this.chartType]
    },
    chartTypeComparison: function() {
      if (!this.$comparisonEl) return null
      return this.$comparisonEl.chartTypeMappings[this.$comparisonEl.chartType]
    },
    comparisonChartNodes: function() {
      if (!this.$comparisonEl) return null
      return this.$comparisonEl.chartNodes.map(el => this.deepCloneObj(el))
    },
    comparisonChartNodesTitles: function() {
      if (!this.$comparisonEl) return null
      return this.$comparisonEl.chartNodesTitles
    },
    confirmStatus() {
      return this.$store.getters["confirm/getStatus"]
    }
  },
  mounted() {
    if (
      this.analysisData.comparisonCategories &&
      Array.isArray(this.analysisData.comparisonCategories)
    ) {
      this.comparisonCategories = this.analysisData.comparisonCategories
      this.comparisonTypeActive = this.analysisData.comparisonType
    }

    this.$watch(
      "comparisonCategories",
      function(val) {
        if (!val) return
        this.unsavedChanges = true
      }.bind(this),
      { deep: true }
    )
  },
  methods: {
    closeModal() {
      this.comparisonType = null
    },
    confirmSelection(selected) {
      this.comparisonTypeActive = this.comparisonType
      this.comparisonCategories = selected.filter(category =>
        this.containsInterestingStories(category[0])
      )

      // close modal
      this.closeModal()
    },
    containsInterestingStories(categoryID) {
      if (this.comparisonTypeActive !== "suggested") return true

      let contains = false
      for (let key in this.analysisData.summary_table) {
        let item = this.analysisData.summary_table[key]
        item.analysis_by_categories[categoryID].mean_values.map(function(el) {
          if (el.is_outlier) contains = true
        })
        if (contains) break
      }
      return contains
    },
    removeCategory(index) {
      this.comparisonCategories.splice(index, 1)

      // remove comparison component
      if (this.comparisonCategories.length === 0) {
        this.comparisonTypeActive = null
      }
    },

    /* Save and remove methods */
    async saveToReport() {
      if (this.propReadOnly) return
      try {
        this.setLoading(true)
        const organization = this.$store.getters["user/getOrganization"]
          ? this.$store.getters["user/getOrganization"].organization_name
          : ""

        await this.createReportIfEmpty(
          this.$options.filters.formatDate(this.project.last_modified_on),
          this.dataset.name,
          organization
        )
        const performanceUUID_report = await this.updateReportWithPerformance(
          this.project.report,
          this.project.performanceAnalysis[this.index].performanceUUID_report,
          this.analysisData,
          this.chartTypePerformance,
          this.chartTypeComparison,
          this.comparisonChartNodes,
          this.comparisonChartNodesTitles
        )
        // update performance analysis with newly generated or prev. performance UUID
        this.project.performanceAnalysis[
          this.index
        ].performanceUUID_report = performanceUUID_report
        await this.saveProject(this.project)
        this.onSuccessfulSaveToReport()
      } catch (e) {
        throw new Error("ProjectAnalysisPerformance:saveToReport " + e.message)
      } finally {
        this.setLoading(false)
      }
    },
    /** custom alert handler **/
    onSuccessfulSaveToReport() {
      this.setConfirmText({
        btn: "okay",
        title: "Performance Saved",
        message: "Performance data successfully saved to report."
      })
      this.setConfirmType("success")
      this.setConfirmSourceComponent("performance")
      this.setConfirmIsVisible(true)
    },
    /** delete handlers **/
    confirmDelete() {
      if (this.propReadOnly) return
      this.setConfirmText({
        btn: "yes",
        title: "Confirm Delete",
        message: "Are you sure you want to delete the performance analysis?"
      })
      this.setConfirmType("delete")
      this.setConfirmSourceComponent("performance")
      this.setConfirmTarget(this.index)
      this.setConfirmIsVisible(true)
    },
    async remove() {
      this.project.performanceAnalysis.splice(this.index, 1)
      await this.saveProject(this.project)
    }
  },
  watch: {
    confirmStatus: async function(val) {
      if (!val) return
      const targetIndex = this.$store.getters["confirm/getTarget"]
      const confirmType = this.$store.getters["confirm/getType"]
      const sourceComponent = this.$store.getters["confirm/getSourceComponent"]

      if (
        val &&
        sourceComponent === "performance" &&
        confirmType === "delete" &&
        this.index === targetIndex
      ) {
        await this.remove()
        this.resetConfirm()
      }
    },
    unsavedChanges: async function(val) {
      if (val) {
        Object.assign(this.project.performanceAnalysis[this.index], {
          chartTypePerformance: this.chartTypePerformance,
          chartTypeComparison: this.chartTypeComparison,
          comparisonCategories: this.comparisonCategories,
          comparisonType: this.comparisonTypeActive,
          last_modified_on: this.getCurrTimestamp()
        })
        await this.saveProject(this.project)

        // reset unsaved changes
        this.unsavedChanges = false
      }
    }
  }
}
</script>
