<template>
  <div class="dataset-segments__custom-input">
    <label class="dataset-segments__custom-input__label" :for="uuid">
      <span class="text"> <slot></slot> </span>
      <span class="desc"> <slot name="desc"></slot> </span>
    </label>
    <div class="dataset-segments__custom-input__input">
      <input
        type="text"
        class="input"
        :id="uuid"
        :readOnly="readOnly"
        @keydown.enter.prevent="add"
        v-model="tag"
      />
      <ButtonIconOnly
        class="button"
        icon="add"
        :id="`btn-${uuid}`"
        @click-handler="add"
      >
        Add keyword
      </ButtonIconOnly>
    </div>
  </div>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

export default {
  name: "DatasetSegmentsCustomInput",
  components: {
    ButtonIconOnly
  },
  props: {
    readOnly: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      tag: "",
      uuid: `segs${Date.now()}` //FIXME: update when pigeoncore has uuid implemented
    }
  },
  methods: {
    add() {
      this.$emit("add", this.tag)
      this.tag = ""
    }
  }
}
</script>
