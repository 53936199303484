<template>
  <div class="keywords-input-root">
    <div
      :class="{
        [wrapperClass + ' keywords-input']: true,
        active: isActive
      }"
      ref="kw-wrapper"
    >
      <KeywordTappable
        class="keywords-input-badge pill"
        :value="tag.value"
        :key="index"
        :also-found-ref="'also-found'"
        v-for="(tag, index) in tags"
        @ban="$emit('ban', $event)"
      >
        <template v-slot="{ remove }">
          <ButtonIconOnly
            icon="remove"
            :id="tag.value"
            class="keywords-input-remove"
            @click-handler="remove(index)"
          >
            <VisibleText>remove </VisibleText>{{ tag.value }}
          </ButtonIconOnly>
        </template>
      </KeywordTappable>
      <p class="sr-only" :id="uuid" v-if="!hintId">
        <VisibleText>Hit enter to add to list</VisibleText>
      </p>
      <input
        type="text"
        ref="taginput"
        aria-label="keyword"
        :aria-describedby="hintId || uuid"
        v-model="input"
        @keydown.enter.stop="tagFromInput"
        @keydown.8="removeLastTag"
        @focus="onFocus"
        @click="onClick"
        @blur="onBlur"
        @value="tags"
      />
    </div>
    <AlsoFound
      class="ki-also-found"
      ref="also-found"
      @save="keyword => addTag({ key: '', value: keyword })"
    >
      <button
        type="button"
        class="also-found__save-all-btn"
        @click="saveAllCurrent"
      >
        <SvgIconDecorative icon="add" />
        <VisibleText>ADD ALL</VisibleText>
      </button>
    </AlsoFound>
  </div>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import KeywordTappable from "./KeywordsInputKeywordTappable.vue"
import AlsoFound from "./AlsoFound.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

export default {
  name: "KeywordsInput",
  components: {
    ButtonIconOnly,
    KeywordTappable,
    AlsoFound,
    SvgIconDecorative
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },

    validate: {
      type: Function,
      default: () => true
    },

    deleteOnBackspace: {
      type: Boolean,
      default: false
    },

    addTagsOnBlur: {
      type: Boolean,
      default: false
    },

    wrapperClass: {
      type: String,
      default: "keywords-input-wrapper-default"
    },
    // if you want a custom hint message that exists outside of this component
    hintId: {
      type: String
    }
  },

  data() {
    return {
      tags: [],
      input: "",
      isActive: false,
      uuid: Date.now() //FIXME: when pigeon core is updated
    }
  },

  computed: {
    $alsoFound: function() {
      return this.$refs["also-found"]
    }
  },

  created() {
    this.tagsFromValue()
  },

  mounted() {
    // Emit an event
    this.$emit("initialized")
  },

  watch: {
    tags() {
      // Updating the hidden input
      this.hiddenInput = JSON.stringify(this.tags)

      // Update the bound v-model value
      this.$emit("input", this.tags)
    },
    value() {
      this.tagsFromValue()
    }
  },

  methods: {
    /**
     * Add a tag whether from user input
     *
     * @returns void
     */
    tagFromInput() {
      // If we're adding an unexisting tag
      let text = this.input.trim()

      // If the new tag is not an empty string and passes validation
      if (text.length && this.validate(text)) {
        this.input = ""

        let newTag = {
          key: "",
          value: text
        }

        this.addTag(newTag)
      }
    },

    /**
     * Add/Select a tag
     *
     * @param tag
     * @returns void | Boolean
     */
    addTag(tag) {
      // Attach the tag if it hasn't been attached yet
      if (!this.tagSelected(tag)) {
        this.tags.push(tag)

        // Emit events
        this.$nextTick(() => {
          this.$emit("tag-added", tag.value)
          this.$emit("keywords-updated")
        })
      }
    },

    /**
     * Remove the last tag in the tags array.
     *
     * @returns void
     */
    removeLastTag() {
      if (!this.input.length && this.deleteOnBackspace && this.tags.length) {
        this.removeTag(this.tags.length - 1)
      }
    },

    /**
     * Remove the selected tag at the specified index.
     *
     * @param index
     * @returns void
     */
    removeTag(index) {
      let tag = this.tags[index]

      this.tags.splice(index, 1)

      // Emit events
      this.$nextTick(() => {
        this.$emit("tag-removed", tag.value)
        this.$emit("keywords-updated")
      })
    },

    /**
     * Clear the list of selected tags.
     *
     * @returns void
     */
    clearTags() {
      this.tags.splice(0, this.tags.length)
    },

    /**
     * Replace the currently selected tags with the tags from the value.
     *
     * @returns void
     */
    tagsFromValue() {
      if (this.value && this.value.length) {
        if (!Array.isArray(this.value)) {
          alert("The v-model value must be an array!")

          return
        }

        let tags = this.value

        // Don't update if nothing has changed
        if (this.tags == tags) {
          return
        }

        this.clearTags()

        for (let tag of tags) {
          this.addTag(tag)
        }
      } else {
        if (this.tags.length == 0) {
          return
        }

        this.clearTags()
      }
    },

    /**
     * Check if a tag is already selected.
     *
     * @param tag
     * @returns Boolean
     */
    tagSelected(tag) {
      if (!tag) {
        return false
      }

      const searchQuery = tag.value.toLowerCase()

      for (let selectedTag of this.tags) {
        const compareable = selectedTag.value.toLowerCase()

        if (
          compareable.length == searchQuery.length &&
          compareable.search(searchQuery) > -1
        ) {
          return true
        }
      }

      return false
    },

    /**
     * Clear the input.
     *
     * @returns void
     */
    clearInput() {
      this.input = ""
    },

    /**
     * Process the onfocus event.
     *
     * @param e
     * @returns void
     */
    onFocus(e) {
      this.$emit("focus", e)

      this.isActive = true
    },

    /**
     * Process the onClick event.
     *
     * @param e
     * @returns void
     */
    onClick(e) {
      this.$emit("click", e)

      this.isActive = true
    },

    /**
     * Process the onblur event.
     *
     * @param e
     * @returns void
     */
    onBlur(e) {
      this.$emit("blur", e)

      if (this.addTagsOnBlur) {
        // Add the inputed tag
        this.tagFromInput(true)
      }

      this.isActive = false
    },

    /* Also found methods */
    saveAllCurrent() {
      for (let value of this.$alsoFound.similar) {
        this.addTag({ key: "", value: value })
      }
    }
  }
}
</script>
