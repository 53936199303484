<template>
  <div class="comparison-analysis">
    <Spinner
      class="comparison-analysis__spinner"
      :is-loading="showSpinner"
      :componentStyle="true"
      message="loading..."
      v-if="showSpinner"
    />
    <MessageBlock
      class="comparison-analysis__empty-message"
      message-type="information"
      title="No interesting stories"
      v-if="noInterestingStories"
    >
      Sorry, we could not find any interesting stories on the dataset. Please
      try making your own comparisons.
    </MessageBlock>

    <div class="comparison-analysis__wrapper" v-if="chartNodes.length > 0">
      <h4 class="title">{{ title }}</h4>
      <div class="comparison-analysis__heading">
        <div class="performance-analysis__select-chart">
          <label
            :for="`comparison-analysis__select-chart-${index}`"
            class="inline-edit-text__label"
            style="color: var(--base-colour)"
          >
            <VisibleText>Select comparision chart type</VisibleText>
          </label>
          <span class="select-wrapper">
            <select
              :id="`comparison-analysis__select-chart-${index}`"
              class="select-chart-type select"
              v-model="chartType"
            >
              <option
                v-for="(cType, index) in Object.keys(chartTypeMappings)"
                :key="`chart-type-${index}`"
                :value="cType"
              >
                {{ cType }}
              </option>
            </select>
          </span>
        </div>
        <div class="comparison-analysis__chart-legend">
          <span
            class="inline-edit-text__label legend-text"
            style="color: var(--base-colour)"
            ><VisibleText>Legend</VisibleText></span
          >
          <span
            class="text"
            :id="`${type}-outlier-${uuid}`"
            :style="chartLegend[type].style"
            v-for="(type, index) in Object.keys(chartLegend)"
            :key="`chart-legend-${index}`"
          >
            {{ chartLegend[type].text }}
          </span>
        </div>
      </div>
      <div
        class="comparison-analysis__chart-wrapper"
        :key="`comparison-chart-${index}`"
        v-for="(chartNode, index) in chartNodes"
      >
        <component
          class="comparison-analysis__chart-content"
          :is="chartTypeMappings[chartType]"
          :value="chartNode"
          :caption="getCatName(index)"
          :index="index"
          v-if="chartNode"
        >
          <template
            v-slot:table-options="{ isVisible, toggleIsVisible }"
            v-if="chartTypeMappings[chartType] == 'Table'"
          >
            <button class="table-node__options-btn" @click="toggleIsVisible">
              {{ isVisible ? "hide" : "show" }}
            </button>
            <button class="table-node__options-btn" @click="removeCat(index)">
              <VisibleText>remove</VisibleText>
            </button>
          </template>
        </component>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { Table, PigeonDocNodeModel } from "@pigeonline/pigeondoc"
import Spinner from "@/components/UI/Spinner.vue"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import ComparisonAnalysisChart from "@/components/Reports/helpers/comparisonAnalysisChart.js"
import MakeComparisonsModal from "../Modals/MakeComparisons.vue"

export default {
  name: "ComparisonAnalysis",
  components: {
    Spinner,
    ButtonIconOnly,
    MessageBlock,
    Table,
    MakeComparisonsModal
  },
  props: {
    index: {
      type: Number
    },
    type: {
      type: String
    },
    data: {
      type: Object,
      default: () => {}
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showSpinner: true,
      chartType: "Tables",
      chartTypeMappings: {
        Tables: "Table"
      },
      chartLegend: {
        positive: {
          style: {
            backgroundColor: "var(--pixie-green)",
            fontStyle: "italic"
          },
          text: "Greater or equal to mean (outlier)"
        },
        negative: {
          style: {
            backgroundColor: "var(--shilo-red)",
            textDecoration: "underline"
          },
          text: "Lower than mean (outlier)"
        }
      },
      chartNodes: [],
      chartNodesTitles: [],
      uuid: Date.now()
    }
  },
  mounted() {
    // fake loader
    this.$watch(
      "categories",
      function() {
        this.showSpinner = true
        setTimeout(
          function() {
            this.computeChartNodes()
            this.showSpinner = false
          }.bind(this),
          1000
        )
      }.bind(this),
      { immediate: true, deep: true }
    )
  },
  computed: {
    title: function() {
      return this.type === "suggested" ? "Generated comparisons" : "Comparisons"
    },
    noInterestingStories: function() {
      return this.type === "suggested" && this.categories.length === 0
    }
  },
  methods: {
    computeChartNodes() {
      if (this.data && this.data.summary_table) {
        this.chartNodes = this.categories.map(
          category =>
            new PigeonDocNodeModel(
              ComparisonAnalysisChart(
                this.chartTypeMappings[this.chartType],
                this.chartLegend,
                this.data,
                category[0], // categoryID
                category[1], // categoryName
                category[2] // categoryValues
              )
            )
        )
        this.chartNodesTitles = this.categories.map(category => category[1])
      } else {
        this.chartNodes = []
        this.chartNodesTitles = []
      }
    },
    getCatName(index) {
      return this.chartNodesTitles[index]
    },
    removeCat(index) {
      this.$emit("remove-category", index)
      this.$nextTick(
        function() {
          this.computeChartNodes()
        }.bind(this)
      )
    }
  }
}
</script>
