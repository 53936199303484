<template>
  <div class="landing-page__wrapper">
    <section
      class="landing-page__hero-section"
      :style="{
        backgroundImage: `url(${require('@/assets/landing/hero.png')})`
      }"
    >
      <div class="landing-page__head">
        <img
          class="landing-page__image landing-page__image--left landing-page__image--logo landing-page__image--padded"
          src="/assets/landing/RAI_logo.png"
          alt="Research AI"
        />
        <nav aria-label="primary" class="landing-page__nav">
          <ul class="landing-page__nav-inner">
            <li class="landing-page__button landing-page__button--small-dark">
              <label for="lang-select" class="label">
                <SvgIconDecorative icon="translate" />
                <span class="sr-only"><VisibleText>language</VisibleText></span>
              </label>
              <select
                class="landing-page__select landing-page__font--white landing-page__font--bold"
                id="lang-select"
                @change="changeLanguage($event)"
              >
                <option value="en">
                  <VisibleText>English</VisibleText>
                </option>
                <option value="fr">
                  <VisibleText>French</VisibleText>
                </option>
              </select>
            </li>
            <li>
              <router-link
                class="landing-page__button landing-page__button--small-dark"
                to="/login"
                style="display: inline-block;"
              >
                <VisibleText v-if="$pigeonline.userIsAuthenticated()"
                  >Return to app</VisibleText
                >
                <VisibleText v-else>Sign up / Log in</VisibleText>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div class="landing__content-box">
        <div
          class="landing-page__content-box landing-page__content-box--padded-bottom"
        >
          <div class="landing-page__seven landing-page__column-widen">
            <h1
              class="landing-page__font--h1 landing-page__font--white landing-page__font--outline"
            >
              <VisibleText
                >Automate research tasks. Save hours of effort and weeks of
                waiting.
              </VisibleText>
            </h1>
            <div
              class="landing-page__font landing-page__font--dark-blue landing-page__font--right-padding landing-page__font--large"
            >
              <VisibleText
                >Using advanced statistics and AI, we seamlessly and instantly
                identify and rank opportunities for improvement of
                citizen/customer service or employee engagement.
              </VisibleText>
            </div>
          </div>
          <div class="landing-page__five">
            <img
              class="landing-page__image landing-page__image--inherit-width landing-page__image--mobile-thin"
              src="/assets/landing/hero_image.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
    <section>
      <h2 class="landing-page__font--h2 landing-page__font--center">
        <VisibleText>How it works</VisibleText>
      </h2>
      <div
        class="landing-page__content-box landing-page__content-box--large-padding landing-page__content-box--mobile-flexcolumn"
      >
        <div
          class="landing-page__three landing-page__shadow landing-page__font landing-page__font--center landing-page__three--mobile-widen"
        >
          <div class="landing-page__font--bold landing-page__font--right">
            1.
          </div>
          <img
            class="landing-page__image landing-page__image--inherit-width--large-pad"
            src="/assets/landing/collect-data_icon.png"
            alt=""
          />
          <h3 class="landing-page__font--h3">Collect data</h3>
          <p>
            <VisibleText
              >Easily upload survey data from any source (e.g. excel spreadsheet
              or your current survey tool).
            </VisibleText>
          </p>
          <span>
            <VisibleText>Need help getting started?</VisibleText>
            <button
              class="landing-page__button landing-page__button--small"
              @click="isContactUsExpanded = !isContactUsExpanded"
            >
              <VisibleText>Get in touch</VisibleText>
            </button>
          </span>
        </div>
        <div
          class="landing-page__three landing-page__shadow landing-page__font landing-page__font--center landing-page__three--mobile-widen"
        >
          <div class="landing-page__font--bold landing-page__font--right">
            2.
          </div>
          <img
            class="landing-page__image landing-page__image--inherit-width--large-pad"
            src="/assets/landing/analyze_icon.png"
            alt=""
          />
          <h3 class="landing-page__font--h3">
            <VisibleText>Analyze</VisibleText>
          </h3>
          <p>
            <VisibleText
              >Our analysis tools help you find meaningful insights thanks to
              suggestive AI. Detailed results that used to be painstaking can
              now be found in clicks.
            </VisibleText>
          </p>
          <span>
            <VisibleText>Already have data?</VisibleText>
            <router-link
              tag="button"
              to="/register"
              class="landing-page__button landing-page__button--small"
            >
              <VisibleText>Register for free</VisibleText>
            </router-link>
          </span>
        </div>
        <div
          class="landing-page__three landing-page__shadow landing-page__font landing-page__font--center landing-page__three--mobile-widen"
        >
          <div class="landing-page__font--bold landing-page__font--right">
            3.
          </div>
          <img
            class="landing-page__image landing-page__image--inherit-width--large-pad"
            src="/assets/landing/improve_icon.png"
            alt=""
          />
          <h3 class="landing-page__font--h3">
            <VisibleText>Improve</VisibleText>
          </h3>
          <p>
            <VisibleText
              >Quick and easy analysis identifies and ranks what issues need
              attention. Improve objectives like client satisfaction and
              employee engagement.
            </VisibleText>
          </p>
        </div>
      </div>
      <div class="landing-page__content-center">
        <button
          class="landing-page__button"
          @click="isVideoClipExpanded = !isVideoClipExpanded"
        >
          <VisibleText>Watch video</VisibleText>
        </button>
      </div>
    </section>
    <div class="landing-page__grey_skew"></div>
    <section class="landing-page__grey-section">
      <h2 class="landing-page__font--h2 landing-page__font--center">
        <VisibleText>What you get</VisibleText>
      </h2>
      <div
        class="landing-page__content-box landing-page__content-box--mobile-flex"
        role="tablist"
        aria-label="What you get"
      >
        <button
          class="landing-page__button landing-page__button--mobile-shrink"
          id="tab-drivers"
          role="tab"
          :aria-selected="tab_active === 0"
          :class="[
            tab_active === 0
              ? 'landing-page__button--light-blue--active'
              : 'landing-page__button--light-blue'
          ]"
          v-on:click="tab_active = 0"
        >
          <VisibleText>Drivers and priorities</VisibleText>
        </button>
        <button
          class="landing-page__button landing-page__button--mobile-shrink"
          id="tab-benchmarking"
          role="tab"
          :aria-selected="tab_active === 1"
          :class="[
            tab_active === 1
              ? 'landing-page__button--light-blue--active'
              : 'landing-page__button--light-blue'
          ]"
          v-on:click="tab_active = 1"
        >
          <VisibleText>Benchmarking</VisibleText>
        </button>
        <button
          class="landing-page__button landing-page__button--mobile-shrink"
          id="tab-performance"
          role="tab"
          :aria-selected="tab_active === 2"
          :class="[
            tab_active === 2
              ? 'landing-page__button--light-blue--active'
              : 'landing-page__button--light-blue'
          ]"
          v-on:click="tab_active = 2"
        >
          <VisibleText>Performance and segmentation</VisibleText>
        </button>
        <button
          class="landing-page__button landing-page__button--mobile-shrink"
          id="tab-text"
          role="tab"
          :aria-selected="tab_active === 3"
          :class="[
            tab_active === 3
              ? 'landing-page__button--light-blue--active'
              : 'landing-page__button--light-blue'
          ]"
          v-on:click="tab_active = 3"
        >
          <VisibleText>Text analysis</VisibleText>
        </button>
      </div>
      <div
        v-show="tab_active === 0"
        aria-labelledby="tab-drivers"
        class="landing-page__content-box landing-page__content-box--large-padding"
        role="tabpanel"
        :aria-hidden="tab_active !== 0"
      >
        <div class="landing-page__four landing-page__font">
          <h3 class="landing-page__font--h3--large">
            <VisibleText>Drivers and priorities</VisibleText>
          </h3>
          <p>
            <VisibleText
              >Predict what drives specific outcomes (e.g. customer
              satisfaction) using an AI that will cycle through a range of
              models until it finds the best ones.
            </VisibleText>
          </p>
        </div>
        <div class="landing-page__seven">
          <img
            class="landing-page__image landing-page__image--inherit-width"
            src="/assets/landing/screenshot_drivers.png"
            alt="Screenshot of drivers analysis"
          />
        </div>
      </div>
      <div
        v-show="tab_active === 1"
        aria-labelledby="tab-benchmarking"
        class="landing-page__content-box landing-page__content-box--large-padding"
        role="tabpanel"
        :aria-hidden="tab_active !== 1"
      >
        <div class="landing-page__four landing-page__font">
          <h3 class="landing-page__font--h3--large">
            <VisibleText>Benchmarking</VisibleText>
          </h3>
          <p>
            <VisibleText
              >Anonymously compare your results to global databases or build
              your own list of questions to allow your organization to compare
              peers and segments.
            </VisibleText>
          </p>
        </div>
        <div class="landing-page__seven">
          <img
            class="landing-page__image landing-page__image--inherit-width"
            src="/assets/landing/screenshot_benchmarking.png"
            alt="Screenshot of benchmarking analysis"
          />
        </div>
      </div>
      <div
        v-show="tab_active === 2"
        aria-labelledby="tab-performance"
        class="landing-page__content-box landing-page__content-box--large-padding"
        role="tabpanel"
        :aria-hidden="tab_active !== 2"
      >
        <div class="landing-page__four landing-page__font">
          <h3 class="landing-page__font--h3--large">
            <VisibleText>Performance and segmentation</VisibleText>
          </h3>
          <p>
            <VisibleText
              >Get an immediate summary of your data, and let the AI find
              interesting outliers, cross-tabs, or segments you may have missed.
            </VisibleText>
          </p>
        </div>
        <div class="landing-page__seven">
          <img
            class="landing-page__image landing-page__image--inherit-width"
            src="/assets/landing/screenshot_performance.png"
            alt="Screenshot of performance analysis"
          />
        </div>
      </div>
      <div
        v-show="tab_active === 3"
        aria-labelledby="tab-text"
        class="landing-page__content-box landing-page__content-box--large-padding"
        role="tabpanel"
        :aria-hidden="tab_active !== 3"
      >
        <div class="landing-page__four landing-page__font">
          <h3 class="landing-page__font--h3--large">
            <VisibleText>Text analysis</VisibleText>
          </h3>
          <p>
            <VisibleText
              >Find important themes hiding in your open-ended text data using
              smart-searching tools or let the AI suggest topics and themes for
              you.
            </VisibleText>
          </p>
        </div>
        <div class="landing-page__seven">
          <img
            class="landing-page__image landing-page__image--inherit-width"
            src="/assets/landing/screenshot_text.png"
            alt="Screenshot of text analysis"
          />
        </div>
      </div>
    </section>
    <section
      class="landing-page__hero-section landing-page__hero-section--padded-top landing-page__hero-section--mobile-fit-height"
      :style="{
        backgroundImage: `url(${require('@/assets/landing/blue_section.png')})`
      }"
    >
      <h2
        class="landing-page__font--h2 landing-page__font--center landing-page__font--white landing-page__font--outline"
      >
        <VisibleText>Useful for</VisibleText>
      </h2>
      <div
        class="landing-page__content-box landing-page__font landing-page__content-box--large-padding landing-page__content-box--mobile-flexcolumn"
      >
        <div
          class="landing-page__three landing-page__shadow landing-page__font--center landing-page__three--mobile-widen"
        >
          <img
            class="landing-page__image landing-page__image--inherit-width--large-pad"
            src="/assets/landing/leaders_icon.png"
            alt=""
          />
          <h3 class="landing-page__font--h3">
            <VisibleText>Managers</VisibleText>
            ...
          </h3>
          <p>
            ...
            <VisibleText
              >looking for a simple way to collect, analyze and share feedback
              with staff and executives.
            </VisibleText>
          </p>
        </div>
        <div
          class="landing-page__three landing-page__shadow landing-page__font--center landing-page__three--mobile-widen"
        >
          <img
            class="landing-page__image landing-page__image--inherit-width--large-pad"
            src="/assets/landing/research_icon.png"
            alt=""
          />
          <h3 class="landing-page__font--h3">
            <VisibleText>Research teams...</VisibleText>
            ...
          </h3>
          <p>
            <VisibleText
              >...with limited experience looking to adopt analytics with little
              risk or setup.
            </VisibleText>
          </p>
        </div>
        <div
          class="landing-page__three landing-page__shadow landing-page__font--center landing-page__three--mobile-widen"
        >
          <img
            class="landing-page__image landing-page__image--inherit-width--large-pad"
            src="/assets/landing/corporate_icon.png"
            alt=""
          />
          <h3 class="landing-page__font--h3">
            <VisibleText>Leaders...</VisibleText>
            ...
          </h3>
          <p>
            ...
            <VisibleText
              >looking for an analytics platform that fits seamlessly with your
              digitization strategies.
            </VisibleText>
          </p>
        </div>
      </div>
    </section>
    <section class="landing-page__section--padded-top">
      <h2 class="landing-page__font--h2 landing-page__font--center">
        <VisibleText>Why we're different</VisibleText>
      </h2>
      <div
        class="landing-page__content-box landing-page__font landing-page__content-box--mobile-flexcolumn"
      >
        <div
          class="landing-page__three landing-page__shadow landing-page__shadow--add-margin landing-page__font--center landing-page__three--mobile-widen"
        >
          <h3 class="landing-page__font--h3">
            <VisibleText>Privacy-first</VisibleText>
          </h3>
          <p>
            <VisibleText
              >ResearchAI's insights engine was built from the ground-up with
              privacy in mind. We do not use any public clouds, and we cherish
              the importance of encryption, right to be forgotten, and data
              sovereignty.
            </VisibleText>
          </p>
        </div>
        <div
          class="landing-page__three landing-page__shadow landing-page__shadow--add-margin landing-page__font--center landing-page__three--mobile-widen"
        >
          <h3 class="landing-page__font--h3">
            <VisibleText>A focus on Return on Investment</VisibleText>
          </h3>
          <p>
            <VisibleText
              >We focus on an out-of-box solution that is easy to set up and
              use. You don't pay until you see meaningful results.
            </VisibleText>
          </p>
        </div>
        <div
          class="landing-page__three landing-page__shadow landing-page__shadow--add-margin landing-page__font--center landing-page__three--mobile-widen"
        >
          <h3 class="landing-page__font--h3">
            <VisibleText>Supportive AI</VisibleText>
          </h3>
          <p>
            <VisibleText
              >A growing collection of machine learning algorithms are designed
              to assist you and give you more control, not less.
            </VisibleText>
          </p>
        </div>
        <div
          class="landing-page__three landing-page__shadow landing-page__shadow--add-margin landing-page__font--center landing-page__three--mobile-widen"
        >
          <h3 class="landing-page__font--h3">
            <VisibleText>Platform for solving business problems</VisibleText>
          </h3>
          <p>
            <VisibleText
              >We work with leading practitioners to bring together the best
              models solving business challenges. We are a trusted partner for
              the Canadian Public Sector.
            </VisibleText>
          </p>
        </div>
      </div>
    </section>
    <section
      class="landing-page__grey-section landing-page__grey-section--margin-top"
    >
      <div
        aria-label="use-cases-heading"
        aria-describedby="carousel-sr-instructions"
        class="landing-page__carousel-wrapper"
        role="region"
        tabindex="0"
        @focus="onCarouselFocus"
      >
        <h2
          class="landing-page__font--h2 landing-page__font--center"
          id="use-cases-heading"
        >
          <VisibleText>Use cases</VisibleText>
        </h2>
        <ul class="landing-page__carousel" :style="carousel_transform">
          <li class="landing-page__carousel__item">
            <div
              class="landing-page__split-content landing-page__split-content--left"
            >
              <h3
                class="landing-page__font--h3 landing-page__font--white landing-page__font--padded"
              >
                <VisibleText
                  >Canadian Province - Vehicle and fleet management agency
                </VisibleText>
              </h3>
              <div
                class="landing-page__font--h3 landing-page__font--white landing-page__font--padded"
              >
                <VisibleText>Saved $20,000 instantly</VisibleText>
              </div>
            </div>
            <div
              class="landing-page__split-content landing-page__split-content--right"
            >
              <p class="landing-page__font landing-page__font--padded">
                <VisibleText
                  >A manager of this agency ran a quick survey prior to making
                  an investment to improve a customer waiting center. Using
                  ResearchAI they found that these investments would not improve
                  satisfaction and that they were outperforming their peers.
                </VisibleText>
              </p>
            </div>
          </li>
          <li class="landing-page__carousel__item">
            <div
              class="landing-page__split-content landing-page__split-content--left"
            >
              <h3
                class="landing-page__font--h3 landing-page__font--white landing-page__font--padded"
              >
                <VisibleText>Canadian Province - Service center</VisibleText>
              </h3>
              <div
                class="landing-page__font--h3 landing-page__font--white landing-page__font--padded"
              >
                <VisibleText
                  >30,000 customers' performance reported in 1/10th the time and
                  effort
                </VisibleText>
              </div>
            </div>
            <div
              class="landing-page__split-content landing-page__split-content--right"
            >
              <p class="landing-page__font landing-page__font--padded">
                <VisibleText
                  >The management team of one of Canada's largest service
                  centers have been keen to upgrade their strategic service
                  performance system. What used to take weeks to build reports
                  across multiple service centers can now be done in days.
                </VisibleText>
              </p>
            </div>
          </li>
          <li class="landing-page__carousel__item">
            <div
              class="landing-page__split-content landing-page__split-content--left"
            >
              <h3
                class="landing-page__font--h3 landing-page__font--white landing-page__font--padded"
              >
                <VisibleText
                  >Institute for Citizen-Centred Services
                </VisibleText>
              </h3>
              <div
                class="landing-page__font--h3 landing-page__font--white landing-page__font--padded"
              >
                <VisibleText
                  >Digitized a benchmarking service allowing it to be done in
                  clicks instead of weeks.
                </VisibleText>
              </div>
            </div>
            <div
              class="landing-page__split-content landing-page__split-content--right"
            >
              <p
                class="landing-page__font landing-page__font--padded landing-page__font--black"
              >
                <VisibleText>
                  <a
                    class="landing-page__font--black"
                    href="https://citizenfirst.ca/"
                    ref="carousel-iccs-link"
                    tabindex="-1"
                  >
                    The ICCS
                  </a>
                  hosts the largest public sector benchmarking service allowing
                  managers to compare their performance to peers. It used to be
                  a manual process, but now ICCS partners can self-serve their
                  benchmarking in minutes.
                </VisibleText>
              </p>
            </div>
          </li>
        </ul>
        <div class="landing-page__content-center">
          <button @click="nextCarousel" class="landing-page__button">
            <VisibleText>Next case</VisibleText>
          </button>
        </div>
      </div>
      <span class="sr-only" aria-live="polite">
        {{ carousel_curr_item }}
      </span>
    </section>
    <p id="carousel-sr-instructions" hidden>
      <VisibleText
        >Use the next case button to navigate between slides
      </VisibleText>
    </p>
    <section
      class="landing-page__hero-section landing-page__hero-section--padded-top landing-page__hero-section--increase-background"
      :style="{
        backgroundImage: `url(${require('@/assets/landing/blue_section2.png')})`
      }"
    >
      <div class="landing-page__content-box">
        <div class="landing-page__seven">
          <img
            class="landing-page__image landing-page__image--inherit-width landing-page__image--inherit-width--small-pad landing-page__image--reverse-margin-top"
            src="/assets/landing/hero_image2.png"
            alt=""
          />
        </div>
        <div class="landing-page__five">
          <div class="landing-page__shadow">
            <h2 class="landing-page__font--h2">
              <VisibleText>What problems can we help you solve?</VisibleText>
            </h2>
            <button
              class="landing-page__button"
              @click="isContactUsExpanded = !isContactUsExpanded"
            >
              <VisibleText>Get in touch</VisibleText>
            </button>
          </div>
        </div>
      </div>
    </section>
    <footer>
      <div
        class="landing-page__content-center landing-page__content-box--no-top-padding"
      >
        <h2 class="landing-page__font--h3">
          <VisibleText>Contact us</VisibleText>
        </h2>
      </div>
      <div class="landing-page__content-box landing-page__content-box--center">
        <div>
          <img
            class="landing-page__image landing-page__image--tile-size landing-page__image--inline"
            src="/assets/landing/mail_icon.png"
            alt=""
          />
          <div style="display: inline-block;" class="landing-page__font">
            <div class="landing-page__font--blue">
              <VisibleText>Email</VisibleText>
            </div>
            <div>research-ai@pigeonline.io</div>
          </div>
        </div>
        <a href="https://www.linkedin.com/company/25166569/" target="_blank">
          <img
            class="landing-page__image landing-page__image--tile-size landing-page__image--inline"
            src="/assets/landing/linkedin.png"
            alt=""
          />
          <span style="display: inline-block;" class="landing-page__font">
            <span
              class="landing-page__footer-text-block landing-page__font--blue"
            >
              LinkedIn
            </span>
            <span
              class="landing-page__footer-text-block landing-page__font--black landing-page__font--underline"
            >
              <VisibleText>Follow us!</VisibleText>
              <SvgIconDecorative icon="externalLink" style="width: 1rem" />
            </span>
          </span>
          <span class="sr-only">
            <VisibleText>(opens in a new tab)</VisibleText>
          </span>
        </a>
      </div>
      <div class="landing-page__content-box">
        <a
          href="https://pigeonline.io"
          class="landing-page__six landing-page__font landing-page__font--margined-top landing-page__font--black landing-page__font--underline"
        >
          <VisibleText>Copyright © 2020 PigeonLine Inc.</VisibleText>
        </a>
        <div
          class="landing-page__six landing-page__font landing-page__font--right landing-page__font--margined-top"
        >
          <VisibleText>PRIVACY POLICY</VisibleText>
        </div>
      </div>
    </footer>
    <ContactUs
      v-bind:status="isContactUsExpanded"
      @close="isContactUsExpanded = false"
    ></ContactUs>
    <VideoClip
      v-bind:status="isVideoClipExpanded"
      @close="isVideoClipExpanded = false"
    ></VideoClip>
  </div>
</template>
<script>
import ContactUs from "@/components/ContactUs/ContactUs.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import VideoClip from "@/components/VideoClip/VideoClip.vue"

import { mapActions } from "vuex"

export default {
  name: "landing",
  components: {
    ContactUs,
    SvgIconDecorative,
    VideoClip
  },
  data() {
    return {
      tab_active: 0,
      carousel_active: 1,
      carousel_transform: null,
      carousel_curr_item: null,
      transition_class: null,
      isContactUsExpanded: false,
      isVideoClipExpanded: false
    }
  },
  created() {
    document.title = `About | ${this.$theme.theme.pageTitle}`
  },
  methods: {
    ...mapActions(["setLanguage", "setLanguageDebug"]),
    changeLanguage(language) {
      this.setLanguage(language.target.value)
    },
    onCarouselFocus() {
      // set to currently active carousel item
      let length = document.getElementsByClassName(
        "landing-page__carousel__item"
      ).length
      this.carousel_curr_item = `Item ${this.carousel_active} of ${length}`
    },
    nextCarousel() {
      let length = document.getElementsByClassName(
        "landing-page__carousel__item"
      ).length
      if (this.carousel_active !== length) {
        let shift_amount = 130 * this.carousel_active
        this.carousel_transform =
          "transform: translateX(-" + shift_amount + "%);"
        this.carousel_active = this.carousel_active + 1
      } else {
        this.carousel_active = 1
        this.carousel_transform = "transform: translateX(0);"
      }

      // set tabindex of ICCS link
      if (this.carousel_active === 3) {
        this.$refs["carousel-iccs-link"].setAttribute("tabindex", "0")
      } else {
        this.$refs["carousel-iccs-link"].setAttribute("tabindex", "-1")
      }

      // curr item text for sr
      this.carousel_curr_item = `Item ${this.carousel_active} of ${length}`
    }
  }
}
</script>
