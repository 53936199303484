// Helpers
import { mapGetters } from "vuex"

// Services
import profilesService from "@/services/profilesService.js"

export default {
  data() {
    return {
      isMounted: false,
      PROFILES_SERVICE: new profilesService(this.$pigeonline),
      currViewIdx: 0,
      unsavedChanges: false,
      users: [],
      resetListener: false
    }
  },
  computed: {
    ...mapGetters("user", {
      profile: "getProfile"
    }),
    modalGroups() {
      if (!this.isMounted) return
      return this.$refs["share-modal"].$el.getElementsByClassName("modal-group")
    },
    usersShared() {
      return this.users
        .filter(user => user.type == "shared" || user.type == "selected")
        .sort((a, b) => new String(a.label).localeCompare(new String(b.label)))
    },
    DEFAULT_PERMS() {
      return {
        project: ["read"],
        dataset: ["read"]
      }
    },
    DEFAULT_PERMS_LABELS() {
      return {
        project: [
          { label: "Read", value: "read", disabled: true },
          { label: "Edit", value: "edit" }
        ],
        dataset: [
          { label: "Read", value: "read", disabled: true },
          { label: "Edit", value: "edit" }
        ]
      }
    },
    SHARING_KEYS() {
      return Object.keys(this.DEFAULT_PERMS)
    },
    SHARING_PK() {
      return "project" // modify this to `dataset` if it's the main project being shared
    },
    PRIMARY_SHARED() {
      return (this[this.SHARING_PK] && this[this.SHARING_PK].shared) || []
    },
    IS_OWNER() {
      let obj = {}
      this.SHARING_KEYS.map(
        function(key) {
          if (!this[key]) return
          obj[key] =
            this.profile && this.profile.django_ref_id === this[key].owner
        }.bind(this)
      )
      return obj
    },
    shareUrl() {
      const domain = `${window.location.protocol}//${window.location.hostname}`
      const port = window.location.port ? `:${window.location.port}` : ""
      const path = this.$route.path
      return `${domain}${port}${path}`
    }
  },
  methods: {
    async mount() {
      this.isMounted = true
      this.users = await this.fetchUsers()
      this.goToView(0)
    },
    bkCompatiblePk(r) {
      return ["string", "number"].includes(typeof r) ? r : r.pk || r.id || -1
    },
    find(user, arr) {
      return arr.filter(r =>
        [user.id, user.email].includes(this.bkCompatiblePk(r))
      )[0]
    },
    sharingPerms(user) {
      let perms = { ...this.DEFAULT_PERMS }
      this.SHARING_KEYS.map(
        function(key) {
          if (!this[key]) return
          if (this.find(user, this[key].shared)) {
            perms[key] = this.find(user, this[key].shared).perms || perms[key]
          }
        }.bind(this)
      )
      return perms
    },
    async fetchUsers() {
      try {
        let response = await this.PROFILES_SERVICE.users()
        let internalUsers = response.reduce((users, user) => {
          let temp = {}
          temp["id"] = user.id
          temp["label"] =
            !user.fullname || user.fullname == "" ? user.email : user.fullname
          temp["value"] = user.email
          temp["type"] = this.find(user, this.PRIMARY_SHARED)
            ? "shared"
            : "suggested"
          temp["permissions"] = this.sharingPerms(user)
          users.push(temp)
          return users
        }, [])
        return internalUsers
          .concat(
            this.PRIMARY_SHARED.filter(
              r =>
                !this.find(
                  { id: this.bkCompatiblePk(r), email: this.bkCompatiblePk(r) },
                  internalUsers
                )
            ).map(row => {
              return {
                id: this.bkCompatiblePk(row),
                label: this.bkCompatiblePk(row),
                value: this.bkCompatiblePk(row),
                type: "shared",
                permissions: this.sharingPerms({ id: row.pk, email: row.pk })
              }
            })
          )
          .sort((a, b) =>
            new String(a.label).localeCompare(new String(b.label))
          )
      } catch (e) {
        throw e
      }
    },
    addNewUser(user) {
      user["permissions"] = this.DEFAULT_PERMS
      this.users = [...this.users, user]
    },
    copy() {
      // copy project url to clipboard
      const copyText = document.getElementById("share-url")
      copyText.select()
      copyText.setSelectionRange(0, 99999)
      document.execCommand("copy")

      this.$refs["copy-url"].textContent = "Successfully copied!"
    },
    saveChanges() {
      this.$emit("saveChanges", this.usersShared, this.SHARING_KEYS)
    },
    closeModal() {
      this.$emit("closeModal")
    },

    // Go to view
    goToView(index) {
      if (index >= this.modalGroups.length || index < 0) return
      if (index > 0) {
        this.modalGroups[index - 1].style.display = "none"
        this.$nextTick(() => this.modalGroups[index].focus())
      }
      if (index < this.modalGroups.length - 1)
        this.modalGroups[index + 1].style.display = "none"
      this.modalGroups[index].style.display = "grid"
      this.modalGroups[index].parentElement.scrollTop = 0
      this.currViewIdx = index
      this.resetListener = !this.resetListener
    },
    back() {
      this.goToView(this.currViewIdx - 1)
    }
  }
}
