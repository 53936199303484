<template functional>
  <div id="project-no-dataset-msg">
    <div class="project-report-message">
      <h1><VisibleText>Dataset deleted</VisibleText></h1>
      <p>
        <VisibleText
          >Oops, it looks like the dataset used in this project has been
          deleted.
        </VisibleText>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectDatasetDeletedMessage"
}
</script>
