<template>
  <div class="index-strength">
    <p class="index-strength__hint" v-if="displayIndexStrengthHint">
      <SvgIconDecorative icon="info" />
      Saving more than one measure will create an index.
    </p>
    <template v-else>
      <span class="index-strength__is-fetching-text" v-if="isFetching"
        >Computing index strength...</span
      >
      <template v-if="!isFetching && displayIndexStrength">
        <span class="index-strength__label">Index Strength: </span>
        <span class="index-strength__value">{{ value.score }}</span>
        <div
          class="index-strength__tooltip-wrapper"
          v-click-outside="closeTooltip"
        >
          <Tooltip
            class="index-strength__tooltip"
            :textVisible="isTooltipVisible"
            @close="toggleTooltip"
          >
            {{ value.evaluation }}
          </Tooltip>
          <button class="index-strength__tooltip-btn" @click="toggleTooltip">
            <SvgIconMeaningful
              icon="info"
              title="Display index strength info"
            />
          </button>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import DriversService from "@/services/driversService.js"

import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"
import Tooltip from "@/components/UI/Tooltip.vue"

export default {
  name: "IndexStrength",
  components: {
    SvgIconDecorative,
    SvgIconMeaningful,
    Tooltip
  },
  props: {
    datasetId: {
      type: String
    },
    clientQuestionsIds: {
      type: Array
    }
  },
  data() {
    return {
      DRIVERS_SERVICE: new DriversService(this.$pigeonline),
      isFetching: false,
      isTooltipVisible: false,
      value: null
    }
  },
  computed: {
    displayIndexStrengthHint: function() {
      return this.clientQuestionsIds.length == 1
    },
    displayIndexStrength: function() {
      return (
        this.value &&
        typeof this.value == "object" &&
        this.value.score != undefined
      )
    }
  },
  mounted() {
    this.$watch(
      "clientQuestionsIds",
      function(val) {
        if (!val || !Array.isArray(val) || val.length <= 1) return
        this.computeCronbackAlpha(val)
      }.bind(this),
      { immediate: true }
    )
  },
  methods: {
    computeCronbackAlpha: async function(clientQuestionsIds) {
      this.isFetching = true
      try {
        this.value = await this.DRIVERS_SERVICE.variableReliability({
          data_set_id: this.datasetId,
          client_questions_ids: clientQuestionsIds
        })
      } catch (e) {
        console.error("IndexStrength.vue:computeCronbackAlpha:: " + e.message)
        this.value = null
      } finally {
        this.isFetching = false
      }
    },
    toggleTooltip() {
      this.isTooltipVisible = !this.isTooltipVisible
    },
    closeTooltip() {
      this.isTooltipVisible = false
    }
  }
}
</script>
