<template>
  <Modal
    id="re-login__modal"
    uid="relogin-modal"
    header-tag="h1"
    :reset-listener="resetListener"
    :show="show"
  >
    <template #header>
      <VisibleText>Please re-enter your password.</VisibleText>
    </template>
    <!-- replace close with non-interactive modal to fix keyboard trap -->
    <template #close><span></span></template>
    <div class="re-login__content">
      <p class="re-login__sub-title">
        <VisibleText>
          Your session expired. Please re-authenticate to access your account
          again.
        </VisibleText>
      </p>
      <Login
        ref="loginForm"
        class="re-login__form"
        @authenticated="authenticated"
        @error="error"
      >
        <template v-slot="{ email, password, update }">
          <label for="email" class="re-login__form-label">
            <div class="re-login__form-input-wrapper">
              <EmailIcon class="re-login__form-icon" />
              <input
                aria-label="email"
                id="re-login__email"
                type="text"
                :value="email"
                disabled
              />
            </div>
          </label>
          <label for="password" class="re-login__form-label">
            <div class="re-login__form-input-wrapper">
              <PasswordIcon class="re-login__form-icon" />
              <input
                aria-label="password"
                id="re-login__password"
                @input="update('password', $event.target.value)"
                type="password"
                :value="password"
              />
            </div>
          </label>
        </template>
      </Login>
      <div class="re-login__redirect-login">
        <VisibleText>Not your account?</VisibleText>
        <a href="#" @click.prevent="goToLogin">
          <VisibleText>Login</VisibleText>
        </a>
        <VisibleText>here.</VisibleText>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/UI/Modal.vue"

// Components
import { Login } from "@pigeonline/core"

// Icons
import EmailIcon from "@/assets/icons/Login/email.svg"
import PasswordIcon from "@/assets/icons/Login/pswd.svg"

export default {
  name: "ReLoginModal",
  components: {
    Modal,
    Login,
    EmailIcon,
    PasswordIcon
  },
  props: ["email", "show"],
  data() {
    return {
      resetListener: false
    }
  },
  mounted() {
    this.$refs.loginForm.update("email", this.email)
    document.body.appendChild(this.$el)
    setTimeout(() => {
      this.resetListener = !this.resetListener
      document.getElementById(`relogin-modal-container`).focus()
    }, 1000)
  },
  methods: {
    error: function() {
      this.$emit("error")
    },
    authenticated: function() {
      this.$emit("authenticated")
    },
    goToLogin: function() {
      this.$emit("goToLogin")
    }
  }
}
</script>
