<template>
  <main>
    <section id="translation-manager" class="translation-manager">
      <h1 class="translation-manager--h1">Translation Manager</h1>
      <button class="translation-manager__button" @click="checkTranslations">
        Check for translations
      </button>
      <button v-if="hasChecked" @click="editMode = !editMode">
        <div v-if="editMode">
          Deactivate edit mode
        </div>
        <div v-else>
          Activate edit mode
        </div>
      </button>
      <button v-if="hasChecked" @click="saveCache">
        Save json to clipboard
      </button>
      <div v-if="changedContent">
        Changes detected:
        <button @click="saveEdits">Sav to backend</button>
        <button @click="cancelEdits">Cancel</button>
      </div>
      <div>
        {{ consoleResponse }}
      </div>
      <div class="translation-manager__search-bar center-align-parent">
        <SearchBar
          class="center-align-child"
          placeholder="Filter"
          @search="search"
        />
      </div>
      <div class="translation-manager__filter-options">
        <label for="duplicates"> Hide duplicates</label>
        <input
          class="translation-manager__filter-options"
          type="checkbox"
          id="filterDuplicates"
          v-model="filterOptions.hideDuplicates"
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>English text</th>
            <th>French text</th>
            <th>Uri</th>
            <th>Parent Component</th>
          </tr>
        </thead>
        <tbody v-if="localTranslationsFiltered">
          <tr
            v-for="visibleText in localTranslationsFiltered"
            :key="visibleText.index"
          >
            <td>
              {{ visibleText.text_en }}
            </td>
            <td>
              <div v-if="!hasChecked">Check for translations</div>
              <div v-else>
                <div v-if="editMode">
                  <input
                    v-model="visibleText.text_fr"
                    placeholder="none"
                    v-on:change="contentChanging(visibleText)"
                  />
                </div>
                <div v-else>
                  {{ visibleText.text_fr }}
                </div>
              </div>
            </td>
            <td>{{ visibleText.uri }}</td>
            <td>{{ visibleText.parentComponent }}</td>
          </tr>
        </tbody>
      </table>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </section>
  </main>
</template>

<script>
import { mapActions } from "vuex"
// Services
import TranslationService from "@/services/translationService.js"

import SearchBar from "@/components/UI/SearchBar.vue"

export default {
  name: "TranslationManager",
  components: {
    SearchBar
  },
  data() {
    return {
      TRANSLATION_SERVICE: new TranslationService(this.$pigeonline),
      hasChecked: false,
      editMode: false,
      localTranslations: null,
      changedContent: false,
      editPayload: [],
      query: {
        term: ""
      },
      consoleResponse: "",
      filterOptions: {
        hideDuplicates: false
      }
    }
  },
  computed: {
    translations() {
      return this.$store.getters.getTranslations
    },
    localTranslationsFiltered() {
      let filtered = this.localTranslations
      if (filtered) {
        if (this.filterOptions.hideDuplicates) {
          let x = {}
          filtered = this.localTranslations.filter(
            obj =>
              !x[obj.text_en.trim().toLowerCase()] &&
              (x[obj.text_en.trim().toLowerCase()] = true)
          )
        } else {
          filtered = this.localTranslations
        }
      }

      if (this.query.term.length > 0) {
        let list = []
        if (filtered) {
          for (var i = 0; i < this.localTranslations.length; i++) {
            if (
              this.localTranslations[i].text_en
                .toLowerCase()
                .includes(this.query.term.toLowerCase())
            ) {
              list.push(this.localTranslations[i])
            }
          }
          if (this.filterOptions.hideDuplicates) {
            let x = {}
            filtered = list.filter(
              obj =>
                !x[obj.text_en.trim().toLowerCase()] &&
                (x[obj.text_en.trim().toLowerCase()] = true)
            )
          } else {
            filtered = list
          }
        }
      }
      return filtered
    }
  },
  mounted() {
    this.setLocalTranslations()
  },
  methods: {
    ...mapActions(["editTranslation"]),
    async checkTranslations() {
      this.hasChecked = true
      let length = this.translations.length
      for (var counter = 0; counter < length; counter++) {
        const response = await this.fetchTranslation(this.translations[counter])
        let updated_translation = this.translations[counter]
        if (response.length > 0) {
          if (response[0].text_fr) {
            updated_translation.text_fr = response[0].text_fr
            updated_translation.fetched_id = response[0]._id.$oid
            this.editTranslation(updated_translation)
          }
        } else {
          this.translations[counter].text_fr = null
        }
      }
      this.setLocalTranslations()
    },
    async fetchTranslation(translation) {
      try {
        const data = translation
          ? {
              text_en: translation.text_en,
              url: translation.uri,
              parent_component: translation.parentComponent
            }
          : {}
        const response = await this.TRANSLATION_SERVICE.translations(null, data)
        return response
      } catch (e) {
        throw new Error("TranslationManager " + e.message)
      }
    },
    async patchTranslation(id, translation) {
      try {
        const data = translation
          ? {
              text_fr: translation.text_fr
            }
          : {}
        const response = await this.TRANSLATION_SERVICE.updateTranslations(
          id,
          data
        )
        return response
      } catch (e) {
        throw new Error("TranslationManager " + e.message)
      }
    },
    async newTranslation(translation) {
      try {
        const data = translation
          ? {
              text_fr: translation.text_fr,
              text_en: translation.text_en,
              url: translation.uri,
              parent_component: translation.parentComponent
            }
          : {}
        const response = await this.TRANSLATION_SERVICE.newTranslation(data)
        return response
      } catch (e) {
        throw new Error("TranslationManager " + e.message)
      }
    },
    setLocalTranslations() {
      this.localTranslations = this.translations
    },
    contentChanging(text) {
      this.editPayload.push(text)
      this.changedContent = true
    },
    cancelEdits() {
      this.checkTranslations()
      this.changedContent = false
    },
    async saveEdits() {
      let length = this.editPayload.length
      let response
      for (var counter = 0; counter < length; counter++) {
        let fetched_id = this.editPayload[counter].fetched_id
        if (fetched_id) {
          delete this.editPayload[counter].fetched_id
          response = await this.patchTranslation(
            fetched_id,
            this.editPayload[counter]
          )
        } else {
          response = await this.newTranslation(this.editPayload[counter])
        }
      }
      if (response) {
        this.consoleResponse = "Successfully saved translation"
      }
      this.changedContent = false
    },
    async saveCache() {
      let filteredTranslations = this.localTranslations.filter(
        translation => translation.text_fr
      )
      if (!navigator.clipboard) {
        // Clipboard API not available
        return
      }
      const text = JSON.stringify(filteredTranslations)
      try {
        await navigator.clipboard.writeText(text)
      } catch (err) {
        console.error("Failed to copy!", err)
      }
    },
    search(query) {
      this.query.term = query
    }
  }
}
</script>
