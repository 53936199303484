/*******************************
 *
 * Generate nodes required for summary table section
 * @param {Object} data summaryResultsTable from benchmarkAnalysisMixin
 * @returns {Object} pigeondoc table node
 *
 ******************************/
const SummaryTable = (data, columnNames) => {
  const rowNames = data.map(item => item["question"])

  const tableData = data.reduce((array, item) => {
    let row = []
    columnNames.forEach(col => {
      if (col === "question") return
      else row.push(item[col])
    })
    array.push(row)
    return array
  }, [])

  const capitalizeColNames = columnNames.reduce((capitalized, colName) => {
    capitalized.push(colName.replace(/(^\w|\s\w)/g, m => m.toUpperCase()))
    return capitalized
  }, [])

  return {
    columnNames: capitalizeColNames,
    rowNames: rowNames,
    data: tableData
  }
}

export default SummaryTable
