<template>
  <Modal
    id="modal-banned-keywords"
    class="project-analysis-text-modal"
    header-tag="h3"
    uid="banned-keywords-modal"
    :show="show"
    @close="closeModal"
  >
    <template #header class="modal-title">
      <VisibleText>Banned Keywords</VisibleText>
    </template>
    <p class="hint-text" id="banned-keyword-hint">
      <VisibleText
        >Hit enter to add a word to the banned keywords list.
      </VisibleText>
    </p>
    <KeywordsInput
      class="banned-keywords__ki-wrapper"
      ref="banned-keywords-input"
      hint-id="banned-keyword-hint"
      :key="updateKey"
      :value="taggable(bannedKeywords)"
      @keywords-updated="unsavedChanges = true"
    />
    <template #footer>
      <button
        class="banned-keywords__save-btn btn-default"
        type="button"
        @click.prevent="save"
        :disabled="!unsavedChanges"
      >
        <VisibleText>Save changes</VisibleText>
      </button>
      <button class="btn-cancel" type="button" @click="closeModal">
        Close
      </button>
    </template>
  </Modal>
</template>

<script>
// Components
import Modal from "@/components/UI/Modal.vue"
import KeywordsInput from "../Components/KeywordsInput.vue"

// Mixins
import AnalysisTextMixin from "@/utils/mixins/analysisTextMixin.js"

export default {
  name: "BannedKeywords",
  mixins: [AnalysisTextMixin],
  components: {
    Modal,
    KeywordsInput
  },
  props: {
    show: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      unsavedChanges: false,
      updateKey: Date.now()
    }
  },
  methods: {
    taggable(keywords) {
      return keywords.map(
        function(text, index) {
          return { key: index, value: this.clean(text) }
        }.bind(this)
      )
    },
    clean(keyword) {
      return keyword.trim().toLowerCase()
    },
    async save() {
      // update store
      const bannedKeywords = this.$refs["banned-keywords-input"].tags.map(
        el => el.value
      )
      const bannedKeywordsOld = [...this.bannedKeywords]
      this.setBannedKeywords(bannedKeywords)

      // save to backend
      this.setShowSpinner(true)
      try {
        // update project
        this.project["textAnalysis"]["bannedKeywords"] = bannedKeywords
        this.project["textAnalysis"]["themes"] = await this.prepareThemes(
          this.deepCloneObj(this.themes)
        )
        this.setProject(await this.$pigeonline.projects.update(this.project))
        this.setThemes(this.project["textAnalysis"]["themes"])

        // success
        alert(`Your changes have been saved successfully.`)
        this.unsavedChanges = false
      } catch (e) {
        alert("An error occured. Please try again later.")
        // revert the changes
        this.setBannedKeywords(bannedKeywordsOld)
        throw new Error("BannedKeywords.vue:save: " + e)
      } finally {
        this.setShowSpinner(false)
      }
    },
    closeModal() {
      this.$emit("closeModal")
    }
  },
  watch: {
    bannedKeywords: function(val) {
      if (val.length > 0) {
        this.bannedKeywords_ = this.bannedKeywords
        this.updateKey = Date.now()
      }
    }
  }
}
</script>
