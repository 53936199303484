<template>
  <section id="benchmark-manager-groups" class="section__content">
    <ListGrid
      class="benchmark-manager__groups"
      :custom-grid-style="{
        gridTemplateColumns: '1fr 1fr 0.5fr 0.5fr 1fr 0.5fr 3rem'
      }"
      :headers="headers"
      :list="benchmarkGroups"
    >
      <template slot-scope="{ item, index }">
        <ListGridItem col="0" :headers="headers" :row="index">
          <router-link
            :to="{ name: 'benchmarkGroupEdit', params: { id: item.id } }"
            class="benchmark-manager__groups-item-name"
            v-if="isOwner(item.owner)"
          >
            {{ item.name }}
          </router-link>
          <template v-else>{{ item.name }}</template>
        </ListGridItem>

        <ListGridItem col="1" :headers="headers" :row="index">
          <span class="benchmark-manager__groups-item-created">
            {{ item.created_by.name }}
          </span>
          <span class="benchmark-manager__groups-item-org">
            {{ item.created_by.organization }}
          </span>
        </ListGridItem>

        <ListGridItem col="2" :headers="headers" :row="index">
          {{ item.questions }}
        </ListGridItem>

        <ListGridItem
          class="benchmark-manager__groups-item-approved"
          col="3"
          :headers="headers"
          :row="index"
        >
          {{ item.approved_matches }}
        </ListGridItem>

        <ListGridItem
          col="4"
          :headers="headers"
          :row="index"
          :class="[
            'benchmark-manager__groups-item-accesslevel-wrapper',
            { 'non-editable-cascade': !isOwner(item.owner) }
          ]"
        >
          <div
            class="benchmark-manager__groups-item-accesslevel-selected"
            :ref="item.id"
            @click="showSelect(item.id)"
          >
            <span class="benchmark-manager__groups-item-accesslevel">
              {{ ACCESS_TYPE_OPTIONS[item.access_type] }}
            </span>
            <span class="benchmark-manager__groups-item-accesslevel-desc">
              {{ ACCESS_TYPE_TO_DESC[item.access_type] }}
            </span>
          </div>
          <label class="sr-only" :for="`select-access-level-${item.id}`">
            <VisibleText>select access type</VisibleText>
          </label>
          <select
            class="select"
            name="access-level"
            :id="`select-access-level-${item.id}`"
            :ref="`${item.id}-select`"
            style="display: none"
            @change="saveAccessLevel($event, item.id, index)"
          >
            <option
              v-for="level in Object.keys(ACCESS_TYPE_OPTIONS)"
              :key="level"
              :value="level"
            >
              {{ ACCESS_TYPE_OPTIONS[level] }} -
              {{ ACCESS_TYPE_TO_DESC[level] }}
            </option>
          </select>
        </ListGridItem>

        <ListGridItem
          col="5"
          :headers="headers"
          :row="index"
          :class="{ 'non-editable-cascade': !isOwner(item.owner) }"
        >
          <button
            :class="[
              'toggle-btn',
              item.auto_approve ? 'toggle-btn-on' : 'toggle-btn-off'
            ]"
            @click="toggleAutoApprove(item.id, index)"
          >
            <span class="toggle-btn-on-txt">
              <VisibleText>on</VisibleText>
            </span>
            <span class="toggle-btn-off-txt">
              <VisibleText>off</VisibleText>
            </span>
          </button>
        </ListGridItem>

        <ListGridItem
          class="benchmark-manager__groups-item-delete"
          style="overflow: unset"
          col="6"
          :headers="headers"
          :row="index"
        >
          <ButtonIconOnly
            icon="delete"
            :id="`delete-${item.id}`"
            v-if="isOwner(item.owner)"
            @click-handler="deleteGroup(item.id, index)"
          >
            <span><VisibleText>delete</VisibleText> {{ item.name }}</span>
          </ButtonIconOnly>
        </ListGridItem>
      </template>
    </ListGrid>
  </section>
</template>

<script>
// Componenets
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import ListGrid from "@/components/UI/ListGrid.vue"
import ListGridItem from "@/components/UI/ListGridItem.vue"

// Helpers
import { mapActions } from "vuex"

// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

// Services
import DatasetsService from "@/services/datasetsService.js"

export default {
  name: "BenchmarkManagerGroups",
  components: {
    ButtonIconOnly,
    ListGrid,
    ListGridItem
  },
  data() {
    return {
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      ACCESS_TYPE_TO_DESC: {
        private: "only you can use this group",
        public__all: "anyone can use this group",
        public__org: "only users in your organization can use this group"
      },
      ACCESS_TYPE_OPTIONS: {
        private: "private",
        public__all: "public",
        public__org: "organization"
      },
      benchmarkGroups: [],
      headers: [
        "Group name",
        "Owner",
        "Questions",
        "Approved",
        "Access Level",
        "Auto Approve",
        ""
      ]
    }
  },
  async created() {
    try {
      this.setLoading(true)
      await this.loadData()
      this.setLoading(false)
    } catch (e) {
      throw new Error("BenchmarkManagerGroups:created " + e.message)
    }
  },
  methods: {
    ...mapActions("loader", ["setLoading"]),
    async saveGroup(group) {
      try {
        if (!this.isOwner(group.owner)) return
        await this.$pigeonline.projects.update(group)
      } catch (e) {
        throw new Error("BenchmarkManagerGorups.vue:saveGroup " + e.message)
      }
    },
    async deleteGroup(id, index) {
      if (!confirm("Are you sure you want to delete this group?")) return
      try {
        // delete benchmark gorup
        await this.$pigeonline.projects.delete(id)
        this.benchmarkGroups.splice(index, 1)

        // delete global questions in this benchmark group
        const globalQuestions = await this.DATASETS_SERVICE.globalQuestions(
          null,
          {
            benchmark_group: id
          }
        )
        Promise.all(
          globalQuestions.map(async question => {
            this.DATASETS_SERVICE.deleteGlobalQuestion(question._id.$oid)
          })
        )
      } catch (e) {
        throw new Error("BenchmarkManagerGorups.vue:deletegroup " + e.message)
      }
    },
    async updateGroupApprovedMatches(benchmarkGroup) {
      const globalQuestions = await this.DATASETS_SERVICE.globalQuestions(
        null,
        {
          benchmark_group: benchmarkGroup.id
        }
      )
      let totalApproved = globalQuestions.reduce(
        (total, question) => (total = total + question.matches.approved),
        0
      )
      // number of matches has changed
      if (totalApproved != benchmarkGroup.approved_matches) {
        benchmarkGroup.approved_matches = totalApproved
        await this.saveGroup(benchmarkGroup)
      }
    },
    async loadData() {
      this.benchmarkGroups = await this.$pigeonline.projects.get(BenchmarkGroup)
      // only update groups that you or your organization owns
      await Promise.all(
        this.benchmarkGroups
          .filter(
            group =>
              !(
                group.access_type === "public__all" &&
                !this.isOwner(group.owner)
              ) && group.auto_approve
          )
          .map(async group => {
            await this.updateGroupApprovedMatches(group)
          })
      )
    },
    hideSelect(id) {
      this.$refs[id].style.display = "grid"
      this.$refs[`${id}-select`].style.display = "none"
    },
    isOwner(groupOwner) {
      return groupOwner == this.$store.getters["user/getProfile"].django_ref_id
    },
    showSelect(id) {
      this.$refs[id].style.display = "none"
      this.$refs[`${id}-select`].style.display = "block"
    },
    saveAccessLevel($event, id, index) {
      this.hideSelect(id)
      this.$set(this.benchmarkGroups[index], "access_type", $event.target.value)
      this.saveGroup(this.benchmarkGroups[index])
    },
    toggleAutoApprove(id, index) {
      const val = !this.benchmarkGroups[index].auto_approve
      this.$set(this.benchmarkGroups[index], "auto_approve", val)
      this.saveGroup(this.benchmarkGroups[index])
    }
  }
}
</script>
