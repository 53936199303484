<template>
  <DatasetSegmentsItemReadOnly
    parent="DatasetSegmentsItem"
    :segment-name="item.label || item.value"
    v-if="readOnly"
  />
  <div class="dataset-segments__tag-item" v-else>
    <span class="dataset-segments__tag-item-title">
      <label
        class="form-label form-checkbox-label"
        :for="`${category}-${item.value}`"
      >
        <input
          style="margin-right: 0.5rem;"
          type="checkbox"
          :checked="isChecked"
          :id="`${category}-${item.value}`"
          :name="item.value"
          :value="item.value"
          @input.stop="onChange($event.target.checked)"
        />
        <strong>{{ item.label || item.value }}</strong>
        <span
          class="dataset-segments__tag-item-count"
          v-if="item.value !== 'None' && segmentDetailsAvailable"
        >
          {{ segmentDetails[item.value] || 0 }}
          <VisibleText> benchmarks</VisibleText>
        </span>
      </label>
    </span>
    <!--------------------------------------------
    |
    | OPTIONS
    |
    --------------------------------------------->
    <span
      class="dataset-segments__tag-item-options"
      v-if="item.value !== 'None'"
    >
      <button type="button" :class="classObjectAllOption" @click="all">
        all respondents
      </button>
      <template v-if="validQuestions.length > 0">
        <span style="color: #000">|</span>
        <button type="button" :class="classObjectSomeOption" @click="some">
          some respondents
        </button>
      </template>
    </span>
    <!--------------------------------------------
    |
    | DESCRIPTION
    |
    --------------------------------------------->
    <span class="dataset-segments__tag-item-desc" v-if="item.desc">
      {{ item.desc }}
    </span>
    <!--------------------------------------------
    |
    | RESPONDENT DETAILS
    |
    --------------------------------------------->
    <div class="dataset-segments__tag-item-seg-details" v-if="isDetailsVisible">
      <div class="with-select">
        Those who responded to
        <select aria-label="select question" v-model="questionID">
          <option
            v-for="question in validQuestions"
            :key="question._id.$oid"
            :value="question._id.$oid"
          >
            {{ question.question_title }} - {{ question.question_text }}
          </option>
        </select>
      </div>
      <div class="with-select">
        and answered
        <select aria-label="select value" v-model="questionValue">
          <option :value="null">any value</option>
          <option
            v-for="value in selectedQuestionValues"
            :key="value"
            :value="value"
          >
            {{ value }}
          </option>
        </select>
      </div>
      <div>are in this segment.</div>
    </div>
  </div>
</template>

<script>
import DatasetSegmentsItemReadOnly from "./DatasetSegmentsItemReadOnly.vue"

import { mapGetters } from "vuex"

export default {
  name: "DatasetSegmentItem",
  components: {
    DatasetSegmentsItemReadOnly
  },
  props: {
    category: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    readOnly: {
      default: () => false,
      type: Boolean
    },
    segmentData: {
      default: () => {
        return null
      },
      type: Object,
      required: false
    }
  },
  data() {
    return {
      isDetailsVisible: false,
      questionID: null,
      questionValue: ""
    }
  },
  computed: {
    ...mapGetters("datasetDetails", {
      clientQuestions: "getClientQuestions",
      dataset: "getDataset",
      segmentDetails: "getSegmentDetails"
    }),
    validQuestions() {
      return this.clientQuestions.filter(question =>
        ["CAT", "SCALE_1_TO_5", "OPEN_ENDED"].includes(
          question.approved_data_type
        )
      )
    },
    classObjectAllOption() {
      return [
        "dataset-segments__tag-item-options-btn",
        this.isChecked &&
        !this.isDetailsVisible &&
        Object.keys(this.segmentData).length < 1
          ? "selected"
          : ""
      ]
    },
    classObjectSomeOption() {
      return [
        "dataset-segments__tag-item-options-btn",
        this.isChecked &&
        (this.isDetailsVisible || Object.keys(this.segmentData).length > 0)
          ? "selected"
          : ""
      ]
    },
    isChecked() {
      return !!this.segmentData
    },
    selectedQuestionValues() {
      if (this.questionID) {
        const uniqueVals = this.clientQuestions.find(
          question => question._id.$oid === this.questionID
        ).unique_values
        return uniqueVals.map(v => v.value)
      }
      return []
    },
    segmentDetailsAvailable() {
      return (
        this.dataset.benchmark_group && this.dataset.benchmark_group.length > 0
      )
    }
  },
  methods: {
    all() {
      this.isDetailsVisible = false
      this.questionID = null
      this.questionValue = ""
      this.onChange(true)
    },
    onChange(checked) {
      this.$emit(
        "checked",
        checked,
        this.item.value,
        this.questionID,
        this.questionValue,
        this.category
      )
    },
    some() {
      this.isDetailsVisible = true
    }
  },
  watch: {
    segmentData: function() {
      if (this.segmentData && Object.keys(this.segmentData).length > 0) {
        this.questionID = this.segmentData.client_question_id
        this.questionValue = this.segmentData.value || null
      }
    },
    questionID: function() {
      this.onChange(true)
    },
    questionValue: function() {
      this.onChange(true)
    }
  }
}
</script>
