<template>
  <section id="project-analysis-drivers">
    <!--------------------------------------------
    |
    | HEADER
    |
    --------------------------------------------->
    <div class="project-analysis__header">
      <h2 class="project-analysis__header-title">
        <VisibleText>Drivers Analysis</VisibleText>
      </h2>
      <button
        class="project-analysis__header-add-btn"
        @click="openCreateNewDriversModal"
        :disabled="propReadOnly"
      >
        <VisibleText>Add New Drivers Analysis</VisibleText>
      </button>
    </div>

    <!--------------------------------------------
    |
    | DESCRIPTIONS
    |
    --------------------------------------------->
    <p class="drivers__desc">
      <VisibleText
        >Drivers analysis is a technique used to make predictions about any
        scale data.
      </VisibleText>
      <template
        v-if="$pigeonline.permissions.hasPermission('benchmarking-group-iccs')"
      >
        <VisibleText
          >This can help organizations prioritize where to focus energies. As an
          ICCS subscriber, you can find which CMT questions drive Overall
          Satisfaction.
        </VisibleText>
      </template>
      <template v-else>
        <VisibleText
          >By finding what scale questions predict an outcome like "overall
          satisfaction" or "willingness to recommend", organizations can
          prioritize where to focus energies.
        </VisibleText>
      </template>
    </p>

    <p
      class="warning-msg__inline"
      v-if="statusNoAutoDriversFound && statusName.length === 0"
    >
      <VisibleText
        >Out tool could not automatically find an outcome question, you can
        create one by clicking "add new drivers analysis" button below.
      </VisibleText>
    </p>

    <!--------------------------------------------
    |
    | WARNINGS AND INFO MESSAGES
    |
    --------------------------------------------->
    <DriversMessages :status-name="statusName" />

    <!--------------------------------------------
    |
    | DRIVERS
    |
    --------------------------------------------->
    <template v-if="savedDriversAnalysis">
      <ProjectAnalysisDriversItem
        v-for="(driver, index) in savedDriversAnalysis"
        :key="`${index}-${driver.last_modified_on}`"
        :drivers-analysis-object="driver"
        :prop-read-only="propReadOnly"
        @toggle-rerender="setSavedDriversAnalysis"
      />
    </template>

    <!--------------------------------------------
    |
    | NEW DRIVER MODAL
    |
    --------------------------------------------->
    <DriversVariables
      dependent-variable-title="Create New Drivers Analysis"
      :dependent-questions="new Array()"
      :suggested-variables="suggestedVariables"
      :new-driver="true"
      :show-drivers-variables="isNewDriversModalVisible"
      @modify-vars="createNewDrivers"
      @close-drivers-variables="isNewDriversModalVisible = false"
    />
  </section>
</template>

<script>
// Components
import DriversMessages from "./ProjectAnalysisDriversMessages.vue"
import DriversVariables from "./ProjectAnalysisDriversVariables.vue"
import ProjectAnalysisDriversItem from "./ProjectAnalysisDriversItem.vue"

// Helpers
import moment from "moment"

// Mixins
import DriversAnalysisMixin from "@/utils/mixins/driversAnalysisMixin.js"
import ProjectMixin from "@/utils/mixins/projectMixin.js"

export default {
  name: "ProjectAnalysisDrivers",
  mixins: [DriversAnalysisMixin, ProjectMixin],
  components: {
    DriversMessages,
    DriversVariables,
    ProjectAnalysisDriversItem
  },
  data() {
    return {
      savedDriversAnalysis: [],
      statusNoAutoDriversFound: false,
      suggestedVariables: null,
      isNewDriversModalVisible: false
    }
  },
  async created() {
    /**
     * backwards compatability: project.driversAnalysis updated from object
     * to array
     */
    if (this.project.driversAnalysis) {
      this.setSavedDriversAnalysis()
    } else {
      this.suggestedVariables = await this.fetchSuggestedVariables()
      // returned an error, no drivers automatically found
      if ([406, 424].includes(this.suggestedVariables)) {
        this.statusNoAutoDriversFound = true
      } else {
        this.useSuggestedVariables()
      }
    }
  },
  computed: {
    statusName() {
      if (this.suggestedVariables == 406 && this.scaleQuestions.length < 3)
        return "notEnoughScale"
      if (
        this.$pigeonline.permissions.hasPermission("benchmarking-group-iccs") &&
        this.suggestedVariables == 424
      )
        return "noCmt"
      if (this.statusOrgNotVerified) return "orgNotVerified"
      return ""
    },
    scaleQuestions() {
      return this.$store.getters["datasetDetails/getClientQuestions"].filter(
        question => question.approved_data_type === "SCALE_1_TO_5"
      )
    }
  },
  methods: {
    createNewDrivers(dependent, independent) {
      const driversObjectWithSuggestedVars = {
        dependent_variable_id: dependent,
        dependent_variable_title: this.getDependentVarTitle(dependent),
        independent_variables_ids: independent,
        last_modified_on: moment().valueOf(),
        uuid: (Math.random() * Date.now()).toFixed(0)
      }
      this.savedDriversAnalysis.unshift(driversObjectWithSuggestedVars)
    },
    setSavedDriversAnalysis() {
      /**
       * backwards compatability: project.driversAnalysis updated from object
       * to array
       */
      if (
        Array.isArray(this.project.driversAnalysis) &&
        this.project.driversAnalysis.length > 0
      ) {
        this.savedDriversAnalysis = this.project.driversAnalysis
      } else if (Object.keys(this.project.driversAnalysis).length > 0) {
        this.savedDriversAnalysis.unshift(this.project.driversAnalysis)
      }
    },
    useSuggestedVariables() {
      this.createNewDrivers(
        this.suggestedVariables.dependent_variable_id,
        this.suggestedVariables.independent_variables_ids
      )
    },
    async openCreateNewDriversModal() {
      if (!this.suggestedVariables)
        this.suggestedVariables = await this.fetchSuggestedVariables()
      this.isNewDriversModalVisible = true
    },
    async fetchSuggestedVariables() {
      try {
        const datasetId = this.$store.getters["datasetDetails/getDataset"]._id
          .$oid
        const response = await this.DRIVERS_SERVICE.suggestedVariables({
          data_set_id: datasetId
        })
        return response
      } catch (e) {
        throw new Error(
          "ProjectAnalysisDrivers:fetchSuggestedVariables" + e.message
        )
      }
    }
  }
}
</script>
