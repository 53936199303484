import SurveyMethodologyTable from "../helpers/surveyMethodology.js"

const surveyMethodologySection = (data, orgs) => {
  const headings = [
    {
      type: "heading",
      content: "APPENDIX 1 <span class='report__subtitle'>Methodology</span>",
      id: "section-title-appendix",
      meta: {
        level: "h1",
        pdfConfig: {
          pageBreak: "before"
        }
      }
    }
  ]

  const description = {
    type: "text",
    content:
      "All organizations included in this report have been approved by the Institute for Citizen-Centred Service. Only Common Measurement Tool questions (5 point scale) are used for comparison. While use of the CMT helps ensure the questions and response scales are consistent, many other methodological factors can affect the comparability of survey results. This section is included for analysts who want to assess more closely the various methodological factors that might affect comparability"
  }

  const table = SurveyMethodologyTable(data, orgs)

  return [...headings, description, table]
}

export default surveyMethodologySection
