import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default {
  namespaced: true,
  state() {
    return {
      activeSection: null,
      benchmarkGroup: null,
      copyingDetails: {
        isCopyingDetails: false,
        methodName: "copyLabels"
      },
      datasetProject: {},
      editingBenchmark: null,
      deleteIncompleteUpload: false
    }
  },
  getters: {
    getActiveSection: state => state.activeSection,
    getBenchmarkGroup: state => state.benchmarkGroup,
    getCopyingDetails: state => state.copyingDetails,
    getDatasetProject: state => state.datasetProject,
    getEditingBenchmark: state => state.editingBenchmark,
    getDeleteIncompleteUpload: state => state.deleteIncompleteUpload
  },
  mutations: {
    SET_ACTIVE_SECTION(state, payload) {
      Vue.set(state, "activeSection", payload)
    },
    SET_BENCHMARK_GROUP(state, payload) {
      Vue.set(state, "benchmarkGroup", payload)
    },
    SET_COPYING_DETAILS(state, payload) {
      Vue.set(state, "copyingDetails", payload)
    },
    SET_EDITING_BENCHMARK(state, payload) {
      Vue.set(state, "editingBenchmark", payload)
    },
    SET_DATASET_PROJECT(state, payload) {
      Vue.set(state, "datasetProject", payload)
    },
    SET_DELETE_INCOMPLETE_UPLOAD(state, payload) {
      Vue.set(state, "deleteIncompleteUpload", payload)
    },
    RESET_DATASET_WIZARD(state) {
      Vue.set(state, "activeSection", null)
      Vue.set(state, "benchmarkGorup", null)
      Vue.set(state, "copyingDetails", {
        isCopyingDetails: false,
        methodName: "copyLabels"
      })
      Vue.set(state, "datasetProject", {})
      Vue.set(state, "editingBenchmark", null)
      Vue.set(state, "deleteIncompleteUpload", null)
    }
  },
  actions: {
    async setActiveSection({ commit }, payload) {
      await commit("SET_ACTIVE_SECTION", payload || "DatasetUploader")
    },
    async setBenchmarkGroup({ commit }, payload) {
      await commit("SET_BENCHMARK_GROUP", payload)
    },
    async setCopyingDetails({ commit }, payload) {
      await commit("SET_COPYING_DETAILS", payload)
    },
    async setEditingBenchmark({ commit }, payload) {
      await commit("SET_EDITING_BENCHMARK", payload)
    },
    async setDatasetProject({ commit }, payload) {
      await commit("SET_DATASET_PROJECT", payload)
    },
    async setDeleteIncompleteUpload({ commit }, payload) {
      await commit("SET_DELETE_INCOMPLETE_UPLOAD", payload)
    },
    resetDatasetWizard({ commit }) {
      commit("RESET_DATASET_WIZARD")
    }
  }
}
