<template>
  <div>
    <section id="datasets" :aria-hidden="modalOpen" :inert="modalOpen">
      <div class="datasets__search-bar center-align-parent">
        <SearchBar
          class="center-align-child"
          placeholder="Search any dataset"
          @search="search"
        />
      </div>
      <div class="section__top-bar">
        <h1 class="section__top-bar-title">
          <VisibleText>Datasets</VisibleText>
        </h1>
        <div class="section__top-bar-actions-wrapper">
          <HasPermission to="create-dataset">
            <button class="btn-default" @click="upload">
              <SvgIconDecorative icon="upload" />
              <VisibleText>Upload New Data</VisibleText>
            </button>
            <button class="datasets__actions-delete-btn" @click="confirmDelete">
              <SvgIconDecorative icon="delete" />
              <VisibleText>Delete</VisibleText>
            </button>
          </HasPermission>
        </div>
      </div>
      <div class="section__content" style="padding-top: 2rem">
        <DatasetsTable
          :query-term="queryTerm"
          @updateSelected="updateSelected"
          @share-dataset="shareDataset"
        />
      </div>
    </section>
    <DatasetShareModal
      :is-saving="isSavingShared"
      :dataset="shareDatasetProject"
      :show="isShareModalVisible"
      ref="share-modal"
      @saveChanges="saveShared"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
// Components
import DatasetShareModal from "@/components/Share/ShareDataset.vue"
import DatasetsTable from "@/components/Datasets/DatasetsTable.vue"
import SearchBar from "@/components/UI/SearchBar.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIcon"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import SharedStatusMixin from "@/utils/mixins/sharedStatusMixin.js"

export default {
  name: "Datasets",
  mixins: [DatasetDetailsMixin, SharedStatusMixin],
  components: {
    DatasetShareModal,
    DatasetsTable,
    SearchBar,
    SvgIconDecorative
  },
  data() {
    return {
      queryTerm: null,
      selected: [],
      // sharing
      isSavingShared: false,
      isShareModalVisible: false,
      shareModalKey: Date.now(),
      shareDatasetProject: null
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("confirm/resetConfirm")
    next()
  },
  created() {
    this.$store.dispatch("confirm/setConfirmText", {
      btn: "delete dataset(s)",
      message:
        "You will not be able to recover your data or update your report once it is deleted.",
      title: "delete dataset?"
    })
    this.$store.dispatch("confirm/setConfirmType", "delete")
    this.$store.dispatch("confirm/setConfirmSourceComponent", "datasets")
    document.title = `Datasets | ${this.$theme.theme.pageTitle}`
  },
  computed: {
    confrimStatus() {
      return this.$store.getters["confirm/getStatus"]
    },
    modalOpen() {
      return this.$store.getters["globalModule/getModalOpen"]
    }
  },
  methods: {
    confirmDelete() {
      if (this.selected.length == 0) {
        alert("No dataset selected")
        return
      }
      this.$store.dispatch("confirm/setConfirmIsVisible", true)
    },
    search(queryTerm) {
      this.queryTerm = queryTerm
    },
    updateSelected(selected) {
      this.selected = selected
    },
    upload() {
      this.$router.push({ name: "dataset" })
    },
    async updateBenchmarkMatchesCounts(datasetId, benchmarks) {
      const globalQuestions = await this.fetchGlobalQuestions()
      await Promise.all(
        benchmarks.map(async benchmark => {
          await this.onDeleteUpdateGlobalQuestionBenchmarkMatchCount(
            globalQuestions,
            benchmark.global_question_id,
            benchmark
          )
        })
      )
    },
    /**
     * When deleting a dataset, need to update benchmark matches count in the
     * global question object
     */
    async deleteDataset() {
      try {
        this.$store.dispatch("loader/setLoading", true)

        for (const toDelete of this.selected) {
          // update matches count if benchmark matching step is completed
          if (
            toDelete.dataset_proj.status.benchmarksApproved ||
            toDelete.dataset_proj.status.benchmarksSent
          ) {
            const benchmarks = await this.fetchBenchmarks(toDelete.dataset_id)
            await this.updateBenchmarkMatchesCounts(
              toDelete.dataset_id,
              benchmarks
            )
          }

          this.$pigeonline.projects.delete(toDelete.project_id)
          this.DATASETS_SERVICE.delete(toDelete.dataset_id)
        }

        this.$store.dispatch("loader/setLoading", false)
        alert("Successfully Deleted Dataset(s)")
        this.$router.go()
      } catch (e) {
        alert("Sorry there was an error deleting a dataset. Please try again")
        this.$store.dispatch("loader/setLoading", false)
        throw new Error("Datasets.vue:del " + e.message)
      }
    },
    // save shared dataset
    shareDataset(dataset) {
      if (!dataset) return
      this.shareDatasetProject = Object.assign(
        Object.create(Object.getPrototypeOf(dataset)),
        dataset
      )
      this.isShareModalVisible = true
      this.shareModalKey = Date.now()
    },
    async saveShared(users) {
      let shared = {}
      for (let i = 0; i < users.length; i++) {
        let user = users[i]
        for (const [type, permissions] of Object.entries(user.permissions)) {
          if (!shared.hasOwnProperty(type)) shared[type] = []
          shared[type].push([user.id, user.notify || false, permissions])
        }
      }
      if (Object.keys(shared).length === 0) return

      for (const [type, value] of Object.entries(shared)) {
        let _shared = this[type] && this[type].shared

        this.isSavingShared = true
        try {
          switch (type) {
            case "dataset":
              if (!this.shareDatasetProject) continue
              this.shareDatasetProject.shared = value
              try {
                await this.$pigeonline.projects.update(this.shareDatasetProject)
              } catch (e) {
                if (_shared) this.shareDatasetProject.shared = _shared
                console.error(e)
              }
              break
            default:
              continue
          }
        } catch (e) {
          alert(
            "An error occured processing your request. Please try again later."
          )
          throw new Error("DatasetsTable.vue:saveShared:: " + e)
        } finally {
          this.isSavingShared = false
        }
      }

      alert("Your changes have been saved successfully.")
      this.closeModal()
      this.shareDatasetProject = null
      this.isSavingShared = false

      // refresh the page
      window.location.reload()
    },
    closeModal() {
      this.isShareModalVisible = false
      this.$refs["share-modal"].unsavedChanges = false
    }
  },
  watch: {
    confrimStatus: async function(val) {
      const sourceComponent = this.$store.getters["confirm/getSourceComponent"]
      if (val && sourceComponent === "datasets") {
        await this.deleteDataset()
      }
    }
  }
}
</script>
