<template>
  <span
    v-bind:class="componentName"
    v-bind:id="generatedUID"
    :style="noTranslationStyle"
    class="visible-text__content"
  >
    <template v-if="setLanguage === 'fr' && local_fr.length > 0">
      {{ local_fr }}
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </span>
</template>

<script>
import language_cache from "@/components/Translation/LanguageCache.json"

export default {
  name: "VisibleText",
  data() {
    return {
      local_en: "",
      local_fr: ""
    }
  },
  mounted() {
    this.local_en = this.$slots.default[0].text
    this.checkCache()
  },
  computed: {
    componentName() {
      return this.$parent.$options.name
    },
    generatedUID() {
      return this._uid
    },
    setLanguage() {
      return this.$store.getters.getLanguage
    },
    languageDebugger() {
      return this.$store.getters.getLanguageDebug
    },
    noTranslationStyle() {
      return this.setLanguage === "fr" &&
        this.local_fr.length === 0 &&
        this.languageDebugger === "on"
        ? { background: "red" }
        : null
    }
  },
  methods: {
    checkCache() {
      let filteredCache = language_cache.filter(
        translation =>
          translation.text_en === this.local_en &&
          translation.parentComponent === this.componentName &&
          translation.uri === document.baseURI
      )
      if (filteredCache.length > 0) {
        this.local_fr = filteredCache[0].text_fr
      }
    }
  }
}
</script>
