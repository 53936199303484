<template>
  <div class="list-item__wrapper">
    <div class="list-item__content">
      <div class="list-item__content-detail project-name">
        {{ project.name }}
        <span class="project-status" v-if="projectStatus"
          >| {{ projectStatus }}</span
        >
      </div>
      <span class="list-item__content-detail last-modified-date">
        <VisibleText>Last Modified </VisibleText>
        {{ project.last_modified_on.$date | formatDate }}
      </span>
      <ProjectsItemProgress :project="project" />
    </div>
    <ProjectsItemNav :project="project" />
  </div>
</template>

<script>
// Components
import ProjectsItemNav from "@/components/Projects/ProjectsItemNav.vue"
import ProjectsItemProgress from "@/components/Projects/ProjectsItemProgress.vue"

export default {
  name: "ProjectsList",
  components: {
    ProjectsItemNav,
    ProjectsItemProgress
  },
  props: {
    project: { type: Object }
  },
  computed: {
    projectStatus() {
      const id = this.$store.getters["user/getProfile"].django_ref_id
      const email = this.$pigeonline.getUserEmail()

      const shared =
        this.project.shared &&
        Array.isArray(this.project.shared) &&
        this.project.shared.map(r => this.bkCompatiblePk(r))
      if (shared && (shared.includes(id) || shared.includes(email))) {
        return "shared"
      }

      const access = this.project.access_type
      if (access && access == "public__all") return "public"
      if (access && access == "public__org") return "organization"
      if (access && access == "demo") return "demo"

      return null
    }
  },
  methods: {
    bkCompatiblePk(r) {
      return ["string", "number"].includes(typeof r) ? r : r.pk || r.id || -1
    }
  }
}
</script>
