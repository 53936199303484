// Services
import PerformanceService from "@/services/performanceService.js"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import ProjectMixin from "@/utils/mixins/projectMixin.js"

// Components
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Helpers
import moment from "moment"

const DEFAULTS = {
  apiErrorMessage:
    "An error occured processing your request. Please try again later."
}

export default {
  props: {
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  mixins: [DatasetDetailsMixin, ProjectMixin],
  components: {
    SvgIconDecorative
  },
  data() {
    return {
      PERFORMANCE_SERVICE: new PerformanceService(this.$pigeonline),
      DEFAULTS: DEFAULTS
    }
  },
  methods: {
    /* Utils */
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map(item => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },
    getCurrTimestamp() {
      // returns current UNIX timestamp in milliseconds
      return moment().valueOf()
    },

    /**
     * Fetch performance analysis data to generate performance analysis charts.
     * @param {String} datasetID dataset ID
     * @param {Array} clientQuestionIds array of client question ids
     * @param {Array} selectedSegments array of segments
     * @returns {Object}
     */
    async fetchPerformanceAnalysis(
      datasetID,
      clientQuestionIds,
      selectedSegments
    ) {
      try {
        const response = await this.PERFORMANCE_SERVICE.performanceAnalysis({
          data_set_id: datasetID,
          client_questions_ids: clientQuestionIds,
          selected_segments: selectedSegments
        })
        return response.data
      } catch (e) {
        throw new Error(
          "performanceAnalysisMixin.js:fetchPerformanceAnalysis:: " + e.message
        )
      }
    }
  }
}
