<template>
  <DatasetLayout
    section-name="dataset-benchmarks"
    title="Match Global Questions"
  >
    <p>
      <VisibleText
        >Match your questions to questions in our global database. These
        questions will be used to find benchmarks.
      </VisibleText>
    </p>

    <MessageBlock
      message-type="warning"
      title="Benchmarks pending approval"
      v-if="statusBenchmarksPending"
    >
      Questions submitted to the benchmarking service are pending approval.
    </MessageBlock>

    <HasPermission to="benchmarking-group-select">
      <DatasetBenchmarksSelectGroup
        v-if="!isBenchmarkGroupSelected"
        @benchmark-group-selected="isBenchmarkGroupSelected = true"
      />
    </HasPermission>

    <div
      class="dataset__section-group"
      id="dataset-benchmarks__content"
      v-show="isBenchmarkGroupSelected"
    >
      <Tooltip id="global-match-tooltip" v-if="!isTooltipClosed">
        <VisibleText>Let us find global matches for you!</VisibleText>
        <button
          class="dataset-benchmarks__get-matches-btn"
          @click="onClickGetMatches"
        >
          <VisibleText>Get suggested matches</VisibleText>
        </button>
      </Tooltip>
      <DatasetTableData
        class="dataset__section-group-content light-scroll-bar"
        uid="benchmarks"
        :fetch-suggested-matches="getSuggestedMatches"
        :read-only="true"
      />
      <button class="dataset__section-save-btn" @click="save">
        <VisibleText>Continue</VisibleText>
      </button>
    </div>
  </DatasetLayout>
</template>

<script>
// Components
import DatasetLayout from "@/components/Layouts/DatasetLayout.vue"
import DatasetTableData from "@/components/Dataset/DatasetTable/DatasetTableData.vue"
import DatasetBenchmarksSelectGroup from "@/components/Dataset/DatasetBenchmarks/DatasetBenchmarksSelectGroup.vue"
import Tooltip from "@/components/UI/Tooltip.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

// Mixins
import DatasetMixin from "@/utils/mixins/datasetMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"

export default {
  name: "DatasetBenchmarks",
  components: {
    DatasetLayout,
    DatasetTableData,
    DatasetBenchmarksSelectGroup,
    Tooltip,
    MessageBlock
  },
  mixins: [DatasetMixin, DatasetDetailsMixin],
  data() {
    return {
      isBenchmarkGroupSelected: false,
      getSuggestedMatches: false,
      isTooltipClosed: false
    }
  },
  async activated() {
    // dont have permission to select or already has a benchmark group
    if (
      !this.$pigeonline.permissions.hasPermission(
        "benchmarking-group-select"
      ) ||
      this.dataset.benchmark_group
    ) {
      this.isBenchmarkGroupSelected = true
    }
    this.reloadBenchmarks()

    // setup onscroll listener
    document
      .getElementById("dataset-table-data")
      .addEventListener("scroll", this.handleScroll)
  },
  deactivated() {
    if (document.getElementById("dataset-table-data")) {
      document
        .getElementById("dataset-table-data")
        .removeEventListener("scroll", this.handleScroll)
    }
  },
  computed: {
    statusBenchmarksPending() {
      return this.datasetProject.isBenchmarksPending()
    }
  },
  methods: {
    handleScroll() {
      if (document.getElementById("global-match-tooltip")) {
        let rect = document
          .getElementById("global-match")
          .getBoundingClientRect()
        let isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight

        if (!isVisible) {
          document.getElementById("global-match-tooltip").style.opacity = 0
        } else {
          document.getElementById("global-match-tooltip").style.opacity = 1
        }
      }
    },
    onClickGetMatches() {
      this.getSuggestedMatches = true
      this.isTooltipClosed = true
    },
    save() {
      this.setActiveSection("DatasetSegments")
    }
  }
}
</script>
