var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"project-analysis-text-main"}},[_c('div',{staticClass:"main__navbar",attrs:{"aria-hidden":_vm.modalOpen,"inert":_vm.modalOpen}},[_c('div',{staticClass:"main__navbar-item select-question"},[_c('label',{staticClass:"select-question__info-text"},[_vm._v("Showing results from ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"select-question__input select",attrs:{"aria-label":"select question","id":"select-question-text-analysis"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.selectQuestionChanged]}},[_c('option',{attrs:{"disabled":"","value":""}},[_c('VisibleText',[_vm._v("Please select a question")])],1),_vm._l((_vm.textQuestions),function(question,index){return _c('option',{key:("option-" + index),staticClass:"select-question__input-option",domProps:{"value":question._id.$oid}},[_vm._v(_vm._s(question.question_title))])})],2)]),_c('div',{staticClass:"main__navbar-item current-view"},[_c('label',{staticClass:"current-view__info-text sr-only"},[_vm._v("Current view mode")]),_c('ul',{staticClass:"current-view__tabs-list",attrs:{"aria-hidden":_vm.modalOpen,"inert":_vm.modalOpen}},_vm._l((_vm.tabs),function(item){return _c('li',{key:item,staticClass:"current-view__tabs-list-item"},[_c('button',{class:[
              'current-view__tabs-list-item-btn',
              {
                active: _vm.activeTab === item
              }
            ],attrs:{"title":item},on:{"click":function($event){_vm.activeTab = item}}},[_vm._v(" "+_vm._s(item)+" ")])])}),0)])]),_c('Search',{attrs:{"aria-hidden":_vm.modalOpen,"inert":_vm.modalOpen}}),_c('Accordion',{staticClass:"main__collapsible-chart",attrs:{"title-element-type":"h3","icon-location":"right","aria-hidden":_vm.modalOpen,"inert":_vm.modalOpen,"expand":false},on:{"toggled":function($event){return _vm.shouldRenderPosNegChart($event)}},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('span',{staticClass:"main__collapsible-chart-title"},[_c('VisibleText',[_vm._v("Summary of ")]),_c('em',{staticClass:"chart-type"},[_vm._v(_vm._s(_vm.activePosNegChartType))]),_vm._v(" "+_vm._s(("(" + (_vm.textResponsesFiltered.length) + "/" + (_vm.textResponses.length) + ")"))+" ")],1),_c('div',{staticClass:"main__collapsible-chart-filter-pills"},[(_vm.isThemeSelected)?_c('RoundedPill',{attrs:{"icon":"analysis","value":_vm.themes[_vm.selectedThemeIndex].name,"css-styles":{ backgroundColor: 'var(--secondary)' }},on:{"remove":function($event){return _vm.setSelectedThemeIndex(-1)}}}):_vm._e(),(_vm.isSearchMode)?_c('RoundedPill',{attrs:{"icon":"search","value":_vm.search.searchString,"css-styles":{
            backgroundColor: 'yellow',
            color: 'var(--base-colour)'
          }},on:{"remove":_vm.resetSearch}}):_vm._e(),(_vm.isVisFilterActivated)?_c('RoundedPill',{attrs:{"icon":"list","value":"Chart selection","css-styles":{
            backgroundColor: '#56b4e9'
          }},on:{"remove":function($event){return _vm.setSelectedResponseIds([])}}}):_vm._e()],1)]},proxy:true}])},[_c('p',{staticClass:"main__collapsible-chart-heading"},[_c('VisibleText',[_vm._v("Showing ")]),_vm._v(" "+_vm._s(_vm.textResponsesFiltered.length)+" "),_c('VisibleText',[_vm._v(" out of ")]),_vm._v(" "+_vm._s(_vm.textResponses.length)+" "),_c('VisibleText',[_vm._v(" responses.")])],1),(_vm.barChartNode)?_c('PositiveNegativeBarChart',{ref:"pos-neg-chart",staticClass:"main__collapsible-chart-content",attrs:{"value":_vm.barChartNode},on:{"change":_vm.selectionChanged}}):_vm._e(),(!_vm.isActiveTabSentiment && !_vm.isThemeSelected)?_c('span',{staticClass:"info-msg__inline"},[_vm._v(" Select a theme to view scores. ")]):_vm._e()],1),_c('Accordion',{staticClass:"main__tabs",attrs:{"title-element-type":"h3","icon-location":"right","aria-hidden":_vm.modalOpen,"inert":_vm.modalOpen,"expand":true},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('span',{staticClass:"main__tabs-title"},[_vm._v(" Responses "+_vm._s(("(" + (_vm.textResponsesFiltered.length) + "/" + (_vm.textResponses.length) + ")"))+" ")]),_c('div',{staticClass:"main__tabs-filter-pills"},[(_vm.isThemeSelected)?_c('RoundedPill',{attrs:{"icon":"analysis","value":_vm.themes[_vm.selectedThemeIndex].name,"css-styles":{ backgroundColor: 'var(--secondary)' }},on:{"remove":function($event){return _vm.setSelectedThemeIndex(-1)}}}):_vm._e(),(_vm.isSearchMode)?_c('RoundedPill',{attrs:{"icon":"search","value":_vm.search.searchString,"css-styles":{
            backgroundColor: 'yellow',
            color: 'var(--base-colour)'
          }},on:{"remove":_vm.resetSearch}}):_vm._e(),(_vm.isVisFilterActivated)?_c('RoundedPill',{attrs:{"icon":"list","value":"Chart selection","css-styles":{
            backgroundColor: '#56b4e9'
          }},on:{"remove":function($event){return _vm.setSelectedResponseIds([])}}}):_vm._e()],1)]},proxy:true}])},[_c('div',{staticClass:"main__tabs-container"},[_vm._l((_vm.tabs),function(tab){return [_c(tab,{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab == tab),expression:"activeTab == tab"}],key:'tab-' + tab,ref:'tab-' + tab,refInFor:true,tag:"component"})]})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }