<template>
  <ListGrid
    class="dataset-table__data"
    :aria-label="`${dataset.name} data`"
    :id="`dataset-table-select-${uid}`"
    :custom-grid-style="{ gridTemplateColumns: '15% 1fr 1fr 20% 15%' }"
    :headers="headers"
    :list="clientQuestionItems"
  >
    <template slot-scope="{ item, index }">
      <ListGridItem col="0" :headers="headers" :row="index">
        <label
          class="form-label form-checkbox-label"
          :id="`row-${item._id.$oid}`"
        >
          <input
            type="checkbox"
            tabindex="-1"
            :disabled="isDisabled(item)"
            :id="`input-${index}-${uid}`"
            :value="item._id.$oid"
            v-model="selectedQuestions"
          />
          {{ item.question_title }}
        </label>
      </ListGridItem>
      <ListGridItem
        class="question-text"
        col="1"
        :headers="headers"
        :row="index"
        :rowuid="`row-${item._id.$oid}`"
      >
        {{ item.question_text }}
      </ListGridItem>
      <ListGridItem
        class="global-match dataset-table__match-wrapper"
        col="2"
        :headers="headers"
        :row="index"
        :rowuid="`row-${item._id.$oid}`"
        :focus-cell="true"
      >
        <span class="dataset-table__match-title">
          {{
            globalMatch(item._id.$oid)
              ? globalMatch(item._id.$oid).global_question_title
              : ""
          }}
        </span>
        <span class="dataset-table__match-text">
          {{
            globalMatch(item._id.$oid)
              ? globalMatch(item._id.$oid).global_question_text
              : ""
          }}
        </span>
      </ListGridItem>
      <ListGridItem
        col="3"
        :headers="headers"
        :row="index"
        :rowuid="`row-${item._id.$oid}`"
      >
        <DatasetTableDataType :client-question="item" :read-only="true" />
      </ListGridItem>
      <ListGridItem
        col="4"
        style="overflow: hidden"
        :headers="headers"
        :row="index"
        :rowuid="`row-${item._id.$oid}`"
        v-if="forDrivers"
      >
        <button
          class="drivers__select-set-dependent-btn"
          tabindex="-1"
          :disabled="isDisabled(item)"
          @click="$emit('set-dependent', item._id.$oid)"
        >
          <VisibleText>Set as dependent variable</VisibleText>
        </button>
      </ListGridItem>
    </template>
  </ListGrid>
</template>

<script>
// Components
import DatasetTableDataType from "@/components/Dataset/DatasetTable/DatasetTableDataType.vue"
import ListGrid from "@/components/UI/ListGrid.vue"
import ListGridItem from "@/components/UI/ListGridItem.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import ProjectMixin from "@/utils/mixins/projectMixin.js"

export default {
  name: "ProjectAnalysisSelectQuestionsTable",
  mixins: [DatasetDetailsMixin, ProjectMixin],
  props: {
    // // a function to check for any questions you want to disable
    disableInput: {
      default: () => null,
      type: Function
    },
    forDrivers: {
      default: false,
      type: Boolean
    },
    questions: {
      default: () => [],
      type: Array
    },
    uid: {
      default: () => "questions",
      type: String,
      required: true
    },
    selected: {
      default: () => [],
      type: Array,
      required: true
    }
  },
  components: {
    DatasetTableDataType,
    ListGrid,
    ListGridItem
  },
  data() {
    return {
      headers: [
        "question code",
        "question text",
        "global match",
        "data type",
        ""
      ],
      selectedQuestions: []
    }
  },
  created() {
    this.selectedQuestions = this.selected
  },
  computed: {
    clientQuestionItems() {
      // if questions were passed, use those
      if (this.questions.length > 0) return this.questions
      // no questions passed so use all client questions
      else return this.clientQuestions
    }
  },
  methods: {
    globalMatch(questionId) {
      return this.matchesDetails[questionId]
    },
    isDisabled(item) {
      if (this.disableInput) return this.disableInput(item)
      else return false
    }
  },
  watch: {
    selectedQuestions: function() {
      this.$emit("update-selected-questions", this.selectedQuestions, this.uid)
    }
  }
}
</script>
