<template>
  <div>
    <article id="project" v-if="project">
      <!--------------------------------------------
      |
      | HEADER
      |
      --------------------------------------------->
      <div class="project__top-bar" :aria-hidden="modalOpen" :inert="modalOpen">
        <InlineEditTextInput
          label="project title"
          input-id="project-title-input"
          input-name="project-title"
          :disable-editing="disableEditingTitle"
          :input-value="project.name"
          @save="saveProjectTitle"
        >
          <h1 class="project__title">
            {{ project.name }}
          </h1>
        </InlineEditTextInput>
        <span class="project__date">
          <VisibleText>Last modified on </VisibleText>
          {{ project.last_modified_on.$date | formatDateTime }}
        </span>
      </div>

      <div
        class="project__top-bar-2"
        :aria-hidden="modalOpen"
        :inert="modalOpen"
      >
        <ProjectTabs :project="project" />
        <SaveToPdf
          :document="report"
          class="project__top-bar-2-btn project__export-btn"
          v-if="$route.name === 'report-edit' && report"
        >
          <ExportIcon class="svg-icon" />
          <VisibleText>Export To PDF</VisibleText>
        </SaveToPdf>
        <button
          class="project__top-bar-2-btn btn-default"
          v-if="isProjectOwner"
          @click="isShareModalVisible = true"
        >
          <SvgIconDecorative icon="share" />
          <VisibleText>Share Project</VisibleText>
        </button>
      </div>

      <!--------------------------------------------
      |
      | MAIN CONTENT
      |
      --------------------------------------------->
      <div class="project__content">
        <DatasetDeletedMessage v-if="!dataset && errDatasetDeleted" />
        <template v-if="datasetDetailsLoaded">
          <ProjectSettings />

          <MessageBlock
            class="project__demo-msg"
            message-type="information"
            v-if="(isReadOnly || isDemoUser) && $route.name !== 'report-edit'"
          >
            This is a read-only project. Any changes you make will not be saved.
          </MessageBlock>

          <DatasetSummary
            v-if="
              !['report-new', 'report-edit', 'report'].includes($route.name)
            "
            :aria-hidden="modalOpen"
            :inert="modalOpen"
          />

          <keep-alive>
            <router-view
              :prop-read-only="isProjectReadOnly"
              @updateProject="saveProject"
              @loaded="report = $event"
            ></router-view>
          </keep-alive>
        </template>
      </div>
    </article>

    <!--------------------------------------------
      |
      | SHARE MODAL
      |
      --------------------------------------------->
    <ProjectShareModal
      :is-saving="isSavingShared"
      :project="project"
      :dataset="datasetProject"
      :show="isShareModalVisible"
      ref="share-modal"
      @saveChanges="saveShared"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
// Components
import DatasetSummary from "@/components/Dataset/DatasetSummary.vue"
import DatasetDeletedMessage from "@/components/Project/ProjectDatasetDeletedMessage.vue"
import InlineEditTextInput from "@/components/UI/InlineEditTextInput.vue"
import ProjectTabs from "@/components/Project/ProjectTabs.vue"
import ProjectSettings from "@/components/Project/ProjectSettings/ProjectSettings.vue"
import ProjectShareModal from "@/components/Share/ShareProject.vue"
import { SaveToPdf } from "@pigeonline/pigeondoc"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

// Icons
import ExportIcon from "@/assets/icons/export.svg"

// Mixins
import ProjectMixin from "@/utils/mixins/projectMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import SharedStatusMixin from "@/utils/mixins/sharedStatusMixin.js"

// Models
import { PigeonDocModel } from "@pigeonline/pigeondoc"

// Helpers
import { mapActions, mapGetters } from "vuex"

export default {
  name: "Project",
  mixins: [ProjectMixin, DatasetDetailsMixin, SharedStatusMixin],
  components: {
    DatasetSummary,
    DatasetDeletedMessage,
    InlineEditTextInput,
    ProjectTabs,
    ProjectSettings,
    ProjectShareModal,
    SaveToPdf,
    SvgIconDecorative,
    MessageBlock,
    ExportIcon
  },
  data() {
    return {
      isShareModalVisible: false,
      isSavingShared: false,
      errDatasetDeleted: false,
      report: null
    }
  },
  beforeRouteLeave(to, from, next) {
    this.resetDatasetDetails()
    this.resetProject()
    this.resetConfirm()
    next()
  },
  async created() {
    await this.loadData()
  },
  mounted() {
    this.isShareModalVisible = this.$route.query.share == "true"
    if (this.$route.name == "report-edit") {
      setTimeout(() => {
        const pigeondocWrapper = document.getElementsByClassName("pigeondoc")
        if (pigeondocWrapper[0]) {
          this.loaded()
        }
      }, 2000)
    }
  },
  computed: {
    ...mapGetters("datasetWizard", {
      datasetProject: "getDatasetProject"
    }),
    disableEditingTitle() {
      return this.isDemoUser || this.isReadOnly
    },
    isProjectReadOnly() {
      return this.isDemoUser || this.isReadOnly
    }
  },
  methods: {
    ...mapActions("datasetWizard", ["setDatasetProject"]),
    /**
     * when pigeondoc (report) is loaded add theme to class name
     * to apply the appropriate themeing
     * FIXME: temporary solution, need to apply themeing to pigondoc in future
     */
    loaded() {
      const theme = this.$theme.theme.name
      const pigeondocWrapper = document.getElementsByClassName("pigeondoc")
      pigeondocWrapper[0].classList.add(`theme-${theme}`)
      document.title = `${this.project.name} - Report | ${this.$theme.theme.pageTitle}`
    },
    async loadData() {
      await this.setProject(await this.fetchProject())
      this.isReadOnly = this.checkIsReadOnly(this.project)
      if (this.project.datasets && this.project.datasets[0]) {
        const dataset = await this.fetchDataset(this.project.datasets[0]).catch(
          e => {
            this.errDatasetDeleted = true
            console.error(e)
          }
        )
        if (dataset) {
          this.setDataset(dataset)
          this.fetchDatasetProject(dataset._id.$oid)
          await this.loadDatasetDetails(dataset)
        }
      }
      this.isReadOnly = this.checkIsReadOnly(this.project)
    },
    async saveShared(users, sharingTypes) {
      let shared = {}
      sharingTypes.map(function(type) {
        shared[type] = []
      })
      for (let i = 0; i < users.length; i++) {
        let user = users[i]
        for (const [type, permissions] of Object.entries(user.permissions)) {
          shared[type].push([user.id, user.notify || false, permissions])
        }
      }
      if (Object.keys(shared).length === 0) return

      for (const [type, value] of Object.entries(shared)) {
        let _shared = this[type] && this[type].shared

        this.isSavingShared = true
        try {
          switch (type) {
            case "project":
              this.project.shared = value
              try {
                await this.saveProject(this.project)

                // save shared value to project report as well
                if (this.project.report) {
                  const report = await this.$pigeonline.projects.getByID(
                    PigeonDocModel,
                    this.project.report
                  )
                  report.shared = value
                  await this.$pigeonline.projects.update(report)
                }
              } catch (e) {
                if (_shared) this.project.shared = _shared
                console.error(e)
              }
              break
            case "dataset":
              if (!this.datasetProject) continue
              this.datasetProject.shared = value
              await this.setDatasetProject(
                await this.$pigeonline.projects.update(this.datasetProject)
              ).catch(e => {
                if (_shared) this.datasetProject.shared = _shared
                console.error(e)
              })
              break
            default:
              continue
          }
        } catch (e) {
          alert(
            "An error occured processing your request. Please try again later."
          )
          throw new Error("SaveProjects.vue:saveChanges:: " + e)
        } finally {
          this.isSavingShared = false
        }
      }

      alert("Your changes have been saved successfully.")
      this.closeModal()
      this.isSavingShared = false

      // refresh the page
      window.location.reload()
    },
    closeModal() {
      this.isShareModalVisible = false
    },
    async saveProjectTitle(text) {
      try {
        this.project.name = text
        this.saveProject(this.project)
      } catch (e) {
        throw new Error("Project.vue:saveProject " + e.message)
      }
    }
  },
  watch: {
    errDatasetDeleted: function(isDeleted) {
      if (isDeleted) {
        document.title = `${this.project.name} - Error | ${this.$theme.theme.pageTitle}`
      }
    }
  }
}
</script>
