<template>
  <Table
    class="questions__table"
    :caption="caption"
    :hide-caption="false"
    :row-data="questions"
    :column-headers="columnHeaders"
    :sortable="sortable"
    :sortable-order="sortableOrder"
    @sortLocaleCompare="sortLocaleCompare"
  >
    <template slot-scope="{ row }">
      <td class="td-question-select">
        <input
          type="checkbox"
          class="questions__table-checkbox"
          :id="`select-chkbox-${id}-${row.id}`"
          :name="`select-chkbox-${id}-${row.id}`"
          :checked="row.options.selected"
          @click.prevent="toggleSelect(row)"
        />
        <label :for="`select-chkbox-${id}-${row.id}`" class="sr-only">
          select {{ row.question_title }}
        </label>
      </td>
      <td class="td-question-title" data-label="title">
        <span class="questions__table-title">
          {{ row.question_title }}
        </span>
      </td>
      <td class="td-question-wording" data-label="wording">
        <span class="questions__table-wording">
          {{ row.question_text }}
        </span>
      </td>
      <td class="td-question-data-type" data-label="data type">
        <span class="questions__table-data-type">
          {{ TYPE_TO_READABLE[row.approved_data_type] }}
        </span>
      </td>
    </template>
  </Table>
</template>

<script>
// Components
import Table from "@/components/UI/Table.vue"

export default {
  name: "TableQuestions",
  components: {
    Table
  },
  props: {
    caption: {
      type: String,
      default: () => ""
    },
    questionsList: {
      type: Array,
      default: () => []
    },
    enableSortByColHeader: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      questions: [],
      COL_HEADERS: ["Question", "Wording", "Data Type"],
      COL_HEADERS_TO_ATTRIBUTES: {
        Question: "question_title",
        Wording: "question_text",
        "Data Type": "approved_data_type"
      },
      TYPE_TO_READABLE: {
        OPEN_ENDED: "text",
        NUMERIC: "numeric",
        ID: "id",
        CAT: "category",
        SCALE_1_TO_5: "5-point scale",
        DATE: "date",
        OTHER: "other",
        SCALE: "scale"
      }
    }
  },
  computed: {
    id() {
      return this._uid
    },
    columnHeaders() {
      return ["", ...this.COL_HEADERS]
    },
    sortable() {
      let obj = {}
      if (this.enableSortByColHeader) {
        this.COL_HEADERS.map(el => (obj[el] = "sortLocaleCompare"))
      }
      return obj
    },
    sortableOrder() {
      let obj = {}
      if (this.enableSortByColHeader) {
        this.COL_HEADERS.map(el => (obj[el] = 0))
      }
      return obj
    }
  },
  mounted() {
    this.questions = [...this.questionsList]
  },
  methods: {
    select(question) {
      question.options.selected = true
      this.$emit("updated", true)
    },
    toggleSelect(question) {
      question.options.selected = !question.options.selected
      this.$emit("updated", true)
    },
    sortLocaleCompare(colName) {
      // reset sort order of other columns
      Object.keys(this.sortableOrder)
        .filter(k => k != colName)
        .map(k => (this.sortableOrder[k] = 0))

      // initalize sortable order if reset
      if (this.sortableOrder[colName] == 0) {
        this.sortableOrder[colName] = -1
      } else {
        this.sortableOrder[colName] *= -1 // reverse sort order
      }
      this.questions = [...this.questionsList].sort(
        (a, b) =>
          this.sortableOrder[colName] *
          a[this.COL_HEADERS_TO_ATTRIBUTES[colName]].localeCompare(
            b[this.COL_HEADERS_TO_ATTRIBUTES[colName]]
          )
      )
    }
  },
  watch: {
    questionsList: function(val) {
      if (!val || !Array.isArray(val)) return
      this.questions = [...val]
    }
  }
}
</script>
