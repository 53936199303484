import { mapActions, mapGetters } from "vuex"

// Services
import DatasetsService from "@/services/datasetsService.js"
import TextService from "@/services/textService.js"

// Utils
import hash from "@/utils/hash.js"

const API_ERROR_MESSAGE =
  "An error occured processing your request. Please try again later."

const CACHE_RESPONSES_WITH_SENTIMENTS = {}
const CACHE_RESPONSES_THEME = {}
const CACHE_COVERAGE = {}

export default {
  data() {
    return {
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      TEXT_SERVICE: new TextService(this.$pigeonline),
      requiresRefetchingThemeResponses: false
    }
  },
  computed: {
    ...mapGetters("project", {
      project: "getProject"
    }),
    ...mapGetters("globalModule", {
      modalOpen: "getModalOpen"
    }),
    ...mapGetters("analysisText", {
      _activeTab: "getActiveTab",
      _datasetId: "getDatasetId",
      textQuestions: "getTextQuestions",
      selectedTextQuestion: "getSelectedTextQuestion",
      selectedTextQuestionResponses: "getSelectedTextQuestionResponses",
      themes: "getThemes",
      selectedThemeIndex: "getSelectedThemeIndex",
      selectedThemeResponses: "getSelectedThemeResponses",
      sentimentsSortOrder: "getSentimentsSortOrder",
      selectedResponseIds: "getSelectedResponseIds",
      search: "getSearch",
      bannedKeywords: "getBannedKeywords",
      bannedComments: "getBannedComments",
      pinnedComments: "getPinnedComments",
      hiddenComments: "getHiddenComments",
      showSpinner: "getShowSpinner"
    }),
    isSearchMode: function() {
      return this.search.searchString.trim() !== ""
    },
    isThemeSelected: function() {
      return this.selectedThemeIndex !== -1
    },
    isVisFilterActivated: function() {
      return this.selectedResponseIds.length > 0
    },
    isActiveTabSentiment: function() {
      return this._activeTab == "Sentiment"
    },
    searchResponseIds: function() {
      return this.search.searchResults.map(sItem => sItem[0].id)
    },
    selectedTextQuestionId: function() {
      if (!this.selectedTextQuestion) return undefined
      return this.selectedTextQuestion._id.$oid
    },
    selectedThemeResponseIds: function() {
      return this.selectedThemeResponses.map(tItem => tItem[0].id)
    },
    textResponses: function() {
      return this.selectedTextQuestionResponses
        .filter(item => !this.bannedComments.includes(item.id))
        .map(
          function(item) {
            // array of keywords (by each theme) present in a response
            const themeKeywords = this.themes.map(theme =>
              theme.keywords
                .map(k => k.toLowerCase())
                .filter(k => item.response.toLowerCase().includes(k))
            )

            // array of search match keywords
            const searchResultItem = this.search.searchResults[
              this.searchResponseIds.indexOf(item.id)
            ]
            const searchKeywords =
              (searchResultItem &&
                searchResultItem[0].matching_reasons
                  .map(item =>
                    String(item)
                      .trim()
                      .toLowerCase()
                  )
                  .sort((a, b) => b.length - a.length)) ||
              []
            const searchScore = (searchResultItem && searchResultItem[1]) || 0

            return {
              idx: item.idx, // 0-index value of a response
              id: item.id, // response oid
              response: item.response, // response text
              keywords: {
                search: searchKeywords,
                sentiment: {
                  pos: item.sentiment.posKeywords,
                  neg: item.sentiment.negKeywords
                },
                themes: themeKeywords
              },
              scores: {
                search: searchScore,
                sentiment: item.sentiment.score,
                themes: themeKeywords.map((kws, index) =>
                  this.themes[index] && this.themes[index].keywords.length !== 0
                    ? kws.length / this.themes[index].keywords.length
                    : 0
                )
              }
            }
          }.bind(this)
        )
    },
    textResponsesFilteredWithoutVisSelection: function() {
      let responses = [...this.textResponses]

      // when a theme is selected
      if (this.isThemeSelected) {
        responses = responses.filter(rItem =>
          this.selectedThemeResponseIds.includes(rItem.id)
        )
      }

      // active search mode
      if (this.isSearchMode) {
        responses = responses.filter(rItem =>
          this.searchResponseIds.includes(rItem.id)
        )
      }

      return responses
    },
    textResponsesFiltered: function() {
      let responses = this.textResponsesFilteredWithoutVisSelection

      // visualisation: selection/filter
      if (this.isVisFilterActivated) {
        responses = responses.filter(rItem =>
          this.selectedResponseIds.includes(rItem.id)
        )
      }

      return responses
    },
    resultText: function() {
      let responseCount = this.textResponsesFiltered.length
      let responseQuantifier = `<i>${
        this.isVisFilterActivated ? "filtered" : "all"
      }</i>`
      let responseText = ""
      if (this.isSearchMode && this.isThemeSelected) {
        responseText = `Showing search results from ${responseQuantifier} text responses in theme [<span style="font-style: italic;">${
          this.themes[this.selectedThemeIndex].name
        }</span>].`
      }
      if (this.isSearchMode && !this.isThemeSelected) {
        responseText = `Showing search results from ${responseQuantifier} text responses.`
      }

      if (!this.isSearchMode && this.isThemeSelected) {
        responseText = `Showing ${responseQuantifier} text responses from theme [<span style="font-style: italic;">${
          this.themes[this.selectedThemeIndex].name
        }</span>].`
      }

      if (!this.isSearchMode && !this.isThemeSelected) {
        responseText = `Showing ${responseQuantifier} text responses.`
      }
      return responseText + ` ${responseCount} results found. `
    }
  },
  methods: {
    ...mapActions("project", ["setProject"]),
    ...mapActions("analysisText", [
      "setActiveTab",
      "setDatasetId",
      "setTextQuestions",
      "setSelectedTextQuestion",
      "setSelectedTextQuestionResponses",
      "setThemes",
      "setSelectedThemeIndex",
      "setSelectedThemeResponses",
      "setSentimentsSortOrder",
      "setSelectedResponseIds",
      "setSearch",
      "setShowSpinner",
      "setBannedKeywords",
      "setBannedComments",
      "setPinnedComments",
      "setHiddenComments",
      "resetDataset",
      "resetSearch"
    ]),

    /* Local storage */
    getApiLanguage() {
      return window.localStorage.getItem("apiLanguage") || ""
    },

    /* Utils */
    deepCloneObj(obj) {
      // deep clones an object using JSON stringify (data loss might occur)
      if (Array.isArray(obj)) {
        return obj.map(item => JSON.parse(JSON.stringify(item)))
      } else if (typeof obj == "object") {
        return JSON.parse(JSON.stringify(obj))
      }
    },

    arrayEquals(array1, array2) {
      return JSON.stringify(array1.sort()) === JSON.stringify(array2.sort())
    },

    isEmpty(theme) {
      return (
        !theme ||
        (theme.keywords.length === 0 &&
          theme.excerpts.length === 0 &&
          theme.notes.length === 0)
      )
    },

    getResponse(responseId) {
      return this.selectedTextQuestionResponses.filter(
        item => item.id === responseId
      )[0]
    },

    filterBannedKeywords(keywords) {
      return keywords.filter(
        el => this.bannedKeywords.indexOf(el.trim().toLowerCase()) === -1
      )
    },

    /**
     * Ban keyword
     * @param {String} keyword to ban
     * @return None
     */
    async banKeyword(keyword) {
      if (this.bannedKeywords.includes(keyword.trim().toLowerCase())) {
        alert(`Keyword \`${keyword}\` already banned!`)
        return
      }

      // update store
      const bannedKeywordsOld = [...this.bannedKeywords]
      this.setBannedKeywords([...this.bannedKeywords, keyword])

      // save to backend
      this.setShowSpinner(true)
      try {
        // update project
        this.project["textAnalysis"]["bannedKeywords"] = this.bannedKeywords
        this.prepareThemes(this.deepCloneObj(this.themes)).then(themes => {
          this.project["textAnalysis"]["themes"] = themes
          this.$pigeonline.projects.update(this.project).then(project => {
            this.setProject(project)
            this.setThemes(project["textAnalysis"]["themes"])
            alert(`Keyword \`${keyword}\` banned successfully.`)
          })
        })
      } catch (e) {
        alert(API_ERROR_MESSAGE)
        this.setBannedKeywords(bannedKeywordsOld) // restore banned keywords
        throw new Error("analysisTextMixin.js:banKeyword: " + e)
      } finally {
        this.setShowSpinner(false)
      }
    },

    /**
     * Generate themes based on project and client_question id.
     * @return [Object] list of themes
     */
    async generateThemes() {
      try {
        const response = await this.TEXT_SERVICE.keywords({
          project_id: this.project.id,
          data_set_id: this._datasetId,
          client_question_id: this.selectedTextQuestion._id.$oid
        })

        const _map = async function(theme) {
          const keywords = this.filterBannedKeywords(
            theme[1].keywords.map(item => item[0])
          )
          return {
            name: theme[0],
            keywords: keywords,
            coverage: theme[1].coverage,
            sentiment: await this.computeSentiment(keywords),
            excerpts: [],
            notes: []
          }
        }.bind(this)
        return await Promise.all(Object.entries(response).map(theme => _map(theme))) // eslint-disable-line
      } catch (e) {
        console.error(
          "ProjectAnalysisTextTheme.vue:generateThemes: " + e.message
        ) // eslint-disable-line
      }
    },

    /**
     * Fetch text responses for a client question with sentiment score
     * @param None
     * @return [Object] list of text responses
     */
    async fetchTextResponsesWithSentiments() {
      if (!this.selectedTextQuestionId) return []
      this.setShowSpinner(true)
      try {
        const hashKey = this.selectedTextQuestionId + this.getApiLanguage()
        const response =
          CACHE_RESPONSES_WITH_SENTIMENTS[hashKey] ||
          (await this.TEXT_SERVICE.sentiments({
            client_question_id: this.selectedTextQuestionId
          }))
        CACHE_RESPONSES_WITH_SENTIMENTS[hashKey] = response
        return response.map(function(item, index) {
          return {
            idx: index,
            id: item[0].id,
            response: item[0].response,
            sentiment: {
              score: item[1],
              posKeywords: item[2].pos_words_list.map(el =>
                el.trim().toLowerCase()
              ),
              negKeywords: item[2].neg_word_list.map(el =>
                el.trim().toLowerCase()
              )
            }
          }
        })
      } catch (e) {
        throw new Error(
          "analysisTextMixin.vue:fetchTextResponsesWithSentiments: " + e.message
        )
      } finally {
        this.setShowSpinner(false)
      }
    },

    /**
     * Fetch theme responses from the api for a specific theme
     * @param None
     * @return [Object] list of theme responses
     */
    async fetchThemeResponses() {
      this.setShowSpinner(true)
      try {
        const payload = {
          client_question_id: this.selectedTextQuestionId,
          project_id: this.project.id,
          theme_name: this.themes[this.selectedThemeIndex].name
        }
        const hashKey = hash.hashValue(
          JSON.stringify(payload) + this.getApiLanguage()
        )
        const response =
          CACHE_RESPONSES_THEME[hashKey] ||
          (await this.TEXT_SERVICE.filterTheme(payload))
        CACHE_RESPONSES_THEME[hashKey] = response
        return response
      } catch (e) {
        throw new Error(
          "analysisTextMixin.js:fetchThemeResponses: " + e.message
        )
      } finally {
        this.setShowSpinner(false)
      }
    },

    /**
     * Update theme title for given index
     * @param {Int} theme index
     * @param {String} theme title
     * @return None
     */
    async updateThemeTitle(themeIndex, title) {
      let themes = this.deepCloneObj(this.themes)
      if (typeof title !== "string" || title.trim() === "") {
        title = "unnamed theme"
      }
      let themeTitles = themes.map(item => item.name.toLowerCase())
      themeTitles.splice(themeIndex, 1)

      let count = 1
      let loop = themeTitles.includes(title)
      while (loop) {
        if (themeTitles.includes(title + count)) {
          count += 1
          continue
        }
        title = title + count
        break
      }
      themes[themeIndex].name = title

      await this.saveThemes(themes)
    },

    /**
     * Preprocess themes a.k.a. compute coverage and sentiment
     * @param {Object} themes dict
     * @return {Object} preprocess themes dict
     */
    prepareThemes: async function(themes, forceCompute = false) {
      for (let i = 0; i < themes.length; i++) {
        if (!themes[i]) continue

        // recompute coverage if keywords array differs from original
        let keywords = Array.from(
          new Set(this.filterBannedKeywords(themes[i].keywords))
        )
        if (
          forceCompute ||
          !this.arrayEquals(
            keywords,
            this.themes[i] ? this.themes[i].keywords : []
          )
        ) {
          themes[i].coverage = await this.computeCoverage(
            keywords,
            forceCompute
          )
          themes[i].sentiment = await this.computeSentiment(
            keywords,
            forceCompute
          )

          // set refetch to true if selected theme modified
          if (this.selectedThemeIndex === i) {
            this.requiresRefetchingThemeResponses = true
          }
        }
        themes[i].keywords = keywords
      }
      return themes.filter(theme => !this.isEmpty(theme))
    },

    /**
     * Save themes to backend after preprocessing
     * @param {Object} new themes dict
     * @return None
     */
    async saveThemes(newValue, forceCompute = false) {
      this.setShowSpinner(true)

      // clone new value and retain old one
      let oldValue = this.deepCloneObj(this.themes)
      newValue = await this.prepareThemes(
        this.deepCloneObj(newValue),
        forceCompute
      )
      this.setThemes(newValue)
      this.project["textAnalysis"]["themes"] = newValue

      try {
        const response = await this.$pigeonline.projects.update(this.project)
        if (response && response.id) {
          this.setProject(response)
          if (this.selectedThemeIndex >= newValue.length) {
            this.setSelectedThemeIndex(-1) // reset theme
            this.setSelectedThemeResponses([])
          }
        }
      } catch (e) {
        // revert the changes
        this.setThemes(oldValue)
        this.project["textAnalysis"]["themes"] = oldValue
        throw new Error("analysisTextMixin.js:saveThemes: " + e.message)
      } finally {
        this.setShowSpinner(false)
      }
    },

    /**
     * Computes coverage for a list of keywords
     * @param [String] list of keywords
     * @return {Float} coverage
     */
    async computeCoverage(keywords, forceCompute = false) {
      try {
        const keywordsFiltered = [...this.filterBannedKeywords(keywords)].sort()
        const hashKey = hash.hashValue(
          this.selectedTextQuestionId +
            keywordsFiltered.join(":") +
            this.getApiLanguage()
        )
        const response =
          (!forceCompute && CACHE_COVERAGE[hashKey]) ||
          (await this.TEXT_SERVICE.coverage({
            project_id: this.project.id,
            client_question_id: this.selectedTextQuestionId,
            search_keywords: keywordsFiltered
          }))
        CACHE_COVERAGE[hashKey] = response
        return response
      } catch (e) {
        throw new Error("analysisTextMixin.js:computeCoverage: " + e.message)
      }
    },

    /**
     * Compute sentiment score for a list of keywords
     * @param [String] list of keywords
     * @return {Object} sentiment score dict
     */
    async computeSentiment(keywords) {
      try {
        const response = await this.TEXT_SERVICE.sentiment({
          project_id: this.project.id,
          client_question_id: this.selectedTextQuestionId,
          keywords: this.filterBannedKeywords(keywords)
        })
        return response
      } catch (e) {
        throw new Error("analysisTextMixin.js:computeSentiment: " + e.message)
      }
    },

    generateSentimentText(score) {
      return score >= 0
        ? `${(score * 100).toFixed(1)}% positive sentiment`
        : `${(score * -100).toFixed(1)}% negative sentiment`
    },

    /**
     * Select a text question (set as active)
     * @param [Object] question object
     */
    async selectTextQuestion(question) {
      await this.setSelectedTextQuestion(question)
      await this.updateSelectedTextQuestionResponses()
    },

    /**
     * Update text question responses of a selected question
     * @param None
     */
    async updateSelectedTextQuestionResponses() {
      if (!this.selectedTextQuestion) return
      await this.setSelectedTextQuestionResponses(
        await this.fetchTextResponsesWithSentiments()
      )

      // update themes data
      let shouldSaveUpdatedThemes = false
      if (this.project.textAnalysis && this.project.textAnalysis.themes) {
        Promise.all(
          this.project.textAnalysis.themes
            .filter(theme => theme != null)
            .map(
              async function(theme) {
                const keywords = this.filterBannedKeywords(theme.keywords)

                // update theme keywords
                theme.keywords = [...keywords]

                // recompute sentiment
                if (
                  !theme.sentiment ||
                  typeof theme.sentiment != "object" ||
                  !theme.sentiment.keywords_sentiment
                ) {
                  theme.sentiment = await this.computeSentiment(keywords) // API: text/sentiments/custom_string
                  shouldSaveUpdatedThemes = true
                }

                // recompute coverage
                if (!theme.coverage || typeof theme.coverage != "object") {
                  theme.coverage = await this.computeCoverage(keywords) // API: text/word_coverage
                  shouldSaveUpdatedThemes = true
                }
                return theme
              }.bind(this)
            )
        ).then(themes => {
          this.setThemes(themes)
          if (shouldSaveUpdatedThemes) {
            this.project["textAnalysis"]["themes"] = themes
            this.$pigeonline.projects
              .update(this.project)
              .then(response => {
                if (response && response.id) {
                  this.setProject(response)
                }
              })
              .catch(e => {
                throw new Error(
                  "analysisTextMixin.js:updateSelectedTextQuestionResponses: " +
                    e.message
                )
              })
          }
        })
      }
    },

    /** Response list item methods **/
    async toggleResponseItemPin(responseId) {
      const pinnedComments = [...this.pinnedComments]
      if (pinnedComments.includes(responseId)) {
        pinnedComments.splice(pinnedComments.indexOf(responseId), 1)
      } else {
        pinnedComments.push(responseId)
      }

      // save to backend
      this.setShowSpinner(true)
      try {
        // update project
        this.project["textAnalysis"]["pinnedComments"] = pinnedComments
        this.setProject(await this.$pigeonline.projects.update(this.project))
        this.setPinnedComments(pinnedComments)
      } catch (e) {
        alert(API_ERROR_MESSAGE)
        throw new Error("analysisTextMixin.js:toggleResponseItemPin: " + e)
      } finally {
        this.setShowSpinner(false)
      }
    },
    async banResponseItem(responseId) {
      if (this.bannedComments.includes(responseId)) return

      // save to backend
      this.setShowSpinner(true)
      try {
        // update project
        await this.TEXT_SERVICE.banComment({
          project_id: this.project.id,
          client_question_id: this.selectedTextQuestion._id.$oid,
          response_id: responseId
        })

        // update store
        this.setBannedComments([...this.bannedComments, responseId])

        // recompute coverage/sentiment and save themes
        await this.saveThemes(this.themes, true)
      } catch (e) {
        alert(API_ERROR_MESSAGE)
        throw new Error("analysisTextMixin.js:banResponseItem: " + e)
      } finally {
        this.setShowSpinner(false)
      }
    },
    async toggleResponseItemHide(responseId) {
      const hiddenComments = [...this.hiddenComments]
      if (hiddenComments.includes(responseId)) {
        hiddenComments.splice(hiddenComments.indexOf(responseId), 1)
      } else {
        hiddenComments.push(responseId)
      }

      // save to backend
      this.setShowSpinner(true)
      try {
        // update project
        this.project["textAnalysis"]["hiddenComments"] = hiddenComments
        this.setProject(await this.$pigeonline.projects.update(this.project))
        this.setHiddenComments(hiddenComments)
      } catch (e) {
        alert(API_ERROR_MESSAGE)
        throw new Error("analysisTextMixin.js:toggleResponseItemHide: " + e)
      } finally {
        this.setShowSpinner(false)
      }
    }
  }
}
