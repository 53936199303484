import Vue from "vue"

export default {
  namespaced: true,
  state() {
    return {
      modalOpen: false
    }
  },
  getters: {
    getModalOpen: state => state.modalOpen
  },
  mutations: {
    SET_MODAL_OPEN(state, payload) {
      Vue.set(state, "modalOpen", payload)
    },
    RESET_GLOBAL_MODULE(state) {
      Vue.set(state, "modalOpen", false)
    }
  },
  actions: {
    async setModalOpen({ commit }, payload) {
      await commit("SET_MODAL_OPEN", payload)
    },
    async resetGlobalModule({ commit }) {
      await commit("RESET_GLOBAL_MODULE")
    }
  }
}
