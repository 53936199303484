import moment from "moment"

export default {
  formatDate: value => {
    if (value) {
      return moment(value).format("MMMM D, YYYY")
    }
  },
  formatDateTime: value => {
    if (value) {
      return moment(value).format("MMMM D, YYYY hh:mm A")
    }
  },
  /**
   * Search through object values of specified (allowed) keys
   * @param {Array} array of objects
   * @param {Array} keys list of allowed keys to construct search (world)
   * @param {String} searchTerm term to search for
   */
  searchInObjectValues: (array, keys, searchTerm) => {
    if (!searchTerm) return array

    const regExp = new RegExp(`${searchTerm}`, "ig")
    let filtered = []

    filtered = array.filter(item => {
      let match = false
      keys.forEach(key => {
        if (item[key].search(regExp) !== -1) match = true
      })
      return match
    })

    return filtered || array
  },
  replaceSpaceWithDash: words => {
    if (!words) return ""
    return words.replace(/\s/g, "-")
  },

  /**
   * Convert number to fixed decimal places
   */
  toFixed: (number, decimals = 2) => {
    if (!number || typeof number != "number") number = 0
    return number.toFixed(decimals)
  }
}
