<template>
  <div
    class="ui-selectable"
    ref="selectable-container"
    v-click-outside="closeTools"
  >
    <div
      v-show="showTools"
      aria-label="text tools"
      class="ui-selectable__tools"
      role="dialog"
      ref="selectable-tools"
      tabindex="-1"
      :data-opened-tools="showTools"
      :style="{
        left: `${x}px`,
        top: `${y}px`
      }"
    >
      <button
        v-if="!highlightNodeSelected"
        class="ui-selectable__tools-item"
        @click.prevent="handleAction('excerpt')"
      >
        <VisibleText>Excerpt</VisibleText>
      </button>
      <button
        v-if="!highlightNodeSelected"
        class="ui-selectable__tools-item"
        @click.prevent="handleAction('keyword')"
      >
        <VisibleText>Keyword</VisibleText>
      </button>
      <button
        class="ui-selectable__tools-item"
        @click.prevent="handleAction('ban')"
      >
        <VisibleText>Ban</VisibleText>
      </button>
      <button
        v-if="highlightNodeSelected"
        class="ui-selectable__tools-item"
        style="color: orange;"
        @click.prevent="handleAction('remove')"
      >
        <VisibleText>Remove </VisibleText>`{{
          selectedText.length > 25
            ? selectedText.substring(0, 24) + "..."
            : selectedText
        }}`
      </button>
    </div>
    <slot />
  </div>
</template>

<script>
import HighlightMixin from "../Mixins/highlightMixin.js"

export default {
  name: "Selectable",
  mixins: [HighlightMixin],
  props: {
    highlightItemsFlat: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      x: 0,
      y: 0,
      showTools: false,
      highlightNodeSelected: false,
      selectedText: ""
    }
  },
  computed: {
    selectableEl() {
      return this.$slots.default[0].elm.childNodes[1]
    },
    selectableTools() {
      return this.$refs["selectable-tools"]
    },
    highlightElements() {
      return document.getElementsByClassName("main__highlight")
    },
    selectedThemeIndex() {
      return this.$store.getters["analysisText/getSelectedThemeIndex"]
    }
  },
  updated() {
    this.wrapMark()
  },
  mounted() {
    this.$refs["selectable-container"].addEventListener(
      "mouseup",
      () => setTimeout(this.onMouseUp.bind(this), 10),
      true
    )
    this.$refs["selectable-container"].addEventListener(
      "click",
      this.selectElement
    )
  },
  methods: {
    closeTools() {
      this.showTools = false
    },
    getSelectedTextInfo(selection) {
      const selectionRange = selection.getRangeAt(0)
      let startIndex = selectionRange.startOffset
      let prevNode = selectionRange.startContainer.previousSibling
      while (prevNode) {
        startIndex += prevNode.textContent.length
        prevNode = prevNode.previousSibling
      }

      return {
        startIndex:
          startIndex +
          (selection.toString().length -
            selection.toString().trimStart().length), // add trimmed white spaces
        length: selection.toString().trim().length,
        content: selection.toString().trim()
      }
    },

    selectElement(event) {
      if (!event.target.classList.contains("main__highlight")) {
        return
      }
      var sel, range
      var el = event.target
      if (window.getSelection && document.createRange) {
        //Browser compatibility
        sel = window.getSelection()
        if (sel.toString() == "") {
          //no text selection
          range = document.createRange() //range object
          range.selectNodeContents(el) //sets Range
          sel.removeAllRanges() //remove all ranges from selection
          sel.addRange(range) //add Range to a Selection.
        }
      } else if (document.selection) {
        //older ie
        sel = document.selection.createRange()
        if (sel.text == "") {
          //no text selection
          range = document.body.createTextRange() //Creates TextRange object
          range.moveToElementText(el) //sets Range
          range.select() //make selection.
        }
      }

      // handle show tools clipping
      if (this.x < this.selectableTools.offsetWidth / 2) {
        this.x = this.selectableTools.offsetWidth / 2 + 5
      }
    },
    onMouseUp() {
      const selection = window.getSelection()

      // iterrupt mosue up event if the element clicked on was a highlighted word
      if (selection.anchorNode.nodeName === "MARK") return

      let startNode, endNode
      try {
        startNode = selection.getRangeAt(0).startContainer.parentNode
        endNode = selection.getRangeAt(0).endContainer.parentNode
      } catch (e) {
        this.showTools = false
        return
      }

      // selected content is not within the component
      if (
        selection.toString().trim() == "" ||
        !this.selectableEl.contains(startNode) ||
        !this.selectableEl.contains(endNode)
      ) {
        this.showTools = false
        return
      }

      this.highlightNodeSelected = this.highlightItemsFlat.includes(
        selection
          .toString()
          .trim()
          .toLowerCase()
      )

      const parentPos = this.$el.getBoundingClientRect()
      const childPos = selection.getRangeAt(0).getBoundingClientRect()
      const top = childPos.top - parentPos.top
      const left = childPos.left - parentPos.left
      const width = childPos.width

      if (!width) {
        this.showTools = false
        return
      }
      this.x = left + width / 2
      this.y = top - 10
      this.showTools = true
      this.selectedText = selection.toString()

      // handle show tools clipping
      this.$nextTick(
        function() {
          if (this.x < this.selectableTools.offsetWidth / 2) {
            this.x = this.selectableTools.offsetWidth / 2 + 5
          }
        }.bind(this)
      )
    },
    handleAction(action) {
      this.$emit(action, this.selectedText)
      this.showTools = false
    }
  },
  watch: {
    selectedThemeIndex: {
      immediate: true,
      handler: function() {
        //the list was updated so trigger highlight wrappers
        this.$nextTick(() => {
          this.wrapMark()
        })
      }
    }
  }
}
</script>
