<template>
  <section id="project-analysis">
    <h2 class="sr-only">Analysis</h2>
    <ul
      class="project-analysis__tabs-list"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
    >
      <li
        v-for="item in tabs"
        :key="item"
        :class="[
          'project-analysis__tabs-list-item',
          {
            'project-analysis__tabs-list-item-active': activeTab === item
          }
        ]"
      >
        <button
          class="project-analysis__tabs-list-item-btn"
          @click="activeTab = item"
        >
          <span class="project-analysis__tabs-list-item-text">
            {{ item }}
          </span>
        </button>
      </li>
    </ul>
    <component
      :is="activeTab == 'Text' ? 'AnalysisText' : activeTab"
      :prop-read-only="propReadOnly"
      @updateActiveTab="updateActiveTab"
    ></component>
  </section>
</template>

<script>
// Components
import Benchmarking from "./ProjectAnalysisBenchmarking/ProjectAnalysisBenchmarking.vue"
import Drivers from "./ProjectAnalysisDrivers/ProjectAnalysisDrivers.vue"
import AnalysisText from "./ProjectAnalysisText/ProjectAnalysisText.vue"
import Performance from "./ProjectAnalysisPerformance/ProjectAnalysisPerformance.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"

export default {
  name: "ProjectAnalysis",
  mixins: [DatasetDetailsMixin],
  components: {
    Benchmarking,
    Drivers,
    AnalysisText,
    Performance
  },
  props: {
    propReadOnly: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      tabs: ["Drivers", "Performance", "Benchmarking", "Text"],
      activeTab: ""
    }
  },
  async created() {
    if (this.dataset) this.activeTab = "Drivers"

    // if user cannot create text analysis, remove from tabs
    if (!this.$pigeonline.permissions.hasPermission("create-analysis-text"))
      this.tabs.pop()
  },
  computed: {
    modalOpen() {
      return this.$store.getters["globalModule/getModalOpen"]
    }
  },
  methods: {
    updateActiveTab(tab) {
      this.activeTab = tab
    }
  },
  watch: {
    activeTab: function() {
      document.title = `${this.$store.getters["project/getProject"].name} - Analysis - ${this.activeTab} | ${this.$theme.theme.pageTitle}`
    }
  }
}
</script>
