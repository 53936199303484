var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{attrs:{"aria-label":"dataset","id":"dataset-progress"}},[_c('ol',{staticClass:"dataset-progress__list"},[_c('li',[_c('button',{staticClass:"dataset-progress__view-btn",attrs:{"disabled":!_vm.$store.getters['datasetDetails/getDataset']},on:{"click":_vm.backToDataset}},[_c('TableIcon',{staticClass:"table-icon"}),_c('span',{staticClass:"title"},[_c('VisibleText',[_vm._v("View Dataset")])],1),_c('span',{staticClass:"subtitle"},[_c('VisibleText',[_vm._v("Go to data table view.")])],1)],1)]),_vm._l((_vm.items),function(item){return _c('li',{key:item.name,class:[
        'dataset-progress__list-item',
        {
          'dataset-progress__list-item-active':
            _vm.activeSection === item.component
        }
      ]},[_c('button',{staticClass:"dataset-progress__list-item-btn",attrs:{"disabled":_vm.isDisabled(item)},on:{"click":function($event){return _vm.goToSection(item)}}},[_c('span',{staticClass:"dataset-progress__list-item-title"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{staticClass:"dataset-progress__list-item-step"},[_vm._v(" "+_vm._s(item.step)+" "),_c('HasPermission',{attrs:{"to":"benchmarking-group-select"}},[(item.optional)?[_vm._v(" ( "),_c('VisibleText',[_vm._v("optional")]),_vm._v(" ) ")]:_vm._e()],2)],1),_c('span',{class:[
            'dataset-progress__list-item-status',
            _vm.progressClassName(item)
          ]},[_vm._v(" "+_vm._s(_vm.sectionProgress(item))+" ")])])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }