<template>
  <div class="dataset-segments__custom">
    <DatasetSegmentsCustomInput :read-only="readOnly" @add="addTag">
      {{ label }}
      <template #desc v-if="description">
        {{ description }}
      </template>
    </DatasetSegmentsCustomInput>
    <DatasetSegmentsCustomList :tags="tags" @remove="removeTag" />
  </div>
</template>

<script>
import DatasetSegmentsCustomInput from "./DatasetSegmentsCustomInput.vue"
import DatasetSegmentsCustomList from "./DatasetSegmentsCustomList.vue"

export default {
  name: "DatasetSegmentsCustom",
  components: {
    DatasetSegmentsCustomInput,
    DatasetSegmentsCustomList
  },
  props: {
    description: {
      default: () => "Enter tags",
      type: String
    },
    label: {
      type: String,
      required: true
    },
    tags: {
      default: () => [],
      type: Array
    },
    readOnly: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    addTag(tag) {
      this.$emit("add-tag", tag)
    },
    removeTag(tag) {
      this.$emit("remove-tag", tag)
    }
  }
}
</script>
