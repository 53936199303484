<template functional>
  <span
    :class="['message__inline', data.class, data.staticClass]"
    :role="props.role"
  >
    <svg
      focusable="false"
      role="img"
      :class="[`message__inline-icon--${props.messageType}`, 'icon']"
      v-if="props.messageType.length > 0"
    >
      <title>{{ props.messageType }}</title>
      <use :xlink:href="`#${props.messageType}`" aria-hidden="true" />
    </svg>
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    messageType: {
      default: "",
      type: String
    },
    role: {
      default: "status",
      type: String
    }
  }
}
</script>
