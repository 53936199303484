<template>
  <div :class="['response-list-item', { 'is-pinned': isPinned }]">
    <span class="response-list-item__is-pinned-label" v-if="isPinned"
      >Pinned</span
    >
    <template v-if="!isHidden">
      <UISelectable
        class="response-list-item__selectable-text"
        :highlightItemsFlat="highlightItems"
        @excerpt="$emit('excerpt', $event)"
        @keyword="$emit('keyword', $event)"
        @note="$emit('note', $event)"
        @ban="$emit('ban', $event)"
        @remove="$emit('remove', $event)"
      >
        <p>
          <span class="response-list-item__selectable-text-title">
            Respondent {{ item.idx + 1 }}
          </span>
          <span
            v-html="highlightMethod(item.response, item.keywords)"
            class="highlighted-content"
          />
        </p>
      </UISelectable>
      <div
        class="response-list-item__options sr-show-on-focus"
        tabindex="0"
        :aria-label="`Respondent ${item.idx + 1} options`"
      >
        <button
          type="button"
          class="response-list-item__options-btn"
          @click="$emit('toggle-response-pin', item.id)"
        >
          <SvgIconDecorative icon="pin" />
          {{ isPinned ? "Unpin" : "Pin" }}
        </button>
        <button
          type="button"
          class="response-list-item__options-btn"
          @click="$emit('ban-response', item.id)"
        >
          <SvgIconDecorative icon="ban" />
          Ban
        </button>
        <button
          type="button"
          class="response-list-item__options-btn"
          @click="$emit('toggle-response-hide', item.id)"
        >
          <SvgIconDecorative icon="eye" />
          Hide
        </button>
      </div>
    </template>
    <div class="response-list-item__is-hidden" v-else>
      <button
        type="button"
        class="response-list-item__options-btn show-response-btn"
        @click="$emit('toggle-response-hide', item.id)"
      >
        <SvgIconDecorative icon="eye" />
        Show hidden response
      </button>
    </div>
  </div>
</template>

<script>
// Components
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import UISelectable from "../UI/Selectable.vue"

export default {
  name: "ResponseListItem",
  components: {
    SvgIconDecorative,
    UISelectable
  },
  props: {
    item: {
      type: Object
    },
    isPinned: {
      type: Boolean,
      default: () => false
    },
    isHidden: {
      type: Boolean,
      default: () => false
    },
    highlightItems: {
      type: Array,
      default: () => []
    },
    highlightMethod: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
