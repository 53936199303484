import DriversAnalysisChart from "../helpers/driversAnalysisChart.js"
import PriorityMatrixChart from "../helpers/priorityMatrixChart.js"

const getDriversDetailsText = data => {
  const str = `We use ${data.estimator_name.replace(/_/g, " ") ||
    "regression"} to compute this driver. `
  const str2 = `${data.dynamic_summary.drivers_summary} The following\
  chart shows each driver and their relative importance to each other.`
  return str.concat(str2)
}

const getMatrixDetailsText = data => {
  return `${data.dynamic_summary.priority_matrix_summary}`
}

const driversSection = (title, data, uuid) => {
  const driversTitle = {
    type: "heading",
    content: `${title} drivers`,
    id: "section-subtitle-drivers",
    meta: {
      level: "h2",
      driversUUID: uuid
    }
  }
  const driversDetailsText = getDriversDetailsText(data)
  const topDriversDetails = {
    type: "text",
    content: driversDetailsText,
    id: "drivers-top-details"
  }
  const driversChart = DriversAnalysisChart(title, data)
  const drivers = [driversTitle, topDriversDetails, driversChart]

  const matrixDetailsText = getMatrixDetailsText(data)
  const matrixDetails = {
    type: "text",
    content: matrixDetailsText,
    id: "drivers-matrix-details"
  }
  const matrixChart = PriorityMatrixChart(title, data)
  const matrix = [matrixDetails, matrixChart]

  return [...drivers, ...matrix]
}

export default driversSection
