<template>
  <div>
    <section id="project-analysis-text-main-sentiment">
      <div class="sentiment__header">
        <ul class="sentiment__header-labels main__search-labels">
          <transition name="fade">
            <li v-show="isSearchMode">
              <span
                class="main__highlight yellow"
                id="sentiment-search-keyword"
              >
                <VisibleText>Search keyword</VisibleText>
              </span>
            </li>
          </transition>
          <transition name="fade">
            <li>
              <span
                class="main__highlight positive-sentiment"
                id="positive-keyword"
              >
                <VisibleText>Positive keyword</VisibleText>
              </span>
            </li>
          </transition>
          <transition name="fade">
            <li>
              <span
                class="main__highlight negative-sentiment"
                id="negative-keyword"
              >
                <VisibleText>Negative keyword</VisibleText>
              </span>
            </li>
          </transition>
        </ul>
        <div
          class="select-wrapper sentiment__sort-wrapper"
          v-if="!search.isSearching"
        >
          <label for="sentiment-sort-by" class="sentiment__sort-label">
            <VisibleText>Sort by:</VisibleText>
          </label>
          <select
            id="sentiment-sort-by"
            class="select sentiment__sort-options"
            v-model.number="sortBy"
          >
            <option value="0">
              <VisibleText>Default</VisibleText>
            </option>
            <option value="1">
              <VisibleText>Positive sentiments</VisibleText>
            </option>
            <option value="-1">
              <VisibleText>Negative sentiments</VisibleText>
            </option>
          </select>
        </div>
      </div>

      <ul class="sentiment__response-list" ref="sentimentResponseList">
        <li
          class="sentiment__response-list-item"
          v-for="item in textResponsesSorted"
          :key="item.idx"
        >
          <ResponseListItem
            :item="item"
            :is-pinned="pinnedComments.includes(item.id)"
            :is-hidden="hiddenComments.includes(item.id)"
            :highlight-method="highlight"
            @excerpt="onExcerpt"
            @keyword="onKeyword"
            @note="onNote"
            @ban="onBan"
            @toggle-response-pin="toggleResponseItemPin"
            @ban-response="banResponseItem"
            @toggle-response-hide="toggleResponseItemHide"
          />
        </li>
      </ul>
    </section>

    <SelectedTextModal
      uid="selected-text-sentiment-modal"
      :type="selectedTextModalType"
      :text="selectedText"
      :show="isSelectedTextModalVisible"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
// Components
import ResponseListItem from "./Components/ResponseListItem.vue"

// Mixins
import AnalysisTextMixin from "@/utils/mixins/analysisTextMixin.js"
import SelectTextMixin from "./Mixins/selectTextMixin.js"

export default {
  name: "ProjectTextAnalysisMainSentiment",
  mixins: [AnalysisTextMixin, SelectTextMixin],
  components: {
    ResponseListItem
  },
  data() {
    return {}
  },
  computed: {
    sortBy: {
      get: function() {
        return this.sentimentsSortOrder
      },
      set: function(val) {
        this.setSentimentsSortOrder(val)
      }
    },
    textResponsesSorted: function() {
      const pinnedResponses = this.textResponsesFiltered.filter(el =>
        this.pinnedComments.includes(el.id)
      )
      const unpinnedResponses = this.textResponsesFiltered.filter(
        el => !this.pinnedComments.includes(el.id)
      )
      if (this.sortBy !== 0) {
        return [
          ...pinnedResponses,
          ...unpinnedResponses.sort(
            (a, b) =>
              this.sortBy *
              (this.findResponseScore(b) - this.findResponseScore(a))
          )
        ]
      }
      return [...pinnedResponses, ...unpinnedResponses]
    }
  },
  methods: {
    findResponseScore(response) {
      return response.scores.sentiment || 0
    },
    highlight(text, matchKws) {
      var result = text // text
      const pattern = new RegExp(
        "\\b(" +
          [
            ...matchKws.search.filter(item => item !== ""),
            ...matchKws.sentiment.neg.filter(item => item !== ""),
            ...matchKws.sentiment.pos.filter(item => item !== "")
          ].join("|") +
          ")(\\b)?",
        "gi"
      )
      result = text.replace(pattern, match => {
        if (matchKws.search.includes(match.toLowerCase())) {
          return (
            '<mark class="main__highlight yellow" data-highlight-type="search-keyword">' +
            match +
            "</mark>"
          )
        }
        if (matchKws.sentiment.neg.includes(match.toLowerCase())) {
          return (
            '<mark class="main__highlight negative-sentiment" data-highlight-type="negative-keyword">' +
            match +
            "</mark>"
          )
        }
        if (matchKws.sentiment.pos.includes(match.toLowerCase())) {
          return (
            '<mark class="main__highlight positive-sentiment" data-highlight-type="positive-keyword">' +
            match +
            "</mark>"
          )
        }
        return match
      })
      return result
    }
  },
  watch: {
    sentimentsSortOrder: function() {
      this.$refs.sentimentResponseList.scrollTop = 0
    },
    search: function(val) {
      const searchString = val.searchString
      // reset sort
      if (searchString && searchString.trim() != "") this.sortBy = 0
    }
  }
}
</script>
