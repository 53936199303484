<template>
  <Accordion title-element-type="h3" :icon="accordionIcon">
    <!--------------------------------------------
    | 
    | Question heading
    |
    --------------------------------------------->
    <template #heading>
      {{ question.question_title }}
    </template>

    <!--------------------------------------------
    | 
    | Question details
    |
    --------------------------------------------->
    <template #additionalDetails>
      <!-- Question text -->
      <span class="editor-question__text">
        {{ question.question_text }}
      </span>

      <!-- Details -->
      <span class="editor-question__type">
        <SvgIconDecorative icon="analysis" class="editor-question__type-icon" />
        <span
          class="editor-question__type-text"
          v-show="question.data_type && !editDataType"
          @click.stop="editDataType = true"
        >
          {{ DATA_TYPE_TO_READABlE[question.data_type] }}
        </span>
        <SelectDataType
          class="editor-question__select"
          :id="question._id.$oid"
          :init-data-type="question.data_type"
          :sr-only-label="true"
          @set-data-type="setDataType"
          v-show="!question.data_type || editDataType"
          v-click-outside="closeEditing"
        >
          <VisibleText>select data type</VisibleText>
        </SelectDataType>
      </span>
      <span class="editor-question__approved">
        <SvgIconDecorative
          icon="approved"
          class="editor-question__approved-icon"
        />
        <strong>{{ question.matches.approved }}</strong>
        <VisibleText> approved</VisibleText>
      </span>
      <span class="editor-question__pending">
        <SvgIconDecorative
          icon="pending"
          class="editor-question__pending-icon"
        />
        <strong>{{ question.matches.pending }}</strong>
        <VisibleText> pending</VisibleText>
      </span>
      <ButtonIconOnly
        class="editor-question__remove"
        icon="remove"
        :id="`${question._id.$oid}-del`"
        @click-handler="deleteQuestion"
      >
        <VisibleText>delete </VisibleText>
        {{ question.question_title }}
      </ButtonIconOnly>
    </template>

    <!-- Matches -->
    <h4 style="font-size: 1.05rem; margin-bottom: 2rem">
      {{ `${question.question_title} - Benchmark Matches` }}
    </h4>
    <BenchmarkManagerMatches
      :global-question-id="question._id.$oid"
      @updateGlobalQuestionMatches="updateGlobalQuestionMatches"
    />
  </Accordion>
</template>

<script>
// Components
import Accordion from "@/components/UI/Accordion.vue"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import LoadingSpinner from "@/components/UI/Spinner.vue"
import SelectDataType from "@/components/Dataset/DatasetCleaner/DatasetCleanerSelectType.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

const BenchmarkManagerMatches = () => ({
  component: import("./BenchmarkManagerMatches.vue"),
  loading: LoadingSpinner
})

// Services
import DatasetsService from "@/services/datasetsService.js"

// Consts
import { DATA_TYPE_TO_READABlE } from "@/utils/consts/constsDataTypes.js"

export default {
  name: "BenchmarkManagerEditorQuestion",
  components: {
    Accordion,
    ButtonIconOnly,
    BenchmarkManagerMatches,
    SelectDataType,
    SvgIconDecorative
  },
  props: {
    benchmarkGroup: {
      default: () => {},
      type: Object,
      required: true
    },
    index: {
      default: null,
      type: Number,
      required: true
    },
    question: {
      default: () => {},
      type: Object,
      required: true
    }
  },
  data() {
    return {
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      DATA_TYPE_TO_READABlE: DATA_TYPE_TO_READABlE,
      accordionIcon: {
        expanded: "chevronUp",
        collapsed: "chevronDown"
      },
      editDataType: false
    }
  },
  methods: {
    closeEditing() {
      this.editDataType = false
    },
    deleteQuestion() {
      if (confirm("Are you sure you want to delete this question?")) {
        this.benchmarkGroup.questions = Math.max(
          0,
          this.benchmarkGroup.questions - 1
        )
        this.benchmarkGroup.approved_matches = Math.max(
          0,
          this.benchmarkGroup.approved_matches - this.question.matches.approved
        )
        this.$pigeonline.projects.update(this.benchmarkGroup)

        this.DATASETS_SERVICE.deleteGlobalQuestion(this.question._id.$oid)
        this.$emit("removeGlobalQuestion", this.index)
      }
    },
    updateGlobalQuestionMatches(type) {
      if (type === "approved") {
        this.question.matches.approved += 1
        this.question.matches.pending = Math.max(
          0,
          this.question.matches.pending - 1
        )
        this.benchmarkGroup.approved_matches += 1
      }

      if (type === "rejected") {
        this.question.matches.approved = Math.max(
          0,
          this.question.matches.approved - 1
        )
        this.question.matches.pending = Math.max(
          0,
          this.question.matches.pending - 1
        )
        this.benchmarkGroup.approved_matches = Math.max(
          0,
          this.benchmarkGroup.approved_matches - 1
        )
      }

      this.DATASETS_SERVICE.updateGlobalQuestion(this.question._id.$oid, {
        matches: this.question.matches
      })
      this.$pigeonline.projects.update(this.benchmarkGroup)
    },
    setDataType(dataType) {
      if (this.question.data_type === dataType) return
      this.editDataType = false
      this.$set(this.question, "data_type", dataType)
      this.DATASETS_SERVICE.updateGlobalQuestion(this.question._id.$oid, {
        data_type: dataType
      })
    }
  }
}
</script>
