<template>
  <nav aria-label="project" class="project-tabs">
    <ul class="project-tabs__list">
      <li
        v-for="item in items"
        :key="item"
        :class="[
          'project-tabs__list-item',
          {
            'project-tabs__list-item-active': $route.name.includes(item)
          }
        ]"
      >
        <router-link :to="getPath(item)" class="project-tabs__list-item-link">
          <span class="project-tabs__list-item-text"> {{ item }} </span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
// Models
import BenchmarkingProject from "@/models/BenchmarkingProjectModel"

export default {
  name: "ProjectTabs",
  props: {
    project: { type: BenchmarkingProject }
  },
  data() {
    return {
      items: ["data", "analysis", "report"]
    }
  },
  methods: {
    getPath(item) {
      let pathEnd = ""
      if (item === "analysis") {
        pathEnd = "analysis"
      } else if (item === "report") {
        // direct to empty state or report
        pathEnd = `report/${
          this.project.report ? this.project.report : "empty"
        }`
      } else {
        pathEnd = ""
      }

      return `/project/${this.project.id}/${pathEnd}`
    }
  }
}
</script>
