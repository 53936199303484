<template>
  <div>
    <section
      id="projects"
      :class="[{ hide_list: selectedComponent == null }]"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
    >
      <!--------------------------------------------
      |
      | SEARCH BAR
      |
      --------------------------------------------->
      <div class="projects__search-bar center-align-parent">
        <SearchBar
          class="center-align-child"
          placeholder="Search any project"
          @search="search"
        />
      </div>

      <!--------------------------------------------
      |
      | HEADER
      |
      --------------------------------------------->
      <div class="section__top-bar">
        <h1 class="projects__title">
          <VisibleText>Projects </VisibleText>( {{ totalProjects }} )
        </h1>
        <div class="projects__view-select-wrapper">
          <form @submit.prevent="saveUIPreferences">
            <select
              v-model="vModels.uiPreferences.projects.projectsViewMode"
              style="display:none;"
            >
              <option value="ProjectsList">
                <VisibleText>List</VisibleText>
              </option>
              <option value="ProjectsGrid">
                <VisibleText>Grid</VisibleText>
              </option>
            </select>
            <ButtonIconOnly
              icon="grid"
              id="grid-view"
              :class="[
                {
                  'projects__view-select-active':
                    selectedComponent == 'ProjectsGrid'
                },
                'projects__view-select-btn'
              ]"
              @click-handler="
                selectedComponent = 'ProjectsGrid'
                vModels.uiPreferences.projects.projectsViewMode = 'ProjectsGrid'
              "
            >
              <VisibleText>grid view</VisibleText>
            </ButtonIconOnly>
            <ButtonIconOnly
              icon="list"
              id="list-view"
              :class="[
                {
                  'projects__view-select-active':
                    selectedComponent == 'ProjectsList'
                },
                'projects__view-select-btn'
              ]"
              @click-handler="
                selectedComponent = 'ProjectsList'
                vModels.uiPreferences.projects.projectsViewMode = 'ProjectsList'
              "
            >
              <VisibleText>list view</VisibleText>
            </ButtonIconOnly>
          </form>
        </div>
      </div>

      <div :class="createItemsWrapperClass">
        <div id="create-content" class="create-content">
          <button
            class="create-btn"
            type="button"
            @click="isProjectModalVisible = true"
          >
            <span class="center-align-parent" id="create-icon-wrapper">
              <SvgIconDecorative
                icon="add"
                class="center-align-child"
                id="create-icon"
              />
            </span>
            <span class="create-title">Create new project</span>
            <span class="create-text">
              Analyze data and create reports in just a few clicks
            </span>
          </button>
        </div>
      </div>

      <!--------------------------------------------
      |
      | PROJECTS LSIT
      |
      --------------------------------------------->
      <PastProjects
        :class="itemsWrapperClass"
        :models="[BenchmarkingProject]"
        :search="query"
        :sort="sort"
        @pastProjectsRetreived="pastProjectsRetreived"
        @pastProjectsFiltered="projectsFiltered"
      >
        <template slot-scope="{ project }">
          <component :is="selectedComponent" :project="project"></component>
        </template>
      </PastProjects>
    </section>

    <!--------------------------------------------
    |
    | CREATE PROJECT MODAL
    |
    --------------------------------------------->
    <ProjectsCreateModal
      :show="isProjectModalVisible"
      @close="isProjectModalVisible = false"
    />
  </div>
</template>

<script>
// Components
import { PastProjects, CreateProject } from "@pigeonline/core"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import SearchBar from "@/components/UI/SearchBar.vue"
import ProjectsList from "@/components/Projects/ProjectsList.vue"
import ProjectsGrid from "@/components/Projects/ProjectsGrid.vue"
import ProjectsCreateModal from "@/components/Projects/ProjectsCreateModal/ProjectsCreateModal.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Utils
import UserMixin from "@/utils/mixins/userMixin"

// Models
import BenchmarkingProject from "@/models/BenchmarkingProjectModel"

export default {
  name: "Projects",
  mixins: [UserMixin],
  components: {
    ButtonIconOnly,
    CreateProject,
    PastProjects,
    ProjectsList,
    ProjectsGrid,
    ProjectsCreateModal,
    SearchBar,
    SvgIconDecorative
  },
  data() {
    return {
      BenchmarkingProject: BenchmarkingProject,
      selectedComponent: null,
      totalProjects: 0,
      firstLoad: true,
      isProjectModalVisible: false,
      query: {
        fields: ["name"],
        term: ""
      },
      sort: {
        last_modified_on: "desc"
      },
      vModels: {
        uiPreferences: {
          projects: {
            projectsViewMode: ""
          }
        }
      }
    }
  },
  created() {
    this.setLoading(true)
    document.title = `Projects | ${this.$theme.theme.pageTitle}`

    // setup delete modal
    this.$store.dispatch("confirm/setConfirmText", {
      btn: "delete project",
      message: "This will also delete your report.",
      title: "delete project?"
    })
    this.$store.dispatch("confirm/setConfirmType", "delete")
    this.$store.dispatch("confirm/setConfirmSourceComponent", "projects")
  },
  mounted() {
    this.$watch(
      "profile",
      function(newVal) {
        if (!newVal) return
        this.setValue("projectsViewMode", newVal.projectsViewMode)
        this.checkUIPreferences()
      },
      { deep: true, immediate: true }
    )
  },
  computed: {
    modalOpen() {
      return this.$store.getters["globalModule/getModalOpen"]
    },
    createItemsWrapperClass() {
      return [
        { "projects-grid-wrapper": this.selectedComponent === "ProjectsGrid" },
        { "projects-list-wrapper": this.selectedComponent === "ProjectsList" },
        "create-wrapper"
      ]
    },
    itemsWrapperClass() {
      return [
        { "projects-grid-wrapper": this.selectedComponent === "ProjectsGrid" },
        { "projects-list-wrapper": this.selectedComponent === "ProjectsList" },
        "projects__items-wrapper"
      ]
    }
  },
  methods: {
    checkUIPreferences() {
      if (this.firstLoad) {
        this.selectedComponent = this.vModels.uiPreferences.projects.projectsViewMode
        this.firstLoad = false
        if (!this.selectedComponent) {
          this.selectedComponent = "ProjectsGrid"
        }
      }
    },
    pastProjectsRetreived(projects) {
      this.totalProjects = projects.length
      this.$store.dispatch("loader/setLoading", false)
    },
    projectsFiltered(projects) {
      this.totalProjects = projects.length
    },
    projectCreated(project) {
      this.$router.push({
        path: `/project/${project.id}`
      })
    },
    projectCreationError() {},
    search(query) {
      this.query.term = query
    },
    async saveUIPreferences() {
      this.setLoading(true)
      try {
        const profile = await this.$pigeonline.profile.save({
          projectsViewMode: this.vModels.uiPreferences.projects.projectsViewMode
        })
        this.setProfile(profile)
      } catch (e) {
        alert(
          "An error occured when processing your request. Please try again later"
        )
        throw new Error("Projects.vue:saveUIPreferences " + e.message)
      }
      this.setLoading(false)
    },
    setValue(property, val) {
      if (val) {
        this.vModels.uiPreferences.projects[property] = val
      }
    }
  }
}
</script>
