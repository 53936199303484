export default function() {
  const driversAnalysisIconSrc = "/files/ReportIcons/driversAnalysis.svg"
  let driversAnalysisHeading = `<img src="${driversAnalysisIconSrc}" class="report__header-icon" alt="" />\
PRIORITIES FOR SERVICE IMPROVEMENT`

  const heading = {
    type: "heading",
    content: driversAnalysisHeading,
    id: "section-title-drivers",
    meta: {
      level: "h1",
      pdfConfig: {
        pageBreak: "before"
      }
    }
  }

  return heading
}
