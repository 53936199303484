<template>
  <div>
    <p>
      Please answer a few questions about your data. This can help facilitate
      better comparisons, target generated insights, or make it easier to search
      and discover your data later.
    </p>
    <form
      class="form dataset-segments__form dataset__section-group"
      @submit.prevent
      novalidate
    >
      <div class="dataset__section-group-content light-scroll-bar">
        <DatasetSegmentsCustom
          class="dataset-segments__form-group"
          label="Tag your data"
          description="Tags describe your data with simple keywords."
          id="uncategorized"
          :read-only="readOnly"
          :tags="uncategorizedTags"
          @add-tag="addTag('uncategorized', $event)"
          @remove-tag="removeTag('uncategorized', $event)"
        />
        <p class="p">or answer the following:</p>
        <DatasetSegmentsQuestionnaire
          :segments="datasetSegments"
          :read-only="readOnly"
        />
      </div>
      <button
        type="submit"
        class="dataset__section-save-btn"
        :disabled="!$pigeonline.permissions.hasPermission('update-dataset')"
        @click="$emit('save')"
      >
        save
      </button>
      <MessageInline :message-type="messageType">
        {{ stateText }}
      </MessageInline>
    </form>
  </div>
</template>

<script>
// Components
import DatasetSegmentsQuestionnaire from "../DatasetSegmentsQuestionnaire.vue"
import DatasetSegmentsCustom from "../DatasetSegmentsCustom/DatasetSegmentsCustom.vue"
import MessageInline from "@/components/UI/Message/MessageInline.vue"

// Mixin
import DatasetSegmentsMixin from "../Mixins/datasetSegmentsMixin.js"

export default {
  name: "DatasetSegmentsForm",
  mixins: [DatasetSegmentsMixin],
  components: {
    DatasetSegmentsCustom,
    DatasetSegmentsQuestionnaire,
    MessageInline
  },
  props: {
    state: {
      type: Object
    }
  },
  computed: {
    messageType() {
      if (this.state.isError) return "error"
      else if (this.state.isSaved) return "success"
      return ""
    },
    stateText() {
      if (this.state.isError)
        return "Sorry there were errors in the form. Please try again."
      else if (this.state.isSaved) return "Dataset details saved."
      return ""
    },
    uncategorizedTags() {
      return this.datasetProject.customSegments &&
        this.datasetProject.customSegments.uncategorized
        ? this.datasetProject.customSegments.uncategorized
        : []
    }
  }
}
</script>
