<template>
  <div id="dataset-progress-wrapper">
    <DatasetProgressList
      :aria-hidden="modalOpen"
      :inert="modalOpen"
      :items="list"
    />
    <button
      class="dataset-progress__connect-btn"
      @click="isProjectsCreateModalVisible = true"
      :disabled="!isDatasetCleaningComplete"
    >
      <VisibleText>connect dataset to project</VisibleText>
    </button>
    <ProjectsCreateModal
      :cleaning-complete="isDatasetCleaningComplete"
      :pre-selected="datasetProject.dataset_id"
      :show="isProjectsCreateModalVisible"
      @close="isProjectsCreateModalVisible = false"
    />
  </div>
</template>

<script>
// Components
import DatasetProgressList from "./DatasetProgressList"
import ProjectsCreateModal from "@/components/Projects/ProjectsCreateModal/ProjectsCreateModal.vue"

// Mixins
import DatasetMixin from "@/utils/mixins/datasetMixin.js"

export default {
  name: "DatasetProgress",
  mixins: [DatasetMixin],
  components: {
    DatasetProgressList,
    ProjectsCreateModal
  },
  data() {
    return {
      list: [
        {
          name: "Upload your file",
          step: "step 1",
          component: "DatasetUploader",
          statusName: "datasetUploadingCompleted",
          completed: false
        },
        {
          name: "Clean your data",
          step: "step 2",
          component: "DatasetCleaner",
          statusName: "datasetColumnDetailsCompleted",
          completed: false
        },
        {
          name: "Match global questions",
          step: "step 3",
          component: "DatasetBenchmarks",
          statusName: "benchmarksApproved",
          completed: false,
          skipped: false,
          optional: true
        },
        {
          name: "Define your data",
          step: "step 4",
          component: "DatasetSegments",
          statusName: "datasetSegmentsCompleted",
          completed: false
        }
      ],
      isProjectsCreateModalVisible: false
    }
  },
  computed: {
    isDatasetCleaningComplete() {
      return (
        Object.keys(this.datasetProject).length > 0 &&
        this.datasetProject.currStatusText() === "complete"
      )
    },
    modalOpen() {
      return this.$store.getters["globalModule/getModalOpen"]
    }
  },
  watch: {
    datasetProject: {
      handler(val) {
        if (val) {
          this.list.forEach((item, index) => {
            if (this.datasetProject.status[item.statusName])
              this.$set(this.list[index], "completed", true)

            // set values for step 3
            if (item.statusName === "benchmarksApproved") {
              this.datasetProject.status.benchmarksSkipped
                ? this.$set(this.list[index], "skipped", true)
                : this.$set(this.list[index], "skipped", false)
            }
          })
        }
      },
      deep: true
    }
  }
}
</script>
