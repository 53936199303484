<template>
  <Modal
    id="share-projects"
    ref="share-modal"
    uid="share-dataset-modal"
    :show="show"
    :reset-listener="resetListener"
    @close="closeModal"
  >
    <Spinner id="share-spinner" :component-style="true" :isLoading="isSaving" />

    <template #header>
      {{ currViewIdx == 0 ? "Share Dataset" : "Manage Permissions" }}
    </template>

    <ModalGroupLayout
      :showBackBtn="false"
      @back="back"
      @changed="unsavedChanges = true"
    >
      <Sharing
        :label="'Add people to start sharing your dataset.'"
        :shared="users"
        ref="sharing"
        @addNewUser="addNewUser($event)"
      />
    </ModalGroupLayout>

    <ModalGroupLayout @back="back" @changed="unsavedChanges = true">
      <ManagePermissions
        :shared="usersShared"
        :types="SHARING_KEYS.filter(key => IS_OWNER[key])"
        :perm-labels="DEFAULT_PERMS_LABELS"
      />
    </ModalGroupLayout>

    <template #footer>
      <div class="share-copy-url">
        <a
          role="button"
          ref="copy-url"
          class="share__copy-url-btn"
          @click="copy"
        >
          <VisibleText>Copy link</VisibleText>
        </a>
        <input
          aria-label="share url"
          type="text"
          id="share-url"
          class="share__copy-url"
          readonly
          :value="shareUrl"
        />
      </div>
      <button
        class="share__next-btn"
        @click="goToView(1)"
        v-if="modalGroups && currViewIdx == 0"
      >
        <VisibleText>Next: Manage Permissions</VisibleText>
      </button>
      <button
        class="share__save-changes-btn"
        :aria-disabled="!unsavedChanges"
        :disabled="!unsavedChanges"
        @click="saveChanges"
        v-if="modalGroups && currViewIdx == modalGroups.length - 1"
      >
        <VisibleText>Save changes</VisibleText>
      </button>
    </template>
  </Modal>
</template>

<script>
// Components
import Modal from "@/components/UI/Modal.vue"
import ModalGroupLayout from "./Base/ModalGroupLayout.vue"
import Sharing from "./Base/Sharing.vue"
import ManagePermissions from "./Base/ManagePermissions.vue"
import Spinner from "@/components/UI/Spinner.vue"

// Mixins
import ShareMixin from "./Mixins/shareMixin.js"

export default {
  name: "ShareDataset",
  mixins: [ShareMixin],
  components: {
    Modal,
    ModalGroupLayout,
    Sharing,
    ManagePermissions,
    Spinner
  },
  props: {
    dataset: {
      default: () => null,
      type: Object
    },
    isSaving: {
      default: () => false,
      type: Boolean
    },
    show: {
      default: () => false,
      type: Boolean
    }
  },
  computed: {
    DEFAULT_PERMS() {
      return {
        dataset: ["read"]
      }
    },
    DEFAULT_PERMS_LABELS() {
      return {
        dataset: [
          { label: "Read", value: "read", disabled: true },
          { label: "Edit", value: "edit" }
        ]
      }
    },
    SHARING_PK() {
      return "dataset"
    },
    shareUrl() {
      if (!this.dataset) return
      const domain = `${window.location.protocol}//${window.location.hostname}`
      const port = window.location.port ? `:${window.location.port}` : ""
      const path = `/dataset/${this.dataset["dataset_id"]}?project_id=${this.dataset.id}`
      return `${domain}${port}${path}`
    }
  },
  watch: {
    dataset: function() {
      this.mount()
    }
  }
}
</script>
