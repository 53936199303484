const replaceSpaceWithDash = words => {
  return words.replace(/\s/g, "-")
}

/**
 * Format values of the summary tables
 * @param {Number} value the value of a column in the summary table
 * @returns {Number} value formatted to 2 decimal places or
 *  "N/A if value is NaN
 */
const formatValue = value => {
  if (isNaN(value) || !value) return "N/A"
  else return value.toFixed(2)
}

const getTableData = uniqueVals => {
  const total = Object.values(uniqueVals).reduce((total, c) => total + c)
  const sortedData = Object.entries(uniqueVals)
    .sort(([v1], [v2]) => +v2 - +v1)
    .reduce((sorted, [response, value]) => {
      const percent = formatValue((100 * value) / total)
      const row = []
      row.push(value)
      row.push(`${percent} %`)
      sorted.set(response, row)
      return sorted
    }, new Map())
  sortedData.set("Total", [total])
  const tableData = {
    rowNames: [...sortedData.keys()],
    data: [...sortedData.values()]
  }
  return tableData
}

const getBarCharData = details => {
  const sortedData = Object.keys(details).sort((a, b) => b - a)
  return sortedData.reduce((data, response) => {
    let obj = {}
    obj["label"] = response // response (1 | 2 | 3 etc.)
    obj["value"] = details[response] // count
    data.push(obj)
    return data
  }, [])
}

/**
 * Get benchmark bar chart data by question
 * @param {Object} data detailed_results object
 * @param {String} question question id we want to filter for
 * @returns {Array} benchmarking bar chart data for question
 */
const getBenchmarkData = (data, question) => {
  return data.find(d => d.client_question_id === question)
}

/**
 * Format benchmark group table data
 * @param {Array} unformatted benchmark group objects
 * @param {String} group name of benchmark group, null if its for "all group" section
 * @param {Boolean} withid include "id" column in table row
 * @returns {Array} of formatted benchmark group table data
 */
const formatBenchmarkGroupData = (unformatted, group = null, withid = null) => {
  return unformatted.map(dataset => {
    let row = []
    // all groups section
    if (!group) {
      row = [
        formatValue(dataset.mean),
        dataset.rank,
        formatValue(dataset.percentile),
        dataset.all_segments.join(", ")
      ]
    } else {
      row = [
        formatValue(dataset.segments_mean[group].mean),
        dataset.segments_mean[group].rank,
        formatValue(dataset.segments_mean[group].percentile),
        dataset.all_segments.join(", ")
      ]
    }
    if (withid) row.unshift(dataset.org_id)
    return row
  })
}

/**
 * Generate the "top and lowest performers" summary table for a benchmark group
 * @param {Array} benchmarkGroupData objects
 * @param {String} group name of benchmark group, null if for "all group" section
 * @returns {Object} Pigeondoc formatted table object
 */
const getPerformersTable = (benchmarkGroupData, group = null) => {
  let performersData = [],
    lowest = null,
    org = null,
    rowNames = [],
    tableData = []

  if (benchmarkGroupData.length <= 3) {
    performersData = formatBenchmarkGroupData(benchmarkGroupData, group, true)
    tableData.push(performersData)
    rowNames = ["Top Performers"]
  }

  if (benchmarkGroupData.length > 3) {
    let indx = benchmarkGroupData
      .slice(0, 3)
      .findIndex(data => data.org_id === "Your org")

    // org is in top 3
    if (indx > -1) {
      performersData = formatBenchmarkGroupData(
        benchmarkGroupData.slice(0, 3),
        group,
        true
      )
      tableData.push(performersData)
      rowNames = ["Top Performers", "Lowest Performer"]
    }
    // org is lowest
    else if (indx === benchmarkGroupData.length) {
      performersData = formatBenchmarkGroupData(
        benchmarkGroupData.slice(0, 3),
        group,
        true
      )
      tableData.push(performersData)
      rowNames = ["Top Performers", "Your Org"]
    }
    // org is somewhere in the middle
    else {
      performersData = formatBenchmarkGroupData(
        benchmarkGroupData.slice(0, 3),
        group,
        true
      )
      org = formatBenchmarkGroupData(
        [benchmarkGroupData.find(data => data.org_id === "Your org")],
        group,
        true
      )
      tableData.push(performersData)
      tableData.push(org[0])
      rowNames = ["Top Performers", "Your Org", "Lowest Performer"]
    }

    lowest = formatBenchmarkGroupData(
      [benchmarkGroupData[benchmarkGroupData.length - 1]],
      group,
      true
    )
    tableData.push(lowest[0])
  }

  let performersTable = {
    type: "table",
    content: {
      data: {
        columnNames: [
          "Category",
          "ID",
          "Average",
          "Rank",
          "Percentile",
          "Segments"
        ],
        rowNames: rowNames,
        data: tableData
      }
    },
    id: `b-group-perf-table-${group ? replaceSpaceWithDash(group) : "all"}`,
    meta: {
      caption: "Top and Lowest Performers",
      state: "visible"
    }
  }

  return performersTable
}

/**
 * Construct vertical bar chart nodes for all organization comparisons for a
 * specific quesiton.
 * @param {Array} averageValues average_values array for a particular question
 * @param {String} questionHeading question title and wording used as a heading
 * @param {String} questionTitle question title
 * @param {Array} allAvg mean_and_count_by_segment[All]
 * @returns {Array} pigeondoc nodes: bar chart and two tables
 */
const getAllGroupsChart = (
  averageValues,
  questionHeading,
  questionTitle,
  allAvg
) => {
  //sort by mean
  averageValues.sort((a, b) => (a.mean < b.mean ? 1 : -1))

  // all groups chart
  let chart = {
    type: "verticalBarChart",
    content: {
      title: `<span class='q-group-b-chart-title'><strong class='q-group-b-chart-title-strong'>All Groups</strong> ${questionHeading}</span>`,
      data: []
    },
    meta: {
      headingLevel: "h3",
      primaryBarColour: "#c6cdd2",
      highlightBarColour: "#6A88AA",
      meanLineColour: "#9F200A",
      pdfConfig: {
        pageBreak: "before"
      }
    },
    id: `b-group-chart-all-${replaceSpaceWithDash(questionTitle)}`
  }

  // all groups table
  let table = {
    type: "table",
    content: {
      data: {
        columnNames: ["ID", "Average", "Rank", "Percentile", "Segments"],
        rowNames: averageValues.map(group => group.org_id),
        data: []
      }
    },
    id: `b-group-table-all-${replaceSpaceWithDash(questionTitle)}`,
    meta: {
      caption: "All Performers",
      state: "hidden",
      pdfConfig: {
        ignore: true
      }
    }
  }

  averageValues.forEach(dataset => {
    // chart data
    let chartObj = {}
    chartObj["value"] = formatValue(dataset.mean)
    chartObj["label"] = dataset.org_id
    if (dataset.is_user) chartObj["highlight"] = true
    chart.content.data.push(chartObj)

    // table data
    table.content.data.data.push([
      formatValue(dataset.mean),
      dataset.rank,
      formatValue(dataset.percentile),
      dataset.all_segments.join(", ")
    ])
  })

  // all groups performers table (summary table)
  let performersTable = getPerformersTable(averageValues)

  // all groups average
  let groupAvgDetails = {
    type: "text",
    content: `<span><strong>Overall average:</strong> ${formatValue(
      allAvg[0]
    )}</span><br/>
    <span><strong>Total # of benchmarks</strong>: ${allAvg[1]}</span>`,
    id: `b-group-avg-details-${replaceSpaceWithDash(questionTitle)}`
  }

  return [chart, groupAvgDetails, performersTable, table]
}

/**
 * Construct vertical bar chart nodes for benchmarking group comparisons
 * (section 3.3). A question is compared against each group.
 * @param {Array} data average_values array for a particular question, each
 * item in the array contains details of a dataset comparator [details of an organization to compare to]
 * @param {Object} means overall means for each benchmark group
 * @param {String} question question wording and title
 * @param {Object} segments client (the dataset being used to generate the report) segments
 * @returns {Array} array of vertical bar chart pigeondoc nodes
 * (one bar chart for each segment group)
 */
const getBenchmarksBySegment = (data, means, question, segments) => {
  let nodes = []

  Object.keys(segments).forEach(group => {
    // check if dataset/organization has data for this segment (group)
    let benchmarkGroupData = data.filter(avgValuesObj =>
      Object.keys(avgValuesObj.segments_mean).includes(group)
    )

    // if only the current users data is available, add a no comparables msg
    if (benchmarkGroupData.length < 2) {
      nodes.push({
        type: "heading",
        content: `<span class='q-group-b-chart-title'><strong class='q-group-b-chart-title-strong'>${group}</strong> ${question}</span>`,
        id: `b-group-no-comparables-h-${replaceSpaceWithDash(group)}`,
        meta: {
          level: "h3",
          pdfConfig: {
            pageBreak: "before"
          }
        }
      })
      nodes.push({
        type: "text",
        content: "There are no other organizations to compare with.",
        id: `b-group-no-comparables-p-${replaceSpaceWithDash(group)}`
      })
      return
    }

    benchmarkGroupData.sort((a, b) =>
      a.segments_mean[group].mean < b.segments_mean[group].mean ? 1 : -1
    )

    // reduce to pigeondoc bar chart node
    let benchmarkGroupDataObj = benchmarkGroupData.reduce((d, i) => {
      let obj = {}
      obj["value"] = formatValue(i.segments_mean[group].mean)
      obj["label"] = i.org_id
      if (i.is_user) obj["highlight"] = true
      d.push(obj)
      return d
    }, [])

    let chart = {
      type: "verticalBarChart",
      content: {
        title: `<span class='q-group-b-chart-title'><strong class='q-group-b-chart-title-strong'>${group}</strong> ${question}</span>`,
        data: benchmarkGroupDataObj
      },
      meta: {
        headingLevel: "h3",
        primaryBarColour: "#c6cdd2",
        highlightBarColour: "#6A88AA",
        meanLineColour: "#9F200A",
        pdfConfig: {
          pageBreak: "before"
        }
      },
      id: `b-group-chart-${replaceSpaceWithDash(group)}`
    }
    nodes.push(chart)

    if (means[group]) {
      let groupAvgDetails = {
        type: "text",
        content: `<span><strong>Overall average:</strong> ${formatValue(
          means[group][0]
        )}</span><br/>
        <span><strong>Total # of benchmarks</strong>: ${
          means[group][1]
        }</span>`,
        id: `b-group-avg-details-${replaceSpaceWithDash(group)}`
      }
      nodes.push(groupAvgDetails)
    }

    // setup performers table (summary table)
    let performersTable = getPerformersTable(benchmarkGroupData, group)
    nodes.push(performersTable)

    let groupTable = {
      type: "table",
      content: {
        data: {
          columnNames: ["ID", "Average", "Rank", "Percentile", "Segments"],
          rowNames: benchmarkGroupData.map(group => group.org_id),
          data: formatBenchmarkGroupData(benchmarkGroupData, group)
        }
      },
      id: `b-group-table-${replaceSpaceWithDash(group)}`,
      meta: {
        caption: "All Performers",
        state: "hidden",
        pdfConfig: {
          ignore: true
        }
      }
    }
    nodes.push(groupTable)
  })
  return nodes
}

/*******************************
 *
 * Generate nodes required for question by question section (section 3)
 * @param {Object} userQuestionDetails client_questions_analysis
 * @param {Object} fullDetails detailed_results response
 *
 ******************************/
const QuestionByQuesiton = (
  userQuestionDetails,
  matchesDetails,
  segments,
  fullDetails
) => {
  let data = []

  userQuestionDetails.forEach((question, indx) => {
    let globalMatch = matchesDetails[question.client_question_id] || "q"
    let questionHeading = `${globalMatch.global_question_title}: ${globalMatch.global_question_text} (${question.question_title})`
    let questionWithDashes = replaceSpaceWithDash(
      globalMatch.global_question_title
    )
    let header = {
      type: "heading",
      content: questionHeading,
      id: `q-group-heading-${questionWithDashes}`,
      meta: {
        level: "h2",
        pdfConfig: {
          pageBreak: "before",
          style: "q-group-heading"
        }
      }
    }

    // remove page break for first question
    if (indx === 0) delete header.meta.pdfConfig.pageBreak

    // possible responses of each question, used as labels (1, 2, 3, 4, 5 etc)
    const uniqueVals = question["unique_values_count"]
    if (Object.keys(uniqueVals).length == 0) return

    // get benchmark data for this specific question (a object in detailed_results)
    const benchmarkData = getBenchmarkData(
      fullDetails,
      question.client_question_id
    )

    // question average score TODO: add this to the backend response of client_questions_analysis
    const avg = formatValue(benchmarkData.mean_and_count_by_segment.User[0])
    let avgText = {
      type: "text",
      content: `<strong>Average Score:</strong> ${avg}`,
      id: `q-group-avg-${questionWithDashes}`
    }

    // question reponses table
    const tableData = getTableData(uniqueVals)
    let scoreTable = {
      type: "table",
      content: {
        data: {
          columnNames: ["Response", "Count", "Percent"],
          rowNames: tableData.rowNames,
          data: tableData.data
        }
      },
      meta: {
        caption: "Response Summary"
      },
      id: `q-group-table-${questionWithDashes}`,
      layout: "document-node--width-50"
    }

    // ueser question response bar chart
    const barChartData = getBarCharData(uniqueVals)
    let barChart = {
      type: "horizontalBarChart",
      content: {
        title:
          "<span class='pdf-hidden-text sr-only'>Response Dstribution</span>",
        data: barChartData
      },
      meta: {
        headingLevel: "span",
        primaryBarColour: "#6A88AA",
        axisLabels: {
          x: "Count",
          y: "Response"
        }
      },
      id: `q-group-chart-${questionWithDashes}`,
      layout: "document-node--width-50"
    }

    // generate "all groups(organizations)" bar chart for this question
    const allGroupsChart = getAllGroupsChart(
      benchmarkData.average_values,
      questionHeading,
      globalMatch.global_question_text,
      benchmarkData.mean_and_count_by_segment["All"]
    )

    // generate bar charts for each benchmark group (for this question)
    const benchmarksByGroup = getBenchmarksBySegment(
      benchmarkData.average_values,
      benchmarkData.mean_and_count_by_segment,
      questionHeading,
      segments
    )

    data = data.concat([header, avgText, scoreTable, barChart])
    data = data.concat(allGroupsChart)
    data = data.concat(benchmarksByGroup)
  })

  return data
}

export default QuestionByQuesiton
