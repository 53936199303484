/**
 * Create chart data for pigeondoc chart
 * @param {Object} data performance analysis chart data
 * @returns style information for table data
 */
const getChartData = (data, includeLinks) => {
  return Object.entries(data).reduce((chartItems, item) => {
    let obj = {}
    obj["label"] = item[1].client_question_analysis.question_text
    obj["value"] =
      (
        Object.entries(
          item[1].client_question_analysis.unique_numbers_count_dict
        )
          .filter(
            i => i[0] >= item[1].client_question_analysis.median_scale_value
          )
          .reduce((total, val) => total + val[1], 0) /
        item[1].client_question_analysis.valid_size
      ).toFixed(2) * 100
    if (includeLinks)
      obj["labelLink"] = `#q-group-heading-${obj["label"].replace(/\s/g, "-")}`
    chartItems.push(obj)
    return chartItems
  }, [])
}

/*******************************
 *
 * Generate nodes required for performance analysis chart
 * @param {Object} data performance analysis chart data
 * @returns {Object} pigeondoc table node
 *
 ******************************/
const PerformanceAnalysisChart = (
  chartType,
  data,
  title,
  includeLinks = true
) => {
  const chartData = getChartData(data.summary_table, includeLinks)
  const chart = {
    type: chartType.charAt(0).toLowerCase() + chartType.slice(1),
    content: {
      title: `<span class='performance-chart-title'>${title}</span>`,
      data: chartData
    },
    id: "performance-analysis-chart",
    meta: {
      headingLevel: "div",
      primaryBarColour: "#6A88AA",
      axisLabels: {
        x: "% Favorable",
        y: "Questions"
      },
      suffix: "%"
    }
  }
  return chart
}

export default PerformanceAnalysisChart
