// Helpers
import { mapActions, mapGetters } from "vuex"

// Services
import BenchmarkingService from "@/services/benchmarkingService.js"
import DatasetsService from "@/services/datasetsService.js"
import DriversService from "@/services/driversService.js"

export default {
  data() {
    return {
      BENCHMARKING_SERVICE: new BenchmarkingService(this.$pigeonline),
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      DRIVERS_SERVICE: new DriversService(this.$pigeonline)
    }
  },
  computed: {
    ...mapGetters("datasetDetails", {
      benchmarks: "getBenchmarks",
      clientQuestions: "getClientQuestions",
      dataset: "getDataset",
      datasetDetailsLoaded: "getDatasetDetailsLoaded",
      globalQuestions: "getGlobalQuestions",
      matchesDetails: "getMatchesDetails",
      segmentDetails: "getSegmentDetails",
      suggestedMatches: "getSuggestedMatches",
      suggestedTypes: "getSuggestedTypes"
    })
  },
  methods: {
    ...mapActions("datasetDetails", [
      "setBenchmarks",
      "setClientQuestions",
      "setDataset",
      "setDatasetDetailsLoaded",
      "setGlobalQuestions",
      "setMatchesDetails",
      "setSegmentDetails",
      "setSuggestedMatches",
      "setSuggestedTypes",
      "updateClientQuestion",
      "resetDatasetDetails"
    ]),
    /************
     *
     * Benchmarks
     *
     ***********/
    clientQuestionIsVersion(clientQuestion, versionNumber = "1") {
      return (
        Boolean(
          clientQuestion.schema_version &&
            clientQuestion.schema_version == versionNumber
        ) || versionNumber === "1"
      )
    },
    /**
     * update global question matches counts
     * depending on benchmark group config
     **/
    updateGlobalQuestionMatchesCounts(globalQuestionId) {
      let globalQ = this.globalQuestions.find(
        q => q._id.$oid == globalQuestionId
      )
      if (this.$store.getters["datasetWizard/getBenchmarkGroup"].auto_approve) {
        this.updateDatasetProjectStatus("benchmarksApproved")
        globalQ.matches.approved += 1
        this.DATASETS_SERVICE.updateGlobalQuestion(globalQ._id.$oid, {
          matches: globalQ.matches
        })
      } else {
        globalQ.matches.pending += 1
        this.DATASETS_SERVICE.updateGlobalQuestion(globalQ._id.$oid, {
          matches: globalQ.matches
        })
      }
    },
    async updateClientQuestionBenchmarkedMatches(clientQuestion, selected) {
      if (!this.clientQuestionIsVersion(clientQuestion, "1"))
        return clientQuestion
      const benchmarked = { benchmarked_global_question_id: selected }
      const response = await this.DATASETS_SERVICE.updateClientQuestion(
        clientQuestion._id.$oid,
        benchmarked
      )
      return response.client_question_object
    },
    async reloadBenchmarks() {
      const benchmarks = await this.fetchBenchmarks(this.dataset._id.$oid)
      this.setBenchmarks(benchmarks)
      this.setMatchesDetails(await this.fetchMatchesDetails(benchmarks))
    },
    /**
     * When deleting a benchmark match, update the global question matches count
     *
     * Global question object:
     * {
     *  "_id" : <objectid>,
     *  "question_title" : <string>,
     *  "question_text" : <string>,
     *  "tags" : <string>,
     *  "benchmark_group" : <objectid>,
     *  "matches" : {       <--- update this field
     *     "approved" : 0,
     *     "pending" : 0
     *   }
     * }
     *
     * @param {Array} globalQuestions all global questions
     * @param {Object} globalQuestionId to update
     * @param {Object} benchmark object to be deleted
     * @returns {Array} benchmark object if update failed
     */
    async onDeleteUpdateGlobalQuestionBenchmarkMatchCount(
      globalQuestions,
      globalQuestionId,
      benchmark
    ) {
      let failed = []
      const globalQ = globalQuestions.find(q => q._id.$oid == globalQuestionId)
      if (!globalQ.matches) return failed
      // if benchmark was approved or pending, reduce global question count
      const approvedCount = benchmark.approved
        ? Math.max(0, globalQ.matches.approved - 1)
        : globalQ.matches.approved
      const pendingCount =
        !benchmark.approved && !benchmark.rejected
          ? Math.max(0, globalQ.matches.pending - 1)
          : globalQ.matches.pending

      // counts were updated
      if (
        approvedCount != globalQ.matches.approved ||
        pendingCount != globalQ.matches.pending
      ) {
        globalQ.matches.approved = approvedCount
        globalQ.matches.pending = pendingCount
        await this.DATASETS_SERVICE.updateGlobalQuestion(globalQ._id.$oid, {
          matches: globalQ.matches
        }).catch(e => {
          /**
           * 304 error because there are multiple matches to a global question
           * so counts were not updated or updates are happening in parallel
           * keep track of any failed benchmark updates and return
           **/
          failed.push(benchmark)
          console.error(e)
        })
        return failed
      }
    },
    async newBenchmarkMatch(
      clientQuestion,
      selectedGlobalQuestionId,
      recommendedGlobalQuestionId
    ) {
      try {
        const data = {
          client_question_id: clientQuestion._id.$oid,
          global_question_id: selectedGlobalQuestionId,
          recommended_global_question_id: recommendedGlobalQuestionId,
          approved: this.$store.getters["datasetWizard/getBenchmarkGroup"]
            .auto_approve,
          auto_approved: this.$store.getters["datasetWizard/getBenchmarkGroup"]
            .auto_approve,
          owner: {
            user: this.$store.getters["user/getProfile"].django_ref_id,
            organization: this.$store.getters["user/getOrganization"]
              .organization_name
          }
        }
        const response = await this.BENCHMARKING_SERVICE.request(data)
        return response
      } catch (e) {
        throw new Error("datasetDetailsMixin:newBenchmarkMatch " + e)
      }
    },
    async deleteBenchmarkMatch(benchmarkId) {
      try {
        const benchmark = this.benchmarks.find(b => (b._id.$oid = benchmarkId))
        // decrese number of approved matches in global question
        this.onDeleteUpdateGlobalQuestionBenchmarkMatchCount(
          this.globalQuestions,
          benchmark.global_question_id,
          benchmark
        )
        await this.BENCHMARKING_SERVICE.deleteBenchmark(benchmarkId)
      } catch (e) {
        throw new Error(
          "datasetDetailsMixin:deleteBenchmarkMatch " + e.messages
        )
      }
    },
    async updateMatch(clientQuestion, selectedGlobalQuestionId) {
      // delete current benchmark
      await this.deleteBenchmarkMatch(clientQuestion.benchmarking_id)

      // update client question based on version
      const clientQ = await this.updateClientQuestionBenchmarkedMatches(
        clientQuestion,
        selectedGlobalQuestionId
      )

      // create new benchmark
      let recommended = ""
      if (!this.clientQuestionIsVersion(clientQuestion, "1"))
        recommended = clientQuestion.benchmarked_global_question.recommended
      await this.newMatch(clientQ, selectedGlobalQuestionId, recommended)
    },
    async newMatch(
      clientQuestion,
      selectedGlobalQuestionId,
      recommendedGlobalQuestionId
    ) {
      const response = await this.newBenchmarkMatch(
        clientQuestion,
        selectedGlobalQuestionId,
        recommendedGlobalQuestionId
      )
      this.updateDatasetProjectStatus("benchmarksSent")
      this.updateGlobalQuestionMatchesCounts(selectedGlobalQuestionId)

      // update client question store
      const clientQ = await this.DATASETS_SERVICE.clientQuestions(
        clientQuestion._id.$oid
      )
      this.updateClientQuestion(clientQ)

      // update store values
      this.reloadBenchmarks()

      return response
    },
    /************
     *
     * Dataset project
     *
     ***********/
    async updateDatasetProjectStatus(status) {
      const datasetProject = this.$store.getters[
        "datasetWizard/getDatasetProject"
      ]
      if (datasetProject.status[status]) return
      datasetProject.updateStatus(status)
      const updatedProject = await this.$pigeonline.projects.update(
        datasetProject
      )
      this.$store.dispatch("datasetWizard/setDatasetProject", updatedProject)
    },
    /************
     *
     * Loading and setting up data in store
     *
     ***********/
    async loadDatasetDetails(dataset) {
      this.$store.dispatch("loader/setLoading", true)
      this.loadDatasetData(dataset)
        .then(
          async ({
            benchmarks,
            clientQuestions,
            globalQuestions,
            segmentDetails
          }) => {
            this.setBenchmarks(benchmarks)
            this.setClientQuestions(clientQuestions)
            this.setGlobalQuestions(globalQuestions)
            this.setSegmentDetails(segmentDetails)
            this.setMatchesDetails(this.fetchMatchesDetails(benchmarks))
            this.setDatasetDetailsLoaded(true)
            this.$store.dispatch("loader/setLoading", false)
          }
        )
        .catch(() => this.$store.dispatch("loader/setLoading", false))
    },
    async loadDatasetData(dataset) {
      const [
        benchmarks,
        clientQuestions,
        globalQuestions,
        segmentDetails
      ] = await Promise.all([
        await this.fetchBenchmarks(dataset._id.$oid),
        await this.fetchClientQuestions(dataset._id.$oid),
        await this.fetchGlobalQuestions(dataset),
        await this.fetchSegmentDetails(dataset.benchmark_group)
      ])
      return { benchmarks, clientQuestions, globalQuestions, segmentDetails }
    },
    /************
     *
     * Updating data in store
     *
     ***********/
    async updateDataset(id, body) {
      const result = await this.DATASETS_SERVICE.update(id, body)
      this.setDataset(result.data_set_object)
    },
    async updateClientQuestions(datasetId) {
      await this.setClientQuestions(await this.fetchClientQuestions(datasetId))
    },
    /************
     *
     * Fetching data
     *
     ***********/
    async fetchDataset(datasetId) {
      try {
        const response = await this.DATASETS_SERVICE.dataset(datasetId)
        return response
      } catch (e) {
        throw new Error("DatasetDetailsMixin:fetchDataset " + e.message)
      }
    },
    async fetchBenchmarks(datasetId) {
      try {
        const response = await this.BENCHMARKING_SERVICE.benchmarking({
          client_data_set_id: datasetId
        })
        return response
      } catch (e) {
        throw new Error("DatasetDetailsMixin.js:fetchBenchmarks " + e.message)
      }
    },
    async fetchClientQuestions(datasetId) {
      try {
        const response = await this.DATASETS_SERVICE.clientQuestions(null, {
          data_set_id: datasetId
        })
        return response
      } catch (e) {
        throw new Error(
          "DatasetDetailsMixin.js:fetchClientQuestions " + e.message
        )
      }
    },
    async fetchGlobalQuestions(dataset = null) {
      try {
        const data = dataset
          ? {
              benchmark_group: dataset.benchmark_group
            }
          : {}
        const response = await this.DATASETS_SERVICE.globalQuestions(null, data)
        return response
      } catch (e) {
        throw new Error(
          "DatasetDetailsMixin.js:fetchGlobalQuestions " + e.message
        )
      }
    },
    async fetchSegmentDetails(datasetBenchmarkGroupId) {
      try {
        if (!datasetBenchmarkGroupId) return {}
        const response = await this.BENCHMARKING_SERVICE.segmentsDetails({
          benchmark_group: datasetBenchmarkGroupId
        })
        return response
      } catch (e) {
        throw new Error(
          "DatasetDetailsMixin.js:fetchSegmentDetails " + e.message
        )
      }
    },
    async fetchSuggestedTypes(datasetId, clientQuestions) {
      try {
        return await this.DRIVERS_SERVICE.dataType({
          data_set_id: datasetId,
          client_question_ids: clientQuestions
        })
      } catch (e) {
        throw new Error("DatasetDetailsMixin:fetchSuggestedTypes" + e.message)
      }
    },
    fetchMatchesDetails(benchmarks) {
      if (this.globalQuestions.length == 0) return {}
      return benchmarks.reduce((matches, match) => {
        let globalQ = this.globalQuestions.find(
          q => q._id.$oid == match.global_question_id
        )
        let temp = {
          benchmark_id: match._id.$oid,
          global_question_id: globalQ._id.$oid,
          global_question_title: globalQ.question_title,
          global_question_text: globalQ.question_text
        }
        matches[match.client_question_id] = temp
        return matches
      }, {})
    }
  }
}
