/**
 * Create chart data for pigeondoc chart
 * @param {Object} data drivers analysis chart data
 * @param {Boolean} includeLinks to question by question section
 * @returns style information for table data
 */
const getChartData = (data, includeLinks) => {
  return data.reduce((chartItems, item) => {
    if (!item[4] || !item[0]) return chartItems
    const matrixLabel = item[3].length > 0 ? item[3] : item[1]
    let obj = {}
    obj["label"] = matrixLabel
    obj["x"] = item[4].toFixed(2)
    obj["y"] = item[0].toFixed(2)
    // if we want to include links & there is a global match
    if (includeLinks && item[3].length > 0)
      obj["link"] = `#q-group-heading-${item[3].replace(/\s/g, "-")}`
    chartItems.push(obj)
    return chartItems
  }, [])
}

/*******************************
 *
 * Generate nodes required for drivers analysis chart
 * @param {String} title of the drivers analysis chart
 * @param {Array} data drivers analysis chart data from drivers/drivers_analysis
 * @returns {Object} pigeondoc table node
 *
 ******************************/
const PriorityMatrixChart = (title, data, includeLinks = true) => {
  //FIXME: is cat_drivers and segment_driverspart of this chart or separate?
  const chartData = getChartData(data.num_drivers, includeLinks)
  const chart = {
    type: "priorityMatrix",
    content: {
      title: `<span class='drivers-chart-title'>Priority Matrix for ${title}</span>`,
      data: chartData
    },
    id: "drivers-priority-matrix",
    meta: {
      headingLevel: "div",
      primaryBarColour: "#6A88AA",
      axisLabels: {
        x: "Performance",
        y: "Importance"
      },
      quadrantLabels: {
        topLeft: "Improve",
        topRight: "Protect",
        bottomLeft: "Monitor",
        bottomRight: "Maintain"
      },
      axisHoverText: {
        x:
          "This value represents the average score from all respondents. The higher the number, the higher your users rated your performance.",
        y: data.y_axis
      }
    }
  }
  return chart
}

export default PriorityMatrixChart
