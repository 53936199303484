const datasetWizardCRUD = ["create-dataset", "update-dataset", "read-dataset"]
const projectCRUD = ["create-project", "update-project", "read-project"]
const allAnalysis = ["create-analysis", "create-analysis-text", "create-report"]

const licenses = {
  free: ["accounts-invite-free", ...projectCRUD, ...allAnalysis],
  basic: [
    "accounts-join-organization",
    "accounts-invite-organization",
    "benchmarking-group-select",
    "benchmarking-manager",
    ...allAnalysis,
    ...datasetWizardCRUD,
    ...projectCRUD
  ],
  iccs: [
    "accounts-join-organization",
    "accounts-invite-organization",
    "benchmarking-group-iccs",
    "create-analysis",
    "create-report",
    ...datasetWizardCRUD,
    ...projectCRUD
  ]
}

licenses["iccs-admin"] = ["benchmarking-manager", ...licenses.iccs]
licenses["basic-iccs"] = ["benchmarking-group-iccs", ...licenses.basic]

export default licenses
