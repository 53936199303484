import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default {
  namespaced: true,
  state() {
    return {
      benchmarks: [],
      clientQuestions: [],
      dataset: null,
      datasetDetailsLoaded: false,
      globalQuestions: [],
      matchesDetails: {},
      segmentDetails: {},
      suggestedMatches: {},
      suggestedTypes: {}
    }
  },
  getters: {
    getBenchmarks: state => state.benchmarks,
    getClientQuestions: state => state.clientQuestions,
    getDataset: state => state.dataset,
    getDatasetDetailsLoaded: state => state.datasetDetailsLoaded,
    getGlobalQuestions: state => state.globalQuestions,
    getMatchesDetails: state => state.matchesDetails,
    getSegmentDetails: state => state.segmentDetails,
    getSuggestedMatches: state => state.suggestedMatches,
    getSuggestedTypes: state => state.suggestedTypes
  },
  mutations: {
    SET_BENCHMARKS(state, payload) {
      Vue.set(state, "benchmarks", payload)
    },
    SET_CLIENT_QUESTIONS(state, payload) {
      Vue.set(state, "clientQuestions", payload)
    },
    SET_DATASET(state, payload) {
      Vue.set(state, "dataset", payload)
    },
    SET_DATASET_DETAILS_LOADED(state, payload) {
      Vue.set(state, "datasetDetailsLoaded", payload)
    },
    SET_GLOBAL_QUESTIONS(state, payload) {
      Vue.set(state, "globalQuestions", payload)
    },
    SET_MATCHES_DETAILS(state, payload) {
      Vue.set(state, "matchesDetails", payload)
    },
    SET_SEGMENT_DETAILS(state, payload) {
      Vue.set(state, "segmentDetails", payload)
    },
    SET_SUGGESTED_MATCHES(state, payload) {
      Vue.set(
        state.suggestedMatches,
        payload.client_question_id,
        payload.global_question_id
      )
    },
    SET_SUGGESTED_TYPES(state, payload) {
      Vue.set(
        state.suggestedTypes,
        payload.client_question_id,
        payload.suggested_type
      )
    },
    UPDATE_CLIENT_QUESTION(state, payload) {
      Vue.set(state.clientQuestions, payload.indx, payload.clientQuestion)
    },
    UPDATE_DATASET_SEGMENTS(state, payload) {
      Vue.set(state.dataset, "segments", payload)
    },
    RESET_DATASET_DETAILS(state) {
      Vue.set(state, "benchmarks", [])
      Vue.set(state, "clientQuestions", [])
      Vue.set(state, "dataset", null)
      Vue.set(state, "datasetDetailsLoaded", false)
      Vue.set(state, "globalQuestions", [])
      Vue.set(state, "matchesDetails", [])
      Vue.set(state, "segmentDetails", [])
      Vue.set(state, "suggestedMatches", [])
      Vue.set(state, "suggestedTypes", [])
    }
  },
  actions: {
    async setBenchmarks({ commit }, payload) {
      await commit("SET_BENCHMARKS", payload)
    },
    async setClientQuestions({ commit }, payload) {
      await commit("SET_CLIENT_QUESTIONS", payload)
    },
    async setDataset({ commit }, payload) {
      await commit("SET_DATASET", payload)
    },
    async setDatasetDetailsLoaded({ commit }, payload) {
      await commit("SET_DATASET_DETAILS_LOADED", payload)
    },
    async setGlobalQuestions({ commit }, payload) {
      await commit("SET_GLOBAL_QUESTIONS", payload)
    },
    async setMatchesDetails({ commit }, payload) {
      await commit("SET_MATCHES_DETAILS", payload)
    },
    async setSegmentDetails({ commit }, payload) {
      await commit("SET_SEGMENT_DETAILS", payload)
    },
    async setSuggestedMatches({ commit }, payload) {
      await commit("SET_SUGGESTED_MATCHES", payload)
    },
    async setSuggestedTypes({ commit }, payload) {
      await commit("SET_SUGGESTED_TYPES", payload)
    },
    async updateClientQuestion({ commit, state }, clientQ) {
      const indx = state.clientQuestions.findIndex(
        q => q._id.$oid === clientQ._id.$oid
      )
      if (indx < 0) return
      const data = {
        indx: indx,
        clientQuestion: clientQ
      }
      await commit("UPDATE_CLIENT_QUESTION", data)
    },
    async updateDatasetSegments({ commit }, segments) {
      await commit("UPDATE_DATASET_SEGMENTS", segments)
    },
    resetDatasetDetails({ commit }) {
      commit("RESET_DATASET_DETAILS")
    }
  }
}
