export default {
  methods: {
    wrapMark() {
      if (!this.$slots.default[0].elm) return
      const highlighted = this.$slots.default[0].elm.querySelectorAll(
        ".main__highlight"
      )
      for (let i = 0; i < highlighted.length; ++i) {
        // already has the markup, skip it
        if (highlighted[i].parentNode.nodeName === "BUTTON") return

        let container = document.createElement("button")
        container.setAttribute("class", "highlighted-container")
        container.setAttribute("type", "button")
        container.setAttribute(
          "aria-describedby",
          highlighted[i].dataset.highlightType
        )
        container.addEventListener("click", this.onHighlightSelect)
        container.addEventListener("mouseup", this.stopPropagating)

        let clonedMark = highlighted[i].cloneNode(true)

        container.appendChild(clonedMark)

        highlighted[i].replaceWith(container)
      }
    },
    onHighlightSelect($e) {
      const parentPos = this.$refs[
        "selectable-container"
      ].getBoundingClientRect()
      const childPos = $e.target.getBoundingClientRect()
      const top = childPos.top - parentPos.top
      const left = childPos.left - parentPos.left
      const width = childPos.width
      if (!width) {
        this.showTools = false
        return
      }
      this.x = left + width / 2
      this.y = top - 10
      this.showTools = true
      this.selectedText = $e.target.textContent
      this.highlightNodeSelected = this.highlightItemsFlat.includes(
        $e.target.textContent.trim().toLowerCase()
      )

      this.$nextTick(function() {
        this.$refs["selectable-tools"].focus()
      })
    },
    stopPropagating($e) {
      $e.stopPropagation()
    }
  }
}
