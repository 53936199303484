<template>
  <section>
    <Modal
      header-tag="h1"
      :uid="`user-guide-modal-${uuid}`"
      :show="isUserGuideExpanded"
      :resetListener="resetListener"
      @close="$emit('close')"
    >
      <template #header>
        <VisibleText>User Guide</VisibleText>
      </template>
      <template slot="close">
        <form
          class="user-guide__form"
          id="user_guide_do_not_show_form"
          @submit.prevent="close_and_save"
        >
          <div class="user-guide__header">
            <input
              type="checkbox"
              class="user-guide__checkbox"
              id="landingPageAutoClose"
              v-model="vModels.uiPreferences.tutorial.landingPageAutoClose"
            />
            <label for="landingPageAutoClose" class="user-guide__form-label">
              <VisibleText>Do not show again</VisibleText>
            </label>
          </div>
          <ButtonIconOnly
            icon="remove"
            id="close-user-guide-modal"
            button-type="submit"
            class="modal-close-btn"
            @click-handler="close_and_save"
          >
            <VisibleText>close</VisibleText>
          </ButtonIconOnly>
        </form>
      </template>
      <div v-if="guideTopic === 0">
        <h2>
          <VisibleText
            >Welcome! Let's go from data to insights in clicks.
          </VisibleText>
        </h2>
        <p>
          <VisibleText
            >Below are guides to help you learn this tool. This will take
            approximately 5 minutes. You should also find a sample dataset,
            sample report, and sample benchmarking group to help you get
            started.
          </VisibleText>
        </p>
        <h3><VisibleText>Guides</VisibleText>:</h3>
        <ul class="tutorial-toc-list">
          <li>
            <a href="#" @click.prevent="changeTopic(1)" id="getting-started"
              ><VisibleText>Getting started</VisibleText></a
            >
          </li>
          <li>
            <a href="#" @click.prevent="changeTopic(2)"
              ><VisibleText>Uploading and cleaning data</VisibleText></a
            >
          </li>
          <li>
            <a href="#" @click.prevent="changeTopic(3)"
              ><VisibleText>Analyzing surveys</VisibleText>
            </a>
          </li>
        </ul>
        <VisibleText
          >Having trouble? Have suggestions for improvement?
        </VisibleText>
        <p class="caption_text">
          <VisibleText>
            We are a young project, but we have bold visions. With your help we
            can make a difference. Please do not hesitate to chat with us (click
            on the chatbox button at the bottom of out site) and say 'Hi!'. This
            will put you in touch with one of our teammates. We look forward to
            learning from you. What works well, what doesn't?
          </VisibleText>
        </p>
        <img
          class="caption_image"
          src="/assets/userguide__contactus.png"
          alt="chatbox"
        />
      </div>
      <div v-if="guideTopic === 1">
        <h2 class="pageCounter">
          <VisibleText>Getting started</VisibleText>
        </h2>
        <span class="pageCounter pageCounter-end">
          {{ this.topicPage }}/3
        </span>
        <div v-if="topicPage === 1">
          <p>
            <VisibleText
              >This tool starts with your data. We will ask questions about your
              data, and then guide you through insights.
            </VisibleText>
          </p>
          <h3>
            <VisibleText>What kind of data?</VisibleText>
          </h3>
          <p>
            <VisibleText
              >We currently focus on survey data. This can come from tools like:
            </VisibleText>
          </p>
          <ul class="tutorial-content-list">
            <li><VisibleText>SPSS</VisibleText></li>
            <li><VisibleText>Stata</VisibleText></li>
            <li><VisibleText>SurveyMonkey</VisibleText></li>
          </ul>
          <h4><VisibleText>Stay tuned!</VisibleText></h4>
          <p><VisibleText>We will be expanding to include:</VisibleText></p>
          <ul class="tutorial-content-list">
            <li><VisibleText>Interviews and transcripts</VisibleText></li>
            <li><VisibleText>CRM software data</VisibleText></li>
            <li><VisibleText>Behavioural data</VisibleText></li>
            <li>
              <VisibleText>Contact us to suggest new data types</VisibleText>
            </li>
          </ul>
        </div>
        <div v-if="topicPage === 2">
          <p>
            <VisibleText
              >If you are an ICCS subscriber, we recommend uploading a survey
              that uses questions from the Common Measurements Tool. To learn
              more, contact the
            </VisibleText>
            <a
              href="https://citizenfirst.ca/measure-and-benchmark/about-the-common-measurements-tool"
            >
              <VisibleText
                >Institute for Citizen-Centred Service
              </VisibleText></a
            >.
          </p>
        </div>
        <div v-if="topicPage === 3">
          <p><VisibleText>This is an overview of the process</VisibleText></p>
          <div class="figure__horizontal">
            <div class="figure__horizontal--square">
              <VisibleText>Upload data</VisibleText>
            </div>
            <div class="figure__horizontal--arrow">→</div>
            <div class="figure__horizontal--square">
              <VisibleText>Clean data</VisibleText>
            </div>
            <div class="figure__horizontal--arrow">→</div>
            <div class="figure__horizontal--square">
              <VisibleText>Analyze</VisibleText>
            </div>
            <div class="figure__horizontal--arrow">→</div>
            <div class="figure__horizontal--square">
              <VisibleText>Save and share</VisibleText>
            </div>
          </div>
        </div>
      </div>
      <div v-if="guideTopic === 2">
        <h2 class="pageCounter">
          <VisibleText>Uploading and cleaning data</VisibleText>
        </h2>
        <span class="pageCounter pageCounter-end">
          {{ this.topicPage }}/6
        </span>
        <div v-if="topicPage === 1">
          <p>
            <VisibleText
              >To upload a survey, click 'datasets' when looking at the main
              page. This will take you to a list of all datasets uploaded by you
              or shared to you. You will also see a sample dataset. Note that
              free users are unable to upload new datasets.
            </VisibleText>
          </p>
          <img
            class="screenshot"
            src="/assets/userguide__upload01.png"
            alt="screenshot of top nav with datasets circled"
          />
        </div>
        <div v-if="topicPage === 2">
          <p>
            <VisibleText
              >For best results, use SPSS file format .sav (this can be exported
              from most survey software). This will allow you to skip the next
              stage.
            </VisibleText>
          </p>
          <p>
            <VisibleText
              >If you do not have .sav, please download and fill in the
              following template:
            </VisibleText>
          </p>
          <button
            @click="download"
            class="dataset-labeller__download download_button"
          >
            <VisibleText>Download Template</VisibleText>
          </button>
          <h4>Using the template</h4>
          <p>
            <VisibleText
              >Respondents are in rows, and questions are in columns:
            </VisibleText>
          </p>
          <img
            class="screenshot"
            style="width:55%"
            src="/assets/userguide__upload02.png"
            alt="screenshot of excel template respondent row and question columns"
          />
          <p>
            <VisibleText
              >Add question wording to match your question code. If you do not
              have specific question codes, just add Q1, Q2, Q3 etc.
            </VisibleText>
          </p>
          <img
            class="screenshot"
            src="/assets/userguide__upload03.png"
            alt="screenshot of excel template code and question wording"
          />
        </div>
        <div v-if="topicPage === 3">
          <p>
            <VisibleText
              >The next step is to clean your data. The tool will guess your
              data types, but it won't always be correct. Please go through each
              survey question and verify.
            </VisibleText>
          </p>
          <img
            class="screenshot"
            src="/assets/userguide__upload04.png"
            alt="screenshot of a question with data type circled"
          />
        </div>
        <div v-if="topicPage === 4">
          <p>
            <VisibleText
              >You can also compare yourself to peers. Set up an internal
              benchmarking service, or register for the ICCS's Public Sector
              benchmarking service, called CitizenFirst Analytics.
            </VisibleText>
          </p>
          <h4><VisibleText>What is a benchmark?</VisibleText></h4>
          <p>
            <VisibleText
              >You may have scores that are similar to questions in a basket of
              peers. Matching your scores to these benchmarking groups allows
              you to see how well you compare.
            </VisibleText>
          </p>
          <h4>
            <VisibleText
              >How can I see if my peers ask similar questions to me?
            </VisibleText>
          </h4>
          <p>
            <VisibleText
              >This tool has an automatic benchmark detector. Just select the
              benchmarking service you would like to compare your scores to, and
              click 'get suggested matches'.
            </VisibleText>
          </p>
        </div>
        <div v-if="topicPage === 5">
          <p>
            <VisibleText
              >Lastly to ensure your research is robust, we ask questions about
              your dataset.
            </VisibleText>
          </p>
          <img
            class="screenshot"
            src="/assets/userguide__upload05.png"
            alt="screenshot of step 4 in dataset wizard"
          />
          <p>
            <VisibleText
              >Tip: By correctly identifying your respondents, you will enable a
              comparison against peers with the same types of respondents.
            </VisibleText>
          </p>
        </div>
        <div v-if="topicPage === 6">
          <p>
            <VisibleText
              >Your data is now ready for analysis! Based on the dataset you
              uploaded, and the cleaning, you may have unlocked a number of
              analytical options. The next guides will help you find insights in
              this data.
            </VisibleText>
          </p>
        </div>
      </div>
      <div v-if="guideTopic === 3">
        <h2 class="pageCounter">
          <VisibleText>Analyzing surveys</VisibleText>
        </h2>
        <span class="pageCounter pageCounter-end">
          {{ this.topicPage }}/10
        </span>
        <div v-if="topicPage === 1">
          <p>
            <VisibleText
              >You have successfully uploaded data and are now ready for
              analysis. To begin, create a new project.
            </VisibleText>
          </p>
          <img
            class="screenshot"
            style="width:60%;"
            src="/assets/userguide__analysis01.png"
            alt="screenshot of projects page with create new project button circled"
          />
        </div>
        <div v-if="topicPage === 2">
          <p>
            <VisibleText
              >Next, you have to connect your project to a cleaned dataset.
            </VisibleText>
          </p>
          <img
            class="screenshot"
            src="/assets/userguide__analysis02.png"
            alt="screenshot of a dataset selected and connect dataset circled"
          />
        </div>
        <div v-if="topicPage === 3">
          <p>
            <VisibleText
              >You can now see your connected data. To run analysis, click this
              tab:
            </VisibleText>
          </p>
          <img
            class="screenshot"
            src="/assets/userguide__analysis03.png"
            alt="screenshot of project tabs with analysis circled"
          />
        </div>
        <div v-if="topicPage === 4">
          <p>
            <VisibleText
              >The tool will have automatically suggested analysis based on the
              type of data you uploaded.
            </VisibleText>
          </p>
          <div class="figure__vertical--card">
            <h4><VisibleText>Drivers Analysis</VisibleText></h4>
            <VisibleText
              >Requires scale questions, like responses between 1 to 5:
            </VisibleText>
            <div class="figure__vertical--indent">
              <p>
                <VisibleText
                  >This will find out what aspects of service are most likely to
                  drive an outcome like client satisfaction or willingness to
                  recommend to others.
                </VisibleText>
              </p>
            </div>
          </div>
          <div class="figure__vertical--card">
            <h4><VisibleText>Benchmarking Analysis</VisibleText></h4>
            <VisibleText
              >Requires you to connect questions to questions in a benchmarking
              service:
            </VisibleText>
            <div class="figure__vertical--indent">
              <p>
                <VisibleText
                  >This will compare your scores to peers.
                </VisibleText>
              </p>
            </div>
          </div>
          <div class="figure__vertical--card">
            <h4><VisibleText>Text Analysis</VisibleText></h4>
            <VisibleText>Requires text questions.</VisibleText>
            <div class="figure__vertical--indent">
              <p>
                <VisibleText
                  >This will help you find themes in your text.
                </VisibleText>
              </p>
            </div>
          </div>
        </div>
        <div v-if="topicPage === 5">
          <h4><VisibleText>What is drivers analysis?</VisibleText></h4>
          <p>
            <VisibleText
              >Drivers analysis is an important tool to help you prioritize
              where to focus your energies. Our tool is running a statistical
              analysis called
            </VisibleText>
            <a href="https://en.wikipedia.org/wiki/Regression_analysis">
              <VisibleText>regression</VisibleText></a
            >
            <VisibleText
              >. This will let us see which questions in your dataset predict
              important outcomes. These questions are called drivers of
              satisfaction.
            </VisibleText>
          </p>
        </div>
        <div v-if="topicPage === 6">
          <h4><VisibleText>Running drivers analysis</VisibleText></h4>
          <p>
            <VisibleText
              >The tool may automatically find an outcome question like 'Overall
              satisfaction' to run predictions.
            </VisibleText>
          </p>
          <img
            class="screenshot"
            src="/assets/userguide__analysis04.png"
            alt="screenshot of dependent variable table"
          />
          <p>
            <VisibleText
              >If it cannot find one, add your own. You can predict what drives
              any scale question.
            </VisibleText>
          </p>
        </div>
        <div v-if="topicPage === 7">
          <h4><VisibleText>Running drivers analysis</VisibleText></h4>
          <p>
            <VisibleText
              >The tool will add all other scale questions. Feel free to modify
              this list until it makes sense to you. You are looking to add
              anything that could predict your outcome.
            </VisibleText>
          </p>
          <img
            class="screenshot"
            src="/assets/userguide__analysis05.png"
            alt="screenshot of independent variable table with modify independent variable button circled"
          />
        </div>
        <div v-if="topicPage === 8">
          <h4><VisibleText>Saving drivers analysis</VisibleText></h4>
          <p>
            <VisibleText
              >If the analysis yielded interesting insights, you can save this
              to your final report by clicking "save to report".
            </VisibleText>
          </p>
          <img
            class="screenshot"
            src="/assets/userguide__analysis06.png"
            alt="screenshot of drivers analysis with save to report button circled"
          />
        </div>
        <div v-if="topicPage === 9">
          <h4><VisibleText>Running benchmarking</VisibleText></h4>
          <p>
            <VisibleText
              >Running benchmarking is even easier. If there are any matches to
              a benchmarking group, summary results should immediately appear.
            </VisibleText>
          </p>
          <img
            class="screenshot"
            src="/assets/userguide__analysis07.png"
            alt="screenshot of benchmarking analysis"
          />
        </div>
        <div v-if="topicPage === 10">
          <h4><VisibleText>Saving benchmarking</VisibleText></h4>
          <p>
            <VisibleText
              >Like before, hit "save to report" to save your benchmarking
              results.
            </VisibleText>
          </p>
          <img
            class="screenshot"
            style="width:25%;"
            src="/assets/userguide__analysis08.png"
            alt="screenshot of save to report button"
          />
          <p>
            <VisibleText
              >Tip: Inside the report you will find many more details about your
              benchmarking.
            </VisibleText>
          </p>
          <img
            class="screenshot"
            style="width:50%;"
            src="/assets/userguide__analysis09.png"
            alt="screenshot of benchmarking details chart"
          />
        </div>
      </div>
      <template slot="footer" v-if="guideTopic > 0">
        <div class="guideFooter">
          <button class="guideFooter__button" @click="changePage(-1)">
            <VisibleText>Back</VisibleText>
          </button>
          <button
            class="guideFooter__button"
            @click="changeTopic(0)"
            v-if="this.isLastPage"
          >
            <VisibleText>Got it!</VisibleText>
          </button>
          <button class="guideFooter__button" @click="changePage(1)" v-else>
            <VisibleText>Next</VisibleText>
          </button>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import Modal from "@/components/UI/Modal.vue"

// Mixin
import UserMixin from "@/utils/mixins/userMixin"
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

// Packages
import axios from "axios"

export default {
  name: "UserGuide",
  mixins: [UserMixin],
  components: {
    ButtonIconOnly,
    Modal
  },
  props: ["status"],
  data() {
    return {
      newStatus: false,
      guideTopic: 0,
      topicPage: 1,
      isLastPage: false,
      firstLoad: true,
      resetListener: false,
      vModels: {
        uiPreferences: {
          tutorial: {
            landingPageAutoClose: ""
          }
        }
      }
    }
  },
  mounted() {
    this.$watch(
      "profile",
      function(newVal) {
        if (!newVal) return
        this.setValue("landingPageAutoClose", newVal.landingPageAutoClose)
        this.checkAutoLoad()
      },
      { deep: true, immediate: true }
    )
  },
  methods: {
    checkAutoLoad() {
      if (
        this.vModels.uiPreferences.tutorial.landingPageAutoClose &&
        this.firstLoad
      ) {
        this.firstLoad = false
        this.isUserGuideExpanded = false
        this.$emit("close")
      } else if (
        !this.vModels.uiPreferences.tutorial.landingPageAutoClose &&
        this.firstLoad
      ) {
        this.firstLoad = false
        this.isUserGuideExpanded = true
      }
    },
    changeTopic(topic) {
      if (topic === 0) {
        this.isLastPage = false
      }
      this.guideTopic = topic
      this.topicPage = 1
      this.resetListener = !this.resetListener
    },
    changePage(page_change) {
      this.topicPage = this.topicPage + page_change
      if (this.guideTopic === 1 && this.topicPage > 2) {
        this.isLastPage = true
      } else if (this.guideTopic === 2 && this.topicPage > 5) {
        this.isLastPage = true
      } else if (this.guideTopic === 3 && this.topicPage > 9) {
        this.isLastPage = true
      } else {
        this.isLastPage = false
      }
      if (this.topicPage < 1) {
        this.changeTopic(0)
      }
      this.resetListener = !this.resetListener
    },
    download() {
      axios({
        url: `${window.location.origin}/files/codebook-template.xlsx`,
        method: "GET",
        responseType: "blob"
      }).then(response => {
        const fileURL = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/vnd.ms-excel"
          })
        )
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", "codebook-template.xlsx")
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    setValue(property, val) {
      if (val) {
        this.vModels.uiPreferences.tutorial[property] = val
      }
    },
    async close_and_save() {
      this.setLoading(true)
      try {
        const profile = await this.$pigeonline.profile.save({
          landingPageAutoClose: this.vModels.uiPreferences.tutorial
            .landingPageAutoClose
        })
        this.setProfile(profile)
        this.isUserGuideExpanded = false
        this.$emit("close")
      } catch (e) {
        alert(
          "An error occured when processing your request. Please try again later"
        )
        this.isUserGuideExpanded = false
        this.$emit("close")
        return
      }
      this.setLoading(false)
    }
  },
  computed: {
    isUserGuideExpanded: {
      get() {
        return this.newStatus
      },
      set(value) {
        this.newStatus = value
      }
    },
    uuid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    }
  },
  watch: {
    status(newVal) {
      this.newStatus = newVal
    },
    guideTopic(index) {
      if (index === 0)
        this.$nextTick(() => document.getElementById("getting-started").focus())
    }
  }
}
</script>
