import SummaryTable from "../helpers/summaryTable"

const benchmarkSection = (data, columnNames, overallSummary) => {
  const benchmarkSummaryIconSrc = "/files/ReportIcons/benchmarkSummary.svg"
  let benchmarkSummaryHeading = `<img src="${benchmarkSummaryIconSrc}" class="report__header-icon" alt=""/>\
  BENCHMARK SUMMARY`

  return [
    {
      type: "heading",
      content: benchmarkSummaryHeading,
      id: "section-title-benchmark-summary",
      meta: {
        level: "h1",
        pdfConfig: {
          pageBreak: "before"
        }
      }
    },
    {
      type: "text",
      content: overallSummary,
      id: "benchmark-summary-overall"
    },
    {
      type: "table",
      content: {
        data: SummaryTable(data, columnNames)
      },
      meta: {
        fixedColumnsIndx: [0, 1, 2, 3],
        caption: "Overall Averages"
      },
      id: "benchmark-summary-table"
    }
  ]
}

export default benchmarkSection
