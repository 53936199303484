<template>
  <article id="benchmark-manager-editor" class="section__content">
    <div class="benchmark-manager__editor-header">
      <InlineEditTextInput
        label="benchmark group name"
        input-id="benchmark-group-name-input"
        input-name="benchmark-group-name"
        :input-value="benchmarkGroup.name"
        @save="saveName"
      >
        <h2 class="benchmark-manager__editor-title">
          {{ benchmarkGroup ? benchmarkGroup.name : "" }}
        </h2>
      </InlineEditTextInput>
      <button
        class="benchmark-manager__editor-add"
        @click="isAddQuestionVisible = true"
      >
        <SvgIconDecorative icon="add" /> <VisibleText>Add Queston</VisibleText>
      </button>
    </div>
    <MessageBlock
      message-type="warning"
      :title="`You have ${pending.total} pending benchmark requests.`"
      v-if="pending.total"
    >
      <ul class="benchmark-manager__editor-pending-list">
        <li
          v-for="question in pending.globalQuestions"
          :key="`pending-${question._id.$oid}`"
        >
          <a
            class="benchmark-manager__editor-pending-list-a"
            :href="`#${question._id.$oid}`"
            @click.prevent="scrollToAnchor(question._id.$oid)"
          >
            {{ question.question_title }}
          </a>
        </li>
      </ul>
    </MessageBlock>
    <div class="benchmark-manager__editor-details">
      <span>
        <span class="highlight"> {{ benchmarkGroup.questions }} </span>
        <VisibleText>Questions</VisibleText>
      </span>
      <span>
        <span class="highlight">{{ benchmarkGroup.approved_matches }} </span>
        <VisibleText>Approved matches</VisibleText>
      </span>
      <span>
        <span class="highlight">
          {{ ACCESS_TYPE_OPTIONS[benchmarkGroup.access_type] }}
        </span>
        <VisibleText>Access Level</VisibleText>
      </span>
      <span>
        <span class="highlight">
          {{ benchmarkGroup.auto_approve ? "ON" : "OFF" }}
        </span>
        <VisibleText>Auto Approve</VisibleText>
      </span>
    </div>
    <BenchmarkManagerNewQuestion
      :benchmark-group="benchmarkGroup"
      v-if="isAddQuestionVisible"
      @close-new-question="isAddQuestionVisible = false"
      @updateGlobalQuestions="updateGlobalQuestions"
    />
    <section
      class="benchmark-manager__editor-question"
      v-for="(question, index) in globalQuestions"
      :key="question._id.$oid"
      :id="question._id.$oid"
    >
      <BenchmarkManagerEditorQuestion
        :question="question"
        :index="index"
        :benchmark-group="benchmarkGroup"
        @removeGlobalQuestion="removeGlobalQuestion"
      />
    </section>
  </article>
</template>

<script>
// Components
import BenchmarkManagerEditorQuestion from "./BenchmarkManagerEditorQuestion.vue"
import BenchmarkManagerNewQuestion from "./BenchmarkManagerNewQuestion.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import InlineEditTextInput from "@/components/UI/InlineEditTextInput.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Helpers
import { mapActions } from "vuex"

// Models
import BenchmarkGroup from "@/models/BenchmarkGroup.js"

// Services
import DatasetsService from "@/services/datasetsService.js"

export default {
  name: "BenchmarkManagerEditor",
  components: {
    BenchmarkManagerNewQuestion,
    BenchmarkManagerEditorQuestion,
    MessageBlock,
    InlineEditTextInput,
    SvgIconDecorative
  },
  data() {
    return {
      ACCESS_TYPE_OPTIONS: {
        private: "private",
        public__all: "public",
        public__org: "organization"
      },
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      benchmarkGroup: {},
      globalQuestions: null,
      pending: {
        total: null,
        globalQuestions: []
      },
      isAddQuestionVisible: false
    }
  },
  created() {
    this.setLoading(true)
    this.loadData().then(({ benchmarkGroup, globalQuestions }) => {
      this.benchmarkGroup = benchmarkGroup
      this.globalQuestions = globalQuestions
      this.setTotalPending(globalQuestions)
      this.setLoading(false)
    })
  },
  methods: {
    ...mapActions("loader", ["setLoading"]),
    async loadData() {
      const [benchmarkGroup, globalQuestions] = await Promise.all([
        this.fetchGroup(),
        this.fetchQuestions()
      ])
      return { benchmarkGroup, globalQuestions }
    },
    /**
     * Fetching and saving project
     * note that vue-core converts projects to apporpriate model so
     * response is of BenchmarkGroup type.
     */
    async fetchGroup() {
      try {
        const response = await this.$pigeonline.projects.getByID(
          BenchmarkGroup,
          this.$route.params.id
        )
        return response
      } catch (e) {
        throw new Error("BenchmarkManagerEditor:fetchProject " + e.message)
      }
    },
    /**
     * Fetching and saving global questions
     */
    async fetchQuestions() {
      try {
        const response = await this.DATASETS_SERVICE.globalQuestions(null, {
          benchmark_group: this.$route.params.id
        })
        return response
      } catch (e) {
        throw new Error("BenchmarkManagerEditor:fetchProject " + e.message)
      }
    },
    saveName(name) {
      this.benchmarkGroup.name = name
      this.$pigeonline.projects.update(this.benchmarkGroup)
    },
    setTotalPending(globalQuestions) {
      this.pending = globalQuestions.reduce(
        (pendingObj, question) => {
          if (question.matches.pending > 0) {
            pendingObj.total += question.matches.pending
            pendingObj.globalQuestions.push(question)
          }
          return pendingObj
        },
        { total: 0, globalQuestions: [] }
      )
    },
    scrollToAnchor(hash) {
      const el = document.getElementById(hash)
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center"
        })
      }
    },
    updateGlobalQuestions(newQuestion) {
      this.globalQuestions.unshift(newQuestion)
      this.isAddQuestionVisible = false
    },
    removeGlobalQuestion(index) {
      this.globalQuestions.splice(index, 1)
    }
  }
}
</script>
