<template>
  <section
    :id="sectionName"
    class="dataset__section"
    :aria-hidden="modalOpen"
    :inert="modalOpen"
  >
    <h2 class="dataset__section-title">{{ title }}</h2>
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: "DatasetLayout",
  props: {
    sectionName: {
      type: String
    },
    title: {
      type: String
    }
  },
  computed: {
    modalOpen() {
      return this.$store.getters["globalModule/getModalOpen"]
    }
  }
}
</script>
