<template>
  <section id="project-analysis-benchmarking">
    <Spinner :is-loading="isLoading" :message="spinnerMessage" />
    <h2 class="project-analysis__header-title">
      <VisibleText>Benchmarking</VisibleText>
    </h2>

    <p class="benchmarking__desc">
      <VisibleText
        >How does your organization compare to your peers? Benchmarks summarize
        averages or outliers based on segments of your dataset. For each
        question in your dataset that matches with our global database, we
        calculate the average and compare your score to each group. This
        indicates how organizations are performing in that group of service
        delivery.
      </VisibleText>
    </p>

    <BenchmarkingMessages
      :status-benchmarks-pending-approval="statusBenchmarksPendingApproval"
      :status-benchmarks-not-available="statusBenchmarksNotAvailable"
      :status-no-scale-questions="statusNoScaleQuestions"
      :status-org-not-verified="statusOrgNotVerified"
      :num-Not-Approved="numNotApprovedBenchmarks"
      :num-Total="numTotalBenchmarks"
    />

    <article
      class="project-analysis-item__wrapper"
      v-if="statusBenchmarksAvailable"
    >
      <div class="project-analysis-item__header-wrapper">
        <h3 class="project-analysis-item__header-wrapper-title">
          <VisibleText>Overall Averages</VisibleText>
        </h3>
        <HasPermission to="create-report">
          <button
            class="project-analysis-item__header-wrapper-btn benchmarking__save-btn"
            @click="save()"
            :disabled="statusDisableSaveToReport"
          >
            <SvgIconDecorative
              icon="report"
              class="project-analysis-item__header-wrapper-icon"
            />
            <VisibleText>Save To Report</VisibleText>
          </button>
        </HasPermission>
      </div>

      <BenchmarkingAvgTable
        :data="summaryResultsTable"
        :column-headers="summaryResultsTableHeaders"
      />
    </article>
  </section>
</template>

<script>
// Components
import BenchmarkingAvgTable from "./ProjectAnalysisBenchmarkingAvgTable.vue"
import BenchmarkingMessages from "./ProjectAnalysisBenchmarkingMessages.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Mixins
import BenchmarkAnalysisMixin from "@/utils/mixins/benchmarkAnalysisMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import ProjectMixin from "@/utils/mixins/projectMixin.js"
import ProjectReportMixin from "@/utils/mixins/projectReportMixin.js"

// Models
import BenchmarkingDataset from "@/models/BenchmarkingDatasetModel"

export default {
  name: "ProjectAnalysisBenchmarking",
  mixins: [
    BenchmarkAnalysisMixin,
    DatasetDetailsMixin,
    ProjectMixin,
    ProjectReportMixin
  ],
  components: {
    BenchmarkingAvgTable,
    BenchmarkingMessages,
    SvgIconDecorative
  },
  data() {
    return {
      benchmarksApproved: true,
      benchmarksSkipped: false,
      numNotApprovedBenchmarks: 0,
      numTotalBenchmarks: 0,
      isLoading: false,
      // prevent saving to report untill we know dataset status
      isDatasetStatusLoading: false,
      spinnerMessage: null
    }
  },
  async created() {
    this.formatTables()
    this.getDatasetStatus()
  },
  beforeDestroy() {
    this.resetConfirm()
  },
  computed: {
    statusDisableSaveToReport() {
      return (
        this.statusOrgNotVerified ||
        this.statusBenchmarksPendingApproval ||
        this.isDatasetStatusLoading ||
        this.propReadOnly
      )
    },
    statusBenchmarksPendingApproval() {
      return !this.benchmarksApproved && !this.benchmarksSkipped
    },
    statusBenchmarksNotAvailable() {
      return (
        !this.benchmarksApproved &&
        this.benchmarksSkipped &&
        !this.statusNoScaleQuestions
      )
    },
    statusNoScaleQuestions() {
      const scaleQuestions = this.clientQuestions.filter(
        question => question.approved_data_type == "SCALE_1_TO_5"
      )
      return scaleQuestions.length === 0
    },
    statusBenchmarksAvailable() {
      return this.benchmarksApproved || this.statusBenchmarksPendingApproval
    }
  },
  methods: {
    async getDatasetStatus() {
      try {
        this.isDatasetStatusLoading = true
        const response = await this.$pigeonline.projects.get(
          BenchmarkingDataset,
          {
            dataset_id: this.dataset._id.$oid
          }
        )
        this.benchmarksApproved = response[0].status.benchmarksApproved
        this.benchmarksSkipped = response[0].status.benchmarksSkipped
        this.isDatasetStatusLoading = false
      } catch (e) {
        throw new Error(
          "ProjectAnalysisBenchmarking:getDatasetStatus " + e.message
        )
      }

      if (!this.benchmarksApproved) {
        this.numNotApprovedBenchmarks = this.benchmarks.filter(
          b => !b.approved
        ).length
        this.numTotalBenchmarks = this.benchmarks.length
      }
    },
    /************
     *
     * Confirmation modals
     *
     ***********/
    onSuccessfulSave() {
      this.setConfirmText({
        btn: "okay",
        title: "Benchmarks Saved",
        message: "Benchmarks successfully saved to report."
      })
      this.setConfirmType("success")
      this.setConfirmSourceComponent("benchmarking")
      this.setConfirmIsVisible(true)
    },
    /************
     *
     * Report generaton related functions
     *
     ***********/
    async save() {
      if (
        this.statusOrgNotVerified ||
        this.statusBenchmarksPendingApproval ||
        this.isDatasetStatusLoading
      )
        return

      this.saveToReport()

      if (!this.project.analysisCompleted) {
        this.project.updateStatus("analysisCompleted")
        this.project.benchmarkAnalysis = [] //TODO: allow customization of benchmark table
        this.saveProject(this.project)
      }
    },
    async saveToReport() {
      try {
        this.isLoading = true
        this.spinnerMessage = "saving to report, please wait"
        const organization = this.$store.getters["user/getOrganization"]
          ? this.$store.getters["user/getOrganization"].organization_name
          : ""

        await this.createReportIfEmpty(
          this.$options.filters.formatDate(this.project.last_modified_on),
          this.dataset.name,
          organization
        )

        // get report data
        const clientQuestionDetails = await this.fetchClientQuestionsDetails()
        const details = await this.fetchDetailedResults()

        // update report
        await this.updateReportWithBenchmarking(
          this.project.report,
          this.summaryResultsTable,
          this.summaryResultsTableHeaders,
          clientQuestionDetails,
          this.matchesDetails,
          this.dataset.segments,
          details,
          this.$store.getters["project/getBenchmarkSummaryResults"]
        )

        this.onSuccessfulSave()
        this.isLoading = false
      } catch (e) {
        console.error(e)
        throw new Error("ProjectAnalysisBenchmarking:saveToReport " + e.message)
      }
    }
  }
}
</script>
