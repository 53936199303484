const ID_TO_QUESTION = {
  "dataset-customer-group": "who are the customers in this data?",
  "dataset-specialize-group": "were they responding to specific service types?",
  "dataset-channels-group":
    "what channels of service delivery did they recieve?",
  "dataset-where-group": "where do you operate?",
  "dataset-gov-group": "is there a specific jurisdiction?",
  "dataset-typeservice-group":
    "what is the nature of the services they recieved?"
}

const ID_TO_SEGMENTS = {
  "dataset-customer-group": [
    {
      label: "Citizens and Businesses (external services)",
      value: "External Services"
    },
    { value: "Businesses (external services)" },
    { value: "Citizens (external services)" },
    {
      label: "Internal Clients/Staff (internal services)",
      value: "Internal Services"
    }
  ],
  "dataset-specialize-group": [
    { value: "Agriculture" },
    { value: "Community and Social Services" },
    { value: "Emergency Services" },
    { value: "Employment Services" },
    { value: "Finance and Payments" },
    { value: "Health" },
    { value: "HR and Compensation" },
    { value: "Immigration and Border Protection" },
    { value: "Investment" },
    { value: "Legal Aid" },
    { value: "Licenses and Permits" },
    { value: "Parks and Environment" },
    { value: "Policing" },
    { value: "Roads and Public Works" },
    { value: "Service Center Transactions" },
    { value: "Tax and Customs Services" },
    { value: "Transit" },
    { value: "Workplace Technology Services" },
    { value: "None" }
  ],
  "dataset-channels-group": [
    { value: "Email" },
    { value: "Mail" },
    { value: "In Person" },
    { value: "Telephone" },
    { value: "Website or app" },
    { value: "None" }
  ],
  "dataset-typeservice-group": [
    {
      value: "Informational",
      desc: "Requests for information or advice only"
    },
    {
      value: "Relational",
      desc:
        "Interactions with a longer duration or higher frequency of \
        interaction.  Or, service that are more difficult to define the \
        beginning or end of."
    },
    {
      value: "Transactional",
      desc:
        "Physical exchange between government and citizen of a tangible \
        good or service, with a short duration or low frequency of \
        interaction. Or, services that are easy to define the beginning \
        and end of."
    },
    {
      value: "Voluntary",
      desc: "Not required by government, obtained by choice."
    },
    { value: "Involuntary", desc: "Required by government." },
    {
      value: "Government-delivered",
      desc:
        "Interactions for which it is completely transparent that the \
        service is being provided by government."
    },
    {
      value: "Third party-delivered",
      desc: "Offered by government through a third or arms-length party."
    },
    {
      value: "Direct",
      desc: "Substantial interaction with municipal staff."
    },
    {
      value: "Indirect",
      desc: "Minimal or no interaction with municipal staf."
    },
    {
      value: "Social entitlement",
      desc:
        "Income benefits/supports that citizens have a right to receive \
        if they qualify."
    }
  ],
  "dataset-gov-group": [
    { value: "National/Federal" },
    { value: "Provincial/State" },
    { value: "Municipal/Regional" },
    { value: "Non-Governmental" }
  ],
  "dataset-where-group": [{ value: "International" }, { value: "Canada" }]
}

export { ID_TO_QUESTION, ID_TO_SEGMENTS }
