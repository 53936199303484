<template>
  <div class="theme-list__wrapper">
    <p class="theme-list__text-wrapper" v-if="text">
      <VisibleText>Select a theme to add</VisibleText>
      <span :class="['theme-list__text-input', type]">{{ text }}</span>
      {{ type }}.
    </p>
    <span class="theme-list__message">
      {{ message }}
    </span>
    <ul class="theme-list__rows">
      <li
        v-for="(theme, index) in themes.filter(t => t != null)"
        :key="index"
        class="theme-list__row"
      >
        <input
          :aria-label="theme.name"
          class="theme-list__row-radio-input"
          type="radio"
          :value="index"
          v-model="selected"
          :disabled="themeContains(index, text)"
          v-show="text"
        />
        <div
          :class="[
            'theme-list__row-content',
            {
              selected: selected === index,
              disabled: themeContains(index, text)
            }
          ]"
        >
          <span class="title">
            <InputEditable
              :ref="'input-editable-' + index"
              :value="theme.name"
              :isFocused="shouldFocusLastTheme && index == themes.length - 1"
              :select="() => {}"
              :uuid="`themelist-${type}-${index}`"
              @update="
                $emit('update-theme-title', { index: index, title: $event })
              "
            />
          </span>
          <KeywordsInput
            v-if="type == 'keyword'"
            class="ki-wrapper"
            :value="taggable(theme.keywords)"
            :validate="el => !isBannedKeyword(el, true)"
            @tag-added="add(index, $event)"
            @tag-removed="remove(index, $event)"
          />
          <span v-if="type == 'excerpt'" class="keywords-list">
            {{ theme.keywords.join(", ") }}
          </span>
          <span class="coverage">
            {{ (theme.coverage.percent || theme.coverage) | toFixed(1) }}%
            <VisibleText> coverage</VisibleText>
          </span>
          <span class="sentiment-score">
            {{
              generateSentimentText(
                (theme.sentiment.keywords_sentiment &&
                  theme.sentiment.keywords_sentiment.compound) ||
                  0
              )
            }}
          </span>
          <ButtonIconOnly
            icon="delete"
            :id="`delete-theme-${index}`"
            @click-handler="deleteTheme(index)"
          >
            <VisibleText>delete theme </VisibleText>
          </ButtonIconOnly>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// Components
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"
import InputEditable from "../UI/InputEditable.vue"
import KeywordsInput from "../Components/KeywordsInput.vue"

export default {
  name: "ThemeList",
  props: {
    type: {
      default: () => "keyword",
      type: String,
      required: true,
      validator: function(value) {
        return ["keyword", "excerpt"].indexOf(value) !== -1
      }
    },
    message: {
      type: String,
      default: function() {
        return ""
      }
    },
    themes: {
      type: Array,
      required: true
    },
    text: {
      type: String,
      required: false
    },
    shouldFocusLastTheme: {
      type: Boolean,
      default: function() {
        return false
      }
    },
    shouldAutoSelectLastTheme: {
      type: Boolean,
      default: function() {
        return false
      }
    }
  },
  components: {
    ButtonIconOnly,
    InputEditable,
    KeywordsInput
  },
  data() {
    return {
      selected: -1 // selected theme index
    }
  },
  computed: {
    bannedKeywords: function() {
      return this.$store.getters["analysisText/getBannedKeywords"]
    }
  },
  methods: {
    /* Utils */
    generateSentimentText(score) {
      return score >= 0
        ? `${(score * 100).toFixed(1)}% positive sentiment`
        : `${(score * -100).toFixed(1)}% negative sentiment`
    },
    isBannedKeyword(keyword, warnIfTrue = false) {
      const val =
        this.bannedKeywords.indexOf(keyword.trim().toLowerCase()) !== -1
      if (val && warnIfTrue) {
        alert(`Can't add \`${keyword}\`. REASON - banned keyword.`)
      }
      return val
    },
    taggable(keywords) {
      return keywords
        .filter(el => !this.isBannedKeyword(el))
        .map(function(text, index) {
          return { key: index, value: text }
        })
    },
    themeContains(index, text) {
      return this.themes[index][`${this.type}s`].indexOf(text) !== -1
    },
    allThemeContains(text) {
      return this.themes.every((_, index) => this.themeContains(index, text))
    },
    addToArray(el, array) {
      if (array.indexOf(el) !== -1) return
      array.push(el)
    },
    removeFromArray(el, array) {
      if (array.indexOf(el) === -1) return
      array.splice(array.indexOf(el), 1)
    },

    /* Theme methods */
    add(index, text) {
      if (this.themeContains(index, text)) return
      this.addToArray(text, this.themes[index][this.type + "s"])
      this.$emit("updated", true)
    },
    remove(index, text) {
      if (!this.themeContains(index, text)) return
      this.removeFromArray(text, this.themes[index][this.type + "s"])
      this.$emit("updated", true)
    },
    deleteTheme: async function(index) {
      this.themes.splice(index, 1)
      this.$forceUpdate()
      this.$emit("updated", true)
    }
  },
  watch: {
    selected: function(val) {
      if (val > -1) this.$emit("updated", true)
    },
    themes: function(val) {
      if (val && this.shouldAutoSelectLastTheme)
        this.selected = this.themes.filter(t => t != null).length - 1
    }
  }
}
</script>
