/***
 * Handle keyboard trap for modals or dialogs that overlay main content
 * and have an open/close function
 ***/

import trapFocus from "../trapFocus"

export default {
  data() {
    return {
      observer: null,
      lastFocused: null
    }
  },
  mounted() {
    // IMPORTANT: assume unique id (uid) set for container

    /** re-login modal, intercept and handle in its own mounted func **/
    if (!document.getElementById(`${this.uid}-container`)) return

    document
      .getElementById(`${this.uid}-container`)
      .addEventListener("keydown", this.handleKeydown, true)

    const onObserveElement = mutations => {
      mutations.forEach(mutation => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "hidden"
        ) {
          this.handleOpenAndClose(mutation.oldValue)
        }
      })
    }
    const target = document.getElementById(`${this.uid}-container`)
    const config = {
      attributes: true,
      attributeFilter: ["hidden"],
      attributeOldValue: true
    }
    this.observer = new MutationObserver(onObserveElement)
    this.observer.observe(target, config)

    // immediately add focus if initial state is show (not hidden)
    if (this.show) document.getElementById(`${this.uid}-container`).focus()
  },
  beforeDestroy() {
    this.$refs["trap-container"].removeEventListener(
      "keydown",
      this.handleKeydown
    )
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    handleKeydown($e) {
      trapFocus($e, document.getElementById(`${this.uid}-container`))
    },
    handleOpenAndClose(oldValue) {
      if (oldValue == "hidden") {
        this.lastFocused = document.activeElement
        this.$store.dispatch("globalModule/setModalOpen", true)
        document.getElementById(`${this.uid}-container`).focus()
      } else if (oldValue == null) {
        // if no last focused, set to main element
        if (!this.lastFocused) {
          this.lastFocused = document.getElementById("main-content")
        }
        this.$store.dispatch("globalModule/setModalOpen", false)
        this.lastFocused.focus()
        this.close()
      }
    },
    resetKeydownListener() {
      document
        .getElementById(`${this.uid}-container`)
        .removeEventListener("keydown", this.handleKeydown)
      document
        .getElementById(`${this.uid}-container`)
        .addEventListener("keydown", this.handleKeydown, true)
    }
  }
}
