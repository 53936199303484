<template>
  <div class="projects__progress">
    <span class="projects__progress-text">
      {{ PROJECT_STATUS_TO_DESCRIPTION[project.currStatus()] }}
      <span class="projecst__progress-text-percent">
        {{ project.percentStatus() }}
      </span>
    </span>
    <div class="projects__progress-bar" aria-hidden="true">
      <span
        :class="[
          'projects__progress-bar-status',
          progressClassName(project.currStatus())
        ]"
        :style="progressBarStyle"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectsItemProgress",
  props: {
    project: { type: Object }
  },
  data() {
    return {
      PROJECT_STATUS_TO_DESCRIPTION: {
        datasetSelected: "in progress",
        analysisCompleted: "in progress",
        reportCreated: "complete",
        started: "started"
      }
    }
  },
  computed: {
    progressBarStyle() {
      return `width: ${this.project.percentStatus()}`
    }
  },
  methods: {
    progressClassName(status) {
      let className = null

      switch (status) {
        case "datasetSelected":
        case "analysisCompleted":
          className = "inprogress"
          break
        case "reportCreated":
          className = "complete"
          break
        default:
          className = "inprogress"
      }

      return className
    }
  }
}
</script>
