// Mixins
import DatasetMixin from "@/utils/mixins/datasetMixin.js"

export default {
  mixins: [DatasetMixin],
  computed: {
    datasetSegments() {
      return this.$store.getters["datasetDetails/getDataset"].segments
    }
  },
  methods: {
    /***********
     *
     * Custom events
     *
     ***********/
    async addTag(category, tag) {
      if (!this.datasetProject.schema_version)
        await this.convertOldCustomSegments()
      this.datasetProject.addCustomSegment(category, tag)
      this.updateSegments(tag, {})
      await this.saveDatasetProject(this.datasetProject)

      // update questionnaire complete/incomplete status
      if (category !== "uncategorized") this.setQuestionAsComplete(category)
    },
    async removeTag(category, tag) {
      this.datasetProject.removeCustomSegment(category, tag)
      this.removeSegment(tag)
      await this.saveDatasetProject(this.datasetProject)

      // update questionnaire complete/incomplete status
      if (category !== "uncategorized") this.setQuestionAsIncomplete(category)
    },
    removeSegment(segment) {
      if (segment == "None") return
      /* eslint-disable no-unused-vars */
      const { [segment]: _, ...segments } = this.datasetSegments
      this.$store.dispatch("datasetDetails/updateDatasetSegments", segments)
    },
    updateSegments(segment, segmentObject = {}) {
      if (segment !== "None")
        this.$set(this.datasetSegments, segment, segmentObject)
    },
    /***********
     *
     * Questionnaire progress handlers
     *
     ***********/
    /**
     * add to step5 progress if segments are added to a category
     */
    setQuestionAsComplete(category) {
      if (this.datasetProject.progress.step5.indexOf(category) === -1)
        this.datasetProject.progress.step5.push(category)
    },
    /**
     * remove category from step5 progress if all inputs are unchecked
     * & custom input is empty
     */
    setQuestionAsIncomplete(category) {
      const checkboxes = document
        .getElementById(category)
        .querySelectorAll('[type="checkbox"]')
      const isAllUnchecked = [...checkboxes].every(c => !c.checked)
      const customSegments = this.datasetProject.customSegments[category]

      if (isAllUnchecked && (!customSegments || customSegments.length === 0)) {
        const indx = this.datasetProject.progress.step5.indexOf(category)
        this.datasetProject.progress.step5.splice(indx, 1)
      }
    },
    /***********
     *
     * Helper methods
     *
     ***********/
    /**
     * convert custom segments so its compatible with schema version 2.
     * in older versions custom tag were by default categorized as typeservice group.
     * assumes datasetProject is v1 and datasetProject.customSegment is a string
     */
    async convertOldCustomSegments() {
      if (!this.datasetProject.customSegments) {
        this.datasetProject.customSegments = {}
        this.datasetProject.schema_version = "2"
        await this.saveDatasetProject(this.datasetProject)
      } else {
        const customSegments = (" " + this.datasetProject.customSegments).slice(
          1
        ) // copy string
        this.datasetProject.customSegments = {}
        this.datasetProject.schema_version = "2"
        if (customSegments && customSegments.length > 0) {
          customSegments.split(",").forEach(seg => {
            this.datasetProject.addCustomSegment(
              "dataset-typeservice-group",
              seg
            )
          })
        }
        await this.saveDatasetProject(this.datasetProject)
      }
    }
  }
}
