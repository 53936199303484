<template>
  <MessageBlock class="banner-msg" message-type="warning">
    <template #description>
      <span class="desc">
        <VisibleText>
          Your account setup is not complete. Please click
        </VisibleText>
        <router-link :to="{ name: 'accountNextSteps' }">
          <VisibleText> finish setting up your account. </VisibleText>
        </router-link>
      </span>
    </template>
  </MessageBlock>
</template>

<script>
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

export default {
  name: "AccountSetupIncompleteMessage",
  components: {
    MessageBlock
  }
}
</script>
