<template>
  <Modal
    class="set-performance-type"
    :uid="`set-performance-type-${updateKey}`"
    :key="updateKey"
    :show="show"
    :reset-listener="resetListener"
    @close="$emit('close-modal')"
  >
    <template v-slot:header>
      Select performance type
    </template>
    <p class="set-performance-type__hint">
      Select the way you would like your performance score to be displayed.
    </p>
    <PerformanceTypes
      ref="performance-types"
      :selected-type="selectedType"
      @updated="handlePerformanceTypesUpdate"
    />
    <template v-slot:footer>
      <button
        class="set-performance-type__save-btn"
        @click="savePerformanceType"
        :disabled="isSavePerformanceTypesBtnDisabled"
      >
        <SvgIconDecorative
          icon="check"
          class="set-performance-type__save-btn-icon"
        />
        Save performance type
      </button>
    </template>
  </Modal>
</template>

<script>
// Components
import Modal from "@/components/UI/Modal.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import PerformanceTypes from "../Components/PerformanceTypes.vue"

// Models
import BenchmarkingProject from "@/models/BenchmarkingProjectModel"

export default {
  name: "SetPerformanceType",
  components: {
    Modal,
    SvgIconDecorative,
    PerformanceTypes
  },
  props: {
    project: {
      type: BenchmarkingProject
    },
    show: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      isSavePerformanceTypesBtnDisabled: true,
      updateKey: Date.now(),
      resetListener: false
    }
  },
  computed: {
    selectedType: function() {
      if (
        !this.project.settings.performanceType ||
        typeof this.project.settings.performanceType != "object"
      )
        return
      return this.project.settings.performanceType.selected
    }
  },
  methods: {
    handlePerformanceTypesUpdate() {
      this.isSavePerformanceTypesBtnDisabled = !this.$refs["performance-types"]
        .selected
    },
    savePerformanceType() {
      this.$emit(
        "save-performance-type",
        this.$refs["performance-types"].selected
      )
    }
  },
  watch: {
    show: function(val) {
      if (!val) return
      this.isSavePerformanceTypesBtnDisabled = true
      this.updateKey = Date.now()
      this.resetListener = !this.resetListener
    }
  }
}
</script>
