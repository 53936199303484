import section1 from "./section1"
import benchmarkSection from "./benchmarkSection"
import questionByQuestionSection from "./questionByQuesitonSection"
import driversSection from "./driversSection"
import performanceSection from "./performanceSection"
import surveyMethodologySection from "./surveyMethodologySection"

export default {
  section1,
  benchmarkSection,
  questionByQuestionSection,
  driversSection,
  performanceSection,
  surveyMethodologySection
}
