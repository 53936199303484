import logo from "@/styles/themes/iccs/Citizen-First.svg"
export default {
  name: "iccs",
  pageTitle: "Citizen First Analytics",
  colours: {
    "primary-brand-ui-colour": "#1254A6",
    "secondary-brand-ui-colour": "#F59936",
    primary: "#1254A6",
    secondary: "#BB5C21",
    "secondary-light": "#C75301"
  },
  images: {
    favicon: require("@/styles/themes/iccs/favicon.ico"),
    header: logo
  }
}
