<template>
  <div class="dataset-table__match-wrapper">
    <!--------------------------------------
     | read only
    ---------------------------------------->
    <template v-if="readOnly && globalMatch">
      <span class="dataset-table__match-title">
        {{ globalMatch.global_question_title }}
      </span>
      <span class="dataset-table__match-text">
        {{ globalMatch.global_question_text }}
      </span>
    </template>

    <!--------------------------------------
     | already has a global match
    ---------------------------------------->
    <template v-if="!readOnly && globalMatch">
      <span class="dataset-table__match-title">
        {{ globalMatch.global_question_title }}
      </span>
      <span class="dataset-table__match-text">
        {{ globalMatch.global_question_text }}
      </span>
      <HasPermission to="update-dataset">
        <button
          tabindex="-1"
          class="dataset-table__match-action-btn remove"
          @click="removeMatch()"
          @keyup.left.stop="goLeftOrUp"
          @keyup.up.stop="goLeftOrUp"
          @keyup.right.stop="goRightOrDown"
          @keyup.down.stop="goRightOrDown"
        >
          <SvgIconDecorative icon="remove" />
          <span><VisibleText>remove</VisibleText></span>
        </button>
        <button
          tabindex="-1"
          class="dataset-table__match-action-btn modify"
          @click="openSelector()"
          @keyup.left.stop="goLeftOrUp"
          @keyup.up.stop="goLeftOrUp"
          @keyup.right.stop="goRightOrDown"
          @keyup.down.stop="goRightOrDown"
        >
          <SvgIconDecorative icon="edit" />
          <span><VisibleText>modify</VisibleText></span>
        </button>
      </HasPermission>
    </template>

    <!--------------------------------------
     | suggested global match
    ---------------------------------------->
    <SkeletonBox height="3rem" v-if="isLoadingSuggestedMatches" />
    <template v-if="!readOnly && !globalMatch && suggestedMatch">
      <span class="dataset-table__match-title">
        <div class="dataset-table__match-suggested" title="suggested match">
          <span class="sr-only">
            <VisibleText>suggested match</VisibleText>
          </span>
        </div>
        {{ suggestedMatch.question_title }}
      </span>
      <span class="dataset-table__match-text">
        {{ suggestedMatch.question_text }}
      </span>
      <HasPermission to="update-dataset">
        <button
          tabindex="-1"
          class="dataset-table__match-action-btn approve"
          @click="approveSuggestedMatch()"
          @keyup.left.stop="goLeftOrUp"
          @keyup.up.stop="goLeftOrUp"
          @keyup.right.stop="goRightOrDown"
          @keyup.down.stop="goRightOrDown"
        >
          <SvgIconDecorative icon="check" />
          <VisibleText>approve</VisibleText>
        </button>
        <button
          tabindex="-1"
          class="dataset-table__match-action-btn modify"
          @click="openSelector()"
          @keyup.left.stop="goLeftOrUp"
          @keyup.up.stop="goLeftOrUp"
          @keyup.right.stop="goRightOrDown"
          @keyup.down.stop="goRightOrDown"
        >
          <SvgIconDecorative icon="edit" />
          <span><VisibleText>modify</VisibleText></span>
        </button>
      </HasPermission>
    </template>

    <!--------------------------------------
     | no match found
    ---------------------------------------->
    <button
      v-if="
        !readOnly &&
          !isLoadingSuggestedMatches &&
          !suggestedMatch &&
          !globalMatch
      "
      tabindex="-1"
      class="dataset-table__match-action-btn add"
      @click="openSelector()"
    >
      <VisibleText>add global match</VisibleText>
    </button>
  </div>
</template>

<script>
// Components
import SkeletonBox from "@/components/UI/LoadingSkeletonBox.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIcon"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"

export default {
  name: "DatasetTableGlobalMatch",
  mixins: [DatasetDetailsMixin],
  props: {
    clientQuestion: {
      default: () => {},
      type: Object
    },
    getSuggestedMatches: {
      default: false,
      type: Boolean
    },
    readOnly: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      benchmarkId: null,
      suggestedMatch: null,
      isLoadingSuggestedMatches: false
    }
  },
  components: {
    SkeletonBox,
    SvgIconDecorative
  },
  computed: {
    globalMatch() {
      return this.matchesDetails[this.clientQuestion._id.$oid]
    }
  },
  methods: {
    openSelector() {
      this.$emit("modifyGlobalMatch")
    },
    setSuggestedMatch(globalQuestionId) {
      this.suggestedMatch = this.globalQuestions.find(
        question => question._id.$oid === globalQuestionId
      )
      this.setSuggestedMatches({
        client_question_id: this.clientQuestion._id.$oid,
        global_question_id: globalQuestionId
      })
    },
    goLeftOrUp($e) {
      if ($e.target.previousElementSibling) {
        $e.target.previousElementSibling.focus()
      }
    },
    goRightOrDown($e) {
      if ($e.target.nextElementSibling) {
        $e.target.nextElementSibling.focus()
      }
    },
    async approveSuggestedMatch() {
      if (!this.suggestedMatch) return
      const match = await this.newMatch(
        this.clientQuestion,
        this.suggestedMatch._id.$oid,
        this.suggestedMatch._id.$oid
      )
      this.benchmarkId = match._id.$oid
    },
    async fetchSuggestedMatches() {
      this.isLoadingSuggestedMatches = true
      const benchmarkGroupId = this.$store.getters[
        "datasetWizard/getBenchmarkGroup"
      ].id
      const data = {
        client_questions_ids: [this.clientQuestion._id.$oid],
        benchmark_group: benchmarkGroupId,
        number_of_questions: 1
      }
      const response = await this.BENCHMARKING_SERVICE.recommendedGlobalQuestions(
        data
      )
      const score = response[0].top_global_questions[0][1]

      // only if the match is significant
      if (score > 0.35)
        this.setSuggestedMatch(response[0].top_global_questions[0][0])
      this.$emit("finishFetchingSuggestedMatches")
      this.isLoadingSuggestedMatches = false
    },
    async removeMatch() {
      await this.deleteBenchmarkMatch(this.benchmarkId)
      // update client question store
      const clientQ = await this.DATASETS_SERVICE.clientQuestions(
        this.clientQuestion._id.$oid
      )
      this.updateClientQuestion(clientQ)
      // reset values
      this.benchmarkId = null
      this.suggestedMatch = null
      this.reloadBenchmarks()
    }
  },
  watch: {
    globalMatch: {
      immediate: true,
      handler(val) {
        if (this.matchesDetails && val) {
          this.benchmarkId = this.matchesDetails[
            this.clientQuestion._id.$oid
          ].benchmark_id
        }
      }
    },
    getSuggestedMatches: function(val) {
      if (val && !this.globalMatch) this.fetchSuggestedMatches()
    }
  }
}
</script>
