<template functional>
  <div
    :class="[
      `message__block--${props.messageType}`,
      `message__block`,
      data.class,
      data.staticClass
    ]"
  >
    <p class="message__block__details">
      <span class="icon-wrapper">
        <svg class="svg-icon icon" focusable="false" role="img">
          <title>{{ props.messageType }}</title>
          <use :xlink:href="`#${props.messageType}`" aria-hidden="true" />
        </svg>
      </span>
      <VisibleText class="title" v-if="props.title">
        {{ props.title }}
      </VisibleText>
      <!-- allow override VisibleText if there are nested elements -->
      <slot name="description">
        <VisibleText class="desc">
          <slot></slot>
        </VisibleText>
      </slot>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    messageType: {
      default: "information",
      type: String
    },
    title: {
      default: null,
      type: String
    }
  }
}
</script>
