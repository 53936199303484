export default {
  data() {
    return {
      isReadOnly: false
    }
  },
  methods: {
    bkCompatiblePk(r) {
      return ["string", "number"].includes(typeof r) ? r : r.pk || r.id || -1
    },
    currUserProfileId() {
      return this.$store.getters["user/getProfile"]
        ? this.$store.getters["user/getProfile"].django_ref_id
        : -1
    },
    checkIsReadOnly(project) {
      // is the owner, not readonly
      if (project.owner == this.currUserProfileId()) return false
      const sharedUser = this.getSharedUser(project)
      // not a shared user
      if (!sharedUser) return true
      // shared but does not have edit rights
      return !(sharedUser.perms && sharedUser.perms.includes("edit"))
    },
    getSharedUser(project) {
      const profileEmail = this.$pigeonline
        .getUserEmail()
        .trim()
        .toLowerCase()
      let sharedUser = project.shared.filter(
        r =>
          this.bkCompatiblePk(r) == this.currUserProfileId ||
          this.bkCompatiblePk(r) == profileEmail
      )[0]
      return sharedUser
    }
  }
}
