import QuestionByQuestion from "../helpers/quesitonByQuestion"

const randomOrgLabel = orgPrefix => {
  return `${orgPrefix} (q${Math.floor(Math.random() * (20 - 1)) + 1})`
}

const questionByQuestionSection = (
  userQuestionDetails,
  matchesDetails,
  segments,
  fullDetails
) => {
  const qbyqIconSrc = "/files/ReportIcons/qByq.svg"
  let qbyqHeading = `<img src="${qbyqIconSrc}" class="report__header-icon" alt=""/>\
  QUESTION BY QUESTION RESULTS`

  const headings = [
    {
      type: "heading",
      content: qbyqHeading,
      id: "section-title-question-by-question",
      meta: {
        level: "h1",
        pdfConfig: {
          pageBreak: "before"
        }
      }
    }
  ]

  const description = {
    type: "text",
    content:
      "For every CMT question, this section provides summary information on how your clients responded. It also provides comparison data against each benchmarking group.",
    id: "question-by-question-desc"
  }

  const idStore = {}
  let orgNo = 1

  fullDetails.forEach(question => {
    let idToIndxStore = new Map()

    question.average_values.forEach((org, indx) => {
      if (org.is_user) {
        org.org_id = "Your org"
      } else if (idToIndxStore.has(org.data_set_id)) {
        /**
         * organization already exists, update the org name to include
         * a generic question marker (random number)
         **/

        // update first occurance of dataset
        let firstOccurance =
          question.average_values[idToIndxStore.get(org.data_set_id)[0]].org_id
        if (!firstOccurance.search(/(\(q)*/g)) {
          question.average_values[
            idToIndxStore.get(org.data_set_id)[0]
          ].org_id = randomOrgLabel(idStore[org.data_set_id])
        }

        // set this orgs details and add index to index array
        org.org_id = randomOrgLabel(idStore[org.data_set_id])
        idToIndxStore.get(org.data_set_id).push(indx)
      } else {
        idStore[org.data_set_id] = "Org" + orgNo
        idToIndxStore.set(org.data_set_id, [indx])
        org.org_id = idStore[org.data_set_id]
        orgNo++
      }
    })

    idToIndxStore.clear()
  })

  let questionByQuestionObject = QuestionByQuestion(
    userQuestionDetails,
    matchesDetails,
    segments,
    fullDetails
  )

  return {
    orgs: idStore,
    nodes: [...headings, description, ...questionByQuestionObject]
  }
}

export default questionByQuestionSection
