<template>
  <table>
    <caption :class="{ 'visually-hidden': hideCaption }">
      <slot name="caption">
        {{ caption }}
      </slot>
    </caption>
    <thead>
      <slot name="thead" :columnHeaders="columnHeaders">
        <tr>
          <component
            v-for="(header, index) in columnHeaders"
            :is="header && header.length > 0 ? 'th' : 'td'"
            :key="`table-column-header-${index}`"
            :scope="header && header.length > 0 ? 'col' : null"
          >
            <slot name="header" :header="header" :sortable="sortable">
              <span
                v-if="sortable && sortable[header]"
                tabindex="0"
                @click="$emit(`${sortable[header]}`, header)"
                style="cursor: pointer; user-select: none;"
              >
                {{ header }}
                <SvgIconDecorative
                  v-if="sortableOrder && sortableOrder[header] != undefined"
                  :style="{
                    visibility:
                      sortableOrder[header] == 0 ? 'hidden' : 'visible',
                    width: '1rem'
                  }"
                  :icon="sortableOrder[header] < 0 ? 'arrowUp' : 'arrowDown'"
                />
              </span>
              <span v-else> {{ header }} </span>
            </slot>
          </component>
        </tr>
      </slot>
    </thead>
    <tbody>
      <slot name="tbody" :rowData="rowData">
        <tr v-for="(row, index) in rowData" :key="`table-row-${index}`">
          <slot :row="row" :index="index">
            <td
              v-for="key in rowKeys"
              :key="`table-row-${index}-cell-${key}`"
              v-html="row[key]"
            ></td>
          </slot>
        </tr>
      </slot>
    </tbody>
  </table>
</template>

<script>
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

//TODO: implement row headers
export default {
  name: "Table",
  components: {
    SvgIconDecorative
  },
  props: {
    hideCaption: {
      type: Boolean,
      required: false,
      default: false
    },
    caption: {
      type: String,
      required: true
    },
    columnHeaders: {
      type: Array,
      required: false,
      default: () => []
    },
    rowData: {
      type: Array,
      required: true,
      default: () => []
    },
    rowKeys: {
      type: Array,
      required: false,
      default: () => []
    },
    sortable: {
      type: Object,
      required: false,
      default: () => {}
    },
    sortableOrder: {
      type: Object,
      required: false,
      default: () => {}
    }
  }
}
</script>
