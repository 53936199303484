<template>
  <span
    ref="curr-cell"
    class="list-grid-item"
    tabindex="-1"
    :aria-describedby="ariaDescribedby"
    :data-row="row"
    :data-col="col"
    :data-focus="focusCell ? 'cell' : 'default'"
    :role="col === '0' ? 'rowheader' : 'gridcell'"
    @keyup.down.stop="goDown"
    @keyup.right.stop="goRight"
    @keyup.left.stop="goLeft"
    @keyup.up.stop="goUp"
    @keyup.enter="enterWidget"
    @keyup.esc="exitWidget"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "ListGridItem",
  props: {
    col: {
      default: () => "0",
      type: String,
      required: true
    },
    headers: {
      default: () => [],
      type: Array,
      required: true
    },
    row: {
      default: () => 0,
      type: Number,
      required: true
    },
    rowuid: {
      default: () => "",
      type: String
    },
    // force the focus onto the cell
    focusCell: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      navigationDisabled: false
    }
  },
  mounted() {
    // set tabindex 0 if its the first cell (entry point)
    if (this.row == 0 && this.col == 0) {
      let widget = this.$refs["curr-cell"].querySelector(
        "button, a, input[type=checkbox], input[type=radio]"
      )
      if (widget) widget.tabIndex = "0"
      else this.$refs["curr-cell"].tabIndex = "0"
    }
  },
  computed: {
    ariaDescribedby() {
      const header = this.$options.filters.replaceSpaceWithDash(
        this.headers[this.col]
      )
      return this.rowuid.length > 0 ? `${this.rowuid} ${header}` : header
    }
  },
  methods: {
    enterWidget() {
      let widgets = this.$refs["curr-cell"].querySelectorAll(
        "textarea, select, button, a, input[type=text]"
      )
      if (widgets.length > 0) {
        widgets[0].focus()
        if (widgets.length > 1 || widgets[0].nodeName == "SELECT") {
          this.navigationDisabled = true
        }
      }
    },
    exitWidget() {
      if (!this.navigationDisabled) return
      this.navigationDisabled = false
      this.$refs["curr-cell"].focus()
    },
    focusNextCell(nextCell) {
      let widget = nextCell.querySelector(
        "button, a, input[type=checkbox], input[type=radio], input[type=text], textarea"
      )
      if (widget && nextCell.getAttribute("data-focus") !== "cell") {
        widget.tabIndex = "0"
        widget.focus()
      } else {
        nextCell.tabIndex = "0"
        nextCell.focus()
      }
    },
    goDown($e) {
      if (this.navigationDisabled) return
      let cellSelector = `span[data-col='${this.col}']`
      let nextRow = this.$refs["curr-cell"].parentElement.nextElementSibling

      if (nextRow) {
        $e.target.tabIndex = "-1"
        let nextCell = nextRow.querySelector(cellSelector)
        this.focusNextCell(nextCell)
      }
    },
    goLeft($e) {
      if (this.navigationDisabled) return
      let nextCell = this.$refs["curr-cell"].previousElementSibling
      if (nextCell) {
        $e.target.tabIndex = "-1"
        this.focusNextCell(nextCell)
      }
    },
    goRight($e) {
      if (this.navigationDisabled) return
      let nextCell = this.$refs["curr-cell"].nextElementSibling
      if (nextCell) {
        $e.target.tabIndex = "-1"
        this.focusNextCell(nextCell)
      }
    },
    goUp($e) {
      if (this.navigationDisabled) return
      let cellSelector = `span[data-col='${this.col}']`
      let nextRow = this.$refs["curr-cell"].parentElement.previousElementSibling
      if (nextRow) {
        $e.target.tabIndex = "-1"
        let nextCell = nextRow.querySelector(cellSelector)
        this.focusNextCell(nextCell)
      }
    }
  }
}
</script>
