// Components
import Modal from "@/components/UI/Modal.vue"
import ThemeList from "../Components/ThemeList.vue"

// Mixins
import AnalysisTextMixin from "@/utils/mixins/analysisTextMixin.js"

// Library
import _ from "lodash"

export default {
  data() {
    return {
      themeList: [],
      unsavedChanges: false,
      unwatchThemeListListener: null,
      updateKey: Date.now(),
      resetListener: false,
      shouldFocusLastTheme: false,
      shouldAutoSelectLastTheme: false
    }
  },
  mixins: [AnalysisTextMixin],
  components: {
    Modal,
    ThemeList
  },
  computed: {
    themeListRef: function() {
      return `theme-list-${this._uid}`
    }
  },
  mounted() {
    this.reset()
  },
  methods: {
    getThemeListRef() {
      return this.$refs[this.themeListRef]
    },
    async closeModal() {
      if (this.unsavedChanges) {
        if (
          !confirm(
            "You have unsaved changes. Are you sure you want to continue?"
          )
        ) {
          return
        }
      }
      this.unsavedChanges = false
      this.$emit("closeModal")

      // refetch theme responses
      if (
        this.selectedThemeIndex !== -1 &&
        this.requiresRefetchingThemeResponses
      ) {
        this.setShowSpinner(true)
        this.setSelectedThemeResponses(await this.fetchThemeResponses())
        this.setShowSpinner(false)
      }
    },

    save: async function() {
      this.setShowSpinner(true)

      // save text into selected theme
      const text = this.getThemeListRef().text
      const selected = this.getThemeListRef().selected
      const type = this.getThemeListRef().type
      const isSelectMode = text && selected >= 0
      if (isSelectMode) {
        this.themeList[selected][`${type}s`].push(text)
      }

      // save themes
      try {
        await this.saveThemes(this.themeList)

        // success
        alert("Your changes have been saved successfully.")

        // close modal if save coming from select or auto create mode
        if (isSelectMode) this.closeModal()
      } catch (e) {
        // error
        alert(
          "An error occured processing your request. Please try again later."
        )
        console.error("themeListMixin.js:save: " + e)
      } finally {
        this.setShowSpinner(false)
      }
    },

    /* Theme methods */
    getValidatedThemeTitle(newTitle, oldTitle, themeTitles) {
      if (typeof newTitle !== "string" || newTitle.trim() === "") {
        newTitle = "unnamed theme"
      }
      themeTitles.splice(themeTitles.indexOf(oldTitle.trim().toLowerCase()), 1)

      let count = 1
      let loop = themeTitles.includes(newTitle.trim().toLowerCase())
      while (loop) {
        if (themeTitles.includes(newTitle.trim().toLowerCase() + count)) {
          count += 1
          continue
        }
        newTitle = newTitle + count
        break
      }
      return newTitle
    },
    updateThemeTitle(theme, title, themeTitles) {
      if (!themeTitles) {
        themeTitles = this.themeList.map(item => item.name.trim().toLowerCase())
      }
      theme.name = this.getValidatedThemeTitle(title, theme.name, themeTitles)

      // force update
      this.$forceUpdate()
    },
    createTheme(title = "unnamed theme", keywords = []) {
      this.shouldFocusLastTheme = true
      this.shouldAutoSelectLastTheme = true
      this.themeList = [
        ...this.themeList,
        {
          name: "",
          keywords: keywords,
          coverage: {},
          excerpts: [],
          sentiment: {},
          notes: []
        }
      ]
      this.updateThemeTitle(this.themeList[this.themeList.length - 1], title)
    },
    reset() {
      if (this.unwatchThemeListListener) this.unwatchThemeListListener()

      this.themeList = _.cloneDeep(this.themes)
      this.unsavedChanges = false
      this.updateKey = Date.now()
      this.resetListener = !this.resetListener
      this.shouldFocusLastTheme = false
      this.shouldAutoSelectLastTheme = false

      this.unwatchThemeListListener = this.$watch(
        "themeList",
        function(val) {
          if (!val) return
          this.unsavedChanges = true
        }.bind(this),
        {
          deep: true
        }
      )
    }
  },
  watch: {
    themes: function(val) {
      if (val) this.reset()
    },
    show: function(val) {
      if (val) this.reset()
    }
  }
}
