<template>
  <div>
    <!--------------------------------------
    | read only
    ---------------------------------------->
    <template v-if="readOnly && clientQuestion.approved_data_type">
      {{
        TYPE_TO_READABLE[clientQuestion.approved_data_type] ||
          SCALE_TYPE_TO_READABLE[clientQuestion.approved_data_type]
      }}
    </template>

    <!--------------------------------------
    | editing
    ---------------------------------------->
    <template v-if="!readOnly">
      <div class="select-wrapper">
        <label
          class="sr-only"
          :for="`edit-data-type-${clientQuestion._id.$oid}`"
        >
          <VisibleText>select data type</VisibleText>
        </label>
        <select
          :class="['select', { 'dataset-table__type-unknown': unknown }]"
          :id="`edit-data-type-${clientQuestion._id.$oid}`"
          v-model="dataType"
          tabindex="-1"
        >
          <option disabled value="">select data type</option>
          <optgroup label="Data Types">
            <option
              v-for="type in Object.keys(TYPE_TO_READABLE)"
              :key="type"
              :value="type"
            >
              {{ TYPE_TO_READABLE[type] }}
            </option>
          </optgroup>
          <optgroup label="Scale Types">
            <option
              v-for="scale in Object.keys(SCALE_TYPE_TO_READABLE)"
              :key="scale"
              :value="scale"
            >
              {{ SCALE_TYPE_TO_READABLE[scale] }}
            </option>
          </optgroup>
        </select>
      </div>
      <span v-if="unknown">
        <SvgIconMeaningful
          class="dataset-table__type-icon"
          icon="question"
          title="unknown data type"
        />
      </span>
    </template>
  </div>
</template>

<script>
// Components
import SvgIconMeaningful from "@/components/UI/Svg/SvgIconMeaningful.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import DataTypeMixin from "@/components/Dataset/DatasetTable/mixins/datatypeMixin.js"

export default {
  name: "DatasetTableDataType",
  mixins: [DatasetDetailsMixin, DataTypeMixin],
  components: {
    SvgIconMeaningful
  },
  props: {
    clientQuestion: {
      default: () => {},
      type: Object,
      required: true
    },
    readOnly: {
      default: false,
      type: Boolean
    },
    getSuggestedDataType: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      dataType: "",
      suggestedMatch: false,
      unknown: false
    }
  },
  created() {
    this.setDataType()
  },
  methods: {
    setDataType() {
      if (
        this.clientQuestion.approved_data_type &&
        this.clientQuestion.approved_data_type.length > 0
      )
        this.dataType = this.clientQuestion.approved_data_type
    },
    async fetchSuggestedType() {
      const validTypes = [
        ...Object.keys(this.SCALE_TYPE_TO_READABLE),
        ...Object.keys(this.TYPE_TO_READABLE)
      ]
      const suggestedObj = await this.fetchSuggestedTypes(
        this.dataset._id.$oid,
        [this.clientQuestion._id.$oid]
      )
      const suggested = Object.entries(suggestedObj)
      this.setSuggestedTypes({
        client_question_id: suggested[0][0],
        suggested_type: suggested[0][1]
      })
      /**
       * If CAT_OR_SCALE, suggested or current type that is not included
       * in our valid types - set as unknown match
       * TODO: more complex type warnings (ie. its 1_TO_11 is same as 0_to_10)
       * If there is no approved data type, assume suggestion is correct
       */
      if (
        suggested[0][1] === "CAT_OR_SCALE" ||
        !validTypes.includes(suggested[0][1]) ||
        (this.clientQuestion.approved_data_type &&
          !validTypes.includes(this.clientQuestion.approved_data_type))
      ) {
        this.unknown = true
      } else if (!this.clientQuestion.approved_data_type) {
        this.suggestedMatch = true
        this.dataType = suggested[0][1]
      }
    }
  },
  watch: {
    dataType: {
      immediate: false,
      handler() {
        // can update data type if you have permission or its different from approved type
        if (
          this.$pigeonline.permissions.hasPermission("update-dataset") &&
          this.dataType != this.clientQuestion.approved_data_type &&
          !["CAT_OR_SCALE"].includes(this.dataType)
        ) {
          this.saveDataType(this.clientQuestion, this.dataType)
          this.unknown = false
        }
      }
    },
    "clientQuestion.approved_data_type": function() {
      this.setDataType()
    },
    getSuggestedDataType: function(val) {
      if (val) this.fetchSuggestedType()
    }
  }
}
</script>
