<template>
  <div>
    <Table
      class="questions__table"
      :caption="caption"
      :hide-caption="false"
      :row-data="questions"
      :column-headers="
        columnHeaders.filter(
          el => shouldDisplayValues || el.trim().toLowerCase() != 'value'
        )
      "
      :sortable="sortable"
      :sortable-order="sortableOrder"
      @sortLocaleCompare="sortLocaleCompare"
    >
      <template v-slot:header="{ header }">
        <template v-if="header == 'SELECT_ALL'">
          <input
            type="checkbox"
            class="questions__table-checkbox"
            name="select-all-questions"
            :id="`chkbox-select-all-${id}`"
            v-model="selectAllFlag"
            @change="toggleSelectAll"
          />
          <label :for="`chkbox-select-all-${id}`" class="sr-only">
            <VisibleText>select all</VisibleText>
          </label>
        </template>
      </template>
      <template slot-scope="{ row }">
        <td class="td-question-select">
          <input
            type="checkbox"
            class="questions__table-checkbox"
            name="question"
            :id="`select-chkbox-${id}-${row.id}`"
            :value="row.id"
            :checked="row.options.selected"
            @input="toggleSelect(row)"
            :disabled="row.options.disabled"
          />
          <label :for="`select-chkbox-${id}-${row.id}`" class="sr-only">
            <VisibleText>select </VisibleText>{{ row.name }}
          </label>
        </td>
        <td class="td-question-select-values" v-if="shouldDisplayValues">
          <select
            class="questions__table-select"
            v-model="row.valuesForComparison"
            @change="select(row)"
            :aria-label="`${row.question_title} values`"
            :disabled="row.options.disabled"
          >
            <option :value="valuesOf(row)">{{
              row.options.disabled ? "n/a" : "Any value"
            }}</option>
            <option
              v-for="(value, index) in valuesOf(row)"
              :key="`${row.id}-value-${index}`"
              :value="[value]"
            >
              {{ value }}
            </option>
          </select>
        </td>
        <td class="td-question-title" data-label="title">
          <span class="questions__table-title">
            {{ row.question_title }}
          </span>
        </td>
        <td class="td-question-wording" data-label="wording">
          <span class="questions__table-wording">
            {{ row.question_text }}
          </span>
        </td>
        <td class="td-question-data-type" data-label="data type">
          <span class="questions__table-data-type">
            {{ TYPE_TO_READABLE[row.approved_data_type] }}
          </span>
        </td>
      </template>
    </Table>
  </div>
</template>

<script>
// Components
import Table from "@/components/UI/Table.vue"

export default {
  name: "TableQuestions",
  components: {
    Table
  },
  props: {
    caption: {
      type: String,
      default: () => "Questions"
    },
    questionsList: {
      type: Array
    },
    shouldDisplayValues: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      TYPE_TO_READABLE: {
        OPEN_ENDED: "text",
        NUMERIC: "numeric",
        ID: "id",
        CAT: "category",
        SCALE_1_TO_5: "5-point scale",
        DATE: "date",
        OTHER: "other",
        SCALE: "scale"
      },
      questions: [],
      columnHeaders: [
        "SELECT_ALL",
        "Value",
        "Question",
        "Wording",
        "Data Type"
      ],
      sortable: {
        Question: "sortLocaleCompare",
        Wording: "sortLocaleCompare",
        "Data Type": "sortLocaleCompare"
      },
      sortableOrder: {
        Question: 0,
        Wording: 0,
        "Data Type": 0
      },
      sortableColsToAtts: {
        Question: "question_title",
        Wording: "question_text",
        "Data Type": "approved_data_type"
      },
      selectAllFlag: false
    }
  },
  computed: {
    id() {
      return this._uid
    },
    selectedQuestions() {
      return this.questions.filter(el => el.options.selected)
    }
  },
  mounted() {
    this.questions = [...this.questionsList].map(
      function(el) {
        el.options.selected = false
        return el
      }.bind(this)
    )
  },
  methods: {
    valuesOf(question) {
      return question.unique_values
        .filter(el => el.is_valid)
        .map(el => el.value)
    },
    select(question) {
      question.options.selected = true
      this.$emit("updated", true)
    },
    toggleSelect(question) {
      question.options.selected = !question.options.selected
      this.$emit("updated", true)
    },
    toggleSelectAll() {
      this.questions.map(
        function(question) {
          question.options.selected =
            !question.options.disabled && this.selectAllFlag
        }.bind(this)
      )
      this.$emit("updated", true)
    },
    sortLocaleCompare(colName) {
      // reset sort order of other columns
      Object.keys(this.sortableOrder)
        .filter(k => k != colName)
        .map(k => (this.sortableOrder[k] = 0))

      // initalize sortable order if reset
      if (this.sortableOrder[colName] == 0) {
        this.sortableOrder[colName] = -1
      } else {
        this.sortableOrder[colName] *= -1 // reverse sort order
      }
      this.questions = [...this.questionsList].sort(
        (a, b) =>
          this.sortableOrder[colName] *
          a[this.sortableColsToAtts[colName]].localeCompare(
            b[this.sortableColsToAtts[colName]]
          )
      )
    }
  }
}
</script>
