<template>
  <section class="share__add-users-wrapper" style="position: relative">
    <div v-click-outside="hideSuggestedUsers">
      <input
        aria-label="add people"
        type="text"
        class="share__add-users-input"
        placeholder="Add people"
        v-model="input"
        @focus="showSuggestedUsers = true"
        ref="add-user-input"
      />
      <div class="share__add-users-suggested" v-if="isSuggestedUsersVisible">
        <ul class="share__add-users-list">
          <li
            class="share__add-users-list-item"
            v-for="user in suggestedUsers"
            :key="user.value"
          >
            <button
              class="user-details user-btn"
              @click="addSuggestedUser(user)"
            >
              <p class="user-label">{{ user.label }}</p>
              <p class="user-value">{{ user.value }}</p>
            </button>
          </li>
          <li
            class="share__add-users-list-item add-new"
            v-if="!isDuplicateEmail(clean(input)) || suggestedUsers.length == 0"
          >
            <button class="user-details user-btn" @click="addNewUser">
              <p class="user-label">
                <VisibleText>Add </VisibleText>
                `{{ clean(input) }}`
              </p>
              <p class="user-value">
                <VisibleText>An invitation email will be sent.</VisibleText>
              </p>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="share__add-users-main" v-if="!isSuggestedUsersVisible">
      <div
        class="share__add-users-selected-empty"
        v-if="selectedUsers.length == 0"
      >
        <p>{{ label }}</p>
      </div>
      <ul class="share__add-users-list">
        <li
          class="share__add-users-list-item"
          v-for="(user, index) in selectedUsers"
          :key="'new__' + index"
        >
          <div class="user-row">
            <div class="user-details">
              <p class="user-label">{{ user.label }}</p>
              <p class="user-value">{{ user.value }}</p>
            </div>
            <div class="user-actions">
              <label
                class="user-actions__checkbox-notify"
                :for="'checkbox-notify__' + index"
                v-if="!user.notifyDisabled"
              >
                <input
                  type="checkbox"
                  :name="'checkbox-notify__' + index"
                  v-model="user.notify"
                />
                <VisibleText>Notify</VisibleText>
              </label>
              <span v-else>
                <VisibleText>An invitation email will be sent.</VisibleText>
              </span>
              <ButtonIconOnly
                icon="remove"
                :id="`remove-${user.label}`"
                @click-handler="removeUser(user)"
              >
                <VisibleText>remove user</VisibleText>
              </ButtonIconOnly>
            </div>
          </div>
        </li>
      </ul>
      <ul class="share__add-users-list shared" v-if="sharedUsers.length > 0">
        <li class="share__add-users-list-heading">
          <VisibleText>Shared </VisibleText>({{ sharedUsers.length }})
        </li>
        <li
          class="share__add-users-list-item"
          v-for="(user, index) in sharedUsers"
          :key="'shared__' + index"
        >
          <div class="user-row">
            <div class="user-details">
              <p class="user-label">{{ user.label }}</p>
              <p class="user-value">{{ user.value }}</p>
            </div>
            <div class="user-actions">
              <ButtonIconOnly
                icon="remove"
                :id="`remove-notcurr-${user.label}`"
                v-if="!isCurrentUser(user)"
                @click-handler="removeUser(user)"
              >
                <VisibleText>remove user</VisibleText>
              </ButtonIconOnly>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

export default {
  name: "BaseSharing",
  components: {
    ButtonIconOnly
  },
  props: {
    label: {
      type: String,
      default: function() {
        return "Add people to start sharing."
      }
    },
    shared: {
      type: Array
    }
  },
  data() {
    return {
      users: [],
      input: "",
      selectIndex: 0,
      showSuggestedUsers: false
    }
  },
  computed: {
    selectedUsers() {
      return this.users
        .filter(user => user.type == "selected")
        .sort((a, b) => b.selectIndex - a.selectIndex)
    },
    sharedUsers() {
      return this.users
        .filter(user => user.type == "shared")
        .sort((a, b) => this.sharedIndex(b) - this.sharedIndex(a))
    },
    suggestedUsers() {
      return this.input
        ? this.users
            .filter(
              user => user.type == "suggested" && !this.isCurrentUser(user)
            )
            .filter(
              user =>
                user.label
                  .toLowerCase()
                  .includes(this.input.toLowerCase().trim()) ||
                user.value
                  .toLowerCase()
                  .includes(this.input.toLowerCase().trim())
            )
            .sort((a, b) => a.value.localeCompare(b.value))
        : []
    },
    isSuggestedUsersVisible() {
      return this.input.trim() != "" && this.showSuggestedUsers
    }
  },
  methods: {
    hideSuggestedUsers() {
      this.showSuggestedUsers = false
    },
    isCurrentUser(user) {
      return user.value == this.$pigeonline.getUserEmail()
    },
    sharedIndex(user) {
      return this.shared.indexOf(user.id) == -1
        ? this.shared.indexOf(user.value)
        : this.shared.indexOf(user.id)
    },
    clean(email) {
      return String(email)
        .toLowerCase()
        .trim()
    },
    isValidEmail(email) {
      // eslint-disable-next-line no-useless-escape
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.clean(email))
    },
    isDuplicateEmail(email) {
      return this.users.filter(user => user.value == email).length > 0
    },
    resetInput() {
      this.input = ""
    },
    addSuggestedUser(user) {
      user["type"] = "selected"
      user["notify"] = true
      user["selectIndex"] = this.selectIndex++
      this.resetInput()
      this.$parent.$emit("changed", true)
    },
    addNewUser() {
      const email = this.clean(this.input)
      if (!this.isValidEmail(email)) {
        alert("Please enter a valid email.")
        this.$refs["add-user-input"].focus()
        return
      }

      if (!this.isDuplicateEmail(email)) {
        // create new user and add it to user list with type 'selected'
        let user = {
          id: email,
          label: email,
          value: email,
          type: "selected",
          notify: true,
          notifyDisabled: true,
          selectIndex: this.selectIndex++
        }
        this.$emit("addNewUser", user)
      } else {
        alert("This user has already been added.")
        this.$refs["add-user-input"].focus()
        return
      }
      this.resetInput()
      this.$parent.$emit("changed", true)
    },
    removeUser(user) {
      user["type"] = "suggested"
      this.$parent.$emit("changed", true)
    }
  },
  watch: {
    shared: {
      immediate: true,
      handler: function(val) {
        if (val) {
          this.users = [...this.shared]
        }
      }
    }
  }
}
</script>
