<template>
  <div id="project-analysis-text-theme">
    <section
      class="theme__container"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
    >
      <h3 class="theme__title"><VisibleText>Saved themes</VisibleText></h3>

      <button
        class="theme__auto-generate"
        @click="isAutoGenerateModalVisible = true"
      >
        <VisibleText>Auto-generate themes</VisibleText>
      </button>

      <!----- NO SAVED THEMES MESSAGE ---->
      <MessageBlock
        message-type="information"
        title="No themes found"
        v-if="isThemeListEmpty"
      >
        Auto-generate a theme or create a new one.
      </MessageBlock>

      <!----- THEME LIST ---->
      <div class="theme__list" v-else>
        <div
          class="theme__list-row-wrapper"
          v-for="(theme, index) in themes"
          :key="index"
        >
          <div class="theme__list-row">
            <span
              :class="[
                'theme__list-row-title',
                { selected: index === selectedThemeIndex }
              ]"
            >
              <InputEditable
                :value="theme.name"
                :inputClass="'theme__list-row-title-input'"
                :select="() => toggleTheme(index)"
                :uuid="`saved-themes-${index}`"
                @update="updateThemeTitle(index, $event)"
              />
            </span>
            <div class="theme__list-row-info">
              <details class="theme__list-row-info-keywords disclosure">
                <summary>
                  {{ theme.keywords.length }}
                  <VisibleText>keywords</VisibleText>
                </summary>
                <span
                  class="disclosure-content"
                  v-if="theme.keywords.length > 0"
                  v-html="formatInfo(theme.keywords)"
                >
                </span>
              </details>
              <details class="theme__list-row-info-excerpts disclosure">
                <summary>
                  {{ theme.excerpts.length }}
                  <VisibleText>excerpts</VisibleText>
                </summary>
                <span
                  class="disclosure-content"
                  v-if="theme.excerpts.length > 0"
                  v-html="formatInfo(theme.excerpts, 'list')"
                >
                </span>
              </details>
              <details class="theme__list-row-info-notes disclosure">
                <summary>
                  {{ theme.notes.length }}
                  <VisibleText>notes</VisibleText>
                </summary>
                <span
                  class="disclosure-content"
                  v-if="theme.notes.length > 0"
                  v-html="formatInfo(theme.notes, 'list')"
                >
                </span>
              </details>
            </div>
            <div class="theme__list-row-coverage">
              <span class="theme__list-row-coverage-title">
                <VisibleText>Coverage</VisibleText>
              </span>
              <span class="theme__list-row-coverage-percent"
                >{{ theme.coverage.percent | toFixed(1) }}%</span
              >
              <span class="theme__list-row-coverage-num-responses">
                {{
                  `(${theme.coverage.num_responses} / ${textResponses.length}`
                }})
              </span>
            </div>
            <SentimentCharts
              :single-stack-data="
                generateSingleStackData(
                  theme.sentiment.keywords_sentiment,
                  theme.name
                )
              "
              :pos-neg-data="
                generatePosNegData(
                  theme.sentiment.respondent_sentiment,
                  theme.name
                )
              "
            />
          </div>
          <div class="theme__list-options">
            <button
              :class="[
                'theme__list-options-btn-show',
                { selected: index === selectedThemeIndex }
              ]"
              @click="toggleTheme(index)"
            >
              {{ index === selectedThemeIndex ? "Show all" : "Show this only" }}
            </button>
          </div>
        </div>
      </div>

      <!----- THEME ACTIONS ---->
      <div class="theme__helper-container">
        <p class="theme__helper-text"></p>
        <div class="theme__helper-options">
          <button
            type="button"
            class="theme__helper-options-btn"
            @click="isEditModalVisible = true"
          >
            <SvgIconDecorative icon="edit-alt" />
            Edit themes
          </button>
          <button
            type="button"
            class="theme__helper-options-btn"
            @click="isBannedKeywordsModalVisible = true"
          >
            <SvgIconDecorative icon="ban" />
            Show banned keywords
          </button>
        </div>
      </div>
    </section>

    <!----- MODALS---->
    <BannedKeywordsModal
      :show="isBannedKeywordsModalVisible"
      @closeModal="closeModal"
      v-if="!isThemeListEmpty"
    />
    <ThemesEditModal :show="isEditModalVisible" @closeModal="closeModal" />
    <ThemesAutoGenerateModal
      v-if="isAutoGenerateModalVisible"
      :show="isAutoGenerateModalVisible"
      @closeModal="closeModalAutoGenerate"
    />
  </div>
</template>

<script>
// Components
import InputEditable from "./ProjectAnalysisTextMain/UI/InputEditable.vue"
import SentimentCharts from "./ProjectAnalysisTextMain/Components/SentimentCharts.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import { PigeonDocNodeModel } from "@pigeonline/pigeondoc"

// Modal
import ThemesAutoGenerateModal from "./ProjectAnalysisTextMain/Modals/ThemesAutoGenerate.vue"
import ThemesEditModal from "./ProjectAnalysisTextMain/Modals/ThemesEdit.vue"
import BannedKeywordsModal from "./ProjectAnalysisTextMain/Modals/BannedKeywords.vue"

// Mixins
import AnalysisTextMixin from "@/utils/mixins/analysisTextMixin.js"

export default {
  name: "ProjectAnalysisTextTheme",
  mixins: [AnalysisTextMixin],
  components: {
    InputEditable,
    ThemesAutoGenerateModal,
    ThemesEditModal,
    BannedKeywordsModal,
    SentimentCharts,
    SvgIconDecorative,
    MessageBlock
  },
  data() {
    return {
      isEditModalVisible: false,
      isAutoGenerateModalVisible: false,
      isBannedKeywordsModalVisible: false
    }
  },
  computed: {
    isActive() {
      return this.currentState
    },

    checkedValue: {
      get() {
        return this.defaultState
      },
      set(newValue) {
        this.currentState = newValue
      }
    },
    isThemeListEmpty: function() {
      return (
        this.themes && Array.isArray(this.themes) && this.themes.length == 0
      )
    }
  },
  methods: {
    truncate(text, length) {
      return text.length > length ? text.substring(0, length) + "..." : text
    },
    formatInfo(content, type = "text") {
      if (type == "list") {
        const maxItems = 3
        const displayItems = content.slice(0, maxItems)
        const hiddenItems = content.slice(maxItems, content.length)
        let listContent = displayItems
          .map(el => `<li>${this.truncate(el, 40)}</li>`)
          .join("")
        if (hiddenItems.length > 0) {
          listContent += `<li>${hiddenItems.length} more items</li>`
        }
        return `<ul>${listContent}</ul>`
      }
      return this.truncate(
        content.map(el => (el.includes(",") ? `"${el}"` : el)).join(", "),
        100
      )
    },
    toggleTheme(index) {
      if (index === this.selectedThemeIndex) {
        this.setSelectedThemeIndex(-1)
      } else {
        this.setSelectedThemeIndex(index)
      }
    },
    closeModal() {
      this.isEditModalVisible = false
      this.isBannedKeywordsModalVisible = false
    },
    closeModalAutoGenerate() {
      this.isAutoGenerateModalVisible = false
    },

    // sentiment chart methods
    calcRespondentSentimentTotals(data) {
      return data.reduce(
        (totals, data) => {
          if (data > -0.05 && data < 0.05) totals.neu++
          if (data >= 0.05) totals.pos++
          if (data <= -0.05) totals.neg++
          return totals
        },
        { neu: 0, pos: 0, neg: 0 }
      )
    },
    generateSingleStackData: function(data, themeName) {
      if (!data || typeof data == "undefined") data = {}
      const description = `The chart represents sentiment of keywords in \
      theme <em>${themeName}</em>. It shows ${data.neg_percent}% are negative, \
      ${data.neu_percent}% are neutral, and ${data.pos_percent}% are positive.`
      return new PigeonDocNodeModel({
        type: "horizontalSingleStackBarChart",
        content: {
          data: [
            {
              label: "negative",
              value: data.neg_percent || 0,
              fillColor: "#cc6677"
            },
            {
              label: "neutral",
              value: data.neu_percent || 0,
              fillColor: "#eee"
            },
            {
              label: "positive",
              value: data.pos_percent || 0,
              fillColor: "#117733"
            }
          ]
        },
        meta: {
          axisLabels: {
            x: "score",
            y: "sentiment"
          },
          description: description
        }
      })
    },
    generatePosNegData: function(data, themeName) {
      if (!data || !Array.isArray(data)) data = []
      const SCALE_DOWN_FACTOR = Math.ceil(
        Math.max(...this.themes.map(theme => theme.coverage.num_responses)) / 30
      )
      const totals = this.calcRespondentSentimentTotals(data)
      const description = `The chart shows sentiments for each \
      respondent in theme <em>${themeName}</em>. It shows ${totals.neg} responses have a \
      negative sentiment, ${totals.neu} are netural, and ${totals.pos} are positive.`
      return new PigeonDocNodeModel({
        type: "positiveNegativeBarChartSmall",
        content: {
          data: data.filter((_, index) => index % SCALE_DOWN_FACTOR == 0),
          meta: {
            positiveColour: "#117733",
            negativeColour: "#cc6677",
            neutralColour: "#eee"
          }
        },
        meta: {
          description: description
        }
      })
    }
  }
}
</script>
