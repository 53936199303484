<template>
  <Modal
    class="project-analysis-text-modal"
    header-tag="h3"
    :uid="uid"
    :show="show"
    :reset-listener="resetListener"
    @close="closeModal"
  >
    <template #header class="modal-title">
      <VisibleText>Edit themes: Add </VisibleText>
      {{ type }}
    </template>
    <ThemeList
      :ref="themeListRef"
      :type="type"
      :text="text"
      :themes="themeList"
      :should-focus-last-theme="shouldFocusLastTheme"
      :should-auto-select-last-theme="shouldAutoSelectLastTheme"
      :key="updateKey"
      @update-theme-title="
        updateThemeTitle(themeList[$event.index], $event.title)
      "
      @updated="unsavedChanges = true"
    />
    <template #footer class="modal-footer">
      <div class="modal-footer__options">
        <button
          class="btn-default"
          role="button"
          @click.stop="save"
          :disabled="!unsavedChanges"
        >
          <VisibleText>Save changes</VisibleText>
        </button>
        <button
          class="btn-create btn-secondary"
          role="button"
          @click.stop="createTheme"
        >
          <VisibleText>Create new theme</VisibleText>
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
// Mixins
import ThemeListMixin from "../Mixins/themeListMixin.js"

export default {
  name: "ThemesAdd",
  mixins: [ThemeListMixin],
  props: {
    type: {
      default: () => "keyword",
      type: String
    },
    text: {
      default: () => "",
      type: String
    },
    autoCreateTheme: {
      default: () => false,
      type: Boolean
    },
    autoCreateThemeTitle: {
      default: () => "unnamed theme",
      type: String
    },
    autoCreateThemeKeywords: {
      default: () => [],
      type: Array
    },
    uid: {
      default: () => "themes add",
      type: String,
      required: true
    },
    show: {
      default: () => false,
      type: Boolean
    }
  },
  data() {
    return {
      isAutoCreateEnabled: true
    }
  },
  watch: {
    updateKey: function(val) {
      if (!val) return

      // auto create a theme
      // should be called only when auto create enabled
      // -- usually once per component update when autoCreateTheme = True
      if (this.isAutoCreateEnabled && this.autoCreateTheme) {
        // disable auto create to prevent re-creating the same theme again
        // when the component updates
        this.isAutoCreateEnabled = false
        this.createTheme(
          this.autoCreateThemeTitle,
          this.filterBannedKeywords(this.autoCreateThemeKeywords)
        )
      }
    },
    show: function(val) {
      if (!val) {
        // re-enable auto create mode on modal close
        this.isAutoCreateEnabled = true
      }
    }
  }
}
</script>
