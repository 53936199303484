<template>
  <DatasetLayout section-name="dataset-table-view" :title="dataset.name">
    <DatasetTableData
      class="dataset__section-group-content"
      uid="dataset-view"
      style="margin-top: 2rem"
      :read-only="true"
    />
  </DatasetLayout>
</template>

<script>
// Components
import DatasetLayout from "@/components/Layouts/DatasetLayout.vue"
import DatasetTableData from "@/components/Dataset/DatasetTable/DatasetTableData.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"

export default {
  name: "DatasetTableView",
  mixins: [DatasetDetailsMixin],
  components: {
    DatasetLayout,
    DatasetTableData
  },
  props: {
    readOnly: {
      default: () => false,
      type: Boolean
    }
  },
  async activated() {
    this.reloadBenchmarks()
  }
}
</script>
