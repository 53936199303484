<template>
  <ul class="dataset-segments__custom-list">
    <li
      v-for="tag in tags"
      :key="tag"
      class="dataset-segments__custom-list__item"
    >
      {{ tag }}
      <ButtonIconOnly
        class="button"
        icon="remove"
        :id="Date.now().toString()"
        @click-handler="$emit('remove', tag)"
      >
        remove tag
      </ButtonIconOnly>
    </li>
  </ul>
</template>

<script>
import ButtonIconOnly from "@/components/UI/Button/ButtonIconOnly.vue"

export default {
  name: "DatasetSegmentsCustomList",
  components: {
    ButtonIconOnly
  },
  props: {
    tags: {
      type: Array
    }
  }
}
</script>
