<template>
  <article id="project-analysis-text" v-if="dataset" style="position: relative">
    <Spinner
      :is-loading="showSpinner"
      :shouldFocusMessage="false"
      style="z-index: 10003; position: absolute;"
    />
    <h2 class="sr-only"><VisibleText>Text Analysis</VisibleText></h2>
    <MessageBlock
      message-type="information"
      title="No text responses"
      v-if="showNoTextResponsesMessage"
    >
      No text responses found. Please try a different dataset.
    </MessageBlock>

    <div
      class="analysis-text__select-language-input"
      v-if="selectedTextQuestion"
    >
      <label class="analysis-text__select-language-input-text"
        >Set algorithm language:</label
      >
      <template v-for="(langValue, langKey) in languageList">
        <input
          type="radio"
          :id="`lang__${langKey}`"
          :key="`lang__${langKey}`"
          :value="langKey"
          v-model="languagePicked"
        />
        <label :for="`lang__${langKey}`" :key="`lang-label__${langKey}`">{{
          langValue
        }}</label>
      </template>
    </div>
    <ProjectAnalysisTextTheme v-if="selectedTextQuestion" />
    <ProjectAnalysisTextMain v-if="selectedTextQuestion" />
  </article>
</template>

<script>
import { axios } from "@pigeonline/core"

// Components
import ProjectAnalysisTextTheme from "./ProjectAnalysisTextTheme.vue"
import ProjectAnalysisTextMain from "./ProjectAnalysisTextMain/ProjectAnalysisTextMain.vue"
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

// Mixins
import ProjectMixin from "@/utils/mixins/projectMixin.js"
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import AnalysisTextMixin from "@/utils/mixins/analysisTextMixin.js"

export default {
  name: "ProjectAnalysisText",
  mixins: [ProjectMixin, DatasetDetailsMixin, AnalysisTextMixin],
  components: {
    ProjectAnalysisTextMain,
    ProjectAnalysisTextTheme,
    MessageBlock
  },
  computed: {
    datasetId: function() {
      return this.dataset._id.$oid
    },
    showNoTextResponsesMessage: function() {
      return (
        this.textQuestions &&
        Array.isArray(this.textQuestions) &&
        this.textQuestions.length === 0 &&
        !this.showSpinner
      )
    }
  },
  data() {
    return {
      languageList: {
        "en-US,en": "English",
        "fr-FR,en": "French"
      },
      languagePicked: "en-US,en"
    }
  },
  async created() {
    this.setShowSpinner(true)

    const apiLanguage = window.localStorage.getItem("apiLanguage")
    if (apiLanguage && this.languageList[apiLanguage]) {
      this.languagePicked = apiLanguage
    }

    this.resetDataset()

    if (this.datasetId) {
      let clientQuestions = await this.fetchClientQuestions(this.datasetId)

      // fetch and set text questions
      this.setDatasetId(this.datasetId)
      if (clientQuestions && Array.isArray(clientQuestions)) {
        await this.setTextQuestions(
          clientQuestions.filter(c => c.approved_data_type === "OPEN_ENDED")
        )
      }

      const currProject = this.$store.getters["project/getProject"]

      // load state from project store obj
      const loadStateFromObj = (key, setMethod) => {
        if (currProject.textAnalysis && currProject.textAnalysis[key]) {
          setMethod(currProject.textAnalysis[key])
        }
      }
      loadStateFromObj("themes", this.setThemes)
      loadStateFromObj("bannedKeywords", this.setBannedKeywords)
      loadStateFromObj("bannedComments", this.setBannedComments)
      loadStateFromObj("pinnedComments", this.setPinnedComments)
      loadStateFromObj("hiddenComments", this.setHiddenComments)

      // HOTFIX: to prevent errors caused due to null field
      if (currProject.textAnalysis == null) {
        currProject.textAnalysis = {}
      }

      // ban keyword event handler
      this.$root.$on("ban-keyword", this.banKeyword)

      // select a text question
      if (this.textQuestions.length > 0) {
        await this.selectTextQuestion(this.textQuestions[0])
      }
    }

    this.setShowSpinner(false)
  },
  beforeDestroy() {
    this.$root.$off("ban-keyword", this.banKeyword)
  },
  watch: {
    languagePicked: async function(val) {
      if (!val) return

      // store language to localStorage
      window.localStorage.setItem("apiLanguage", val)

      // add global language param to api endpoints
      axios.interceptors.request.use(
        function(config) {
          if (!config.headers) config.headers = {}
          config.headers["Accept-Language"] = val
          return config
        },
        function(error) {
          return Promise.reject(error)
        }
      )

      // update text responses
      this.updateSelectedTextQuestionResponses()
    },
    selectedThemeIndex: async function(val) {
      this.setSelectedThemeResponses([])

      // handle null theme index
      if (val == -1) {
        this.setShowSpinner(true)
        setTimeout(
          (() => {
            this.setShowSpinner(false)
          }).bind(this),
          1000
        )
        return
      }

      this.setShowSpinner(true)
      this.setSelectedThemeResponses(await this.fetchThemeResponses())
      this.setShowSpinner(false)
    }
  }
}
</script>
