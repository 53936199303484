<template>
  <div>
    <article class="project-analysis-item__wrapper">
      <div class="project-analysis-item__header-wrapper">
        <h3 class="project-analysis-item__header-wrapper-title">
          {{ dependentVariableTitle }}
        </h3>
        <!--------------------------------------------
        |
        | ACTIONS
        |
        --------------------------------------------->
        <button
          class="project-analysis-item__header-wrapper-btn"
          @click="isSelectVarVisible = true"
          :disabled="statusDisableActionBtns"
        >
          <SvgIconDecorative
            class="project-analysis-item__header-wrapper-icon"
            icon="edit-alt"
          />
          <VisibleText>Modify Variables</VisibleText>
        </button>
        <button
          class="project-analysis-item__header-wrapper-btn"
          @click="save"
          :disabled="statusDisableActionBtns"
        >
          <SvgIconDecorative
            class="project-analysis-item__header-wrapper-icon"
            icon="report"
          />
          <VisibleText>Save To report</VisibleText>
        </button>
        <button
          class="project-analysis-item__header-wrapper-btn delete"
          @click="onClickDelete"
          :disabled="statusDisableActionBtns"
        >
          <SvgIconDecorative
            class="project-analysis-item__header-wrapper-icon-delete"
            icon="delete"
          />
          <VisibleText>Delete</VisibleText>
        </button>
      </div>

      <!--------------------------------------------
      |
      | CHARTS
      |
      --------------------------------------------->
      <DriversCharts
        :dependent-variable-title="dependentVariableTitle"
        :chart-data="chartData"
        :isLoading="isLoadingChartData"
      />
    </article>
    <!--------------------------------------------
    |
    | VARIABLES MODAL
    |
    --------------------------------------------->
    <DriversVariables
      :dependent-variable-title="dependentVariableQuestionTitle"
      :dependent-questions="dependentQuestions"
      :independent-questions="independentQuestions"
      :show-drivers-variables="isSelectVarVisible"
      @modify-vars="modifyVars"
      @close-drivers-variables="isSelectVarVisible = false"
    />
  </div>
</template>

<script>
// Components
import DriversCharts from "./ProjectAnalysisDriversCharts.vue"
import DriversVariables from "./ProjectAnalysisDriversVariables.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Mixins
import DatasetDetailsMixin from "@/utils/mixins/datasetDetailsMixin.js"
import DriversAnalysisMixin from "@/utils/mixins/driversAnalysisMixin.js"
import ProjectMixin from "@/utils/mixins/projectMixin.js"
import ProjectReportMixin from "@/utils/mixins/projectReportMixin.js"

// Helpers
import moment from "moment"

export default {
  name: "ProjectAnalysisDriversItem",
  mixins: [
    DatasetDetailsMixin,
    DriversAnalysisMixin,
    ProjectMixin,
    ProjectReportMixin
  ],
  props: {
    driversAnalysisObject: {
      default: () => {},
      type: Object,
      required: true
    }
  },
  components: {
    DriversCharts,
    DriversVariables,
    SvgIconDecorative
  },
  data() {
    return {
      dependentVariableTitle: "overall satisfaction",
      dependentQuestions: null, /// used to build var table
      independentQuestions: null, // used to build var table
      isSelectVarVisible: false,
      statusCanRunDrivers: true,
      selectedDependentVars: null,
      selectedIndependentVars: null,
      isDatasetChanged: false
    }
  },
  async created() {
    this.setVariables(
      this.driversAnalysisObject.dependent_variable_id,
      this.driversAnalysisObject.independent_variables_ids,
      this.driversAnalysisObject.dependent_variable_title
    )
    // backwards compatability: if no chart data fetch it
    if (!this.driversAnalysisObject.chart_data) {
      await this.setChartData(
        this.driversAnalysisObject.dependent_variable_id,
        this.driversAnalysisObject.independent_variables_ids
      )
      this.saveDriversToProject(this.driversAnalysisObject.uuid)
    } else this.chartData = this.driversAnalysisObject.chart_data
  },
  computed: {
    statusDisableActionBtns() {
      return (
        ["notEnoughScale", "noCmt", "orgNotVerified"].includes(
          this.statusName
        ) ||
        this.isLoadingChartData ||
        this.propReadOnly
      )
    },
    dependentVariableQuestionTitle() {
      if (Object.keys(this.matchesDetails).includes(this.selectedDependentVars))
        return this.matchesDetails[this.selectedDependentVars]
          .global_question_title
      else {
        const question = this.clientQuestions.find(
          q => q._id.$oid === this.selectedDependentVars
        )
        return question.question_title
      }
    }
  },
  methods: {
    /**
     * checks if dataset has changed since drivers analysis was last run
     * TODO: finish this - dataset needs ability to save last modified
     */
    checkLastModified() {
      // backwards compatibility: if no last modified
      if (!this.dataset.last_modified_on) return
      const lastModifiedAnalysis = this.driversAnalysisObject.last_modified_on
      const lastModifiedDataset = this.dataset.last_modified_on
      this.isDatasetChanged = Boolean(
        lastModifiedDataset > lastModifiedAnalysis
      )
    },
    filterClientQuestions(questionIDs) {
      return this.clientQuestions.filter(q => questionIDs.includes(q._id.$oid))
    },
    async deleteDriver() {
      const indx = this.project.driversAnalysis.findIndex(
        d => d.last_modified_on == this.driversAnalysisObject.last_modified_on
      )
      if (indx < 0) return
      this.project.driversAnalysis.splice(indx, 1)
      await this.saveProject(this.project)
      this.$emit("toggle-rerender")
    },
    /**
     * backwards compatability: project.driversAnalysis updated from object
     * to array, replace
     */
    async handleBackwardsCompatibility() {
      if (!this.project.driversAnalysis) {
        this.project.driversAnalysis = []
        await this.saveProject(this.project)
      } else if (
        !Array.isArray(this.project.driversAnalysis) &&
        Object.keys(this.project.driversAnalysis).length > 0
      ) {
        this.project.driversAnalysis = new Array(this.driversAnalysisObject)
        await this.saveProject(this.project)
      }
    },
    /************
     *
     * Setters
     *
     ***********/
    setVariables(dependentVar, independentVars, dependentVarTitle) {
      this.dependentVariableTitle = dependentVarTitle
      this.selectedDependentVars = dependentVar
      this.dependentQuestions = this.filterClientQuestions(dependentVar)
      this.selectedIndependentVars = independentVars
      this.independentQuestions = this.filterClientQuestions(independentVars)
    },
    async setChartData(dependentVar, independentVars) {
      this.isLoadingChartData = true
      this.chartData = await this.fetchChartData(
        this.dataset._id.$oid,
        dependentVar,
        independentVars
      )
      this.isLoadingChartData = false
    },
    /************
     *
     * Saving methods
     *
     ***********/
    async modifyVars(dependent, independent) {
      this.statusCanRunDrivers = true
      this.setVariables(
        dependent,
        independent,
        this.getDependentVarTitle(dependent)
      )
      await this.setChartData(dependent, independent)
      this.saveDriversToProject(this.driversAnalysisObject.uuid)
    },
    async save() {
      if (this.propReadOnly) return
      const uuid = await this.saveDriversToReport()
      this.project.updateStatus("analysisCompleted")
      this.saveDriversToProject(uuid)
    },
    async saveDriversToProject(uuid = -1) {
      if (this.propReadOnly) return
      const newObject = {
        dependent_variable_id: this.selectedDependentVars,
        dependent_variable_title: this.dependentVariableTitle,
        independent_variables_ids: this.selectedIndependentVars,
        chart_data: this.chartData,
        last_modified_on: moment().valueOf(),
        uuid: uuid
      }
      await this.handleBackwardsCompatibility()

      // find it in currently saved drivers, if there update, if not add it
      const indx = this.project.driversAnalysis.findIndex(
        d => d.last_modified_on == this.driversAnalysisObject.last_modified_on
      )
      if (indx > -1) this.project.driversAnalysis.splice(indx, 1, newObject)
      else this.project.driversAnalysis.unshift(newObject)

      await this.saveProject(this.project)
      this.$emit("toggle-rerender")
    },
    async saveDriversToReport() {
      try {
        this.isLoading = true
        const organization = this.$store.getters["user/getOrganization"]
          ? this.$store.getters["user/getOrganization"].organization_name
          : ""

        await this.createReportIfEmpty(
          this.$options.filters.formatDate(this.project.last_modified_on),
          this.dataset.name,
          organization
        )
        const uuid = await this.updateReportWithDrivers(
          this.driversAnalysisObject.uuid,
          this.project.report,
          this.chartData,
          this.dependentVariableTitle
        )
        this.isLoading = false
        this.onSuccessfulSave()
        return uuid
      } catch (e) {
        throw new Error(
          "ProjectAnalysisDrivers:saveDriversToReport " + e.message
        )
      }
    },
    /************
     *
     * Confirmation modals
     *
     ***********/
    onClickDelete() {
      const indx = this.project.driversAnalysis.findIndex(
        d => d.last_modified_on == this.driversAnalysisObject.last_modified_on
      )
      this.setConfirmText({
        btn: "delete drivers",
        title: "delete drivers analysis?"
      })
      this.setConfirmType("delete")
      this.setConfirmSourceComponent("drivers")
      this.setConfirmTarget(indx)
      this.setConfirmIsVisible(indx > -1)
    },
    onSuccessfulSave() {
      this.setConfirmText({
        btn: "okay",
        title: "Drivers Saved",
        message: "Drivers successfully saved to report."
      })
      this.setConfirmType("success")
      this.setConfirmSourceComponent("drivers")
      this.setConfirmIsVisible(true)
    }
  },
  watch: {
    confirmStatus: async function(val) {
      const indx = this.project.driversAnalysis.findIndex(
        d => d.last_modified_on == this.driversAnalysisObject.last_modified_on
      )
      if (
        val &&
        this.confirmSourceComponent === "drivers" &&
        this.confirmType === "delete" &&
        this.confirmTarget === indx
      ) {
        await this.deleteDriver()
      }
    }
  }
}
</script>
