<template>
  <article id="project-analysis-performance" class="performance">
    <!--------------------------------------------
    |
    | HEADER
    |
    --------------------------------------------->
    <div class="project-analysis__header">
      <h2 class="project-analysis__header-title">
        <VisibleText>Performance Analysis</VisibleText>
      </h2>
      <button
        class="project-analysis__header-add-btn"
        @click="showCreateModal"
        :disabled="propReadOnly"
      >
        <VisibleText>Add New Performance Analysis</VisibleText>
      </button>
    </div>

    <!--------------------------------------------
    |
    | DESCRIPTIONS
    |
    --------------------------------------------->
    <p class="performance__desc">
      <VisibleText
        >Select a range of questions to conduct performance analysis on.
        Performance analysis will show you how well these questions score, and
        will find unique stories by finding outliers and difference in
        performance across segments.
      </VisibleText>
    </p>

    <!--------------------------------------------
    |
    | WARNINGS AND INFO MESSAGES
    |
    --------------------------------------------->
    <MessageBlock
      class="performance__no-valid-questions"
      message-type="information"
      title="No valid questions"
      v-if="noValidQuestions"
    >
      Sorry, there are no valid questions present in the dataset. Currently,
      only scale and open-ended questions are supported.
    </MessageBlock>

    <!--------------------------------------------
    |
    | PERFROMANCE ANALYSIS
    |
    --------------------------------------------->
    <template v-else>
      <PerformanceAnalysis
        v-for="(analysis, index) in project.performanceAnalysis"
        :key="`performance-analysis-${analysis.id}`"
        :analysisData="analysis"
        :questionsData="clientQuestionsPreprocessed"
        :index="index"
      />
    </template>

    <!--------------------------------------------
    |
    | MODALS
    |
    --------------------------------------------->
    <CreateNewAnalysisModal
      :show="isCreateModalVisible"
      :data="clientQuestionsPreprocessed"
      @close-modal="closeModal"
      @confirmSelection="generatePerformanceAnalysis($event)"
    />
  </article>
</template>

<script>
// Components
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"
import PerformanceAnalysis from "./Components/PerformanceAnalysis.vue"
import CreateNewAnalysisModal from "./Modals/CreateNewAnalysis.vue"

// Mixins
import PerformanceAnalysisMixin from "@/utils/mixins/performanceAnalysisMixin.js"

export default {
  name: "ProjectAnalysisPerformance",
  mixins: [PerformanceAnalysisMixin],
  components: {
    MessageBlock,
    PerformanceAnalysis,
    CreateNewAnalysisModal
  },
  data() {
    return {
      clientQuestionsPreprocessed: [],
      isCreateModalVisible: false,

      // prevent saving to report untill we know dataset status
      isDatasetStatusLoading: false
    }
  },
  computed: {
    noValidQuestions: function() {
      return (
        this.clientQuestionsPreprocessed.filter(
          el =>
            el.approved_data_type &&
            (el.approved_data_type.match(/(SCALE_\d+_TO_\d+)+/) ||
              el.approved_data_type === "OPEN_ENDED")
        ).length === 0
      )
    }
  },
  created() {
    // setup delete modal details
    this.$store.dispatch("confirm/setConfirmText", {
      btn: "delete analysis",
      title: "delete performance analysis?"
    })
    this.$store.dispatch("confirm/setConfirmType", "delete")
    this.$store.dispatch("confirm/setConfirmSourceComponent", "performance")
  },
  mounted() {
    this.clientQuestionsPreprocessed = this.preprocessClientQuestions()

    if (
      !this.project.performanceAnalysis ||
      !Array.isArray(this.project.performanceAnalysis)
    ) {
      this.project.performanceAnalysis = []
    }

    // attach a random id to each analysis object
    this.project.performanceAnalysis.map(
      function(el) {
        if (!el.id) el.id = this.generateRandomId()
        if (!el.last_modified_on) el.last_modified_on = this.getCurrTimestamp()
        el.isFetching = false
        this.mapClientQuestionsToBenchmarkGlobalQuestions(el.summary_table)
      }.bind(this)
    )

    // auto create a performance analysis if empty
    if (this.project.performanceAnalysis.length == 0) {
      this.autoCreatePerformanceAnalysis()
    }
  },
  methods: {
    /* Utils */
    generateRandomId() {
      return (Math.random() * Date.now()).toFixed(0)
    },

    /* Modal methods */
    showCreateModal() {
      this.isCreateModalVisible = true
    },
    closeModal() {
      this.isCreateModalVisible = false
    },

    /* Component methods */
    preprocessClientQuestions() {
      return this.deepCloneObj(this.clientQuestions).map(
        function(el) {
          el.id = el._id.$oid
          if (el.question_text.trim() === "") {
            // fallback to question_title if question_text empty
            el.question_text = el.question_title
          }

          // check if the client question has a global benchmark question
          if (
            el.benchmarked_global_question &&
            el.benchmarked_global_question.selected
          ) {
            const temp = this.globalQuestions.find(
              g => g._id.$oid == el.benchmarked_global_question.selected
            )
            if (temp && temp.question_title) {
              // update question_text to global benchmark question_title
              el.question_text = temp.question_title
            }
          }

          el.options = {
            selected: false,
            disabled: false
          }
          return el
        }.bind(this)
      )
    },
    async generatePerformanceAnalysis(selected) {
      this.closeModal()
      try {
        // add new performance placeholder
        this.project.performanceAnalysis.unshift({
          id: this.generateRandomId(),
          last_modified_on: this.getCurrTimestamp(),
          isFetching: true
        })
        const response = await this.fetchPerformanceAnalysis(
          this.dataset._id.$oid,
          selected.map(el => el[0]), // el: [id, question_title]
          []
        )
        if (response && typeof response === "object") {
          this.mapClientQuestionsToBenchmarkGlobalQuestions(
            response.summary_table
          )
          Object.assign(this.project.performanceAnalysis[0], response, {
            isFetching: false
          })
          await this.saveProject(this.project)
        } else {
          throw new Error("Invalid performance response.")
        }
      } catch (e) {
        alert(this.DEFAULTS.apiErrorMessage)

        // remove newly pushed performance
        this.project.performanceAnalysis.pop(0)
        throw new Error(
          "ProjectAnalysisPerformance:generateAnalysisFromSelection:: " +
            e.message
        )
      }
    },
    mapClientQuestionsToBenchmarkGlobalQuestions(summaryTable) {
      for (let key in summaryTable) {
        let clientQuestion = this.clientQuestionsPreprocessed.find(
          q => q.id == key
        )
        summaryTable[key].client_question_analysis.question_text =
          clientQuestion.question_text
      }
    },
    async autoCreatePerformanceAnalysis() {
      const mode = function(arr) {
        return arr
          .sort(
            (a, b) =>
              arr.filter(v => v === a).length - arr.filter(v => v === b).length
          )
          .pop()
      }
      const mostCommonDataType = mode(
        this.clientQuestionsPreprocessed
          .filter(
            q =>
              q.approved_data_type &&
              (q.approved_data_type.match(/(SCALE_\d+_TO_\d+)+/) ||
                q.approved_data_type === "OPEN_ENDED")
          )
          .map(q => q.approved_data_type)
      )
      let selected = this.clientQuestionsPreprocessed
        .filter(
          q =>
            q.approved_data_type && q.approved_data_type === mostCommonDataType
        )
        .map(q => [q.id, q.question_title])
      if (selected.length === 0) return
      await this.generatePerformanceAnalysis(selected)
    }
  }
}
</script>
