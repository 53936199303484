<template>
  <div class="performance-types">
    <label class="performance-types__label" for="performance-type-radio">
      Performance type:
    </label>
    <fieldset
      class="performance-types__radio"
      name="performance-type-radio"
      v-for="(value, key) in types"
      :key="`radio-group-${key}`"
    >
      <input
        class="performance-types__radio-input"
        type="radio"
        :id="key"
        :key="`radio-group-input-${key}`"
        :value="value.id"
        v-model="selected"
        name="performance-type-radio-input"
      />
      <label
        class="performance-types__radio-label"
        :for="key"
        :key="`radio-group-label-${key}`"
        >{{ value.label }}</label
      >
    </fieldset>
    <div class="performance-types__example" v-if="selected">
      <span
        class="performance-types__label"
        for="performance-types-example-text"
      >
        Example:
      </span>
      <p
        class="performance-types__example-text"
        name="performance-types-example-text"
      >
        Overall satisfaction: {{ exampleTextSelected }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PerformanceTypes",
  props: {
    selectedType: {
      type: String
    }
  },
  data() {
    return {
      selected: null,
      types: {
        percentage: {
          id: "percentage",
          label: "Percentage",
          exampleText: "23%"
        },
        scaleAverage: {
          id: "scale-average",
          label: "Scale Average",
          exampleText: "3.56/5"
        },
        topN: {
          id: "top-n",
          label: "Top-N",
          exampleText: "11%"
        }
      }
    }
  },
  computed: {
    exampleTextSelected: function() {
      return Object.values(this.types).find(el => el.id == this.selected)
        .exampleText
    }
  },
  mounted() {
    this.selected = this.selectedType
  },
  watch: {
    selected: function(val) {
      if (!val || val == this.selectedType) return
      this.$emit("updated", val)
    }
  }
}
</script>
