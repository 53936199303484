// Services
import DatasetsService from "@/services/datasetsService.js"

// consts
import {
  TYPE_TO_READABLE,
  SCALE_TYPE_TO_READABLE
} from "@/utils/consts/constsDataTypes.js"

export default {
  data() {
    return {
      DATASETS_SERVICE: new DatasetsService(this.$pigeonline),
      // readable / UI friendly version of data types
      TYPE_TO_READABLE: TYPE_TO_READABLE,
      SCALE_TYPE_TO_READABLE: SCALE_TYPE_TO_READABLE
    }
  },
  methods: {
    cleanAllValues(uniqueValues) {
      return uniqueValues.reduce((values, val) => {
        val.is_valid = true
        values.push(val)
        return values
      }, [])
    },
    cleanValuesForScale(valid, invalid) {
      valid.forEach(val => {
        val.is_valid = true
      })
      invalid.forEach(val => {
        val.is_valid = false
      })
      return [...valid, ...invalid]
    },
    getValidScales(dataType) {
      let valid = []
      switch (dataType) {
        case "SCALE_1_TO_5":
          valid = [1, 2, 3, 4, 5]
          break
        case "SCALE_1_TO_3":
          valid = [1, 2, 3]
          break
        case "SCALE_1_TO_7":
          valid = [1, 2, 3, 4, 5, 6, 7]
          break
        case "SCALE_0_TO_11":
          valid = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
          break
      }
      return valid
    },
    /**
     * When type is text (OPEN_ENDED) a new field "all_responses"
     * is created in the API call. We need to call PUT to update the whole client
     * question object.
     * @param {Object} clientQuestion to edit
     * @param {Object} data fields to update
     */
    async handleTextType(clientQuestion, data) {
      try {
        let copyClientQuestion = { ...clientQuestion }
        copyClientQuestion.approved_data_type = data.approved_data_type
        copyClientQuestion.scale_range = data.scale_range
        copyClientQuestion.unique_values = data.unique_values

        await this.DATASETS_SERVICE.editClientQuestion(
          copyClientQuestion._id.$oid,
          copyClientQuestion
        )
      } catch (e) {
        throw new Error("DatasetTableDataType " + e.message)
      }
    },
    /**
     * Clean values if it is scale, cat, or open ended
     * other types are not cleaned.
     */
    async saveDataType(clientQuestion, dataType) {
      if (clientQuestion.approved_data_type === dataType) return

      let scaleRange = null,
        uniqueValues = clientQuestion.unique_values

      if (Object.keys(this.SCALE_TYPE_TO_READABLE).includes(dataType)) {
        const vals = this.getValidScales(dataType)
        const valid = clientQuestion.unique_values.filter(item =>
          vals.includes(parseInt(item.value, 10))
        )
        const invalid = clientQuestion.unique_values.filter(
          item => !vals.includes(parseInt(item.value, 10))
        )
        scaleRange = this.SCALE_TYPE_TO_READABLE[dataType]
        uniqueValues = this.cleanValuesForScale(valid, invalid)
      }

      if (Object.keys(this.TYPE_TO_READABLE).includes(dataType)) {
        scaleRange = null
        uniqueValues = this.cleanAllValues(clientQuestion.unique_values)
      }

      const data = {
        approved_data_type: dataType,
        scale_range: scaleRange,
        unique_values: uniqueValues
      }

      if (dataType === "OPEN_ENDED") {
        await this.handleTextType(clientQuestion, data)
        return
      }

      try {
        const updatedClientQ = await this.DATASETS_SERVICE.updateClientQuestion(
          clientQuestion._id.$oid,
          data
        )
        this.$store.dispatch(
          "datasetDetails/updateClientQuestion",
          updatedClientQ.client_question_object
        )
      } catch (e) {
        throw new Error("DatasetTableDataType " + e.message)
      }
    }
  }
}
