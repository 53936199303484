<template>
  <MessageBlock class="banner-msg" message-type="warning">
    <template #description>
      <span class="desc">
        <VisibleText>
          You are currently using a demo verison. To access all features, please
        </VisibleText>
        <router-link :to="{ name: 'accountNextStepsAccountType' }">
          <VisibleText> connect to your organization </VisibleText>
        </router-link>
        ,
        <VisibleText>
          or to learn more on how to upgrade your account contact
        </VisibleText>
        <a
          href="mailto: michal.dziong@iccs-isac.org"
          v-if="$theme.theme.name === 'iccs'"
        >
          michal.dziong@iccs-isac.org
        </a>
        <a href="mailto: researchai@pigeonline.io" v-else>
          researchai@pigeonline.io
        </a>
      </span>
    </template>
  </MessageBlock>
</template>

<script>
import MessageBlock from "@/components/UI/Message/MessageBlock.vue"

export default {
  name: "AccountUpgradeMessage",
  components: {
    MessageBlock
  }
}
</script>
