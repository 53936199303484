import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters("user", {
      profile: "getProfile"
    }),
    isDemoUser: function() {
      if (!this.profile) return false
      const group_tag = this.profile.group_tag
      return typeof group_tag === "string"
        ? group_tag === "demo_user"
        : group_tag.includes("demo_user")
    }
  }
}
