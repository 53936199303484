// Components
import SelectedTextModal from "../Modals/ThemesAdd.vue"

export default {
  data() {
    return {
      selectedText: "",
      isSelectedTextModalVisible: false,
      selectedTextModalType: "keyword"
    }
  },
  components: {
    SelectedTextModal
  },
  methods: {
    onExcerpt(text) {
      this.selectedText = text.trim()
      this.isSelectedTextModalVisible = true
      this.selectedTextModalType = "excerpt"
    },
    onKeyword(text) {
      this.selectedText = text.trim().toLowerCase()
      this.isSelectedTextModalVisible = true
      this.selectedTextModalType = "keyword"
    },
    onNote(text) {
      alert("NOTE: " + text)
    },
    onBan(text) {
      this.$root.$emit("ban-keyword", text)
    },
    closeModal() {
      this.isSelectedTextModalVisible = false
      // reset to default
      this.selectedTextModalType = "keyword"
    }
  }
}
