<template>
  <section id="project-analysis-text-main">
    <div class="main__navbar" :aria-hidden="modalOpen" :inert="modalOpen">
      <div class="main__navbar-item select-question">
        <label class="select-question__info-text">Showing results from </label>
        <select
          aria-label="select question"
          class="select-question__input select"
          id="select-question-text-analysis"
          v-model="selected"
          @change="selectQuestionChanged"
        >
          <option disabled value="">
            <VisibleText>Please select a question</VisibleText>
          </option>
          <option
            class="select-question__input-option"
            v-for="(question, index) in textQuestions"
            :key="`option-${index}`"
            :value="question._id.$oid"
            >{{ question.question_title }}</option
          >
        </select>
      </div>
      <div class="main__navbar-item current-view">
        <label class="current-view__info-text sr-only">Current view mode</label>
        <ul
          class="current-view__tabs-list"
          :aria-hidden="modalOpen"
          :inert="modalOpen"
        >
          <li
            class="current-view__tabs-list-item"
            v-for="item in tabs"
            :key="item"
          >
            <button
              :class="[
                'current-view__tabs-list-item-btn',
                {
                  active: activeTab === item
                }
              ]"
              :title="item"
              @click="activeTab = item"
            >
              {{ item }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <Search :aria-hidden="modalOpen" :inert="modalOpen" />
    <Accordion
      class="main__collapsible-chart"
      title-element-type="h3"
      icon-location="right"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
      :expand="false"
      @toggled="shouldRenderPosNegChart($event)"
    >
      <template #heading>
        <span class="main__collapsible-chart-title">
          <VisibleText>Summary of </VisibleText>
          <em class="chart-type">{{ activePosNegChartType }}</em>
          {{ `(${textResponsesFiltered.length}/${textResponses.length})` }}
        </span>
        <div class="main__collapsible-chart-filter-pills">
          <!-- REPLACE ICON BELOW WITH FILTER ICON -->
          <RoundedPill
            icon="analysis"
            :value="themes[selectedThemeIndex].name"
            :css-styles="{ backgroundColor: 'var(--secondary)' }"
            @remove="setSelectedThemeIndex(-1)"
            v-if="isThemeSelected"
          />
          <RoundedPill
            icon="search"
            :value="search.searchString"
            :css-styles="{
              backgroundColor: 'yellow',
              color: 'var(--base-colour)'
            }"
            @remove="resetSearch"
            v-if="isSearchMode"
          />
          <RoundedPill
            icon="list"
            value="Chart selection"
            :css-styles="{
              backgroundColor: '#56b4e9'
            }"
            @remove="setSelectedResponseIds([])"
            v-if="isVisFilterActivated"
          />
        </div>
      </template>
      <p class="main__collapsible-chart-heading">
        <VisibleText>Showing </VisibleText>
        {{ textResponsesFiltered.length }} <VisibleText> out of </VisibleText>
        {{ textResponses.length }} <VisibleText> responses.</VisibleText>
      </p>
      <PositiveNegativeBarChart
        ref="pos-neg-chart"
        class="main__collapsible-chart-content"
        v-if="barChartNode"
        :value="barChartNode"
        @change="selectionChanged"
      />
      <span
        class="info-msg__inline"
        v-if="!isActiveTabSentiment && !isThemeSelected"
      >
        Select a theme to view scores.
      </span>
    </Accordion>
    <Accordion
      class="main__tabs"
      title-element-type="h3"
      icon-location="right"
      :aria-hidden="modalOpen"
      :inert="modalOpen"
      :expand="true"
    >
      <template #heading>
        <span class="main__tabs-title">
          Responses
          {{ `(${textResponsesFiltered.length}/${textResponses.length})` }}
        </span>
        <div class="main__tabs-filter-pills">
          <!-- REPLACE ICON BELOW WITH FILTER ICON -->
          <RoundedPill
            icon="analysis"
            :value="themes[selectedThemeIndex].name"
            :css-styles="{ backgroundColor: 'var(--secondary)' }"
            @remove="setSelectedThemeIndex(-1)"
            v-if="isThemeSelected"
          />
          <RoundedPill
            icon="search"
            :value="search.searchString"
            :css-styles="{
              backgroundColor: 'yellow',
              color: 'var(--base-colour)'
            }"
            @remove="resetSearch"
            v-if="isSearchMode"
          />
          <RoundedPill
            icon="list"
            value="Chart selection"
            :css-styles="{
              backgroundColor: '#56b4e9'
            }"
            @remove="setSelectedResponseIds([])"
            v-if="isVisFilterActivated"
          />
        </div>
      </template>
      <div class="main__tabs-container">
        <template v-for="tab in tabs">
          <component
            :key="'tab-' + tab"
            :ref="'tab-' + tab"
            :is="tab"
            v-show="activeTab == tab"
          >
          </component>
        </template>
      </div>
    </Accordion>
  </section>
</template>

<script>
// Components
import Search from "./Components/Search.vue"
import Sentiment from "./ProjectAnalysisTextMainSentiment.vue"
import Themes from "./ProjectAnalysisTextMainThemes.vue"
import RoundedPill from "./UI/RoundedPill.vue"
import Accordion from "@/components/UI/Accordion.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"
import {
  PigeonDocNodeModel,
  PositiveNegativeBarChart
} from "@pigeonline/pigeondoc"

// Mixins
import AnalysisTextMixin from "@/utils/mixins/analysisTextMixin.js"
import SelectTextMixin from "./Mixins/selectTextMixin.js"

// Consts
const SENTIMENT_FILTER_BY = ["positive", "negative", "neutral"]
const SIMILARITY_SCORE_FILTER_BY = []

export default {
  name: "ProjectAnalysisTextMain",
  mixins: [AnalysisTextMixin, SelectTextMixin],
  components: {
    Search,
    Themes,
    Sentiment,
    RoundedPill,
    Accordion,
    SvgIconDecorative,
    PositiveNegativeBarChart
  },
  data() {
    return {
      tabs: ["Sentiment", "Themes"],
      selected: null, // selected client question
      isAutoGenerateModalVisible: false,
      isMounted: false
    }
  },
  computed: {
    activeTab: {
      get() {
        return this._activeTab
      },
      set(value) {
        this.setActiveTab(value)
      }
    },
    $activeTab() {
      if (!this.isMounted) return false
      return this.$refs["tab-" + this.activeTab][0]
    },
    activePosNegChartType() {
      return this.activeTab.toLowerCase() === "sentiment"
        ? "Response Sentiment"
        : "Response Theme Strength"
    },
    barChartResponsesAll() {
      return [
        ...this.$activeTab.textResponsesFilteredWithoutVisSelection
      ].map(el => this.mapResponseToBarChartNodeItem(el))
    },
    barChartResponsesFilteredAndSorted() {
      return [...this.$activeTab.textResponsesSorted].map(el =>
        this.mapResponseToBarChartNodeItem(el)
      )
    },
    barChartNode() {
      if (
        !this.isMounted ||
        this.textResponsesFiltered.length < 1 ||
        (!this.isActiveTabSentiment && !this.isThemeSelected)
      ) {
        return false
      }

      const title = this.activePosNegChartType
      const tableColumns = ["respondent", "response", "score", this.activeTab]
      const tableFilterByLabel =
        this.activeTab.toLowerCase() === "sentiment"
          ? SENTIMENT_FILTER_BY
          : SIMILARITY_SCORE_FILTER_BY

      return new PigeonDocNodeModel({
        type: "positiveNegativeBarChart",
        content: {
          title: `${title}`,
          data: this.barChartResponsesFilteredAndSorted,
          data__ALL: this.barChartResponsesAll
        },
        meta: {
          positiveColour: "#117733",
          negativeColour: "#cc6677",
          highlightColour: "#56b4e9",
          selectedColour: "#0072b2",
          table: {
            columns: tableColumns,
            filterByLabel: tableFilterByLabel
          }
        }
      })
    }
  },
  mounted() {
    this.isMounted = true

    // update selected text option
    if (this.selectedTextQuestion && this.selectedTextQuestion._id) {
      this.selected = this.selectedTextQuestion._id.$oid
    }
  },
  methods: {
    mapResponseToBarChartNodeItem(el) {
      return {
        id: el.id,
        responseID: el.idx,
        response: el.response,
        score: this.$activeTab.findResponseScore(el),
        label: this.getScoreLabel(el.scores)
      }
    },
    shouldRenderPosNegChart(bool) {
      if (!bool) return
      if (!this.$refs["pos-neg-chart"]) return
      this.$refs["pos-neg-chart"].renderChart()
    },
    selectionChanged(selection) {
      const ids = selection.map(el => el.id)
      const selectedResponses = this.selectedTextQuestionResponses.filter(el =>
        ids.includes(el.id)
      )
      this.setSelectedResponseIds(selectedResponses.map(el => el.id))
    },
    selectQuestionChanged() {
      if (this.selected !== this.selectedTextQuestion._id.$oid) {
        this.selectTextQuestion(
          this.textQuestions.find(c => c._id.$oid === this.selected)
        )
      }
    },
    getScoreLabel(scores) {
      if (this.activeTab.toLowerCase() === "sentiment") {
        if (scores.sentiment >= 0.05) return "positive"
        if (scores.sentiment <= -0.05) return "negative"
        if (scores.sentiment > -0.05 && scores.sentiment < 0.05)
          return "neutral"
      } else {
        return this.themes[this.selectedThemeIndex].name
      }
    }
  }
}
</script>
